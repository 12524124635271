import React, { Component } from 'react';
import { Navbar, Nav, Container, Image } from 'react-bootstrap';
// import { GoogleLogout } from 'react-google-login';
import { GetText } from '../../helper';
import '../../config/global';
import defPicProfile from '../../img/pic-profile-sq.gif';
import { ReactComponent as Gear } from "../../img/icons/gear.svg";

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[13];
var sticky = '';

export default class MenuMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      member: [],
      language: '',
      member_picture: defPicProfile,
      expired: 0
    }
    this.setLanguage = this.setLanguage.bind(this);
    this.checkToken = this.checkToken.bind(this);
    this.logOut = this.logOut.bind(this);
  }
  setLanguage(language) {
    this.setState({
      language: language
    }, () => {
      localStorage.setItem('language', language);
      var divs = document.getElementsByClassName('nav-language');
      for (var i = 0; i < divs.length; i++) {
        if (divs[i].id === 'nav-' + language.toLowerCase()) {
          divs[i].classList.add('active');
        } else {
          divs[i].classList.remove('active');
        }
      }
    });
    if (language !== localStorage.getItem('language')) {
      window.location.reload();
    }
  }

  showLogo = () => {
    if (window.pageYOffset > sticky) {
      document.getElementById('logo-menu').classList.remove('d-none');
    } else {
      document.getElementById('logo-menu').classList.add('d-none');
    }
  }

  checkToken() {
    fetch(global.config.urlLink+"/traininguserview/" + localStorage.getItem('doc_id'), {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey
      }
    })
    .then(response => {
      if(response.status === 200) {  return response.json(); } 
      else { console.log('Something happened wrong.'); }
    })
    .then(resJson => {
      if(resJson.values[0].content[0].maincontent !== undefined) {
        var token = resJson.values[0].content[0].maincontent.uniqueToken;
        if(token === undefined) { 
          this.logOut();
        } else if (token !== localStorage.getItem('token')) {
          this.logOut();
        }
      }  else {
        this.logOut();
      }
    });
  }
  
  logOut = () => {
    // console.log("logout");
    var dataText = '{"docid":"' + localStorage.getItem('doc_id') + '","form_id":"clear-token"}';
    var dataSend = JSON.parse(dataText);
    fetch(global.config.urlLink+"/clearToken/", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey
      },
      body: JSON.stringify(dataSend)
    }).then(response => {
      if(response.status === 200) {
        return response.json();
      }
    }).then(resultJson => {
      localStorage.clear();
      localStorage.setItem('logged_in', 0);
      localStorage.setItem('language',this.state.language);
      window.location.href = '/';
    });
   
  }

  componentDidMount() {
    /* ============ */
    var id = localStorage.getItem('doc_id');
    fetch(global.config.urlLink+"/traininguserview/" + id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey
      }
    })
    .then(response => {
      if(response.status === 200) {  return response.json(); } 
      else { console.log('Something happened wrong.'); }
    })
    .then(resJson => {
      if(resJson.values[0].content[0].maincontent !== undefined) {
        var member_picture = resJson.values[0].content[0].maincontent.memberPicture;
        if(member_picture === undefined || member_picture === "") { member_picture = defPicProfile; }
        this.setState({
          member: resJson.values[0].content[0].maincontent,
          member_picture: member_picture
        })
        if (localStorage.getItem('status') !== resJson.values[0].content[0].maincontent.memberPlan) {
          localStorage.setItem("status", resJson.values[0].content[0].maincontent.memberPlan);
        }
        localStorage.setItem("ulvl", resJson.values[0].content[0].maincontent.userLevel);
        
        var member_company = resJson.values[0].content[0].maincontent.memberCompany;
        if(member_company === undefined) { member_company = ""; }
        localStorage.setItem("mcomp", member_company);

        var token = resJson.values[0].content[0].maincontent.uniqueToken;
        
        if(token === undefined) { 
          this.logOut();
        } else if (token !== localStorage.getItem('token')) {
          this.logOut();
        }
        
      } else {
        this.logOut();
      }
    });
    /* ============ */
    var language = localStorage.getItem('language');
    if(language === undefined || language === "undefined" || language === "null" || language === null) { language = ""; }
    if (language !== "") {
      this.setLanguage(language);
    } else {
      fetch('https://ipapi.co/json/', {
        method: "GET",
      }).then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          console.log("Something happened wrong");
        }
      }).then((resultJson) => {
        var result = resultJson.country_name === 'Indonesia' ? 'Indonesian' : 'English';
        this.setLanguage(result);
      });
    }
    /* ============ */
    setInterval(() => {
      this.checkToken();
      var counter = this.state.expired + 1;
      if (counter >= 12 * 24) { //1 days
        this.logOut();
      } else {
        this.setState({
          expired: counter
        })
      }
    }, 5 * 60 * 1000); //5 mins
    // this.checkToken();
    /* ============ */
    sticky = document.getElementById('main-menu').offsetTop;
    window.addEventListener('scroll', this.showLogo);
  }

  render() {
    return (
      <section className="menu-member">
        <Navbar className="nav-main" collapseOnSelect expand="lg" bg="primary" variant="dark">
          <Container>
            <Navbar.Brand href="/">
              <img src={process.env.PUBLIC_URL + "/img/logo-novaio-white.png"} width="140" alt="Novaio Academy"/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
            <Navbar.Collapse id="responsive-navbar-nav" className="bg-member">
              <Nav className="mr-auto">&nbsp;</Nav>
              <Nav className="navbar-language">
                <Nav.Link className="nav-language" href="#!" onClick={() => this.setLanguage('English')} id="nav-english">
                  <img src={process.env.PUBLIC_URL + "/img/flag-en.png"} className="br-4" alt="English"/>
                  <label>
                    <strong>EN</strong>
                  </label>
                </Nav.Link>
                <Nav.Link className="nav-language" href="#!" onClick={() => this.setLanguage('Indonesian')} id="nav-indonesian">
                  <img src={process.env.PUBLIC_URL + "/img/flag-id.png"} className="br-4" alt="Indonesia"/>
                  <label>
                    <strong>ID</strong>
                  </label>
                </Nav.Link>
              </Nav>
              <Nav>
                <Nav.Link className="nav-profile" href="/account">
                  <label>
                    <span>{this.state.member.memberName}</span>
                    <br/>
                    <small>{this.state.member.memberPlan}</small>
                  </label>
                  <Image src={this.state.member_picture} referrerPolicy="no-referrer" roundedCircle/>
                </Nav.Link>
                <Nav.Link className="nav-mobile" href="/">{GetText() ? "Beranda" : "Home"}</Nav.Link>
                <Nav.Link className="nav-mobile" href="/libraries">Library</Nav.Link>
                <Nav.Link className="nav-mobile" href="/courses">Workshop</Nav.Link>
                <Nav.Link className="nav-mobile" href="#/">
                  <button className="btn-logout font-weight-bold" type="button" onClick={() => this.logOut()}>{GetText() ? "Keluar" : "Logout"}</button>
                  {/* <GoogleLogout
                    clientId= {global.config.clientLoginId}
                    className="btn-logout"
                    buttonText={GetText() ? "Keluar" : "Logout"}
                    onLogoutSuccess={this.logOut}
                  >
                  </GoogleLogout> */}
                </Nav.Link>
              </Nav>
              {(localStorage.getItem("ulvl") === global.config.userLevel[0])?  
              <Nav className="navbar-setting">
                <Nav.Link className="nav-setting" href="/settings"><Gear width={25} /></Nav.Link>
              </Nav>
              : <></>
              }
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Navbar className="mb-5 nav-second" collapseOnSelect expand="lg" bg="primary" variant="dark" sticky="top" id="main-menu">
          <Container>
            <Navbar.Brand href="/">
              <img className="d-none" src={process.env.PUBLIC_URL + "/img/logo-novaio-white.png"} height="39" alt="Novaio Academy" id="logo-menu"/>
            </Navbar.Brand>
            <Nav className="ml-auto nav-secondary">
              <Nav.Link className="nav-desktop" href="/">{GetText() ? "Beranda" : "Home"}</Nav.Link>
              <Nav.Link className="nav-desktop" href="/libraries">Library</Nav.Link>
              <Nav.Link className="nav-desktop" href="/courses">Workshop</Nav.Link>
              <Nav.Link className="nav-desktop" href="#/">
                <button className="btn-logout font-weight-bold" type="button" onClick={() => this.logOut()}>{GetText() ? "Keluar" : "Logout"}</button>
                {/* <GoogleLogout
                  clientId= {global.config.clientLoginId}
                  className="btn-logout"
                  buttonText={GetText() ? "Keluar" : "Logout"}
                  onLogoutSuccess={this.logOut}
                >
                </GoogleLogout> */}
              </Nav.Link>
            </Nav>
          </Container>
        </Navbar>
      </section>
    );
  }
}