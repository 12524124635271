import React, { Component } from 'react';
import Moment from 'moment';
import { GetText, ToggleArrow } from '../helper';
import { Container, Form, Button, Accordion, Card, Popover, OverlayTrigger, Modal } from 'react-bootstrap';
import { FaCopy, FaChevronRight, FaChevronDown, FaDownload } from 'react-icons/fa';
import NumberFormats from '../config/number-format';
import RandomNumber from '../config/random-number';
import QRCode from 'react-qr-code';
import html2canvas from 'html2canvas';
import '../config/global';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[13];
const authKey2 = global.config.authBearerKey+"##"+global.config.kindcode[14];
const authKey3 = global.config.authBearerKey+"##"+global.config.kindcode[40];

function getAvailableBank() {
  return new Promise((resolve) => {
    var xenditRequest = {
      request: 'available banks'
    }
    fetch(global.config.urlLink + "/trainingpayment", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }, 
      body: JSON.stringify(xenditRequest)
    })
    .then(response => {
      if(response.status === 200) {
        return response.json();
      } else {
        console.log('Something happened wrong');
      }
    })
    .then(resultJson => {
      resolve(resultJson.values);
    });
  })
}
function getLastStatTr(id) {
  return new Promise((resolve) => {
    fetch(global.config.urlLink + "/traininguserview/" + id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey
      }
    })
    .then(response => {
      if(response.status === 200) { 
        return response.json();
      } else {
        console.log('Something happened wrong');
      }
    })
    .then(resultJson => {
      var content = resultJson.values[0].scontent.sort((b, a) => a.subcontent.requestSubscribeDate._seconds - b.subcontent.requestSubscribeDate._seconds);
      resolve(content);
    });
  })
}
async function loadDatas(docid) {
  var [thing1, thing2] = await Promise.all([getLastStatTr(docid), getAvailableBank()]);
  var arrReturnValue = {};
  arrReturnValue.lastTransaction = thing1;
  arrReturnValue.availableBank = thing2;
  return arrReturnValue;
}

export default class Subscribe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // google_id: localStorage.getItem('google_id'),
      doc_id: localStorage.getItem('doc_id'),
      order_type: '',
      order_period: 1,
      payment_method: '',
      payment_bank: '',
      payment_account: '',
      payment_currency: GetText() ? global.config.subscribePlan[0].currency[0] : global.config.subscribePlan[0].currency[1],
      payment_amount: 0,
      payment_card_number: '',
      payment_card_expiry_month: '',
      payment_card_expiry_year: '',
      payment_card_cvc: '',
      xendit_token: '',
      card_url: '',
      card_status: '',
      available_banks: [],
      modal_show: false,
      order_id: "NA"+new Date().getTime()+RandomNumber(101,999),
      invoice_id: '',
      subscribe_ln: '',
      amount: 0,
      payment_voucher_code: '',
      payment_voucher_amount: 0,
      grand_total: 0,
      qr_string: '',
      symbol_currency: GetText()? global.config.subscribePlan[0].symbolCurrency[0] : global.config.subscribePlan[0].symbolCurrency[1],
      form_error: []
    }
    this.getVoucher = this.getVoucher.bind(this);
    this.setType = this.setType.bind(this);
    this.setMethod = this.setMethod.bind(this);
    this.setBank = this.setBank.bind(this);
    this.setPhone = this.setPhone.bind(this);
    this.setCardNumber = this.setCardNumber.bind(this);
    this.setCardExpiry = this.setCardExpiry.bind(this);
    this.setCardCvc = this.setCardCvc.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.subscribe = this.subscribe.bind(this);
    this.createCharge = this.createCharge.bind(this);
    this.createInvoice = this.createInvoice.bind(this);
  }
  
  componentDidMount() {
    var docid = this.state.doc_id;
    loadDatas(docid).then((response) => {
      var subscribeid="", statuz="";
      var contents = response.lastTransaction;
      if(contents.length > 0) { 
        subscribeid = contents[0].subdocid+contents[0].subcontent.orderId.substring(2,contents[0].subcontent.orderId.length);
        statuz = contents[0].subcontent.subscribeStatus;
      }
      if (statuz === 'New Request') {
        window.location.href = "/invoice/subscription/" + subscribeid;
      } else if (statuz === 'Active') {
        window.location.href = "/invoice/subscription/" + subscribeid;
      } else {
        this.setState({
          available_banks: response.availableBank,
          subscribe_ln: subscribeid
        }) 
        document.getElementById('form').classList.remove('d-none');
      }      
    });

    const script = document.createElement('script');
    script.src = 'https://js.xendit.co/v1/xendit.min.js';
    script.async = true;
    script.onload = () => {
      const xendit = window['Xendit'];
      xendit.setPublishableKey(global.config.xenditKey);
      this.xendit = xendit;
    };
    document.body.appendChild(script);
  }
  downloadQr = () => {
    const qrContainer = document.getElementById("qris");
    html2canvas(qrContainer).then(function (canvas) {
      const image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
      const downloadLink = document.createElement("a");
      downloadLink.setAttribute("download", "qr-code.png");
      downloadLink.setAttribute("href", image);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  }
  setType(event) {
    var amount = 0, pcurrency="";
    if(GetText() === true) {
      amount = global.config.subscribePlan[event.target.value].price[0];
      pcurrency = global.config.subscribePlan[event.target.value].currency[0];
    } else {
      amount = global.config.subscribePlan[event.target.value].price[1];
      pcurrency = global.config.subscribePlan[event.target.value].currency[1];
    }
    var order_type = global.config.subscribePlan[event.target.value].type;
    var grand_total = amount - this.state.payment_voucher_amount;

    this.setState({
      order_type: order_type,
      payment_amount: grand_total,
      amount: amount,
      grand_total: grand_total,
      payment_currency: pcurrency, 
      form_error: []
    });
  }

  setMethod(event) {
    this.setState({
      payment_method: event.target.value,
      form_error: []
    });
  }

  setBank(event) {
    this.setState({
      payment_bank: event.target.value,
      form_error: []
    });
  }
  setPhone(event) {
    var phone = event.target.value.replace(/\D/g,'');
    var first = Array.from(phone)[0];
    var second = Array.from(phone)[1];
    if (first === '0') {
      phone = '+62' + phone.substring(1);
    } else if ((first === '6') && (second === '2')) {
      phone = '+' + phone;
    } else {
      phone = '+62' + phone;
    }
    this.setState({
      payment_phone: phone,
      form_error: []
    });
  }
  setCardNumber(event) {
    this.setState({
      payment_card_number: event.target.value,
      form_error: []
    });
  }

  setCardExpiry(event) {
    var expiry = event.target.value.split('/');
    this.setState({
      payment_card_expiry_month: expiry[0],
      payment_card_expiry_year: '20' + expiry[1],
      form_error: []
    });
  }

  setCardCvc(event) {
    this.setState({
      payment_card_cvc: event.target.value,
      form_error: []
    });
  }

  closeModal() {
    this.setState({
      modal_show: false
    });
    document.getElementById('submit').innerText = (GetText() ? "Langganan" : "Subscribe");
    document.getElementById('submit').removeAttribute('disabled');
    return;
  }
  getVoucher(event) {
    if(event.target.value.length > 5) {
        var error = this.state.form_error;
        var dataSend2 = {
          voucher_code: event.target.value,
          users_email: localStorage.getItem("email")
        };
        fetch(global.config.urlLink+ "/cekvoucher/", {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': authKey3
        }, body: JSON.stringify(dataSend2)
      })
      .then(response => {
        if(response.status === 200) {
          return response.json();
        } else {
          console.log('Something happened wrong');
        }
      })
      .then(resultJson => {
        if(resultJson.status === '200') { 
          var voucherAmount = 0;
          if(GetText() === true) { voucherAmount = resultJson.values[0].voucherValue[0]; } 
          else { voucherAmount = resultJson.values[0].voucherValue[1]; }
          var gtotal = this.state.amount-voucherAmount;
          this.setState({
            payment_voucher_code: event.target.value,
            payment_voucher_amount: voucherAmount,
            grand_total: gtotal,
            form_error: []
          });
        } else {
            error.push('payment_voucher_code');
            this.setState({
              payment_voucher_code: event.target.value,
              payment_voucher_amount: 0,
              grand_total: this.state.amount,
              form_error: error
            });
        }
      }).catch(err => { 
            error.push('payment_voucher_code');
            this.setState({
              payment_voucher_code: event.target.value,
              payment_voucher_amount: 0,
              grand_total: this.state.amount,
              form_error: error
            });
      });
    } else {
      this.setState({
        payment_voucher_code: event.target.value,
        payment_voucher_amount: 0,
        grand_total: this.state.amount,
        form_error: []
      });
    }
  }
  async subscribe(event) {
    event.preventDefault();
    document.getElementById('submit').innerText = 'Loading...';
    document.getElementById('submit').setAttribute('disabled', 'true');

    var error = [];
    var dataSend = [];
    if (this.state.payment_method === 'Bank Transfer') {
      dataSend = {
        order_id: this.state.order_id,
        trmember_id: this.state.doc_id,
        subscribe_plan: this.state.order_type,
        subscribe_period: this.state.order_period,
        subscribe_price: this.state.amount,
        payment_method: this.state.payment_method,
        payment_currency: this.state.payment_currency,
        subscribe_gtotal: this.state.grand_total,
        subscribe_vouchercode: this.state.payment_voucher_code,
        subscribe_voucheramount: this.state.payment_voucher_amount,
        users_email: localStorage.getItem("email"),

        payment_bank: '',
        payment_account:"",
        payment_amount: this.state.grand_total,
        payment_info: "Bank Transfer",
      };
      this.createInvoice(dataSend);
    } else if (this.state.payment_method === 'Virtual Account') {
      if (!(this.state.order_type)) {
        error.push('order_type');
      }
      if (!(this.state.payment_method)) {
        error.push('payment_method');
      }
      if (!(this.state.payment_bank)) {
        error.push('payment_bank');
      }
      if (error.length) {
        this.setState({
          form_error: error
        });
        document.getElementById('submit').innerText = (GetText() ? "Langganan" : "Subscribe");
        document.getElementById('submit').removeAttribute('disabled');
        return;
      } else {
        var xenditSend = {
          request: 'create va',
          order_id: this.state.order_id,
          payment_method: this.state.payment_method,
          payment_bank: this.state.payment_bank,
          payment_currency: this.state.payment_currency,
          payment_amount: this.state.grand_total,
          payment_description: 'Novaio ' + this.state.order_type +  ' Subscription @ ' + this.state.grand_total + ' ' + this.state.payment_currency
        }
        var responseVa = await fetch(global.config.urlLink + "/trainingpayment", {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
          body: JSON.stringify(xenditSend)
        });
        var accountVa = await responseVa.json();
        this.setState({
          payment_account: accountVa.values.account_number
        });
        dataSend = {
          order_id: this.state.order_id,
          trmember_id: this.state.doc_id,
          subscribe_plan: this.state.order_type,
          subscribe_period: this.state.order_period,
          payment_method: this.state.payment_method,
          payment_bank: this.state.payment_bank,
          // payment_account: this.state.payment_account,
          payment_account: accountVa.values.account_number,
          payment_currency: this.state.payment_currency,
          payment_amount: this.state.grand_total,
          payment_info: "VA " + this.state.payment_bank + "<br/>" + accountVa.values.account_number,
          payment_response: JSON.stringify(accountVa.values),

          subscribe_price: this.state.amount,
          subscribe_gtotal: this.state.grand_total,
          subscribe_vouchercode: this.state.payment_voucher_code,
          subscribe_voucheramount: this.state.payment_voucher_amount,
          users_email: localStorage.getItem("email"),

          // payment_status: jsonStripe.values.status, // 'succeeded'
          subscribe_remarks: "Pay using Virtual Account "+this.state.payment_bank+" (Xendit)"
        }
        this.createInvoice(dataSend);
      }
    } else if (this.state.payment_method === 'Credit Card') {
      if (!(this.state.order_type)) {
        error.push('order_type');
      }
      if (!(this.state.payment_method)) {
        error.push('payment_method');
      }
      if (!(this.state.payment_card_number)) {
        error.push('payment_card_number');
      }
      if (!(this.state.payment_card_expiry_month)) {
        error.push('payment_card_expiry');
      }
      if (!(this.state.payment_card_expiry_year)) {
        error.push('payment_card_expiry');
      }
      if (!(this.state.payment_card_cvc)) {
        error.push('payment_card_cvc');
      }
      if (error.length) {
        this.setState({
          form_error: error
        });
        document.getElementById('submit').innerText = (GetText() ? "Langganan" : "Subscribe");
        document.getElementById('submit').removeAttribute('disabled');
        return;
      } else {
        if (this.state.payment_currency === 'IDR') {
          const cardData = {
            card_number: this.state.payment_card_number,
            card_exp_month: this.state.payment_card_expiry_month,
            card_exp_year: this.state.payment_card_expiry_year,
            card_cvn: this.state.payment_card_cvc,
            currency: 'IDR',
            amount: this.state.grand_total,
            is_multiple_use: false,
            should_authenticate: true,
          };  
          
          this.xendit.card.createToken(cardData, (err, token) => {
            if (err) {
              this.setState({
                card_status: 'declined',
                modal_show: true
              });
              return;
            } else {
              if (token.status === 'VERIFIED') {
                this.setState({
                  payment_account: token.id,
                  card_status: 'success',
                  modal_show: false
                });
                this.createCharge(token.id);
              } else if (token.status === 'IN_REVIEW') {
                this.setState({
                  payment_account: token.id,
                  card_url: token.payer_authentication_url,
                  card_status: '3dsecure',
                  modal_show: true
                });
                return;
              } else {
                this.setState({
                  card_status: 'declined',
                  modal_show: true
                });
                document.getElementById('submit').innerText = (GetText() ? "Langganan" : "Subscribe");
                document.getElementById('submit').removeAttribute('disabled');
                return;
              }
            }
          });   
          
        } else {
          var stripeSend = {
            request: 'create payment',
            order_id: this.state.order_id,
            name: localStorage.getItem('name'),
            email: localStorage.getItem('email'),
            country_code: GetText() ? 'ID' : 'SG',
            currency: GetText() ? 'idr' : 'usd',
            card_number: this.state.payment_card_number,
            card_exp_month: this.state.payment_card_expiry_month,
            card_exp_year: this.state.payment_card_expiry_year,
            card_cvc: this.state.payment_card_cvc,
            // amount: this.state.payment_amount
            amount: this.state.grand_total
          }
          var responseStripe = await fetch(global.config.urlLink + "/internalpayment", {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey2
            },
            body: JSON.stringify(stripeSend)
          });
          var jsonStripe = await responseStripe.json();
          if (jsonStripe.values.status !== undefined && jsonStripe.values.status === 'succeeded') {
            this.setState({
              payment_account: jsonStripe.values.id,
              card_status: 'success',
              modal_show: false
            });
            var payment_infoz = "Credit Card ";
            // if(jsonStripe.values[2].card_brand !== undefined) { payment_infoz += jsonStripe.values[2].card_brand; }
            // if(jsonStripe.values[2].issuing_bank_name !== undefined) { payment_infoz += "<br/>"+jsonStripe.values[2].issuing_bank_name; }
            // if(jsonStripe.values[2].approval_code !== undefined) { payment_infoz += "<br/>Approval Code : "+jsonStripe.values[2].approval_code; }
            
            dataSend = {
              order_id: this.state.order_id,
              trmember_id: this.state.doc_id,
              subscribe_plan: this.state.order_type,
              subscribe_period: this.state.order_period,
              payment_method: this.state.payment_method,
              payment_bank: '',
              payment_account: jsonStripe.values.id,
              payment_currency: this.state.payment_currency,
              payment_amount: this.state.grand_total,
              payment_info: payment_infoz,
              payment_response: JSON.stringify(jsonStripe.values),
              // payment_response: JSON.stringify(jsonStripe.values[2]),
              
              subscribe_price: this.state.amount,
              subscribe_gtotal: this.state.grand_total,
              subscribe_vouchercode: this.state.payment_voucher_code,
              subscribe_voucheramount: this.state.payment_voucher_amount,
              users_email: localStorage.getItem("email"),
              payment_status: jsonStripe.values.status, // 'succeeded'
              subscribe_remarks: "Pay using Credit Card (Stripe)"
              
            }
            this.createInvoice(dataSend);
          } else if (jsonStripe.values.status === 'requires_action') {
            this.setState({
              payment_account: jsonStripe.values.id,
              card_url: jsonStripe.values.next_action.use_stripe_sdk.stripe_js,
              card_status: '3dsecure',
              modal_show: true
            });
            return;
          } else if (jsonStripe.values.code === 'incorrect_number') {
            this.setState({
              card_status: 'incorrect_number',
              modal_show: true
            });
            return;
          } else if (jsonStripe.values.code === 'incorrect_cvc') {
            this.setState({
              card_status: 'incorrect_cvc',
              modal_show: true
            });
            return;
          } else if (jsonStripe.values.code === 'expired_card' || jsonStripe.values.code === 'invalid_expiry_year' || jsonStripe.values.code === 'invalid_expiry_month') {
            this.setState({
              card_status: 'incorrect_expiry',
              modal_show: true
            });
            return;
          } else {
            this.setState({
              card_status: 'declined',
              modal_show: true
            });
            return;
          }
        }
      }
    } else if (this.state.payment_method === 'eWallet') {
      if (!(this.state.payment_phone)) {
        error.push('payment_phone');
      }
      if (error.length) {
        this.setState({
          form_error: error
        });
        document.getElementById('submit').innerText = (GetText() ? "Langganan" : "Subscribe");
        document.getElementById('submit').removeAttribute('disabled');
        return;
      }
      var ewalletSend = {
        request: 'ewallet charge',
        order_id: this.state.order_id,
        payment_amount: this.state.payment_amount,
        payment_bank: this.state.payment_bank,
        payment_phone: this.state.payment_phone,
      }
      var responseEwallet = await fetch(global.config.urlLink + "/trainingpayment", {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify(ewalletSend)
      });
      var jsonEwallet = await responseEwallet.json();
      if (jsonEwallet.values.status === 'PENDING') {
        this.setState({
          card_url: jsonEwallet.values.actions.desktop_web_checkout_url,
          card_status: '3dsecure',
          modal_show: true
        });
      } else if (jsonEwallet.values.status === 'SUCCESS') {
        this.setState({
          payment_account: jsonEwallet.data.id,
          card_status: 'success',
          modal_show: false
        });
        dataSend = {          
          order_id: this.state.order_id,
          trmember_id: this.state.doc_id,
          subscribe_plan: this.state.order_type,
          subscribe_period: this.state.order_period,
          payment_method: this.state.payment_method,
          payment_bank: '',
          payment_account: jsonEwallet.data.id, //this.state.payment_account,
          payment_currency: this.state.payment_currency,
          payment_amount: this.state.payment_amount,
          payment_info: "eWallet"
        }
        this.createInvoice(dataSend);
      } else {
        this.setState({
          card_status: 'declined',
          modal_show: true
        });
        return;
      }
    } else if (this.state.payment_method === 'QRIS') {

      var qrSend = {
        request: 'create qr',
        order_id: this.state.order_id,
        payment_currency: this.state.payment_currency,
        payment_amount: this.state.grand_total,
        payment_description: 'Novaio ' + this.state.order_type +  ' Subscription @ ' + this.state.grand_total + ' ' + this.state.payment_currency
      }      
      var responseQr = await fetch(global.config.urlLink + "/trainingpayment", {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify(qrSend)
      });
      var jsonQr = await responseQr.json();
      if (jsonQr.values.status === 'ACTIVE') {
        this.setState({
          // payment_account: jsonQr.values.id,
          payment_account: jsonQr.values.qr_string,
          qr_string: jsonQr.values.qr_string,
          card_status: 'qrscan',
          modal_show: true
        }, () => {
          var payment_infoz = "QRIS ";
          if(jsonQr.values.id !== undefined) { payment_infoz += "<br/>"+ jsonQr.values.id; }

          dataSend = {
            order_id: this.state.order_id,
            trmember_id: this.state.doc_id,
            subscribe_plan: this.state.order_type,
            subscribe_period: this.state.order_period,
            payment_method: this.state.payment_method,
            payment_bank: '', // this.state.payment_bank
            payment_account: this.state.payment_account, // accountVa.values.account_number ???
            payment_currency: this.state.payment_currency,
            payment_amount: this.state.grand_total,
            // payment_info: jsonQr.values.qr_string,
            payment_info: payment_infoz,
            payment_response: JSON.stringify(jsonQr.values),
              
            subscribe_price: this.state.amount,
            subscribe_gtotal: this.state.grand_total,
            subscribe_vouchercode: this.state.payment_voucher_code,
            subscribe_voucheramount: this.state.payment_voucher_amount,
            users_email: localStorage.getItem("email"),
            // payment_status: jsonQr.values.status, // 'CAPTURED',
            subscribe_remarks: "Pay using QRIS",
          }
          this.createInvoice(dataSend);
        });
      }
    } else {
      if (!(this.state.order_type)) {
        error.push('order_type');
      }
      if (!(this.state.payment_method)) {
        error.push('payment_method');
      }
      if (error.length) {
        this.setState({
          form_error: error
        });
        document.getElementById('submit').innerText = (GetText() ? "Langganan" : "Subscribe");
        document.getElementById('submit').removeAttribute('disabled');
        return;
      }
    }
  }
  async createCharge(token_id) {
    var chargeSend = {
      request: 'create charge',
      order_id: this.state.order_id,
      token_id: token_id,
      payment_amount: this.state.payment_amount,
    }
    var responseCharge = await fetch(global.config.urlLink + "/trainingpayment", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(chargeSend)
    });
    var jsonCharge = await responseCharge.json();
    if (jsonCharge.values.status === 'CAPTURED') {
      this.setState({
        payment_account: jsonCharge.values.id,
        card_status: 'success',
        modal_show: false
      });
      var payment_infoz = "Credit Card ";
      if(jsonCharge.values.card_brand !== undefined) { payment_infoz += jsonCharge.values.card_brand; }
      if(jsonCharge.values.issuing_bank_name !== undefined) { payment_infoz += "<br/>"+jsonCharge.values.issuing_bank_name; }
      if(jsonCharge.values.approval_code !== undefined) { payment_infoz += "<br/>Approval Code : "+jsonCharge.values.approval_code; }
            

      var dataSend = {
        order_id: this.state.order_id,
        token_id: this.state.order_id,
        auth_id: jsonCharge.values.authorization_id, 
        trmember_id: this.state.doc_id,
        subscribe_plan: this.state.order_type,
        subscribe_period: this.state.order_period,
        payment_method: this.state.payment_method,
        payment_bank: jsonCharge.values.card_brand,
        payment_account: jsonCharge.values.id,
        payment_currency: this.state.payment_currency,
        payment_amount: this.state.grand_total,
        payment_info: payment_infoz,
        payment_response: JSON.stringify(jsonCharge.values),
        subscribe_price: this.state.amount,
        subscribe_gtotal: this.state.grand_total,
        subscribe_vouchercode: this.state.payment_voucher_code,
        subscribe_voucheramount: this.state.payment_voucher_amount,
        users_email: localStorage.getItem("email"),
        payment_status: jsonCharge.values.status, // 'CAPTURED',
        subscribe_remarks: "Pay using Credit Card (Xendit)",
        // verified: 'Xendit'
      }
      this.createInvoice(dataSend);
    } else {
      this.setState({
        card_status: 'declined',        
      })
    }
  }
  async createInvoice(dataSend) {
    var responseSubscribe = await fetch(global.config.urlLink+"/trainingaddsubscribe", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey2
      }, 
      body: JSON.stringify(dataSend)
    });
    var jsonSubscribe = await responseSubscribe.json();
    if (jsonSubscribe.values.docid === this.state.doc_id) {      
      if (dataSend.payment_method === 'QRIS') {
        window.location.reload();
      }
      var subscribeid = jsonSubscribe.values.subdocid+this.state.order_id.substring(2,this.state.order_id.length);
      this.setState({
        invoice_id: this.state.order_id,
        subscribe_ln: subscribeid
      });
    }
  }


  orderTypeList() {
    var arrOrderType = []; 
    var arrSubscribeText = [];
    var b=0, addstyles = ""; 
    for(var a=0; a<global.config.subscribePlan.length; a++) {
      var priceText = ""; 
      var priceTypeText="";
      
      if(GetText() === true) { 
        priceText = global.config.subscribePlan[a].symbolCurrency[0]+NumberFormats(global.config.subscribePlan[a].price[0]);  
        priceTypeText= "/"+global.config.subscribePlan[a].name[0];
       } else { 
        priceText = global.config.subscribePlan[a].symbolCurrency[1]+NumberFormats(global.config.subscribePlan[a].price[1]);
        priceTypeText="/"+global.config.subscribePlan[a].name[1];
       }
      if(global.config.subscribePlan.length === 2) {
        b++;
        if(b===1) { 
          addstyles = "col-md-6"; 
          arrSubscribeText.push(<h3 className="px-5">
            <strong><span className="text-primary fw-bold">{priceText}</span>{priceTypeText}</strong>
          </h3>);
        } else if(b===2) { 
          b=0; addstyles = "col-md-6 mt-2 mt-md-0"; 
          arrSubscribeText.push(<h6 className="px-5">
            <strong>{GetText()? 'atau ' : 'or '} <span className="text-primary fw-bold">{priceText}</span>{priceTypeText}</strong>
          </h6>); 
        }
        arrOrderType.push(<div className={addstyles}>
            <div className="box-options">
              <Form.Check type="radio" id={global.config.subscribePlan[a].type}>
                <Form.Check.Input type="radio" name="order_type" value={a} onChange={(e) => this.setType(e)}/>
                <Form.Check.Label>{priceText}{priceTypeText}</Form.Check.Label>
              </Form.Check>
            </div>
          </div>)
        
      } else {
        if(a=== 0) {
          arrSubscribeText.push(<h3 className="px-5">
            <strong><span className="text-primary fw-bold">{priceText}</span>{priceTypeText}</strong>
          </h3>);
        } else {
          arrSubscribeText.push(<h6 className="px-5">
            <strong>{GetText()? 'atau ' : 'or '} <span className="text-primary fw-bold">{priceText}</span>{priceTypeText}</strong>
          </h6>); 
        }
        arrOrderType.push(<div className="w-100 mb-2">
          <div className="col-md-12">
            <div className="box-options">
              <Form.Check type="radio" id={global.config.subscribePlan[a].type}>
                <Form.Check.Input type="radio" name="order_type" value={global.config.subscribePlan[a].type} onChange={(e) => this.setType(e)}/>
                <Form.Check.Label>{GetText() ? global.config.subscribePlan[a].symbolCurrency[0]+NumberFormats(global.config.subscribePlan[a].price[0])+"/"+global.config.subscribePlan[a].name[0] : global.config.subscribePlan[a].symbolCurrency[1]+NumberFormats(global.config.subscribePlan[a].price[1])+"/"+global.config.subscribePlan[a].name[1]}</Form.Check.Label>
              </Form.Check>
            </div>
          </div>
        </div>)

      } 
      
    }
    return [arrOrderType,arrSubscribeText];
  }

  render() {
    const popover = (
      <Popover id="popover">
        <Popover.Title as="p">{GetText() ? "Nomor disalin" : "Number Copied"}</Popover.Title>
      </Popover>
    );
    var orderTypeList = this.orderTypeList();
    return (
      <div className="subscribe">
        {
          this.state.invoice_id ? (
            <Container>
              <div className="row py-5" id="success">
                <div className="col-md-7 mx-auto text-center">
                  <h1><strong>{GetText() ? "Pesanan Anda berhasil diproses!" : "Your order has been created!"}</strong></h1>
                  <div className="py-2">
                    <p className="mt-2">
                      {
                        this.state.payment_method === 'Virtual Account' ? (
                          <>
                            {GetText() ? "Silakan melakukan pembayaran melalui " + this.state.payment_method + " ke akun berikut. Jika Anda telah selesai, mohon tunggu sampai status pesanan berubah." : "Please make your payment through " + this.state.payment_method + " to this account below. If you have made payment, please wait until the order status is changed."}
                          </>
                        ) : ''
                      }
                    </p>
                    <div className="row">
                      <div className="col-md-10 mx-auto">
                        <div className="card card-pop mt-4">
                          <div className="card-body text-left">
                            {
                              this.state.payment_method === 'Virtual Account' ? (
                                <div>
                                  <div className="row">
                                    <div className="col-6">{GetText() ? "Nomor Invoice" : "Invoice ID"}</div>
                                    <div className="col-6"><strong>{this.state.order_id}</strong></div>
                                  </div>
                                  <div className="row">
                                    <div className="col-6">{GetText() ? "Bayar sebelum" : "Payment before"}</div>
                                    <div className="col-6"><strong>{Moment().add('1', 'day').format('D MMMM Y HH:mm')}</strong></div>
                                  </div>
                                  <div className="row">
                                    <div className="col-6">{GetText() ? "Metode Pembayaran" : "Payment Method"}</div>
                                    <div className="col-6"><strong>{this.state.payment_bank + ' ' + this.state.payment_method}</strong></div>
                                  </div>
                                  <div className="row">
                                    <div className="col-6">{GetText() ? "Bayar Ke" : "Payment To"}</div>
                                    <div className="col-6">
                                      <strong>
                                        {this.state.payment_account}
                                        <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                                          <a href="#!" className="text-secondary ml-2"  onClick={() => {navigator.clipboard.writeText(this.state.payment_account)}}><FaCopy/></a>
                                        </OverlayTrigger>
                                      </strong>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-6">{GetText() ? "Jumlah Total" : "Total Amount"}</div>
                                    <div className="col-6 text-primary"><strong>{this.state.symbol_currency} {new Intl.NumberFormat().format(this.state.grand_total)}</strong></div>
                                  </div>
                                </div>
                              ) : 
                              this.state.payment_method === 'QRIS' ? (
                                <div>
                                  <div className="row">
                                    <div className="col-6">{GetText() ? "Nomor Invoice" : "Invoice ID"}</div>
                                    <div className="col-6"><strong>{this.state.order_id}</strong></div>
                                  </div>
                                  <div className="row">
                                    <div className="col-6">{GetText() ? "Jumlah Total" : "Total Amount"}</div>
                                    <div className="col-6 text-primary"><strong>{this.state.symbol_currency} {new Intl.NumberFormat().format(this.state.grand_total)}</strong></div>
                                  </div>
                                  <div className="row">
                                    <div className="col-6">{GetText() ? "Metode Pembayaran" : "Payment Method"}</div>
                                    <div className="col-6"><strong>{this.state.payment_bank + ' ' + this.state.payment_method}</strong></div>
                                  </div>
                                </div>
                              ) : 
                              this.state.payment_method === 'Credit Card' ? (
                                <div>
                                  <div className="row">
                                    <div className="col-6">{GetText() ? "Nomor Invoice" : "Invoice ID"}</div>
                                    <div className="col-6"><strong>{this.state.invoice_id}</strong></div>
                                  </div>
                                  <div className="row">
                                    <div className="col-6">{GetText() ? "Jumlah Total" : "Total Amount"}</div>
                                    <div className="col-6 text-primary"><strong>{this.state.symbol_currency} {new Intl.NumberFormat().format(this.state.grand_total)}</strong></div>
                                  </div>
                                  <div className="row">
                                    <div className="col-6">{GetText() ? "Metode Pembayaran" : "Payment Method"}</div>
                                    <div className="col-6"><strong>{this.state.payment_bank + ' ' + this.state.payment_method}</strong></div>
                                  </div>
                                </div>
                              ) : ''
                            }
                          </div>
                        </div>
                      </div>
                      {
                        this.state.payment_method === 'Virtual Account' ? (
                          <div className="col-md-10 mx-auto">
                            <div className="bg-light p-4 mt-4">
                              <Form className="form-payment">
                                <h6><strong>{GetText() ? "Instruksi Pembayaran" : "Payment Instruction"}</strong></h6>
                                <Accordion className="card-faq mt-4 text-left">
                                  <Card id="accordion-0">
                                    <Accordion.Toggle as={Card.Header} eventKey="0" onClick={()=> ToggleArrow(0)}>
                                      m-Banking
                                      <FaChevronRight className="accordion-toggle toggle-close"/>
                                      <FaChevronDown className="accordion-toggle toggle-open d-none"/>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0" id="collapse-0">
                                      <Card.Body>
                                        {
                                          GetText() ? (
                                            <ol>
                                              <li>Buka aplikasi Mobile {this.state.payment_bank} di hp Anda</li>
                                              <li>Pilih m-Transfer &gt; {this.state.payment_method}</li>
                                              <li>Masukkan nomor {this.state.payment_method} <strong>{this.state.payment_account}</strong> dan pilih Kirim</li>
                                              <li>Lihat data yang tampil di layar. Pastikan kembali jumlah tagihan dan akun tujuan, kemudian pilih Ya</li>
                                              <li>Masukkan PIN m-{this.state.payment_bank} Anda dan klik OK</li>
                                            </ol>
                                          ) : (
                                            <ol>
                                              <li>Open your {this.state.payment_bank} Mobile application on your mobile</li>
                                              <li>Choose m-Transfer &gt; {this.state.payment_method}</li>
                                              <li>Input {this.state.payment_method} number <strong>{this.state.payment_account}</strong> and choose Send</li>
                                              <li>Check the data on screen. Ensure the amount shown and the account used are correct, then choose Yes</li>
                                              <li>Input your m-{this.state.payment_bank} PIN number and click OK</li>
                                            </ol>
                                          )
                                        }
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                  <Card id="accordion-1">
                                    <Accordion.Toggle as={Card.Header} eventKey="1" onClick={()=> ToggleArrow(1)}>
                                      Internet Banking
                                      <FaChevronRight className="accordion-toggle toggle-close"/>
                                      <FaChevronDown className="accordion-toggle toggle-open d-none"/>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="1" id="collapse-1">
                                      <Card.Body>
                                        {
                                          GetText() ? (
                                            <ol>
                                              <li>Pilih Transfer &gt; Transfer ke {this.state.payment_bank} {this.state.payment_method}</li>
                                              <li>Pilih {this.state.payment_method} Number, lalu masukkan nomor rekening {this.state.payment_method} <strong>{this.state.payment_account}</strong> dan klik Lanjutkan</li>
                                              <li>Lihat data yang tampil di layar. Pastikan kembali jumlah tagihan dan akun tujuan, kemudian pilih Ya</li>
                                              <li>Masukkan PIN key-{this.state.payment_bank} Anda dan klik OK</li>
                                            </ol>
                                          ) : (
                                            <ol>
                                              <li>Choose Fund Transfer &gt; Transfer to {this.state.payment_bank} {this.state.payment_method}</li>
                                              <li>Tick the {this.state.payment_method} Number option, then input {this.state.payment_method} Number <strong>{this.state.payment_account}</strong> and choose Continue</li>
                                              <li>Check the data on screen. Ensure the amount shown and the account used are correct, then choose Yes</li>
                                              <li>input your key-{this.state.payment_bank} Response and click Submit</li>
                                            </ol>
                                          )
                                        }
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                  <Card id="accordion-2">
                                    <Accordion.Toggle as={Card.Header} eventKey="2" onClick={()=> ToggleArrow(2)}>
                                      ATM Transfer
                                      <FaChevronRight className="accordion-toggle toggle-close"/>
                                      <FaChevronDown className="accordion-toggle toggle-open d-none"/>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="2" id="collapse-2">
                                      <Card.Body>
                                        {
                                          GetText() ? (
                                            <ol>
                                              <li>Pilih Transaksi Lainnya &gt; Transfer &gt; {this.state.payment_method}</li>
                                              <li>Masukkan nomor rekening {this.state.payment_method} <strong>{this.state.payment_account}</strong> dan pilih OK</li>
                                              <li>Lihat data yang tampil di layar. Pastikan kembali jumlah tagihan dan akun tujuan, kemudian pilih Ya</li>
                                              <li>Simpan resi transfer hingga kami selesai memverifikasi pembayaran Anda</li>
                                            </ol>
                                          ) : (
                                            <ol>
                                              <li>Choose Other Transaction &gt; Transfer &gt; {this.state.payment_method}</li>
                                              <li>Input {this.state.payment_method} Number <strong>{this.state.payment_account}</strong> and choose OK</li>
                                              <li>Check the data on screen. Ensure the amount shown and the account used are correct, then choose Yes</li>
                                              <li>Please keep the receipt until we verified your payment</li>
                                            </ol>
                                          )
                                        }
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>
                              </Form>
                            </div>
                          </div>
                        ) : ''
                      }
                    </div>
                    {
                      this.state.payment_method === 'Virtual Account' ? (
                        <>
                          <a className="btn btn-lg btn-primary mt-4" href={"/invoice/subscription/" + this.state.subscribe_ln}>{GetText() ? "Konfirmasi Pembayaran" : "Confirm Payment"}</a>
                          <br/>
                          <a className="btn btn-outline-primary mt-2" href={"/invoice/subscription/" + this.state.subscribe_ln}>{GetText() ? "Lihat Invoice" : "View Invoice"}</a>
                        </>
                      ) : 
                      (this.state.payment_method === 'Credit Card' || this.state.payment_method === 'QRIS') ? (
                        <a className="btn btn-outline-primary mt-4" href={"/invoice/subscription/" + this.state.subscribe_ln}>{GetText() ? "Lihat Invoice" : "View Invoice"}</a>
                      ) : ''
                    }
                  </div>
                </div>
              </div>
            </Container>
          ) : (
            <Container>
              <div className="row py-5 d-none" id="form">
                <div className="col-md-7 text-center">
                <h2><strong>{GetText() ? "Berlangganan" : "Subscribe"}</strong></h2>
                  <div className="py-5">
                    {orderTypeList[1]}
                    <p className="px-5 my-5">{GetText() ? "Dengan berlangganan, Anda setuju untuk mematuhi semua hukum yang berlaku dan tidak melanggar hak atau kepentingan pihak mana pun. Anda juga menyetujui bahwa Anda tidak akan secara sadar atau sengaja mengirimkan konten yang tidak akurat, memfitnah, atau menyinggung ke situs web. Kebijakan berikut adalah bagian dari perjanjian dan harus diikuti setiap kali Anda mengakses situs web." : "By subscribing, you agree to comply with all applicable laws and refrain from infringing any third-party rights or interest. You must also agree that you will not knowingly or willfully submit inaccurate, defamotory or offensive content to website. In addition, the following policies are part of this agreement and must be followed anytime you access the website."}</p>
                    <p>{GetText() ? "Silakan membaca" : "Please read our"} <a href="/privacy-policy" target="_blank">{GetText() ? "Kebijakan Privasi" : "Privacy Policy"}</a> {GetText() ? "kami." : "before."}</p>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="bg-dark p-4">
                    <Form onSubmit={this.subscribe}>
                      <h4 className="text-white"><strong>{GetText() ? "Informasi Pembayaran" : "Payment Information"}</strong></h4>
                      <div className="row">
                        <div className="col-md-12 mt-4">
                          <Form.Label className="text-white">{GetText() ? "Periode Langganan" : "Subscription Terms"}</Form.Label>
                          {this.state.form_error.some(item => item === 'order_type') ? <span className="error-message">{GetText() ? "Wajib diisi!" : "Required!"}</span> : ''}
                        </div>
                        {orderTypeList[0]}
                        <div className="col-md-12">
                          <Form.Group className="mt-4">
                            <Form.Label className="text-white">{GetText() ? "Metode Pembayaran" : "Payment Method"}</Form.Label>
                            {this.state.form_error.some(item => item === 'payment_method') ? <span className="error-message">{GetText() ? "Wajib diisi!" : "Required!"}</span> : ''}
                            <Form.Control as="select" onChange={(e) => this.setMethod(e)}>
                              <option></option>
                              {
                              GetText() ? 
                                <>
                                {/* <option value="Bank Transfer">Bank Transfer</option> */}
                                <option value="Virtual Account">Virtual Account</option> 
                                <option value="QRIS">QRIS</option>
                                <option value="Credit Card">Kartu Kredit</option>
                                </>
                                : 
                                <>
                                <option value="Credit Card">Credit Card</option>
                                </>

                              }
                              {/* {GetText() ? <option value="Virtual Account">Virtual Account</option> : ''} */}
                              {/* {GetText() ? <option value="eWallet">eWallet</option> : ''} */}
                              {/* {GetText() ? <option value="Credit Card">Kartu Kredit</option> : <option value="Credit Card">Credit Card</option>} */}
                            </Form.Control>
                          </Form.Group>
                        </div>
                        {
                          this.state.payment_method === 'Virtual Account' ? (
                            <div className="col-12" id="payment-virtual-account">
                              <div className="row">
                                {
                                  this.state.available_banks.length > -0 ? (
                                    <div className="col-md-12">
                                      <Form.Group className="mt-2">
                                        <Form.Label className="text-white">{GetText() ? "Pilih Bank" : "Select Bank"}</Form.Label>
                                        {this.state.form_error.some(item => item === 'payment_bank') ? <span className="error-message">{GetText() ? "Wajib diisi!" : "Required!"}</span> : ''}
                                        {
                                          this.state.available_banks.map((bank, i) => {
                                            return (
                                              <div className="box-options mb-2" key={"bank-" + i}>
                                                <Form.Check type="radio" id={"va-" + bank.code.toLowerCase()}>
                                                  <Form.Check.Input type="radio" name="bank_code" value={bank.code} onChange={(e) => this.setBank(e)}/>
                                                  <Form.Check.Label>
                                                    {bank.name}
                                                    <img className="box-options-logo" src={"/img/bank-logo-" + bank.code.toLowerCase() + ".jpg"} alt={"logo-" + bank.code.toLowerCase()}/>
                                                  </Form.Check.Label>
                                                </Form.Check>
                                              </div>
                                            )
                                          })
                                        }
                                      </Form.Group>
                                    </div>
                                  ) : ''
                                }
                              </div>
                            </div>
                          ) : ''
                        }
                        {
                          this.state.payment_method === 'eWallet' ? (
                            <div className="col-12" id="payment-ewallet">
                              <div className="row">
                                <div className="col-md-12">
                                  <Form.Group className="mt-2">
                                    <Form.Label className="text-white">{GetText() ? "Pilih Akun" : "Select Account"}</Form.Label>
                                    {this.state.form_error.some(item => item === 'payment_bank') ? <span className="error-message">{GetText() ? "Wajib diisi!" : "Required!"}</span> : ''}                                        
                                    <div className="box-options mb-2">
                                      <Form.Check type="radio" id="ewallet-dana">
                                        <Form.Check.Input type="radio" name="bank_code" value="ID_DANA" onChange={(e) => this.setBank(e)}/>
                                        <Form.Check.Label>
                                          DANA
                                          <img className="box-options-logo" src="/img/ewallet-logo-dana.jpg" alt="logo-id_dana"/>
                                        </Form.Check.Label>
                                      </Form.Check>
                                    </div>
                                    <div className="box-options mb-2">
                                      <Form.Check type="radio" id="ewallet-ovo">
                                        <Form.Check.Input type="radio" name="bank_code" value="ID_OVO" onChange={(e) => this.setBank(e)}/>
                                        <Form.Check.Label>
                                          OVO
                                          <img className="box-options-logo" src="/img/ewallet-logo-ovo.jpg" alt="logo-id_ovo"/>
                                        </Form.Check.Label>
                                      </Form.Check>
                                    </div>
                                    <div className="box-options mb-2">
                                      <Form.Check type="radio" id="ewallet-linkaja">
                                        <Form.Check.Input type="radio" name="bank_code" value="ID_LINKAJA" onChange={(e) => this.setBank(e)}/>
                                        <Form.Check.Label>
                                          LinkAja
                                          <img className="box-options-logo" src="/img/ewallet-logo-linkaja.jpg" alt="logo-id_linkaja"/>
                                        </Form.Check.Label>
                                      </Form.Check>
                                    </div>
                                    <div className="box-options mb-2">
                                      <Form.Check type="radio" id="ewallet-shopeepay">
                                        <Form.Check.Input type="radio" name="bank_code" value="ID_SHOPEEPAY" onChange={(e) => this.setBank(e)}/>
                                        <Form.Check.Label>
                                          ShopeePay
                                          <img className="box-options-logo" src="/img/ewallet-logo-shopeepay.jpg" alt="logo-id_shopeepay"/>
                                        </Form.Check.Label>
                                      </Form.Check>
                                    </div>
                                    <div className="box-options mb-2">
                                      <Form.Check type="radio" id="ewallet-astrapay">
                                        <Form.Check.Input type="radio" name="bank_code" value="ID_ASTRAPAY" onChange={(e) => this.setBank(e)}/>
                                        <Form.Check.Label>
                                          AstraPay
                                          <img className="box-options-logo" src="/img/ewallet-logo-astrapay.jpg" alt="logo-id_astrapay"/>
                                        </Form.Check.Label>
                                      </Form.Check>
                                    </div>
                                  </Form.Group>
                                </div>                                
                                <div className="col-md-12">
                                  <Form.Group className="mt-2">
                                    <Form.Label className="text-white">{GetText() ? "Nomor HP" : "Phone Number"}</Form.Label>
                                    {this.state.form_error.some(item => item === 'payment_phone') ? <span className="error-message">{GetText() ? "Wajib diisi!" : "Required!"}</span> : ''}
                                    <input className="form-control" type="text" name="payment_phone" onChange={(e) => this.setPhone(e)}/>
                                  </Form.Group>
                                </div>
                              </div>
                            </div>
                          ) : ''
                        }
                        {
                          this.state.payment_method === 'Credit Card' ? (
                            <div className="col-md-12" id="payment-credit-card">
                              <div className="row">
                                <div className="col-md-12">
                                  <Form.Group className="mt-2">
                                    <Form.Label className="text-white">{GetText() ? "Nomor Kartu" : "Card Number"}</Form.Label>
                                    {this.state.form_error.some(item => item === 'payment_card_number') ? <span className="error-message">{GetText() ? "Wajib diisi!" : "Required!"}</span> : ''}
                                    <input className="form-control" type="text" name="payment_card_number" onChange={(e) => this.setCardNumber(e)}/>
                                  </Form.Group>
                                </div>
                                <div className="col-6">
                                  <Form.Group className="mt-2">
                                    <Form.Label className="text-white">{GetText() ? "Kadaluarsa" : "Expiration"}</Form.Label>
                                    {this.state.form_error.some(item => item === 'payment_card_expiry') ? <span className="error-message">{GetText() ? "Wajib diisi!" : "Required!"}</span> : ''}
                                    <input className="form-control" type="input" name="payment_card_expiry" placeholder="--/--" onChange={(e) => this.setCardExpiry(e)}/>
                                  </Form.Group>
                                </div>
                                <div className="col-6">
                                  <Form.Group className="mt-2">
                                    <Form.Label className="text-white">CVC</Form.Label>
                                    {this.state.form_error.some(item => item === 'payment_card_cvc') ? <span className="error-message">{GetText() ? "Wajib diisi!" : "Required!"}</span> : ''}
                                    <input className="form-control" type="text" name="payment_card_cvc" onChange={(e) => this.setCardCvc(e)}/>
                                  </Form.Group>
                                </div>
                              </div>
                            </div>
                          ) : ''
                        }
                        <div className="col-md-12">
                          <Form.Group className="mt-4">
                            <Form.Label className="text-white">{GetText() ? "Kode Voucher" : "Voucher Code"}</Form.Label>
                            {this.state.form_error.some(item => item === 'payment_voucher_code') ? <span className="error-message">{GetText() ? "kode voucher tidak valid" : "invalid voucher code"}</span> : ''}
                            <Form.Control type="text" onKeyUp={(e) => this.getVoucher(e)}>
                            </Form.Control>
                          </Form.Group>
                        </div>
                        <div className="col-6">
                          <p className="mt-3 text-white">Grand Total</p>
                        </div>
                        <div className="col-6 text-right">
                          <p className="mt-3 text-white">{this.state.symbol_currency} {new Intl.NumberFormat().format(this.state.grand_total)}</p>
                        </div>
                        <div className="col-md-12">
                          <p className="mt-3 text-white">{GetText() ? "Anda akan ditagih secara otomatis seminggu sebelum langganan Anda berakhir. Anda juga dapat membatalkan tagihan otomatis kapanpun." : "You will be charged automatically a week before your subscription expires. Cancel renewal at any time."}</p>
                          <Button className="w-100" variant="primary" size="lg" type="submit" id="submit">
                            {GetText() ? "Langganan" : "Subscribe"}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
              <Modal animation={false} show={this.state.modal_show}>
                <Modal.Header>
                  {this.state.card_status === 'incorrect_number' ? (<Modal.Title>{GetText() ? 'Kartu Kredit Ditolak' : 'Card Declined'}</Modal.Title>) : ''}
                  {this.state.card_status === 'incorrect_cvc' ? (<Modal.Title>{GetText() ? 'Kartu Kredit Ditolak' : 'Card Declined'}</Modal.Title>) : ''}
                  {this.state.card_status === 'incorrect_expiry' ? (<Modal.Title>{GetText() ? 'Kartu Kredit Ditolak' : 'Card Declined'}</Modal.Title>) : ''}
                  {this.state.card_status === 'declined' ? (<Modal.Title>{GetText() ? 'Kartu Kredit Ditolak' : 'Card Declined'}</Modal.Title>) : ''}
                  {this.state.card_status === '3dsecure' ? (<Modal.Title>{GetText() ? 'Diperlukan Kode Otentikasi' : 'Authentication Code Required'}</Modal.Title>) : ''}
                  {this.state.card_status === 'qrscan' ? (<Modal.Title>{GetText() ? 'Pindai QRIS' : 'Please Scan QR Code'}</Modal.Title>) : ''}
                </Modal.Header>
                <Modal.Body>
                  {this.state.card_status === 'incorrect_number' ? (GetText() ? 'Kartu kredit Anda ditolak. Mohon periksa kembali nomor kartu Anda.' : 'Your card is declined. Please review your card number.') : ''}
                  {this.state.card_status === 'incorrect_cvc' ? (GetText() ? 'Kartu kredit Anda ditolak. Mohon periksa kembali CVC kartu Anda.' : 'Your card is declined. Please review your card CVC.') : ''}
                  {this.state.card_status === 'incorrect_expiry' ? (GetText() ? 'Kartu kredit Anda ditolak. Mohon periksa kembali kadaluarsa kartu Anda.' : 'Your card is declined. Please review your card expiration.') : ''}
                  {this.state.card_status === 'declined' ? (GetText() ? 'Kartu kredit Anda ditolak. Mohon periksa kembali data kartu Anda.' : 'Your card is declined. Please review your card data.') : ''}
                  {this.state.card_status === '3dsecure' ? (<iframe width="100%" height="600" src={this.state.card_url} title="3dsecure"></iframe>) : <></>}
                  {this.state.card_status === 'qrscan' ? (
                    <div id="qris" className="text-center">
                      <div className="qr-container">
                        <QRCode className="qr-code" value={this.state.qr_string}/>
                      </div>
                    </div>
                  ) : ''}
                </Modal.Body>

                <Modal.Footer>
                {
                  this.state.card_status === '3dsecure' ? 
                    (<a className="btn btn-primary" href="#!" onClick={() => this.closeModal()}>{GetText() ? "Batal" : "Cancel"}</a>)
                  : this.state.card_status === 'qrscan' ? 
                    ( <>
                        <span onClick={this.downloadQr} className="btn btn-secondary"><FaDownload/> Download</span>
                        <a className="btn btn-primary" href="#!" onClick={() => this.closeModal()}>{GetText() ? "Selesai" : "Done"}</a>
                      </>
                    )
                  :  
                  <>
                    <a className="btn btn-primary" href="#!" onClick={() => this.closeModal()}>{GetText() ? "Ulangi" : "Retry"}</a>
                    <a className="btn btn-outline-primary" href="/">{GetText() ? "Kembali ke Beranda" : "Back to Home"}</a>
                  </>
                } 
                </Modal.Footer>
               
              </Modal>
            </Container>
          )
        }
      </div>
      );
    }
  }