import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { FaArrowRight } from 'react-icons/fa';
import { GetText } from './helper';

export default class Error404 extends Component {

  render() {
    return (
      <div className="error403">
        <Container>
          <h1 className="mb-4">{GetText() ? "Akses ditolak" : "Access denied"}</h1>
          <p></p>
          <div className="mt-4">
            <p>{GetText() ? "Maaf, Anda tidak diperkenankan untuk mengakses file." : "We're sorry, but you're not allowed to access the file."}</p>
            <a href="#!" className="link-more" onClick={() => {window.location.href = "/"}}>{GetText() ? "Kembali ke Beranda" : "Back to Home"} <FaArrowRight/></a>
          </div>
        </Container>
      </div>
    );
  }
}