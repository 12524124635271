import React, { Component } from 'react';
import '../ctinc/ct-Style.css';
import '../ctinc/ct-Global';
import '../ctinc/popup.css'
import CTHeader from './ct-fragmentheader';
import CTSidemenu from './ct-fragmentsidemenu';
import CTNA from './ct-fragmentna';
import ReplaceAllContent from '../../../config/replace-all';

const authKey = global.ctconfig.authBearerKey+"##"+global.ctconfig.kindcode[39];

class CTFeedbackDetail extends Component {
    constructor (props) {
        super(props);
        this.state = {
            stTitle: '',
            feedback_docid: '',
            feedback_number: '',
            feedback_text: '',
            feedback_type: '',
            feedback_status: '',
            option1: '',
            option2: '',
            option3: '',
            option4: '',
            option5: ''
        }
        this.loadDetailData = this.loadDetailData.bind(this);
    }
    loadDetailData(element,id) {
        document.getElementById('loading-area').style.display = 'block';
        fetch(global.ctconfig.urlLink+"/feedbackview/"+id, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': authKey,
            }
            }).then(res => {
            if(res.status === 200) 
                return res.json() 
            }).then( resJson => {
            document.getElementById('loading-area').style.display = 'none'; 
            if(resJson.status === '200') { 
                var lText,lNumber,lType,lStatus;
                var option1,option2,option3,option4,option5;
                var resultContent = resJson.values[0].content[0].maincontent;
                if(resultContent.feedbackText !== undefined) {
                    lText = ReplaceAllContent('[;]',',',resultContent.feedbackText);
                    lText = ReplaceAllContent('<br/>','\n',lText);
                }
                if(resultContent.feedbackNumber !== undefined) { lNumber = resultContent.feedbackNumber; }
                if(resultContent.feedbackType !== undefined) { lType = resultContent.feedbackType; }
                if(resultContent.feedbackStatus !== undefined) { lStatus = resultContent.feedbackStatus; }
                if(resultContent.option1 !== undefined) { option1 = resultContent.option1; }
                if(resultContent.option2 !== undefined) { option2 = resultContent.option2; }
                if(resultContent.option3 !== undefined) { option3 = resultContent.option3; }
                if(resultContent.option4 !== undefined) { option4 = resultContent.option4; }
                if(resultContent.option5 !== undefined) { option5 = resultContent.option5; }
                this.setState({
                    stTitle: 'Detail Question',
                    feedback_docid: resJson.values[0].docid,
                    feedback_text: lText,
                    feedback_number: lNumber,
                    feedback_type: lType,
                    feedback_status: lStatus,
                    option1: option1,
                    option2: option2,
                    option3: option3,
                    option4: option4,
                    option5: option5
                }); 
            } else {
                console.log('Something happened wrong');
            }
        }).catch(err => { 
            document.getElementById('loading-area').style.display = 'none'; 
        });
    }

    handleBack = () => {
    //   this.props.history.goBack();
      window.location.href=global.ctconfig.urlHost+'/ctfeedback';
    }

    componentDidMount() {
        var geturl = window.location; // this.props.location; 
        var arrString = geturl.pathname;
        var arrUrl = [];
        arrUrl = arrString.split('/');
        
        if(arrUrl[2] === 'ctviewfeedback') {
            if(arrUrl[3] !== '') { 
                this.loadDetailData(arrUrl[2],arrUrl[3]);
            }
        }
    }

    render() {
        return (
            <div className="main-container">
                <div  className="ct-header-main"><CTHeader/></div>
                <div className="flex-container">
                    <div className="sidebar-main"><CTSidemenu/></div>
                    <div className="main-content">
                        <div className="ct-stTitle">Feedback</div>
                        {((localStorage.getItem('ctulevel') === global.ctconfig.userlevel[0]) || (JSON.parse(localStorage.getItem('cturole'))[2] === "ON") || (JSON.parse(localStorage.getItem('cturole'))[3] === "ON")  || (JSON.parse(localStorage.getItem('cturole'))[4] === "ON"))? 
                        <div>
                            <div className="stIndiTitle">Feedback { this.state.stTitle !== ''? '> '+this.state.stTitle : '' }</div>
                            <div className="mgT30">
                                {/* ====== */}
                                <div className="content-container card card-body">  
                                    <div className="row-field">
                                        <div className="w80p">
                                            <h4>Question {this.state.feedback_number}</h4>
                                        </div>
                                        <div className="divRight w20p"><div><button className="backButton" onClick={this.handleBack}>&#9664; back</button></div></div>
                                    </div>
                                    <div className="divRight">
                                    </div>
                                    <div className="mgT30">
                                        <div className="mgT20 flex-container">
                                            <div className="w20p"><h6>Number</h6></div>
                                            <div className="w80p"><span className="mgR10">:</span>{this.state.feedback_number}</div>
                                        </div>
                                        <div className="mgT20">
                                          <div><h6>Question :</h6></div>
                                          <div>{this.state.feedback_text}</div>
                                        </div>
                                        <div className="mgT20">
                                          <div><h6>Options :</h6></div>
                                          <ol className="list-alpha">
                                            {this.state.option1 ? <li>{this.state.option1}</li> : ''}
                                            {this.state.option2 ? <li>{this.state.option2}</li> : ''}
                                            {this.state.option3 ? <li>{this.state.option3}</li> : ''}
                                            {this.state.option4 ? <li>{this.state.option4}</li> : ''}
                                            {this.state.option5 ? <li>{this.state.option5}</li> : ''}
                                          </ol>
                                        </div>
                                        <div className="mgT20 flex-container">
                                            <div className="w20p"><h6>Type</h6></div>
                                            <div className="w80p"><span className="mgR10">:</span>{this.state.feedback_type}</div>
                                        </div>
                                        <div className="mgT05 flex-container">
                                            <div className="w20p"><h6>Status</h6></div>
                                            <div className="w80p"><span className="mgR10">:</span>{this.state.feedback_status === 1 ? 'Active' : 'Inactive'}</div>
                                        </div>
                                    </div>
                                    <div id="loading-area" className="divHide loading"></div> 
                                </div>
                                {/* ====== */}
                            </div>
                        </div>
                        : 
                        <div><CTNA/></div>
                        }
                    </div>
                </div>
                <div id="loading-area" className="divHide loading"></div>
            </div>
          );
        }
     }
      
export default CTFeedbackDetail;
