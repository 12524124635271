import React, { Component } from 'react';
import '../ctinc/ct-Style-2.css';
import '../ctinc/ct-Style.css';
import '../ctinc/ct-Global';
import DateTimeFormats from '../../../config/date-time-format';
import { FormErrors } from '../ctinc/error-form';
import Alert from '@material-ui/lab/Alert';
import PostAddIcon from '@material-ui/icons/PostAdd';
import DatePicker from 'react-date-picker';
import NAimage from '../../assets/na.png';

const authKey = global.ctconfig.authBearerKey+"##"+global.ctconfig.kindcode[13];
const authKey2 = global.ctconfig.authBearerKey+"##"+global.ctconfig.kindcode[14];
class CTSubscribeDetail extends Component {
    constructor (props) {
        super(props);
        this.state = {
            stPopTitle: '',
            memberdoc_id: '',
            subscribedoc_id: '',
            member_fullname: '',
            member_email: '',
            member_plan: '',
            member_status_subscribe: '',
            member_expired_subscribe: '',
            member_subscribe_plan: '',
            member_subscribe_reqdate: '',
            member_subscribe_period: '',
            member_subscribe_period_type: '',
            member_subscribe_startdate: '',
            member_subscribe_enddate: '',
            member_subscribe_remarks: '',
            member_subscribe_price: 0,
            member_subscribe_grand_total: 0,
            member_subscribe_currency: '',
            member_subscribe_voucher_code: '',
            member_subscribe_voucher_price: 0,
            member_subscribe_verified_date: '',
            member_subscribe_verified_byid: '',
            member_subscribe_verified_byname: '',
            member_subscribe_payment_method: '',
            member_subscribe_payment_notes: '',
            member_subscribe_status: '',
            member_subscribe_received_date: '',
            member_subscribe_received_price: '',
            subscribe_payment_received_date: '',
            subscribe_payment_received_price: '',
            subscribe_payment_verified_comment: '',
            formErrors: {subscribe_payment_received_date: '',subscribe_payment_received_price: '',subscribe_payment_verified_comment: ''},
            paymentReceivedDateValid: false,
            paymentReceivedPriceValid: false,
            formValid: false,
            errmsg: '',
            member_subscribe_confirmation_date: '',
            member_subscribe_confirmation_remarks: '',
            member_subscribe_confirmation_photo: ''
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.loadFormSubscribe = this.loadFormSubscribe.bind(this);
    }
    
    loadFormSubscribe() {
        var othersContent = JSON.parse(document.getElementById("contentoth").innerText);
        var memberId = othersContent.member_id;
        var subscribeId = othersContent.subscribe_id;
        fetch(global.ctconfig.urlLink+"/trainingdetailsubscribe/"+memberId+"/"+subscribeId, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': authKey,
            }
          }).then(res => {
            if(res.status === 200) 
                return res.json() 
          }).then( resJson => {
            console.log(resJson.values[0]);
            document.getElementById('loading-area').style.display = 'none'; 
            if(resJson.status === '200') {
                var memberPlan='Guest', memberStatusSubscribe='',expiredSubscribeDate='';
                var memberSubscribePlan = '', memberSubscribePrice=0, memberSubscribeGTotal=0, memberSubscribePeriod='', memberSubscribePeriodType='', memberSubscribeStartDate='', memberSubscribeEndDate='', memberSubscribeReqDate='', memberSubscribeRemarks='', memberSubscribeCurrency='';
                var memberSubscribeVerifiedDate='', memberSubscribeVerifidById='', memberSubscribeVerifiedByName='', memberSubscribePaymentMethod='', memberSubscribePaymentNotes=''; 
                var memberSubscribeReceivedDate= '', memberSubscriveReceivedPrice='';
                var memberSubscribeVoucherCode='',memberSubscribeVoucherAmount=0;
                if(resJson.values[0].content[0].maincontent.memberPlan !== undefined) { memberPlan = resJson.values[0].content[0].maincontent.memberPlan; }
                if(resJson.values[0].content[0].maincontent.statusSubscribeMember !== undefined) { memberStatusSubscribe = resJson.values[0].content[0].maincontent.statusSubscribeMember; }
                if(resJson.values[0].content[0].maincontent.expiredSubscribeMember !== undefined) { expiredSubscribeDate = DateTimeFormats(resJson.values[0].content[0].maincontent.expiredSubscribeMember._seconds,"date-long"); }
                
                if(resJson.values[0].scontent[0].subcontent.subscribePlan !== undefined) { memberSubscribePlan = resJson.values[0].scontent[0].subcontent.subscribePlan; }
                if(resJson.values[0].scontent[0].subcontent.subscribePrice !== undefined) { memberSubscribePrice = resJson.values[0].scontent[0].subcontent.subscribePrice; }
                if(resJson.values[0].scontent[0].subcontent.subscribeGTotal !== undefined) { memberSubscribeGTotal = resJson.values[0].scontent[0].subcontent.subscribeGTotal; }
                if(resJson.values[0].scontent[0].subcontent.paymentCurrency !== undefined) { memberSubscribeCurrency = resJson.values[0].scontent[0].subcontent.paymentCurrency; }
                if(resJson.values[0].scontent[0].subcontent.subscribeVoucherCode !== undefined) { memberSubscribeVoucherCode = resJson.values[0].scontent[0].subcontent.subscribeVoucherCode; }
                if(resJson.values[0].scontent[0].subcontent.subscribevoucheramount !== undefined) { memberSubscribeVoucherAmount = resJson.values[0].scontent[0].subcontent.subscribevoucheramount; }
                
                if(resJson.values[0].scontent[0].subcontent.orderPeriod !== undefined) { memberSubscribePeriod = resJson.values[0].scontent[0].subcontent.orderPeriod; }
                if(resJson.values[0].scontent[0].subcontent.orderType !== undefined) { memberSubscribePeriodType = resJson.values[0].scontent[0].subcontent.orderType; }
                if(resJson.values[0].scontent[0].subcontent.dateStart !== undefined) { memberSubscribeStartDate = DateTimeFormats(resJson.values[0].scontent[0].subcontent.dateStart._seconds,"datetime-long"); }
                if(resJson.values[0].scontent[0].subcontent.dateEnd !== undefined) { memberSubscribeEndDate = DateTimeFormats(resJson.values[0].scontent[0].subcontent.dateEnd._seconds,"datetime-long"); }
                if(resJson.values[0].scontent[0].subcontent.requestSubscribeDate !== undefined) { memberSubscribeReqDate = DateTimeFormats(resJson.values[0].scontent[0].subcontent.requestSubscribeDate._seconds,"datetime-long"); }
                if(resJson.values[0].scontent[0].subcontent.verifiedSubscribeDate !== undefined) { memberSubscribeVerifiedDate = DateTimeFormats(resJson.values[0].scontent[0].subcontent.verifiedSubscribeDate._seconds,"datetime-long"); }
                if(resJson.values[0].scontent[0].subcontent.verifiedSubscribeById !== undefined) { memberSubscribeVerifidById = resJson.values[0].scontent[0].subcontent.verifiedSubscribeById; }
                if(resJson.values[0].scontent[0].subcontent.verifiedSubscribeByName !== undefined) { memberSubscribeVerifiedByName = resJson.values[0].scontent[0].subcontent.verifiedSubscribeByName; }
                if(resJson.values[0].scontent[0].subcontent.paymentMethod !== undefined) { memberSubscribePaymentMethod = resJson.values[0].scontent[0].subcontent.paymentMethod; }

                if(resJson.values[0].scontent[0].subcontent.paymentBank !== undefined) { memberSubscribePaymentNotes += 'Transfer to : <br/>'+resJson.values[0].scontent[0].subcontent.paymentBank; }
                if(resJson.values[0].scontent[0].subcontent.paymentAccount !== undefined) { memberSubscribePaymentNotes += '<br/>'+resJson.values[0].scontent[0].subcontent.paymentAccount; }
                
                if(resJson.values[0].scontent[0].subcontent.paymentReceivedDate !== undefined) { memberSubscribeReceivedDate = DateTimeFormats(resJson.values[0].scontent[0].subcontent.paymentReceivedDate._seconds,"datetime-long"); }
                if(resJson.values[0].scontent[0].subcontent.paymentReceivedPrice !== undefined) { memberSubscriveReceivedPrice = resJson.values[0].scontent[0].subcontent.paymentReceivedPrice; }
                if(resJson.values[0].scontent[0].subcontent.remarks !== undefined) { memberSubscribeRemarks = resJson.values[0].scontent[0].subcontent.remarks; }
                
                this.setState({
                    stPopTitle: '',
                    memberdoc_id: memberId,
                    subscribedoc_id: subscribeId,
                    
                    member_fullname: othersContent.member_fullname,
                    member_email: othersContent.member_email,
                    member_plan: memberPlan,
                    member_status_subscribe: memberStatusSubscribe,
                    member_expired_subscribe: expiredSubscribeDate,
                    member_subscribe_plan: memberSubscribePlan,
                    member_subscribe_reqdate: memberSubscribeReqDate,
                    member_subscribe_period: memberSubscribePeriod,
                    member_subscribe_period_type: memberSubscribePeriodType,
                    member_subscribe_startdate: memberSubscribeStartDate,
                    member_subscribe_enddate: memberSubscribeEndDate,
                    member_subscribe_remarks: memberSubscribeRemarks,
                    member_subscribe_price: memberSubscribePrice,
                    member_subscribe_grand_total: memberSubscribeGTotal,
                    member_subscribe_currency: memberSubscribeCurrency,
                    member_subscribe_voucher_code: memberSubscribeVoucherCode,
                    member_subscribe_voucher_price: memberSubscribeVoucherAmount,
                    member_subscribe_verified_date: memberSubscribeVerifiedDate,
                    member_subscribe_verified_byid: memberSubscribeVerifidById,
                    member_subscribe_verified_byname: memberSubscribeVerifiedByName,
                    member_subscribe_payment_method: memberSubscribePaymentMethod,
                    member_subscribe_payment_notes: memberSubscribePaymentNotes,
                    member_subscribe_status: resJson.values[0].scontent[0].subcontent.subscribeStatus,
                    member_subscribe_received_date: memberSubscribeReceivedDate,
                    member_subscribe_received_price: memberSubscriveReceivedPrice,
                    subscribe_payment_received_date: '',
                    subscribe_payment_received_price: '',
                    subscribe_payment_verified_comment: '',
                    formErrors: {subscribe_payment_received_date: '',subscribe_payment_received_price: '',subscribe_payment_verified_comment: ''},
                    paymentReceivedDateValid: false,
                    paymentReceivedPriceValid: false,
                    formValid: false,
                    errmsg: '',                    
                    member_subscribe_confirmation_date: resJson.values[0].scontent[0].subcontent.paymentConfirmationDate,
                    member_subscribe_confirmation_remarks: resJson.values[0].scontent[0].subcontent.paymentConfirmationRemarks,
                    member_subscribe_confirmation_photo: resJson.values[0].scontent[0].subcontent.paymentConfirmationPhoto
                });
            } else {
                console.log('Something happened wrong');
            }
          }).catch(err => { 
            document.getElementById('loading-area').style.display = 'none'; 
          }); 
        
    }
    urlcancelForm(valId,divId) {
        document.getElementById(divId).style.display = "none";
        document.getElementById("subscribe-detail-info").style.display = "block";
    }
    urladdPayment(valId) {
        document.getElementById("subscribe-payment-form").style.display = "block";
        document.getElementById("subscribe-detail-info").style.display = "none";
        this.loadFormSubscribe();
    }
    handlePaymentDate = date => {
        this.setState( prevState => {
            return {  
                ...prevState.subscribe_payment_received_date, subscribe_payment_received_date: date
            }
          }, () => { this.validateField('subscribe_payment_received_date', date)
        }) 
    }
    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value},
            () => { this.validateField(name, value)});
    } 
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let paymentReceivedDateValid = this.state.paymentReceivedDateValid;
        let paymentReceivedPriceValid = this.state.paymentReceivedPriceValid;
        
        switch(fieldName) {
            case 'subscribe_payment_received_date': 
                paymentReceivedDateValid = (value !== null && value !== '');
                fieldValidationErrors.subscribe_payment_received_date = paymentReceivedDateValid ? '' : 'payment received date need to be filled';
                break;
            case 'subscribe_payment_received_price': 
                if(value.trim().length > 0) {
                    paymentReceivedPriceValid = Number(value.trim()) >= Number(this.state.member_subscribe_grand_total);
                    fieldValidationErrors.subscribe_payment_received_price = paymentReceivedPriceValid ? '' : 'payment price received need to be paid neither less than '+this.state.member_subscribe_grand_total;
                } else {
                    paymentReceivedPriceValid = value.trim().length > 0;
                    fieldValidationErrors.subscribe_payment_received_price = paymentReceivedPriceValid ? '' : 'payment price received need to be filled';
                }
                break;
            default:
                break;
        }
        this.setState({formErrors: fieldValidationErrors,
                        paymentReceivedDateValid: paymentReceivedDateValid,
                        paymentReceivedPriceValid: paymentReceivedPriceValid,
                    }, this.validateForm);                            
      }
      validateForm() {
        this.setState({formValid: this.state.paymentReceivedDateValid && this.state.paymentReceivedPriceValid });
      }
    
      errorClass(error) {
        return(error.length === 0 ? '' : 'has-error');
      }
      handleSubmit(e) {
        e.preventDefault();
        if(e.target.id === "form-subscribe-payment") { 
            if(this.state.subscribe_payment_received_date !== "" && (this.state.subscribe_payment_received_price !== "" && this.state.subscribe_payment_received_price >= this.state.member_subscribe_grand_total)) {
                document.getElementById('loading-area').style.display = 'block'; 
                var urlFetch2, datatext2;
                var subscribePaymentReceivedDate = this.state.subscribe_payment_received_date;
                var subscribePaymentReceivedPrice = this.state.subscribe_payment_received_price;
                var subscribePaymentVerifiedComment = this.state.subscribe_payment_verified_comment; 
                if(this.textInputPaymentReceivedPrice.value !== '') { subscribePaymentReceivedPrice = this.textInputPaymentReceivedPrice.value; } 
                if(this.textInputPaymentVerifiedComment.value !== '') { subscribePaymentVerifiedComment = this.textInputPaymentVerifiedComment.value; } 
                
                datatext2 ='{"subscribe_payment_received_date":"'+subscribePaymentReceivedDate+'","subscribe_payment_received_price":"'+subscribePaymentReceivedPrice+'","subscribe_payment_verified_comment":"'+subscribePaymentVerifiedComment+'","subscribe_periode":"'+this.state.member_subscribe_period+'","subscribe_periode_type":"'+this.state.member_subscribe_period_type+'","memberdoc_id":"'+this.state.memberdoc_id+'","subscribedoc_id":"'+this.state.subscribedoc_id+'","form_id":"'+e.target.id+'"}';
                urlFetch2 = global.ctconfig.urlLink+"/trainingsubscribeaddpayment";
                var data2 = JSON.parse(datatext2); 
            
                fetch(urlFetch2, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': authKey2,
                    }, body : JSON.stringify(data2)
                }).then(res => {
                    if(res.status === 200) 
                        return res.json() 
                }).then( resJson => {
                    document.getElementById('loading-area').style.display = 'none'; 
                    if(resJson.status === '200') {
                        document.getElementById("subscribe-payment-form").style.display = "none";
                        document.getElementById("subscribe-detail-info").style.display = "block";
                        this.loadFormSubscribe();
                    } else {
                        console.log('Something happened wrong');
                    }
                }).catch(err => err);
            } else {
                var arrfieldName2 = ['subscribe_payment_received_date','subscribe_payment_received_price'];
                var arrvalue2 = [this.state.subscribe_payment_received_date,this.textInputPaymentReceivedPrice.value];
                for(var ck2 in arrfieldName2) {
                    this.validateField(arrfieldName2[ck2], arrvalue2[ck2]);
                }
            }
        }
      }
    componentDidMount() {
        document.getElementById('loading-area').style.display = 'block'; 
        document.getElementById("errormsg").style.display = 'none';
        this.loadFormSubscribe();
    }
    render() {
      return (
        <div className="main-container">
            <div className="mgT20">
            <div className="divhide w100p" id="errormsg"><Alert severity="error">{this.state.errmsg}</Alert></div>
              <div className="mgL20">
                <div className="st3Title">{this.state.member_fullname}</div>
                <div><h5 className="detailJoined">{this.state.member_email}</h5></div>
              </div>
              <div className="mgT20 mgL20">
                {/* ---- */}
                <div>

                    <div className="mgT05 flex-container">
                        <div className="w30p">Status Membership </div>
                        <div className="mgL10"> <span className={this.state.member_plan === "Member"? "fontGreen textBold" : "textBold"}>{this.state.member_plan}</span> </div>
                    </div>
                    {(this.state.member_expired_subscribe !== '')? 
                    <div className="mgT05 flex-container">
                        <div className="w30p">Expired On </div>
                        <div className="mgL10"> {this.state.member_expired_subscribe} </div>
                    </div>
                    : ''}
                    
                    <div className="mgT30 lineBottom">
                        <div className="flex-container">
                            <div className="w30p textBold">Detail Information</div>
                            { 
                                // this.state.member_subscribe_confirmation_date && (this.state.member_subscribe_verified_date === '') ? (
                                    (this.state.member_subscribe_verified_date === '') ? (
                                    <div className="w70p divRight">
                                        <div className="mgR05 linkText cursor-on" onClick={this.urladdPayment.bind(this, this.state.subscribedoc_id)}><PostAddIcon className="mgR05" style={{fontSize:"15pt"}}></PostAddIcon><span style={{marginTop:"3px"}}>Verify Payment &raquo;</span></div>
                                    
                                    </div>
                                ) : ('')
                            }
                        </div>
                    </div>
                    { /* --------- */ }
                    <div className="mgT20 divHide" id="subscribe-payment-form">
                    <form id="form-subscribe-payment" onSubmit={this.handleSubmit}>
                        <div className="smallForm">
                            <div className="flex-container mgT10">
                                <div className="w40p"><label className="mgT10" htmlFor="subscribe_payment_received_date">Payment Received On</label></div>
                                <div className="w60p">
                                    <DatePicker
                                        onChange={this.handlePaymentDate}
                                        value={this.state.subscribe_payment_received_date}
                                        customStyles={{dateInput:{borderWidth: 0}}}
                                        />
                                    <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.subscribe_payment_received_date}/>  }</div>
                                    <div className="mgT05 notesText">
                                    Input date when the payment has been received in the bank account.
                                    </div>
                                </div>
                            </div>

                            <div className="flex-container mgT10">
                                <div className="w40p"><label className="mgT05" htmlFor="subscribe_payment_received_price">Payment Received</label></div>
                                <div className="w60p flex-container">
                                    <div className="mgR20 mgT10">{this.state.member_subscribe_currency} </div>
                                    <div>
                                        <input type="number" className="form-control" name="subscribe_payment_received_price"
                                            placeholder={this.state.subscribe_payment_received_price}
                                            value={this.state.subscribe_payment_received_price}
                                            onChange={this.handleUserInput}  
                                            ref={(input) => this.textInputPaymentReceivedPrice = input}/> 
                                        <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.subscribe_payment_received_price}/>  }</div>
                                        <div className="mgT05 notesText">
                                        Input the amount of payment which has been received in the bank account.
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="mgT30 textBold">Remarks</div>
                            <div className="mgT05">
                                <textarea name="subscribe_payment_verified_comment" className="form-control" 
                                    rows={4} cols={30} 
                                    value={this.state.subscribe_payment_verified_comment} 
                                    onChange={this.handleUserInput} 
                                    ref={(input) => this.textInputPaymentVerifiedComment = input}
                                />
                                
                                <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.subscribe_payment_verified_comment}/>  }</div>
                            </div>
                            <div className="mgT30 divCenter">
                                <div className="flex-container">
                                <button type="submit" className="ct-submitButton mgR20">Submit</button>
                                <div className="ct-submitButton cursor-on" onClick={this.urlcancelForm.bind(this, this.state.subscribedoc_id, "subscribe-payment-form")}>Cancel</div>
                                </div>
                            </div>

                        </div>
                    </form>
                    </div>
                    { /* --------- */ }
                    
                    { /* --------- */ }
                    <div className="mgT20" id="subscribe-detail-info">
                        <div className="mgT20">
                            <div className="mgT10 flex-container">
                                <div className="w30p">Status Request </div>
                                <div className="mgL10">      
                                    {
                                        this.state.member_subscribe_status === 'New Request' ? (
                                            this.state.member_subscribe_confirmation_date ? 'Verifying' : 'New Request'
                                        ) : 'Completed'
                                    }
                                </div>
                            </div>
                            {(this.state.member_subscribe_reqdate !== '')? 
                            <div className="mgT05 flex-container">
                                <div className="w30p">Request On </div>
                                <div className="mgL10 "> {this.state.member_subscribe_reqdate} </div>
                            </div>
                            : ''}
                            <div className="mgT10 flex-container">
                                <div className="w30p">Subscription Plan </div>
                                <div className="mgL10"> {this.state.member_subscribe_plan} </div>
                            </div>
                            <div className="mgT05 flex-container">
                                <div className="w30p">Plan Periode </div>
                                <div className="mgL10"> {this.state.member_subscribe_period} {this.state.member_subscribe_period_type}  </div>
                            </div>
                            {(this.state.member_subscribe_startdate !== '')? 
                            <div className="mgT05 flex-container">
                                <div className="w30p">Subscribe on </div>
                                <div className="mgL10"> {this.state.member_subscribe_startdate}</div>
                            </div>
                            : ''}
                            {(this.state.member_subscribe_enddate !== '')? 
                            <div className="mgT05 flex-container">
                                <div className="w30p">Will be Expired on </div>
                                <div className="mgL10"> {this.state.member_subscribe_enddate} </div>
                            </div>
                            : ''}
                           
                        </div>
                        
                        <div className="mgT30 textBold">Payment Information</div>
                        <div className="mgL10">
                            <div className="mgT10 flex-container">
                                <div className="w30p">Status Payment </div>
                                <div className="mgL10"> {(this.state.member_subscribe_verified_byid !== '')? <span className="textBold">Verified</span> : 'Not Verified' } </div>
                            </div>
                            {(this.state.member_subscribe_verified_byid !== '')?
                            <div className="mgT05 flex-container">
                                <div className="w30p">Verified On </div>
                                <div className="mgL10"> {this.state.member_subscribe_verified_date} <span className="mgL10">by.</span> {this.state.member_subscribe_verified_byname} </div>
                            </div>
                            : '' } 
                            <div className="mgT05 flex-container">
                                <div className="w30p">Total Price </div>
                                <div className="mgL10"> {this.state.member_subscribe_currency + "  " +this.state.member_subscribe_grand_total.toLocaleString('en-US',{maximumFractionDigits:2})} </div>
                            </div>
                            {(this.state.member_subscribe_voucher_price > 0)?
                            <div className="mgT05 flex-container">
                                <div className="w30p"> </div>
                                <div className="mgL10"> 
                                    <span>Subscription Price : {this.state.member_subscribe_currency + "  " +this.state.member_subscribe_price.toLocaleString('en-US',{maximumFractionDigits:2})} </span><br/>
                                    <span>Voucher : {this.state.member_subscribe_currency + "  " +this.state.member_subscribe_voucher_price.toLocaleString('en-US',{maximumFractionDigits:2})  +"  ("+ this.state.member_subscribe_voucher_code+")"}</span>
                                </div>
                            </div>
                            : ''
                            }
                            <div className="mgT05 flex-container">
                                <div className="w30p">Payment Method </div>
                                <div className="mgL10"> 
                                    <span>{this.state.member_subscribe_payment_method} </span>
                                    <br/><span>{this.state.member_subscribe_payment_notes}</span>
                                    {(this.state.member_subscribe_received_date !== '')?<span className="mgT10"><br/>Payment Received On : {this.state.member_subscribe_received_date}</span>: ''}
                                    {(this.state.member_subscribe_received_price !== '')? <span><br/>with amount of payment is {this.state.member_subscribe_currency + "  " +this.state.member_subscribe_received_price.toLocaleString('en-US',{maximumFractionDigits:2})}</span>: ''}
                                    
                                </div>
                            </div>
                            {(this.state.member_subscribe_remarks !== '')?
                            <div className="mgT05 flex-container">
                                <div className="w30p">Remarks </div>
                                <div className="mgL10"> {this.state.member_subscribe_remarks} </div>
                            </div>
                            : ''}
                        </div>
                        {
                            this.state.member_subscribe_confirmation_date ? (
                                <>
                                    <div className="mgT30 textBold">Payment Confirmation</div>
                                    <div className="mgL10">
                                            <div className="mgT05 flex-container">
                                                <div className="w30p">Confirmation Date </div>
                                                <div className="mgL10"> {DateTimeFormats(this.state.member_subscribe_confirmation_date._seconds,"date-long")} </div>
                                            </div>
                                            <div className="mgT05 flex-container">
                                                <div className="w30p">Remarks </div>
                                                <div className="mgL10"> {this.state.member_subscribe_confirmation_remarks} </div>
                                            </div>
                                            <div className="mgT05 flex-container">
                                                <div className="w30p">Transfer Receipt </div>
                                                <div className="mgL10">
                                                {
                                                        this.state.member_subscribe_confirmation_photo && (this.state.member_subscribe_confirmation_photo !== "Please upload a file") ?  (
                                                            <a href={this.state.member_subscribe_confirmation_photo} target="_blank" rel="noreferrer">
                                                                <img width="150" src={this.state.member_subscribe_confirmation_photo} alt=""/>
                                                            </a>
                                                        ) : (
                                                            <img width="150" src={NAimage} alt=""/>
                                                        )
                                                    }
                                                </div>
                                            </div>                        
                                    </div>
                                </>
                            ) : ''
                        }                        
                    </div>
                </div>
                {/* ---- */}
              </div>
              <div className="row-field mgT30 mgB30">                    
              </div>
            </div>
            <div id="loading-area" className="divHide loading"></div> 
        </div>
      );
    }
 }
  
export default CTSubscribeDetail;