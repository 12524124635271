import React, { Component } from 'react';
import { GetText, SearchList } from '../helper';
import { Container, InputGroup, FormControl, Tab, Nav, Card } from 'react-bootstrap';
import { FaSearch, FaArrowDown } from 'react-icons/fa';
import LibraryBlock from './library/Block';
import LibraryList from './library/List';
import '../config/global';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[9];

export default class LibraryBrowse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      libraryList: [],
      libraryFeatured: [],
      libraryCategories: [],
      isLoad: false
    }
  }

  componentDidMount() {
    var dataText ='{"keyword":"","pageShow":"10","lang":"'+localStorage.getItem('language')+'","form_id":"pubonly"}';
    var dataSend = JSON.parse(dataText);

    fetch(global.config.urlLink+ "/librarylist", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey
      }, 
      body : JSON.stringify(dataSend)
    })
    .then(response => {
      if (response.status === 200) {
        return response.json();
      } else {
        console.log('Something happened wrong');
      }
    })
    .then(resultJson => {
      var resultData = [];
      var inum=0;
      for (var i in resultJson.values) {
        var content = resultJson.values[i].content;
          content.id = resultJson.values[i].docid;
          resultData.push(content);
          if (this.state.libraryCategories.indexOf(content.categoryName) < 0) {
            this.state.libraryCategories.push(content.categoryName);
          }
          inum++;
          if(inum === resultJson.values.length) {
            this.setState({
              libraryList: resultData,
              isLoad: true
            });
          }
      }
    });
  }

  render() {
    return (
      <div className="browse-library">

        {
          this.state.libraryList.length > 0 ? (
            <>
            <section>
              <Container>
                <h1 className="mb-4">Library</h1>
                <p className="mb-5">{GetText() ? "Jelajahi katalog lengkap pelatihan dari pakar kami yang mencakup berbagai topik mulai dari dasar-dasar cloud platform hingga transformasi pemasaran digital, yang dirancang untuk membantu Anda mengembangkan keterampilan dan pengetahuan yang Anda butuhkan untuk terus maju dalam karier maupun bisnis Anda. Belajar dengan cepat dan akses 24/7 ke pelatihan kami dari perangkat apa pun." : "Explore our extensive catalog of expert-led courses covering a wide range of topics from basic knowledge to digital marketing, designed to help you develop the skills and knowledge you need to succeed in your career or business. Learn at your own pace with 24/7 access to our courses from any device."}</p>
                <div className="separator mt-0"><div className="icon"><FaArrowDown/></div></div>
              </Container>
            </section>
            <section>
              <Container>
                <h3>{GetText() ? "Library Terbaru" : "Latest Libraries"}</h3>
                <div className="row py-4 mb-5">
                  {
                    this.state.libraryList                    
                    .sort((a,b) => b.createDate._seconds - a.createDate._seconds)
                    .slice(0,4)
                    .map((libraryItem, i) => {
                      return (
                        <div className="col-md-3" key={i}>
                          <LibraryBlock value={libraryItem}/>
                        </div>
                      )
                    })
                  }
                </div>
              </Container>
              <div className="separator mt-0"><div className="icon"><FaArrowDown/></div></div>
            </section>
            </>
          ) 
          : this.state.isLoad === true? (
            <div className="mt-5 mb-5">
              <div style={{height:"100px"}}></div>
              <div className="d-flex align-items-center justify-content-center">
                <Card className='card-pop'>
                  <Card.Body className="text-center">
                    <h1 className="mb-4" style={{fontSize:"50pt", color:"#DC3545"}}>COMING SOON</h1>
                    <p style={{fontSize:"13pt", color:"#595959"}}>{GetText() ?  `Tetaplah bersama kami. Hal yang menarik akan segera hadir. \nJadilah yang pertama mengetahuinya.` : `Stay tuned. Something awesome is coming soon. \nBe the first to know.` } </p>
                  </Card.Body>
                </Card>
              </div>
              <div style={{height:"200px"}}></div>
            </div>
          )
          : <></>
        }
        {
          this.state.libraryCategories.length > 0 ? (
            <section>
              <Container>
                <div className="row py-4">
                  <Tab.Container defaultActiveKey="category-all">
                    <div className="col-md-3">
                      <div className="sidebar-tab sticky-top">
                        <h3 className="mb-4">{GetText() ? "Pencarian" : "Search"}</h3>
                        <InputGroup className="mb-5">
                          <FormControl
                            placeholder={GetText() ? "Ketik di sini..." : "Type here..."}
                            aria-label="Library"
                            aria-describedby="search"
                            id="search"
                            onKeyUp={SearchList}
                         />
                          <InputGroup.Append>
                            <InputGroup.Text><FaSearch/></InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                        <h3 className="mb-4">{GetText() ? "Kategori" : "Category"}</h3>
                        <Nav variant="pills" className="nav-scroll flex-column">
                          <Nav.Item key="all">
                            <Nav.Link eventKey="category-all">{GetText() ? "Semua" : "All"}</Nav.Link>
                          </Nav.Item>
                          {
                            this.state.libraryCategories
                            .sort((a, b) => { return (a < b) ? -1 : ((a > b) ? 1 : 0) })
                            .map((libraryCategory, i) => {
                              return (
                                <Nav.Item key={i}>
                                  <Nav.Link eventKey={"category-" + i}>{libraryCategory}</Nav.Link>
                                </Nav.Item>
                              )
                            })
                          }
                        </Nav>
                      </div>
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-8 bg-light p-5 br-8">
                      <h3 className="mb-4">{GetText() ? "Daftar Library" : "Library List"}</h3>
                      <Tab.Content>
                        <Tab.Pane eventKey="category-all" key="all">
                          {
                            this.state.libraryList
                            .sort((a,b) => b.createDate._seconds - a.createDate._seconds)
                            .map((libraryItem, i) => {
                              return (
                                <LibraryList value={libraryItem} key={'option-' + i}/>
                              )
                            })
                          }
                        </Tab.Pane>
                        {
                          this.state.libraryCategories.map((libraryCategory, i) => {
                            return (
                              <Tab.Pane eventKey={"category-" + i} key={i}>
                                {
                                  this.state.libraryList
                                  .sort((a,b) => b.createDate._seconds - a.createDate._seconds)
                                  .filter(libraryItem => libraryItem.categoryName === libraryCategory)
                                  .map((libraryItem, j) => {
                                    return (
                                      <LibraryList value={libraryItem} key={'library-' + j}/>
                                    )
                                  })
                                }
                              </Tab.Pane>
                            )
                          })
                        }
                      </Tab.Content>
                    </div>
                  </Tab.Container>
                </div>
              </Container>
            </section>
          ) : ''
        }
      </div>
    );
  }
}