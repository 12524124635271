import React, { Component } from 'react';
import parse from 'html-react-parser';
// import Accordion from 'react-bootstrap/Accordion';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {Accordion, Card } from 'react-bootstrap';
// import { FaChevronRight, FaChevronDown } from 'react-icons/fa';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
// import AttachFileIcon from '@material-ui/icons/AttachFile';
import DescriptionIcon from '@material-ui/icons/Description';
import '../ctinc/ct-Style-2.css';
import DateTimeFormats from '../../../config/date-time-format';
import ReplaceAllContent from '../../../config/replace-all';
import Popup from '../ctinc/popup';
import CTHeader from './ct-fragmentheader';
import CTSidemenu from './ct-fragmentsidemenu';
import CTNA from './ct-fragmentna';
import CTFeedbackDetail from './ct-popfeedback-detail';
import NAimage from '../../assets/na.png';
import { ToggleArrow } from '../../../helper';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import VideoIcon from '@material-ui/icons/VideoLibrary';

const authKey = global.ctconfig.authBearerKey+"##"+global.ctconfig.kindcode[9];

class CTLibraryDetail extends Component {
  constructor (props) {
    super(props);
    this.state = {
        stTitle: '',
        library_docid: '',
        library_createby: '',
        library_createdate: '',
        library_title: '',
        library_short_desc: '',
        library_long_desc: '',
        library_language: '',
        library_picture: '',
        library_video: '',
        library_category: '',
        library_subcategory: '',
        library_lesson_list: [],
        library_exam: [],
        library_question_list: [],
        library_feedback_list: [],
        library_answer_list: [],
        isOpen: false,
        otherContent: "",
    }
    this.loadData = this.loadData.bind(this);
  }

  handleBack = () => {
    // this.props.history.goBack();
    window.location.href=global.ctconfig.urlHost+'/ctlibrary';
  }
  togglePopClose() {
    this.setState( prevState => {
      return {  
        ...prevState.isOpen, isOpen: false,
      }
    })
  }
  urldetailFeedback(valId, memberName, memberEmail, submitDate, answerList) {
    this.setState( prevState => {
      return {  
        ...prevState.isOpen, isOpen: true,
        ...prevState.otherContent, otherContent: '{"library_docid":"'+this.state.library_docid+'","library_title":"'+this.state.library_title+'","library_feedback_docid":"'+valId+'","member_name":"'+memberName+'","member_email":"'+memberEmail+'","submit_date":"'+submitDate+'","answer_list":'+JSON.stringify(answerList)+'}'
      }
    })
  }
  loadData(element,id) {
    if(element === "ctviewlibrary") {
      document.getElementById('loading-area').style.display = 'block'; 
      fetch(global.ctconfig.urlLink+"/libraryview/"+id, {
          method: 'GET',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey,
          }
        }).then(res => {
          if(res.status === 200) 
              return res.json() 
        }).then( resJson => {
          document.getElementById('loading-area').style.display = 'none'; 
          if(resJson.status === '200') {
              var lTitle="",lShortDesc="",lLongDesc="",lLanguage="",lPicture="",lVideo="",lCategory="",lSubCategory="",cDate="";
              var lExam ='',lQuestion ='',lAnswer=[];
              if(resJson.values[0].content[0].maincontent.libraryTitle !== undefined) { lTitle = resJson.values[0].content[0].maincontent.libraryTitle; }
              if(resJson.values[0].content[0].maincontent.libraryShortDesc !== undefined) {
                  lShortDesc = ReplaceAllContent('[;]',',',resJson.values[0].content[0].maincontent.libraryShortDesc);
                  lShortDesc = ReplaceAllContent('<br/>','\n',lShortDesc);
              }
              if(resJson.values[0].content[0].maincontent.libraryLongDesc !== undefined) { 
                  lLongDesc = ReplaceAllContent('[;]',',',resJson.values[0].content[0].maincontent.libraryLongDesc);
                  lLongDesc = ReplaceAllContent('<br/>','\n',lLongDesc);
              }
              if(resJson.values[0].content[0].maincontent.libraryLanguage !== undefined) { 
                  lLanguage = resJson.values[0].content[0].maincontent.libraryLanguage;
              }
              if(resJson.values[0].content[0].maincontent.categoryId !== undefined) { 
                  lCategory = resJson.values[0].content[0].maincontent.categoryName;
              }
              if(resJson.values[0].content[0].maincontent.subcategoryId !== undefined) { 
                  lSubCategory = resJson.values[0].content[0].maincontent.subcategoryName;
              }
              if(resJson.values[0].content[0].createdate !== undefined) {
                cDate = DateTimeFormats(resJson.values[0].content[0].createdate._seconds,"datetime-short");
              }
              if(resJson.values[0].content[0].maincontent.libraryPicture !== undefined && resJson.values[0].content[0].maincontent.libraryPicture !== "") { 
                lPicture = resJson.values[0].content[0].maincontent.libraryPicture;
              } else { lPicture = NAimage; }
              if(resJson.values[0].content[0].maincontent.libraryVideo !== undefined && resJson.values[0].content[0].maincontent.libraryVideo !== "") { 
                lVideo = resJson.values[0].content[0].maincontent.libraryVideo;
              }
              if(resJson.values[0].scontent2.length > 0) {
                lExam = resJson.values[0].scontent2[0].subcontent;
                lExam.docid = resJson.values[0].scontent2[0].subdocid;
                if(resJson.values[0].scontent2[0].sub2content.length > 0) {
                  lQuestion = resJson.values[0].scontent2[0].sub2content;
                }
              }
              if(resJson.values[0].scontent3.length > 0) {
                resJson.values[0].scontent3
                .sort((a,b) => b.subcontent.submitDate._seconds - a.subcontent.submitDate._seconds)
                .forEach((feedback, i) => {
                  feedback.sub2content
                  .sort((a, b) => a.subsubcontent.feedbackNumber - b.subsubcontent.feedbackNumber)
                  .forEach((subcontent, j) => {
                    var number = subcontent.subsubcontent.feedbackNumber;
                    var answer = subcontent.subsubcontent.feedbackAnswer;
                    if (number in lAnswer) {
                      var check = 0;
                      lAnswer[number].answer.forEach((item, k) => {
                        if (item.text === answer) {
                          check = 1;
                          item.counter++;
                        }
                      });
                      if (check === 0) {
                        lAnswer[number].answer.push({
                          'text': answer,
                          'counter': 1
                        });
                      }
                    } else {
                      lAnswer[number] = {
                        'question': subcontent.subsubcontent.feedbackText,
                        'type': subcontent.subsubcontent.feedbackType,
                        'answer': [
                          {
                            'text': answer,
                            'counter': 1
                          }
                        ]
                      } 
                    }        
                  });
                });
              }
              this.setState({
                  stTitle: 'Detail Library',
                  library_docid: resJson.values[0].docid,
                  library_createby: resJson.values[0].content[0].maincontent.createByName,
                  library_createdate: cDate,
                  library_title: lTitle,
                  library_short_desc: lShortDesc,
                  library_long_desc: lLongDesc,
                  library_language: lLanguage,
                  library_picture: lPicture,
                  library_video: lVideo,
                  library_category: lCategory,
                  library_subcategory: lSubCategory,
                  library_lesson_list: resJson.values[0].scontent,
                  library_exam: lExam,
                  library_question_list: lQuestion,
                  library_feedback_list: resJson.values[0].scontent3,
                  library_answer_list: lAnswer
              }) 
          } else {
              console.log('Something happened wrong');
          }
        }).catch(err => {
          document.getElementById('loading-area').style.display = 'none'; 
        }); 
    }
  }

  componentDidMount() {
    var geturl = window.location; // this.props.location; 
    var arrString = geturl.pathname;
    var arrUrl = [];
    arrUrl = arrString.split('/');
    
    if(arrUrl[2] === 'ctviewlibrary') {
        if(arrUrl[3] !== '') { 
            this.loadData(arrUrl[2],arrUrl[3]);
        }
    } 
  }

  render() {
   var lessonsList = [];
   if(this.state.library_lesson_list.length > 0) {
    this.state.library_lesson_list.sort((a, b) => a.subcontent.lessonNumber - b.subcontent.lessonNumber);
    for(var ls=0; ls < this.state.library_lesson_list.length; ls++) {
        var retResult3 = this.state.library_lesson_list[ls];
        var attachmentList = [];
        if(retResult3.sub2content.length > 0) {
          for(var ls2=0; ls2 < retResult3.sub2content.length; ls2++) {
            attachmentList.push(<li key={ls2}>
              <div className="w90p"><a href={retResult3.sub2content[ls2].subsubcontent.fileUrl} target="_blank" rel="noreferrer">
                {retResult3.sub2content[ls2].subsubcontent.fileType === 'video/mp4' ? <VideoIcon className="iconGrey mgR10"/> : <PdfIcon className="iconGrey mgR10"/>} 
                {(retResult3.sub2content[ls2].subsubcontent.fileTitle !== undefined && retResult3.sub2content[ls2].subsubcontent.fileTitle !== "")? retResult3.sub2content[ls2].subsubcontent.fileTitle : retResult3.sub2content[ls2].subsubcontent.fileName}
              </a></div>
            </li>
            )
          }
        }
        var attachmentContent = [];
        if(attachmentList.length>0) {
          attachmentContent.push(
            <div className="mgT10 flex-container w100p" >
                <span className="textBold">Attachment</span><br/>
                <ul>{attachmentList}</ul>
            </div>
          )
        }
        var trcls = '';
        var lessonDesc = retResult3.subcontent.lessonDesc !== undefined ?  ReplaceAllContent('[;]',',',ReplaceAllContent('<br/>','\n',retResult3.subcontent.lessonDesc)) : '';
        lessonsList.push(
          <tr key={ls+1} className={trcls}>
            <th scope="row">{retResult3.subcontent.lessonNumber}</th>
            <td>{retResult3.subcontent.lessonTitle}</td>
            <td>
              <div>{lessonDesc}</div>
              <div className="mgT10 flex-container w100p">{attachmentContent}</div>
            </td>
          </tr>
        );
    }
   } else {
      lessonsList.push(<tr key="0">
          <td colSpan="3" className="textCenter"><div className="mgT20 mgB20">:: no record ::</div></td>
        </tr>)
   }
   var answerList = [];
    if (this.state.library_answer_list.length > 0) {
      this.state.library_answer_list
      .forEach((answer, i) => {
        answerList.push(
          <tr key={"answer-" + i}>
            <td>{i}</td>
            <td>
              {answer.question}
              <br/>
              {
                answer.type === 'Multiple Choice' ? (
                  <ul className="mgT10">
                    {
                      answer.answer.map((item, j) => {
                        return (
                          <li key={"text-" + j}>{item.text} ({Math.round(item.counter / this.state.library_feedback_list.length * 100, 0)}%)</li>
                        )
                      })
                    }
                  </ul>
                ) : (
                  <div>
                    {
                      answer.answer.map((item, j) => {
                        if (j < 5) { 
                          return (
                            <blockquote key={"text-" + j}>{item.text}</blockquote>
                          )
                        } else { return ''; }
                      })
                    }
                  </div>
                )
              }
            </td>
          </tr>
        )
      });
    }

    var feedbackList = [];
    if (this.state.library_feedback_list.length > 0) {
      this.state.library_feedback_list
      .sort((a,b) => b.subcontent.submitDate._seconds - a.subcontent.submitDate._seconds)
      .forEach((feedback, i) => {
        feedbackList.push(
          <tr key={"feedback-" + i}>
            <td>{i + 1}</td>
            <td>{DateTimeFormats(feedback.subcontent.submitDate._seconds,"date-long")}</td>
            <td>{feedback.subcontent.memberName}</td>
            <td>{feedback.subcontent.memberEmail}</td>
            <td>
                <a href="#!"><DescriptionIcon className="iconGrey mgR20" onClick={this.urldetailFeedback.bind(this, feedback.subdocid, feedback.subcontent.memberName, feedback.subcontent.memberEmail, feedback.subcontent.submitDate._seconds, feedback.sub2content)} /></a>
            </td>
          </tr>
        );
      });
    }
   return (
    <div className="main-container">
      <div  className="ct-header-main"><CTHeader/></div>
          <div className="flex-container">
              <div className="sidebar-main"><CTSidemenu/></div>
              <div className="main-content">
                <div className="ct-stTitle">Library</div>
                {((localStorage.getItem('ctulevel') === global.ctconfig.userlevel[0]) || (JSON.parse(localStorage.getItem('cturole'))[2] === "ON") || (JSON.parse(localStorage.getItem('cturole'))[3] === "ON") || (JSON.parse(localStorage.getItem('cturole'))[4] === "ON"))? 
                <div>
                <div className="stIndiTitle">Library { this.state.stTitle !== ''? '> '+this.state.stTitle : '' }</div>
                <div className="mgT30">
                    {/* ====== */}
                      <div  className="content-container card card-body">
                        <div className="row-field">
                            <div className="w80p">
                              <h4>{this.state.library_title}</h4>
                              <h5 className="font-sublist">{this.state.library_category} <KeyboardArrowRightIcon className="iconLightGrey"></KeyboardArrowRightIcon> {this.state.library_subcategory}</h5>
                            </div>
                            <div className="divRight w20p"><div><button className="backButton" onClick={this.handleBack}>&#9664; back</button></div></div>
                        </div>
                        <div className="divRight">
                          <div className="detailJoined"><span>Created By : {this.state.library_createby}</span> <span className="mgL20">On : {this.state.library_createdate}</span></div>
                        </div>
                        <div className="mgT20 flex-container">
                            <div className="w20p"><h6>Title</h6></div>
                            <div className="w80p"><span className="mgR10">:</span>{this.state.library_title}</div>
                        </div>
                        <div className="mgT05 flex-container">
                            <div className="w20p"><h6>Category</h6></div>
                            <div className="w80p"><span className="mgR10">:</span>{this.state.library_category} <KeyboardArrowRightIcon className="iconLightGrey"></KeyboardArrowRightIcon> {this.state.library_subcategory}</div>
                        </div>
                        <div className="mgT05 flex-container">
                            <div className="w20p"><h6>Language</h6></div>
                            <div className="w80p"><span className="mgR10">:</span>{this.state.library_language}</div>
                        </div>
                        <div className="mgT20">
                          <div><h6>Short Description :</h6></div>
                          <div>{parse(this.state.library_short_desc)}</div>
                        </div>
                        <div className="mgT30">
                          <div><h6>Introduction :</h6></div>
                          <div>{parse(this.state.library_long_desc)}</div>
                        </div>
                        {(this.state.library_picture !== "" && this.state.library_picture !== undefined)?
                        <div className="mgT30">
                          <div><h6>Picture :</h6></div>
                          <div><img width="150" src={this.state.library_picture} alt=""/></div>
                        </div>
                        : '' }
                        {(this.state.library_video !== "" && this.state.library_video !== undefined)?
                        <div className="mgT30">
                          <div>
                            <h6>Video Intro :</h6>  
                            <iframe src={this.state.library_video} title={this.state.library_title}></iframe>
                            <a href={this.state.library_video} target="_blank" rel="noreferrer">{this.state.library_video}</a> 
                          </div>
                        </div>
                        : '' }
                        <div className="mgT50">
                            <Accordion className="card-toggle mt-4 text-left">
                              <Card id="accordion-0">
                                <Accordion.Toggle as={Card.Header} eventKey="0" onClick={()=> ToggleArrow(0)}>
                                    <h6>Modules</h6>
                                    <KeyboardArrowRightIcon className="accordion-toggle toggle-close"/>
                                    <KeyboardArrowDownIcon className="accordion-toggle toggle-open d-none"/>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0" id="collapse-0">
                                  <Card.Body>
                                    <div id="lessons-area">
                                      
                                      <div className="mgT20">
                                        <table className="table table-hover">
                                          <thead className="thead-light">
                                              <tr key="0">
                                              <th scope="col textWrap">#</th>
                                              <th scope="col textWrap">Subject</th>
                                              <th scope="col textWrap">Description</th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                              {lessonsList}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>  
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                              <Card id="accordion-1">
                                <Accordion.Toggle as={Card.Header} eventKey="1" onClick={()=> ToggleArrow(1)}>
                                  <h6>Exam</h6>
                                  <KeyboardArrowRightIcon className="accordion-toggle toggle-close"/>
                                  <KeyboardArrowDownIcon className="accordion-toggle toggle-open d-none"/>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="1" id="collapse-1">
                                  <Card.Body>
                                    <div id="exam-area">
                                     
                                      <div className="mgT20 flex-container">
                                          <div className="w20p">Description</div>
                                          <div className="w80p"><span className="mgR10">:</span>{this.state.library_exam.examDesc}</div>
                                      </div>
                                      <div className="mgT05 flex-container">
                                          <div className="w20p">Passing Score</div>
                                          <div className="w80p"><span className="mgR10">:</span>{this.state.library_exam.passingScore}</div>
                                      </div>
                                      <div className="mgT05 flex-container">
                                          <div className="w20p">Num. of question</div>
                                          <div className="w80p"><span className="mgR10">:</span>{this.state.library_exam.numberQuestion}</div>
                                      </div>
                                      <div className="mgT20 flex-container">
                                        <div className="w70p st4Title divMiddle">Question List</div>
                                      </div>
                                      <div className="mgT20">
                                        <table className="table table-hover">
                                          <thead className="thead-light">
                                              <tr key="0">
                                              <th scope="col textWrap">#</th>
                                              <th scope="col textWrap">Question</th>
                                              <th scope="col textWrap">Type</th>
                                              <th scope="col textWrap">Answer</th>
                                              </tr>
                                          </thead>                              
                                          <tbody>
                                            {
                                              this.state.library_question_list.length > 0 ? (    
                                                this.state.library_question_list
                                                .sort((a,b) => a.subsubcontent.questionNumber - b.subsubcontent.questionNumber)
                                                .map((question, i) => {
                                                  return (
                                                    <tr key={"question-" + i}>
                                                      <td>{question.subsubcontent.questionNumber}</td>
                                                      <td>
                                                        {question.subsubcontent.questionText}
                                                        <ol className="list-alpha">
                                                          {question.subsubcontent.option1 ? <li>{question.subsubcontent.option1}</li> : ''}
                                                          {question.subsubcontent.option2 ? <li>{question.subsubcontent.option2}</li> : ''}
                                                          {question.subsubcontent.option3 ? <li>{question.subsubcontent.option3}</li> : ''}
                                                          {question.subsubcontent.option4 ? <li>{question.subsubcontent.option4}</li> : ''}
                                                          {question.subsubcontent.option5 ? <li>{question.subsubcontent.option5}</li> : ''}
                                                        </ol>
                                                      </td>
                                                      <td>{question.subsubcontent.questionType}</td>
                                                      <td>{question.subsubcontent.questionAnswer}</td>
                                                    </tr>
                                                  )
                                                })
                                              ) : ''
                                            }
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                              <Card id="accordion-2">
                                <Accordion.Toggle as={Card.Header} eventKey="2" onClick={()=> ToggleArrow(2)}>
                                  <h6>Feedback</h6>
                                  <KeyboardArrowRightIcon className="accordion-toggle toggle-close"/>
                                  <KeyboardArrowDownIcon className="accordion-toggle toggle-open d-none"/>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="2" id="collapse-2">
                                  <Card.Body>
                                    <div id="feedback-area">
                                      <div className="flex-container">
                                        <div className="w70p st4Title">Total <strong>{this.state.library_feedback_list.length}</strong> responses</div>
                                      </div>
                                      <div className="mgT20">                                            
                                        <Tabs defaultIndex={0} onSelect={this.handleSelectTab}>
                                            <TabList className="tabs-class__tab-list">
                                                <Tab  className="tabs-class__tab" disabledClassName="tabs-class__tab--disabled" selectedtabclassname="tabs-class__tab--selected">Summary</Tab>
                                                <Tab  className="tabs-class__tab" disabledClassName="tabs-class__tab--disabled" selectedtabclassname="tabs-class__tab--selected">List</Tab>
                                            </TabList>
                                            <TabPanel className="tabs-class__tab-panel" selectedClassName="tabs-class__tab-panel--selected">
                                              <table className="table table-hover">
                                                <thead className="thead-light">
                                                    <tr>
                                                      <th scope="col textWrap">#</th>
                                                      <th scope="col textWrap">Questions &amp; Answers</th>
                                                    </tr>
                                                </thead>                              
                                                <tbody>
                                                  {answerList}
                                                </tbody>
                                              </table>                                        
                                            </TabPanel>
                                            <TabPanel className="tabs-class__tab-panel" selectedClassName="tabs-class__tab-panel--selected">
                                              <table className="table">
                                                <thead>
                                                    <tr>
                                                      <th scope="col">#</th>
                                                      <th scope="col">Date</th>
                                                      <th scope="col">User</th>
                                                      <th scope="col">Email</th>
                                                      <th scope="col" style={{width:"230px"}}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                  {feedbackList}
                                                </tbody>
                                              </table>
                                            </TabPanel>
                                        </Tabs> 

                                      </div>
                                    </div>
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            </Accordion>
                        </div>
                        
                        {/* <div className="mgT50">
                          <Accordion className="accord" flush>
                            <Accordion.Item eventKey="1">
                              <Accordion.Header>
                                Modules
                                <KeyboardArrowRightIcon className="toggle-open"/>
                                <KeyboardArrowDownIcon className="toggle-close"/>
                              </Accordion.Header>
                              <Accordion.Body>
                                <div id="lessons-area">
                                  <div className="flex-container">
                                    <div className="w70p st4Title divMiddle">Module List</div>
                                    <div className="w30p divRight">
                                    </div>
                                  </div>
                                  <div className="mgT20">
                                    <table className="table table-hover">
                                      <thead className="thead-light">
                                          <tr key="0">
                                          <th scope="col textWrap">#</th>
                                          <th scope="col textWrap">Subject</th>
                                          <th scope="col textWrap">Description</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          {lessonsList}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>                        
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                              <Accordion.Header>
                                Exam
                                <KeyboardArrowRightIcon className="toggle-open"/>
                                <KeyboardArrowDownIcon className="toggle-close"/>
                              </Accordion.Header>
                              <Accordion.Body>
                                <div id="exam-area">
                                  <div className="flex-container">
                                    <div className="w70p st4Title divMiddle">Exam Information</div>
                                    <div className="w30p divRight">
                                    </div>
                                  </div>
                                  <div className="mgT20 flex-container">
                                      <div className="w20p"><h6>Description</h6></div>
                                      <div className="w80p"><span className="mgR10">:</span>{this.state.library_exam.examDesc}</div>
                                  </div>
                                  <div className="mgT05 flex-container">
                                      <div className="w20p"><h6>Passing Score</h6></div>
                                      <div className="w80p"><span className="mgR10">:</span>{this.state.library_exam.passingScore}</div>
                                  </div>
                                  <div className="mgT05 flex-container">
                                      <div className="w20p"><h6>Num. of question</h6></div>
                                      <div className="w80p"><span className="mgR10">:</span>{this.state.library_exam.numberQuestion}</div>
                                  </div>
                                  <div className="mgT20 flex-container">
                                    <div className="w70p st4Title divMiddle">Question List</div>
                                  </div>
                                  <div className="mgT20">
                                    <table className="table table-hover">
                                      <thead className="thead-light">
                                          <tr key="0">
                                          <th scope="col textWrap">#</th>
                                          <th scope="col textWrap">Question</th>
                                          <th scope="col textWrap">Type</th>
                                          <th scope="col textWrap">Answer</th>
                                          </tr>
                                      </thead>                              
                                      <tbody>
                                        {
                                          this.state.library_question_list.length > 0 ? (    
                                            this.state.library_question_list
                                            .sort((a,b) => a.subsubcontent.questionNumber - b.subsubcontent.questionNumber)
                                            .map((question, i) => {
                                              return (
                                                <tr key={"question-" + i}>
                                                  <td>{question.subsubcontent.questionNumber}</td>
                                                  <td>
                                                    {question.subsubcontent.questionText}
                                                    <ol className="list-alpha">
                                                      {question.subsubcontent.option1 ? <li>{question.subsubcontent.option1}</li> : ''}
                                                      {question.subsubcontent.option2 ? <li>{question.subsubcontent.option2}</li> : ''}
                                                      {question.subsubcontent.option3 ? <li>{question.subsubcontent.option3}</li> : ''}
                                                      {question.subsubcontent.option4 ? <li>{question.subsubcontent.option4}</li> : ''}
                                                      {question.subsubcontent.option5 ? <li>{question.subsubcontent.option5}</li> : ''}
                                                    </ol>
                                                  </td>
                                                  <td>{question.subsubcontent.questionType}</td>
                                                  <td>{question.subsubcontent.questionAnswer}</td>
                                                </tr>
                                              )
                                            })
                                          ) : ''
                                        }
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3" key="accordion-3">
                              <Accordion.Header>
                                Feedback
                                <KeyboardArrowRightIcon className="toggle-open"/>
                                <KeyboardArrowDownIcon className="toggle-close"/>
                              </Accordion.Header>
                              <Accordion.Body>
                                <div id="feedback-area">
                                  <div className="flex-container">
                                    <div className="w70p st4Title">Total <strong>{this.state.library_feedback_list.length}</strong> responses</div>
                                  </div>
                                  <div className="mgT20">                                            
                                    <Tabs defaultIndex={0} onSelect={this.handleSelectTab}>
                                        <TabList className="tabs-class__tab-list">
                                            <Tab  className="tabs-class__tab" disabledClassName="tabs-class__tab--disabled" selectedtabclassname="tabs-class__tab--selected">Summary</Tab>
                                            <Tab  className="tabs-class__tab" disabledClassName="tabs-class__tab--disabled" selectedtabclassname="tabs-class__tab--selected">List</Tab>
                                        </TabList>
                                        <TabPanel className="tabs-class__tab-panel" selectedClassName="tabs-class__tab-panel--selected">
                                          <table className="table table-hover">
                                            <thead className="thead-light">
                                                <tr>
                                                  <th scope="col textWrap">#</th>
                                                  <th scope="col textWrap">Questions &amp; Answers</th>
                                                </tr>
                                            </thead>                              
                                            <tbody>
                                              {answerList}
                                            </tbody>
                                          </table>                                        
                                        </TabPanel>
                                        <TabPanel className="tabs-class__tab-panel" selectedClassName="tabs-class__tab-panel--selected">
                                          <table className="table">
                                            <thead>
                                                <tr>
                                                  <th scope="col">#</th>
                                                  <th scope="col">Date</th>
                                                  <th scope="col">User</th>
                                                  <th scope="col">Email</th>
                                                  <th scope="col" style={{width:"230px"}}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                              {feedbackList}
                                            </tbody>
                                          </table>
                                        </TabPanel>
                                    </Tabs> 

                                  </div>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </div> */}
                    </div>
                    {/* ====== */}
                  </div>  
                  {
                    this.state.isOpen && <Popup 
                      content={<CTFeedbackDetail/>}
                      id={this.state.memberdoc_id} 
                      title={"Feedback Form"}
                      others={this.state.otherContent}
                      styles={"70%"}
                      handleClose={this.togglePopClose.bind(this, this.state.memberdoc_id)}
                    />
                  }
              </div>
                :   
                <div><CTNA/></div>
                }
              </div>
          </div>
         
          <div id="loading-area" className="divHide loading"></div>
    </div>
   );
  }
}
 
export default CTLibraryDetail;