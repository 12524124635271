import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import parse from 'html-react-parser';
import { GetText, ReplaceText } from '../helper';
import '../config/global';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[9];

export default class LibraryDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      libraryList: [],
      id: '',
      title: '',
      category: '',
      description: '',
      image: '',
      lesson: []
    }
  }

  componentDidMount() {
    var url = window.location.href;
    var id = url.substring(url.lastIndexOf('/') + 1);

    fetch(global.config.urlLink+"/libraryview/" + id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey
      }
    })
    .then(response => {
      if (response.status === 200) {
        return response.json();
      } else {
        console.log('Something happened wrong');
      }
    })
    .then(resultJson => {
      var content = resultJson.values[0].content[0].maincontent;
      this.setState({
        id: resultJson.values[0].docid,
        title: content.libraryTitle,
        category: content.categoryName,
        description: ReplaceText(content.libraryLongDesc),
        image: content.libraryPicture,
        link: '/library/' + id,
        lesson: resultJson.values[0].scontent
      });
    });
  }
  
  render() {
    return (
      <div className="detail-library">
        <section>
          <Container>
            <div className="row align-items-start m-0">
              <div className="col-md-5 bg-light p-5 br-8">
                <div className="row">
                  <div className="col">
                    <h4>Library</h4>
                  </div>
                  <div className="col-auto text-right">
                    <p className="text-red mt-1"><strong>{this.state.category}</strong></p>
                  </div>
                </div>                
                <div className="text-center">
                  <img className="mxw br-8" src={this.state.image} alt={this.state.title}/>
                  <h5 className="my-4">{this.state.title}</h5>
                  <a href={'/subscribe/'} className="btn btn-lg btn-primary mb-3">{GetText() ? "Daftar" : "Enroll"}</a>
                </div>
              </div>
              <div className="col-md-7 agenda p-5 br-8">
                <h4 className="mb-4">{GetText() ? "Pengantar" : "Introduction"}</h4>
                <p className="mb-5">{parse(this.state.description)}</p>
                {
                  this.state.lesson.length > 0 ? (
                    <div>
                      <h4 className="mb-4">{GetText() ? "Daftar Modul" : "Modules"}</h4>
                      {
                        this.state.lesson
                        .sort((a, b) => a.subcontent.lessonNumber - b.subcontent.lessonNumber)
                        .map((lesson, i) => {
                          return (
                            <div className="row" key={i}>
                              <div className="col-md-3">
                                <label className="label-rounded-2">{GetText() ? "Modul " + (i + 1) : "Module " + (i + 1)}</label>
                              </div>
                              <div className="col-md-9">
                                <h5>{lesson.subcontent.lessonTitle}</h5>
                                <p className="clamp-3">{parse(ReplaceText(lesson.subcontent.lessonDesc))}</p>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  ) : ''
                }
              </div>
            </div>
          </Container>
        </section>
      </div>
    );
  }
}