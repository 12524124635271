/* //global gapi */
import React, { Component } from 'react';
import logoNA from '../../assets/logo-novaio-white.png';
import '../ctinc/ct-Global';
import '../ctinc/ct-Style-2.css';
import '../ctinc/ct-Style.css';
//import Credentials from '../iinc/iOACredentials.json';
import { Icon } from 'react-icons-kit'
import { ic_notifications } from 'react-icons-kit/md';
import {ic_exit_to_app} from 'react-icons-kit/md/ic_exit_to_app'
import { GoogleLogout } from 'react-google-login';
import defPicture from '../../assets/pic-profile-sq.gif';

class CTHeader extends Component {
    constructor(props) {
      super(props);
      this.state = {
        userFullname: '',
        userPicture: '',
        userPosition: '',
        notifCount: 0
      };
      this.handleClick = this.handleClick.bind(this);
    //   this.handleLoad = this.handleLoad.bind(this);
    }
    
    handleClick(ln) {    
        if(ln==='logout') {
         
        localStorage.clear();
        window.location.reload();

        //  console.log(gapi.auth2);
        //  var auth2 = gapi.auth2.getAuthInstance();
        //  auth2.signOut().then(function () {
        //    auth2.disconnect();
        //    localStorage.clear();
        //    alert('User signed out.');
        //    window.location.reload();
        //  }).catch(err => {
        //     console.log(err);
        //     localStorage.clear();
        //  });
        } else if(ln==="notif") {
            alert("under construction")
        } else if(ln==="profile") {
            document.location.href=global.ctconfig.urlHost+"/ctmyprofile";
        }
       //document.location.href=ln;
    }
    /*
    handleLoad() {
        try{
            var userPosition = "Staf", userFullName ="",userPicture=defPicture;
            if(localStorage.getItem('ctuposition') !== '') { userPosition = localStorage.getItem('ctuposition'); }
            if(localStorage.getItem('ctufullname') !== '') { userFullName = localStorage.getItem('ctufullname'); }
            if(localStorage.getItem('ctupicture') !== '') { userPicture = localStorage.getItem('ctupicture'); }
            this.setState({
                // userFullname: ggleUser.Ad,
                // userPicture: ggleUser.SJ,
                //userFullname: ggleUser.getName(),
                userFullname: userFullName,
                userPicture: userPicture,
                userPosition: userPosition,
            });

            // gapi.load('auth2', () => {
            //     this.auth2 = gapi.auth2.init({
            //     //client_id: 'YOUR_CLIENT_ID.apps.googleusercontent.com',
            //     client_id:  global.ctconfig.credClientId,
            //     });
            //     this.auth2.then((res) => {
            //         //console.log('on init');
            //         var ggleUser = res.currentUser.get().getBasicProfile();
            //         console.log(ggleUser);
            //         console.log("Name:"+ggleUser.getName());
                    
            //         this.setState({
            //             // userFullname: ggleUser.Ad,
            //             // userPicture: ggleUser.SJ,
            //             //userFullname: ggleUser.getName(),
            //             userFullname: userFullName,
            //             userPicture: ggleUser.getImageUrl(),
            //             userPosition: userPosition,
            //         });
            //     });
            // }); 
        } catch (e) {
            console.log(e);
            //window.location.reload();
            localStorage.clear();
        }
    }
    */
    componentDidMount() {
        // window.addEventListener('load',this.handleLoad);
        var userPosition = "Staf", userFullName ="",userPicture=defPicture;
        if(localStorage.getItem('ctuposition') !== '') { userPosition = localStorage.getItem('ctuposition'); }
        if(localStorage.getItem('ctufullname') !== '') { userFullName = localStorage.getItem('ctufullname'); }
        if(localStorage.getItem('ctupicture') !== '') { userPicture = localStorage.getItem('ctupicture'); }
        this.setState({
            // userFullname: ggleUser.Ad,
            // userPicture: ggleUser.SJ,
            //userFullname: ggleUser.getName(),
            userFullname: userFullName,
            userPicture: userPicture,
            userPosition: userPosition,
        });
    }
    render() { 
        // console.log(this.state.userPicture);
        //console.log(localStorage.getItem('utk'));
        //console.log(JSON.parse(localStorage.getItem('urole'))[0]);
        //console.log(JSON.stringify(localStorage.getItem('urole')));
      return (
          
        
           <div className="flex-container">
               <div className="flex-container ct-header-left">
                    <div className="mgL20"><a href="/"><img src={logoNA} className="header-logo" alt="logo"/></a></div>
                    <div className="spacer"></div>
                    <div className="header-logo-t divCenter"></div>
               </div>
               <div className="header-menu divCenter">
                  <div className="mgR30 flex-container">
                    <div className="mgR20 divMiddle">
                    {(localStorage.getItem('ctulevel') !== undefined && localStorage.getItem('ctulevel') === global.ctconfig.userlevel[0])?<div className="ct-header-icon cursor-on flex-container divMiddle"><Icon icon={ic_notifications} size={25} className="iconDark" onClick={() => this.handleClick('notif')}/></div>: ''}
                    </div>
                    <div className="mgR30 flex-container header-profile">
                        <div className="mgR10"><img src={this.state.userPicture} className="header-picprofile cursor-on" alt={this.state.userFullname} onClick={() => this.handleClick('profile')}/></div>
                        <div className="divMiddle cursor-on" onClick={() => this.handleClick('profile')}>
                            <div className="textBold">{this.state.userFullname}</div>
                            <div>{this.state.userPosition}</div>
                        </div>
                        
                    </div>
                    <div className="divMiddle">
                        <GoogleLogout
                            clientId= {global.ctconfig.credClientId}
                            className="btn-logout"
                            // buttonText="Logout"
                            onLogoutSuccess={() => this.handleClick('logout')}
                            >
                                <Icon icon={ic_exit_to_app} size={25} className="iconDark"/>
                        </GoogleLogout>
                            
                        {/* <div className="ct-header-icon cursor-on divMiddle" onClick={() => this.handleClick('logout')}><Icon icon={ic_exit_to_app} size={25} className="iconDark"/></div> */}
                    </div>
                  </div>
                   
               </div>
           </div>
       
      );
    }
   }
    
   export default CTHeader;