import React, { Component } from "react";
import Moment from "moment";
import Card from "react-bootstrap/Card";
import { FaRegCalendarAlt } from 'react-icons/fa';

export default class Block extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: "",
			title: "",
			image: "",
			date: "",
			link: ""
		}
	}

	componentDidMount() {
		var content = this.props.value;
		this.setState({
			id: content.id,
			title: content.courseTitle,
			image: content.coursePicture,
			date: Moment.unix(content.courseDate._seconds).format("D MMMM Y"),
			link: '/course/' + content.id
		});
	}

	render() {
		return (
			<a href={this.state.link}>
				<Card className="course-block">
					<Card.Img variant="top" src={this.state.image} alt={"Course " + this.state.title}/>
					<Card.Body className="text-center">
						<Card.Title>{this.state.title}</Card.Title>
						<Card.Text className="mt-2">
							<FaRegCalendarAlt className="mr-1"/>
							{this.state.date}
						</Card.Text>
					</Card.Body>
				</Card>
			</a>
		);
	}
}
