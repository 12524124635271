import React, { Component } from 'react';
import '../ctinc/ct-Style.css';
import '../ctinc/ct-Global';
import { FormErrors } from '../ctinc/error-form';

const authKey = global.ctconfig.authBearerKey+"##"+global.ctconfig.kindcode[9];

class CTLibraryAttachForm extends Component {
    constructor (props) {
        super(props);
        this.state = {
            library_id: '',
            library_title: '',
            library_attach_id: '',
            libfile_title: '',
            libfile_name: '',
            libfile_id: '',
            libfile_url: '',
            file_url: '',
            selectedFileDocument: null,
            selectedFileDocument2: null,
            selectedFileName: null,
            selectedFileName2: null,
            selectedFileSignedUrl: '',
            selectedFileSignedUrl2: '',            
            uploadedFileUrl: global.ctconfig.storageUrl + 'training/library/atch/',
            formErrors: { libfile_title: '', selectedFileDocument: '', file_url: '', selectedFileDocument2: '' },
            selectedFileDocumentValid: false,
            selectedFileDocument2Valid: false,
            file_titleValid: false,
            file_urlValid: false,
            formValid: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    componentDidMount() {
        var othersContent = JSON.parse(document.getElementById("contentoth").innerText);
        this.setState({
            library_id: othersContent.libraryId,
            library_lesson_id: othersContent.libraryLessonId,
            library_title: othersContent.libraryTitle,
            library_attach_id: othersContent.libattachid
        }); 
    }
    
    onChangeFileHandler = (e) => {
        if(e.target.files[0] !== undefined) {
            document.getElementById('loading-area').style.display = 'block'; 
            var fieldname = e.target.name;
            var inputValue = e.target.files[0];
            var inputType = e.target.files[0].type;
            var inputFormat = e.target.files[0].name.split('.').pop();
            var inputName = '';
            const chars = '0123456789abcdefghijklmnopqrstuvwxyz';
            for (let i = 0; i < 12; i++) {
                const index = Math.floor(Math.random() * chars.length);
                inputName += chars.charAt(index);
            }
            var name = '';
            var validname = '';
            var selectedFile = '';
            var selectedName = '';
            let fieldValidationErrors = this.state.formErrors;
            let selectedFileValid = false;            
            if(e.target.id === "fileDocument") {
                if(inputType.indexOf('pdf') > -1 || inputType.indexOf('video') > -1) {
                    selectedFileValid = true;
                }
                name =  'selectedFileDocument';
                validname = 'selectedFileDocumentValid';
                selectedFile = 'selectedFileSignedUrl';
                selectedName = 'selectedFileName';
                fieldValidationErrors.selectedFileDocument = selectedFileValid ? '' : 'Please upload video or pdf only';
            } else {
                if(inputType.indexOf('image') > -1) { 
                    selectedFileValid = true; 
                }
                name =  'selectedFileDocument2';
                validname = 'selectedFileDocument2Valid';
                selectedFile = 'selectedFileSignedUrl2';
                selectedName = 'selectedFileName2';
                fieldValidationErrors.selectedFileDocument2 = selectedFileValid ? '' : 'Please upload image only';
            }
            if(selectedFileValid === true) {
                var datatext ='{"file_name":"' + inputName + '.' + inputFormat + '","file_type":"' + inputType + '","form_id":"attach-library","form_type":"buckets"}';
                // var datatext ='{"file_name":"' + e.target.files[0].name + '","file_type":"' + inputType + '","form_id":"attach-library","form_type":"buckets"}';
                var data = JSON.parse(datatext);
                console.log(global.ctconfig.urlLink);
                console.log(datatext);
                fetch(global.ctconfig.urlLink+"/getsignedurl", {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': authKey,
                    }, body : JSON.stringify(data)
                }).then(res => {
                    if(res.status === 200) 
                        return res.json() 
                }).then(async (resJson) => {
                    console.log(resJson.values);
                    if (resJson.status === "200") {
                        var urlUploadFile = resJson.values;
                        await fetch(urlUploadFile, {
                          method: "PUT",
                          headers: {
                            "content-type": "application/octet-stream",
                          },
                          body: inputValue,
                        }).then(async (res) => {
                          let urlFile = res.url.split("?")[0];
                        //   setForm((prevState) => ({
                        //     ...prevState,
                        //     [fieldname]: urlPDFFile,
                        //   }));
                        console.log("urlFile");
                        console.log(urlFile);
                        console.log(global.ctconfig.urlLink);
                        console.log(datatext);
                          await fetch(global.ctconfig.urlLink + "/makepublic", {
                            method: "POST",
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Access-Control-Allow-Origin': '*',
                                'Authorization': authKey,
                            }, body : JSON.stringify(data),
                          }).then((res) => {
                            console.log(fieldname + " : " + urlFile);
                                console.log(name + " : " + inputValue);
                                    console.log(selectedFile+ " : " + resJson.values);
                                        console.log(selectedName+ " : " + inputName + '.' + inputFormat);
                                        console.log(validname+ " : " + selectedFileValid);
                            if (res.status === 200) {
                                this.setState({
                                    [fieldname]: urlFile,
                                    [name]: inputValue,
                                    [selectedFile]: resJson.values,
                                    [selectedName]: inputName + '.' + inputFormat,
                                    formErrors: fieldValidationErrors,
                                    [validname] : selectedFileValid,
            
                                }, this.validateForm);
                                console.log(this.state);
                            }
                          });
                        });
                    } else {
                        selectedFileValid = false;
                        fieldValidationErrors[name] = selectedFileValid ? '' : 'Failed upload file';
                        this.setState({
                            [name]: null,
                            [selectedFile]: '',
                            formErrors: fieldValidationErrors,
                            [validname] : selectedFileValid,
                        }, this.validateForm);
                    }



                    // this.setState({
                    //     [name]: inputValue,
                    //     [selectedFile]: resJson.values,
                    //     [selectedName]: inputName + '.' + inputFormat,
                    //     formErrors: fieldValidationErrors,
                    //     [validname] : selectedFileValid,

                    // }, this.validateForm);
                    document.getElementById('loading-area').style.display = 'none'; 
                });
            } else {
                document.getElementById('loading-area').style.display = 'none'; 
            }
        } else {
            if(e.target.id === "fileDocument") { 
                this.setState({
                    selectedFileDocument: null,
                    selectedFileSignedUrl: '',
                    formErrors: { selectedFileDocument: '', file_url: '' },
                    selectedFileDocumentValid: false,
                    formValid: false
                }); 
            } else { 
                this.setState({
                    selectedFileDocument2: null,
                    selectedFileSignedUrl2: '',
                    formErrors: { selectedFileDocument2: '' },
                    selectedFileDocument2Valid: false,
                    formValid: false
                }); 
            }
            document.getElementById('loading-area').style.display = 'none'; 
        }
    }
    
    async handleSubmit(e) {
        e.preventDefault();
        document.getElementById('loading-area').style.display = 'block';   
        var fileTitle="";
        if(this.textInputLibFileTitle.value === '') { fileTitle = this.state.libfile_title; } else { fileTitle = this.textInputLibFileTitle.value; }     
        if(fileTitle !== '' && this.state.selectedFileDocument !== null) {
            var uploadedUrl = '', uploadedUrl2 = '', uploadedType = '';
            if(this.state.selectedFileName !== "" && this.state.selectedFileName !== null) { uploadedUrl = this.state.uploadedFileUrl+this.state.selectedFileName; }
            if(this.state.selectedFileName2 !== "" && this.state.selectedFileName2 !== null) { uploadedUrl2 = this.state.uploadedFileUrl+this.state.selectedFileName2; }
            uploadedType = this.state.selectedFileDocument.type;
            
            /*
            if(this.state.selectedFileSignedUrl !== '') {
                await fetch(this.state.selectedFileSignedUrl, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': this.state.selectedFileDocument.type
                    },
                    body: this.state.selectedFileDocument
                });
                uploadedUrl = this.state.uploadedFileUrl + this.state.selectedFileName;
                uploadedType = this.state.selectedFileDocument.type;
            }
            if((this.state.selectedFileDocument2 !== null) && (this.state.selectedFileSignedUrl2 !== '')) {
                await fetch(this.state.selectedFileSignedUrl2, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': this.state.selectedFileDocument2.type
                    },
                    body: this.state.selectedFileDocument2
                });
                uploadedUrl2 = this.state.uploadedFileUrl + this.state.selectedFileName2;
                var datatext2 ='{"file_name":"'+this.state.selectedFileName2+'"}';
                var data2 = JSON.parse(datatext2);
                await fetch(global.ctconfig.urlLink+"/makepublic", {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': authKey,
                    }, body : JSON.stringify(data2)
                });
            }
            */
            var datatext ='{"docid":"'+this.state.library_id+'","subdocid":"'+this.state.library_lesson_id+'","filetitle":"'+fileTitle+'","fileurl":"'+uploadedUrl+'","filetype":"'+uploadedType+'","filethumb":"'+uploadedUrl2+'","form_id":"form-addattachment","form_type":"buckets"}';
            var data = JSON.parse(datatext);
            console.log(data)
            
            fetch(global.ctconfig.urlLink+"/libraryattachmentadd", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }, body : JSON.stringify(data)
            }).then(res => {
                if(res.status === 200) 
                    return res.json() 
            }).then( resJson => {
                document.getElementById('loading-area').style.display = 'none'; 
                if(resJson.status === '200') {
                    window.location.href=global.ctconfig.urlHost+'/cteditdetaillibrary/'+this.state.library_id;
                } else {
                    console.log('Something happened wrong');
                    this.setState({
                        formErrors: { selectedFileDocument: '', file_url: resJson.values[1] },
                        selectedFileDocumentValid : false,
                        file_urlValid : false
                    }, this.validateForm);
                }
            }).catch(err => { 
                console.log(err);
                document.getElementById('loading-area').style.display = 'none'; 
            });
            
        } else {
            document.getElementById('loading-area').style.display = 'none';
            var arrfieldName = ['libfile_title','selectedFileDocument'];
            var arrvalue = [this.textInputLibFileTitle.value,this.state.selectedFileDocument];
            for(var ck in arrfieldName) {
                this.validateField(arrfieldName[ck], arrvalue[ck]);
            }
        }
    }

    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
            [name]: value
        }, () => { 
            this.validateField(name, value)
        });
    }

    validateField(fieldName, value) {        
        let fieldValidationErrors = this.state.formErrors;
        let selectedFileDocumentValid = this.state.selectedFileDocumentValid;
        let file_titleValid = this.state.file_titleValid;
        let file_urlValid = this.state.file_urlValid;
        let selectedFileDocument2Valid = this.state.selectedFileDocument2Valid;
        switch(fieldName) {
            case 'libfile_title': 
                // if(this.state.libfile_title === "") {
                    file_titleValid = value.trim().length > 0;
                    fieldValidationErrors.libfile_title = file_titleValid ? '' : 'title need to be filled';
                // }
                break;
            case 'selectedFileDocument': 
                // if(this.state.file_url === "") {
                    selectedFileDocumentValid = value !== null;
                    fieldValidationErrors.selectedFileDocument = selectedFileDocumentValid ? '' : 'file need to be selected';
                // }
                break;
            case 'file_url': 
                if(this.state.selectedFileDocument === null || this.state.selectedFileDocument === "") {
                    file_urlValid = value !== null;
                    fieldValidationErrors.file_url = file_urlValid ? '' : 'file need to be filled';
                }
                break;
            default:
                break;
        }
        this.setState({
            formErrors: fieldValidationErrors,
            selectedFileDocumentValid: selectedFileDocumentValid,
            file_titleValid: file_titleValid,
            file_urlValid: file_urlValid,
            selectedFileDocument2Valid: selectedFileDocument2Valid,
        }, this.validateForm);
    }

    validateForm() {
        this.setState({
            formValid: this.state.selectedFileDocumentValid && this.state.file_urlValid && this.state.selectedFileDocument2Valid 
        });
    }

    render() {
        console.log(this.state.selectedFileDocument);
        console.log(this.state.selectedFileName);
        console.log(this.state.selectedFileSignedUrl);
        return (
            <div className="main-container">
                <div className="mgT30">
                    <form onSubmit={this.handleSubmit}>
                        <div>
                            <input type="hidden" id="library_id" name="library_id" value={this.state.library_id} />
                            <input type="hidden" id="library_attach_id" name="library_attach_id" value={this.state.library_attach_id} />
                        </div>
                        <div className="row-field mgT10 st4Title">
                            {this.state.library_title}
                        </div>
                        <div className="row-field mgT20">
                            <label className="labelFormL" htmlFor="library_document">
                                <div>Title</div>
                            </label>                            
                            <div className="w70p">
                                <input type="text" className="form-control" name="libfile_title"
                                    placeholder={this.state.libfile_title}
                                    value={this.state.libfile_title}
                                    onChange={this.handleUserInput} 
                                    ref={(input) => this.textInputLibFileTitle = input}
                                    disabled={this.state.input_disabled}/>
                                <div className="mgT05">
                                    { <FormErrors formErrors={this.state.formErrors.libfile_title}/>  }
                                </div>
                            </div>
                        </div>
                        <div className="row-field mgT20">
                            <label className="labelFormL" htmlFor="library_document">
                                <div>Upload File</div>
                            </label>
                            <div>
                                <div>
                                    <input id="fileDocument" type="file" onChange={this.onChangeFileHandler}/>
                                    <div className="mgT05">{<FormErrors formErrors={this.state.formErrors.selectedFileDocument}/>}</div>
                                    <div className="mgT10 warningText">Please upload pdf document or video</div>
                                </div>
                            </div>
                        </div>
                        <div className="row-field mgT20">
                            <label className="labelFormL">
                                <div>Upload Thumbnail</div>
                                <div className="notesText">(for video only)</div>
                            </label>
                            <div>
                                <div>
                                    <input id="fileDocument2" type="file" onChange={this.onChangeFileHandler}/>
                                    <div className="mgT05">{<FormErrors formErrors={this.state.formErrors.selectedFileDocument2}/>}</div>
                                    <div className="mgT10 warningText">Please upload image (jpg/png) with 1280 x 720 pixels</div>
                                </div>
                            </div>                        
                        </div>
                        <div className="row-field mgT30 mgB30">
                            <div className="w100p"><button id="submit-button"  type="submit" className="w100p ct-submitButton">Submit</button></div>
                        </div>
                    </form>
                </div>
                <div id="loading-area" className="divHide loading"></div> 
            </div>
        );
    }
}
      
export default CTLibraryAttachForm;
