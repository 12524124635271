import React, { Component } from 'react';
import '../ctinc/ct-Style.css';
import '../ctinc/ct-Global';
import { FormErrors } from '../ctinc/error-form';

const authKey = global.ctconfig.authBearerKey+"##"+global.ctconfig.kindcode[11];

class CTSubCategoryForm extends Component {
    constructor (props) {
        super(props);
        this.state = {
            category_id: '',
            category_name: '',
            subcategory_id: '',
            subcategory_name: '',
            usage_for: '',
            formErrors: { subcategory_name: ''},
            subcategorynameValid: false,
            formValid: false,
            input_disabled: true
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value},
            () => { this.validateField(name, value)});
    }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let subcategorynameValid = this.state.subcategorynameValid;
        
        switch(fieldName) {
            case 'subcategory_name': 
                subcategorynameValid = value.trim().length > 0;
                fieldValidationErrors.subcategory_name = subcategorynameValid ? '' : 'sub category name need to be filled';
                break;
            default:
                break;
        }
        
        this.setState({formErrors: fieldValidationErrors,
                        subcategorynameValid: subcategorynameValid,
                    }, this.validateForm);
    }
    validateForm() {
        this.setState({formValid: this.state.subcategorynameValid});
    }

    errorClass(error) {
        return(error.length === 0 ? '' : 'has-error');
    }
    handleSubmit(e) {
        e.preventDefault();
        //alert(JSON.stringify(this.state));
        //alert(this.event_enddate)
        if(this.state.subcategory_name !== '') {
            document.getElementById('loading-area').style.display = 'block'; 
            var subcategoryName;
            if(this.textInputSubCategoryName.value === '') { subcategoryName = this.state.subcategory_name; } else { subcategoryName = this.textInputSubCategoryName.value; }
            
            var datatext ='{"category_id":"'+this.state.category_id+'","subcategory_id":"'+this.state.subcategory_id+'","subcategory_name":"'+subcategoryName+'","usage_for":"'+this.state.usage_for+'","form_id":"form-subcategory"}';
            var urlFetch = global.ctconfig.urlLink;
            if(this.state.subcategory_id !== '') { urlFetch = urlFetch+"/categoryedit"; }
            else { urlFetch = urlFetch+"/categoryadd"; }
            console.log(datatext);
            console.log(urlFetch);
            var data = JSON.parse(datatext); 
            
            fetch(urlFetch, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }, body : JSON.stringify(data)
            }).then(res => {
                if(res.status === 200) 
                    return res.json() 
            }).then( resJson => {
                console.log(resJson);
                console.log(resJson.status);
                document.getElementById('loading-area').style.display = 'none'; 
                if(resJson.status === '200') {
                    window.location.href=global.ctconfig.urlHost+'/ctcategory'+this.state.usage_for;
                } else {
                    console.log('Something happened wrong');
                    console.log(resJson);
                }
            }).catch(err => {
                document.getElementById('loading-area').style.display = 'none'; 
                console.log(err);
            });

        } else {
            var arrfieldName = ['subcategory_name'];
            var arrvalue = [this.textInputSubCategoryName.value];
            for(var ck in arrfieldName) {
                this.validateField(arrfieldName[ck], arrvalue[ck]);
            }
        }
    }
    componentDidMount() {
        //var memberId = document.getElementById("contentid").innerText;
        console.log(document.getElementById("contentoth").innerText);
        document.getElementById('loading-area').style.display = 'block'; 
        var othersContent = JSON.parse(document.getElementById("contentoth").innerText);
        if(othersContent.subcatId !== '') {
            fetch(global.ctconfig.urlLink+"/subcategoryview/"+othersContent.usageFor+"/"+othersContent.catId+"/"+othersContent.subcatId, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }
              }).then(res => {
                if(res.status === 200) 
                    return res.json() 
              }).then( resJson => {
                console.log(resJson);
                console.log(resJson.status);
                document.getElementById('loading-area').style.display = 'none'; 
                if(resJson.status === '200') { 
                    this.setState({
                        category_id: resJson.values[0].docid,
                        category_name: resJson.values[0].content[0].maincontent.categoryName,
                        subcategory_id: resJson.values[0].scontent[0].subdocid,
                        subcategory_name: resJson.values[0].scontent[0].subcontent.subcategoryName,
                        usage_for: othersContent.usageFor,
                        formErrors: {subcategory_name: ''},
                        subcategorynameValid: false,
                        formValid: false,
                        input_disabled: false
                    }); 
                } else {
                    console.log('Something happened wrong');
                    console.log(resJson);
                }
              }).catch(err => {
                document.getElementById('loading-area').style.display = 'none'; 
                console.log(err);
              });
        } else {
            document.getElementById('loading-area').style.display = 'none'; 
            this.setState({
                category_id: othersContent.catId,
                category_name: othersContent.categoryName,
                subcategory_id: othersContent.subcatId,
                subcategory_name: '',
                usage_for: othersContent.usageFor,
                formErrors: {subcategory_name: ''},
                subcategorynameValid: false,
                formValid: false,
                input_disabled: false
            }); 
        }
    }

    render() {
        return (
            <div className="main-container">
                <div className="mgT30">
                <form onSubmit={this.handleSubmit}>
                    <div>
                        <input type="hidden" id="category_id" name="category_id" value={this.state.category_id} />
                        <input type="hidden" id="subcategory_id" name="subcategory_id" value={this.state.subcategory_id} />
                    </div>
                    <div className="row-field mgT20">
                        <label className="w30p textMiddle" htmlFor="category_name">Category Name</label>
                        <div className="w70p">{this.state.category_name}</div>
                    </div>
                    <div className="row-field mgT20">
                        <label className="w30p textMiddle" htmlFor="subcategory_name">Sub Category Name</label>
                        <div className="w70p">
                            <input type="text" className="form-control" name="subcategory_name"
                                placeholder={this.state.subcategory_name}
                                value={this.state.subcategory_name}
                                onChange={this.handleUserInput}  
                                ref={(input) => this.textInputSubCategoryName = input}
                                disabled={this.state.input_disabled}/>
                            <div className="mgT05">
                                { <FormErrors formErrors={this.state.formErrors.subcategory_name}/>  }
                            </div>
                        </div>
                    </div>
                    
                    <div className="row-field mgT30 mgB30">
                        <div className="w100p"><button id="submit-button"  type="submit" className="w100p ct-submitButton">Submit</button></div>
                    </div>
                </form>
                </div>
                <div id="loading-area" className="divHide loading"></div> 
            </div>
          );
        }
     }
      
export default CTSubCategoryForm;
