import React, { Component } from 'react';
import Moment from 'moment';
import Axios from 'axios';
import { GetText, ToggleArrow } from '../helper';
import { Container, Form, Button, Accordion, Card } from 'react-bootstrap';
import { FaPrint, FaChevronRight, FaChevronDown } from 'react-icons/fa';
import '../config/global';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[13];
const authKey2 = global.config.authBearerKey+"##"+global.config.kindcode[33];

export default class InvoiceCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      memberId: localStorage.getItem('doc_id'),
      name: '',
      email: '',
      order_id: '',
      order_date: '',
      amount: '',
      payment_method: '',
      payment_currency: '',
      payment_account: '',
      payment_date: '',
      course_id: '',
      course_title: '',
      confirmation_date: '',
      confirmation_remarks: '',
      confirmation_photo: null,
      input_date: '',
      input_remarks: '',
      input_photo: null,
      form_error: []
    };
    this.inputDate = this.inputDate.bind(this);
    this.inputRemarks = this.inputRemarks.bind(this);
    this.inputPhoto = this.inputPhoto.bind(this);
    this.confirm = this.confirm.bind(this);
  }

  componentDidMount() {
    fetch(global.config.urlLink+ "/traininguserview/" + this.state.memberId, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey
      },
    })
    .then(response => {
      if(response.status === 200) {
        return response.json();
      } else {
        console.log("Something happened wrong.");
      }
    })
    .then(resultJson => {
      var member = resultJson.values[0].content[0].maincontent;
      this.setState({
        name: member.memberName,
        email: member.memberEmail
      });
      var purchases = resultJson.values[0].scontent2;
      purchases.forEach((purchase) => {
        var url = window.location.href;
        var id = url.substring(url.lastIndexOf('/') + 1);

        if (purchase.subdocid === id) {
          var content = purchase.subcontent;
          var currency = content.paymentCurrency ? content.paymentCurrency : "IDR";
          this.setState({
            order_id: content.purchaseDate._seconds,
            order_date: Moment.unix(content.purchaseDate._seconds).format("D MMMM Y"),
            course_id: content.courseId,
            course_title: content.courseTitle,
            amount: content.purchasePrice,
            payment_method: content.paymentMethod,
            payment_account: content.paymentInfo.replace("<br/>"," "),
            payment_currency: currency === "IDR" ? "Rp " : "$",
            payment_date: content.paymentReceivedDate ? Moment.unix(content.paymentReceivedDate._seconds).format("D MMMM Y") : '',
            confirmation_date: content.paymentConfirmationDate ? Moment.unix(content.paymentConfirmationDate._seconds).format("D MMMM Y") : '',
            confirmation_remarks: content.paymentConfirmationRemarks ? content.paymentConfirmationRemarks : '',
            confirmation_photo: content.paymentConfirmationPhoto ? content.paymentConfirmationPhoto : '',
          });
        }
      });
    });
  }

  inputDate(event) {
    this.setState({ 
      input_date: event.target.value,
      form_error: [] 
    });
  }

  inputRemarks(event) {
    this.setState({ 
      input_remarks: event.target.value,
      form_error: [] 
    });
  }

  inputPhoto(event) {
    if (event.target.files[0] !== null) {
      var value = event.target.files[0];
      if (value.type.indexOf('image') > -1) {
        this.setState({
          input_photo: value,
          form_error: [] 
        }, () => {
          document.getElementById('input-photo').innerText = this.state.input_photo.name;
        });
      }
    }
  }
  
  confirm(event) {
    event.preventDefault();
    document.getElementById('submit').innerText = 'Loading...';
    document.getElementById('submit').setAttribute('disabled', 'true');

    var url = window.location.href;
    var purchase_id = url.substring(url.lastIndexOf('/') + 1);

    var error = [];
    if (!(this.state.input_date)) {
      error.push('input_date');
    }
    if (!(this.state.input_remarks)) {
      error.push('input_remarks');
    }
    if (error.length) {
      this.setState({
        form_error: error
      });
      document.getElementById('submit').innerText = 'Send';
      document.getElementById('submit').removeAttribute('disabled');
      return;
    }
    
    const data = new FormData();
    data.append('file', this.state.input_photo);
    data.append('source', 'confirmation');
    Axios.post(global.config.urlLink+ "/uploadconfirmpurchase", 
      data, { 
        headers: { 
          'content-type': 'multipart/form-data',
          'Authorization': authKey2
        }
      }
    )
    .then((result) => {
      var photo = result.data.values;
      var dataText = '{"memberdoc_id":"' + localStorage.getItem("doc_id") + '","purchasedoc_id":"' + purchase_id + '","confirmation_date":"' + this.state.input_date + '","confirmation_remarks":"' + this.state.input_remarks + '","confirmation_photo":"' + photo + '"}';
      var dataSend = JSON.parse(dataText);

      fetch(global.config.urlLink+ "/trainingconfirmpurchase", {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': authKey2
        }, 
        body : JSON.stringify(dataSend)
      })
      .then(response => {
        if(response.status === 200) {
          return response.json();
        } else {
          console.log('Something happened wrong.');
        }
      }).then(resultJson => {
        window.scrollTo(0, 0);
        window.location.reload();
      });
    });
  }

  render() {
    return (
      <div className="invoice-course">
        <Container>
          <div className="row visible-print">
            <div className="col-md-12">
              <div className="card card-invoice">
                <div className="card-header p-0 bg-white">
                  <h4 className="mb-0 p-4 bg-dark text-white"><strong>NOVAIO ACADEMY</strong></h4>
                  <div className="p-4">
                    <h1 className="mb-0 my-4">INVOICE</h1>
                    <p className="mb-0"><strong>{GetText() ? "Nomor Invoice" : "Invoice No"}</strong> {this.state.order_id}</p>
                    <p className="mb-0"><strong>{GetText() ? "Tanggal Pesanan" : "Order Date"}</strong> {this.state.order_date}</p>
                  </div>
                </div>
                <div className="card-body p-4">
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead className="card-header bg-dark">
                        <tr>
                          <th width="60%" className="col-3 text-white border-0"><strong>{GetText() ? "Deskripsi" : "Description"}</strong></th>
                          <th width="10%" className="col-1 text-white text-center border-0"><strong>{GetText() ? "Jml" : "Qty"}</strong></th>
                          <th width="30%" className="col-2 text-white text-right border-0"><strong>{GetText() ? "Harga" : "Amount"}</strong></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="col-3 border-0">Workshop<br/><strong>{this.state.course_title}</strong></td>
                          <td className="col-1 text-center border-0">1</td>
                          <td className="col-2 text-right border-0">{this.state.payment_currency + new Intl.NumberFormat().format(this.state.amount)}</td>
                        </tr>
                      </tbody>
                      <tfoot className="card-footer">
                        <tr>
                          <td colSpan="2" className="text-right"><strong>Sub Total</strong></td>
                          <td className="text-right">{this.state.payment_currency + new Intl.NumberFormat().format(this.state.amount)}</td>
                        </tr>
                        <tr>
                          <td colSpan="2" className="text-right"><strong>Total</strong></td>
                          <td className="text-right"><strong>{this.state.payment_currency + new Intl.NumberFormat().format(this.state.amount)}</strong></td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <div className="row mt-5 mb-4">
                    <div className="col-md-4">
                      <h4>{GetText() ? "TAGIHAN KEPADA" : "INVOICED TO"}</h4>
                      <address><strong>{this.state.name}</strong><br/>{this.state.email}</address>
                    </div>
                    <div className="col-md-4">
                      <h4>{GetText() ? "BAYAR KE" : "PAYMENT TO"}</h4>
                      <address><strong>{this.state.payment_method}</strong><br/>{this.state.payment_account}</address>
                    </div>
                    <div className="col-md-4 text-center">
                      <h4>{GetText() ? "STATUS PEMBAYARAN" : "PAYMENT STATUS"}</h4>
                      <h4 className="p-2 bg-primary text-white"><strong>{this.state.payment_date ? (GetText() ? "LUNAS" : "PAID") : (GetText() ? "BELUM BAYAR" : "UNPAID")}</strong></h4>
                      {
                        this.state.payment_date ? (
                          <address><strong>{GetText() ? "Tanggal Bayar" : "Payment Date"}</strong> {Moment(this.state.payment_date).format("D MMMM Y")}</address>
                        ) : ''
                      }
                    </div>
                  </div>
                  <hr/>
                  <div className="row mt-4 mb-0">
                    {
                      GetText() ? (
                        <div className="col-md-12">
                          <h4>SYARAT &amp; KETENTUAN</h4>
                          <address>Dalam kondisi apa pun Novaio Academy tidak bertanggung jawab atas konsekuensi khusus, atau kerusakan yang diakibatkan oleh pelanggaran garansi, keterlambatan atau kinerja Novaio Academy atau wanprestasi lainnya berdasarkan perjanjian ini.</address>
                        </div>
                      ) : (
                        <div className="col-md-12">
                          <h4>TERMS &amp; CONDITIONS</h4>
                          <address>In no event shall Seller be liable for any special consequential, or contingent damages resulting from Seller's Breach of Warranty, delay or performance or any other default hereunder.</address>
                        </div>                        
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row py-5 hidden-print">
            <div className="col-md-7">
              {
                this.state.confirmation_date ? '' : (
                  <div className="bg-light p-4 mb-4">
                    <Form className="form-payment">
                      <h4><strong>{GetText() ? "Instruksi Pembayaran" : "Payment Instruction"}</strong></h4>
                      <Accordion className="card-faq mt-4 text-left">
                        <Card id="accordion-0">
                          <Accordion.Toggle as={Card.Header} eventKey="0" onClick={()=> ToggleArrow(0)}>
                            m-Banking
                            <FaChevronRight className="accordion-toggle toggle-close"/>
                            <FaChevronDown className="accordion-toggle toggle-open d-none"/>
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="0" id="collapse-0">
                            <Card.Body>
                              {
                                GetText() ? (
                                  <ol>
                                    <li>Buka aplikasi Mobile {this.state.payment_bank} di hp Anda</li>
                                    <li>Pilih m-Transfer &gt; {this.state.payment_method}</li>
                                    <li>Masukkan nomor {this.state.payment_method} <strong>{this.state.payment_account}</strong> dan pilih Kirim</li>
                                    <li>Lihat data yang tampil di layar. Pastikan kembali jumlah tagihan dan akun tujuan, kemudian pilih Ya</li>
                                    <li>Masukkan PIN m-{this.state.payment_bank} Anda dan klik OK</li>
                                  </ol>
                                ) : (
                                  <ol>
                                    <li>Open your {this.state.payment_bank} Mobile application on your mobile</li>
                                    <li>Choose m-Transfer &gt; {this.state.payment_method}</li>
                                    <li>Input {this.state.payment_method} number <strong>{this.state.payment_account}</strong> and choose Send</li>
                                    <li>Check the data on screen. Ensure the amount shown and the account used are correct, then choose Yes</li>
                                    <li>Input your m-{this.state.payment_bank} PIN number and click OK</li>
                                  </ol>
                                )
                              }
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                        <Card id="accordion-1">
                          <Accordion.Toggle as={Card.Header} eventKey="1" onClick={()=> ToggleArrow(1)}>
                            Internet Banking
                            <FaChevronRight className="accordion-toggle toggle-close"/>
                            <FaChevronDown className="accordion-toggle toggle-open d-none"/>
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="1" id="collapse-1">
                            <Card.Body>
                              {
                                GetText() ? (
                                  <ol>
                                    <li>Pilih Transfer &gt; Transfer ke {this.state.payment_bank} {this.state.payment_method}</li>
                                    <li>Pilih {this.state.payment_method} Number, lalu masukkan nomor rekening {this.state.payment_method} <strong>{this.state.payment_account}</strong> dan klik Lanjutkan</li>
                                    <li>Lihat data yang tampil di layar. Pastikan kembali jumlah tagihan dan akun tujuan, kemudian pilih Ya</li>
                                    <li>Masukkan PIN key-{this.state.payment_bank} Anda dan klik OK</li>
                                  </ol>
                                ) : (
                                  <ol>
                                    <li>Choose Fund Transfer &gt; Transfer to {this.state.payment_bank} {this.state.payment_method}</li>
                                    <li>Tick the {this.state.payment_method} Number option, then input {this.state.payment_method} Number <strong>{this.state.payment_account}</strong> and choose Continue</li>
                                    <li>Check the data on screen. Ensure the amount shown and the account used are correct, then choose Yes</li>
                                    <li>input your key-{this.state.payment_bank} Response and click Submit</li>
                                  </ol>
                                )
                              }
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                        <Card id="accordion-2">
                          <Accordion.Toggle as={Card.Header} eventKey="2" onClick={()=> ToggleArrow(2)}>
                            ATM Transfer
                            <FaChevronRight className="accordion-toggle toggle-close"/>
                            <FaChevronDown className="accordion-toggle toggle-open d-none"/>
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="2" id="collapse-2">
                            <Card.Body>
                              {
                                GetText() ? (
                                  <ol>
                                    <li>Pilih Transaksi Lainnya &gt; Transfer &gt; {this.state.payment_method}</li>
                                    <li>Masukkan nomor rekening {this.state.payment_method} <strong>{this.state.payment_account}</strong> dan pilih OK</li>
                                    <li>Lihat data yang tampil di layar. Pastikan kembali jumlah tagihan dan akun tujuan, kemudian pilih Ya</li>
                                    <li>Simpan resi transfer hingga kami selesai memverifikasi pembayaran Anda</li>
                                  </ol>
                                ) : (
                                  <ol>
                                    <li>Choose Other Transaction &gt; Transfer &gt; {this.state.payment_method}</li>
                                    <li>Input {this.state.payment_method} Number <strong>{this.state.payment_account}</strong> and choose OK</li>
                                    <li>Check the data on screen. Ensure the amount shown and the account used are correct, then choose Yes</li>
                                    <li>Please keep the receipt until we verified your payment</li>
                                  </ol>
                                )
                              }
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    </Form>
                  </div>
                )
              }
              <div className="card card-pop">
                <div className="card-body p-4">
                  <div className="header">
                    <div className="row">
                      <div className="col-6">
                        <h4 className="mt-1 mb-0">Invoice</h4>
                      </div>
                      <div className="col-6 text-right">
                        <button onClick={() => window.print()} className="btn btn-sm btn-secondary"><FaPrint/> Print</button>
                      </div>
                    </div>
                    <hr/>
                  </div>
                  <div className="row">
                    <div className="col-6 col-md-4"><strong>{GetText() ? "Tanggal Pesanan" : "Order Date"}</strong></div>
                    <div className="col-6 col-md-8 text-right text-md-left">{this.state.order_date}</div>
                  </div>
                  <div className="row">
                    <div className="col-6 col-md-4"><strong>{GetText() ? "Nomor Invoice" : "Invoice No"}</strong></div>
                    <div className="col-6 col-md-8 text-right text-md-left">{this.state.order_id}</div>
                  </div>
                  <div className="row">
                    <div className="col-6 col-md-4"><strong>{GetText() ? "Status Pembayaran" : "Payment Status"}</strong></div>
                    <div className="col-6 col-md-8 text-right text-md-left">{this.state.payment_date ? (GetText() ? "LUNAS pada " : "PAID on ") + Moment(this.state.payment_date).format("D MMMM Y") : (GetText() ? "BELUM DIBAYAR" : "UNPAID")}</div>
                  </div>
                  <hr/>
                  <div className="row">
                    <div className="col-md-12">
                      <strong>{GetText() ? "Tagihan Kepada" : "Invoiced To"}</strong>
                      <address>{this.state.name}<br/>{this.state.email}</address>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead className="card-header">
                        <tr>
                          <td className="col-3 border-0"><strong>{GetText() ? "Deskripsi" : "Description"}</strong></td>
                          <td className="col-1 text-center border-0"><strong>{GetText() ? "Jumlah" : "Qty"}</strong></td>
                          <td className="col-2 text-right border-0"><strong>{GetText() ? "Harga" : "Amount"}</strong></td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="col-3 border-0">Workshop<br/><small><strong>{this.state.course_title}</strong></small></td>
                          <td className="col-1 text-center border-0">1</td>
                          <td className="col-2 text-right border-0">{this.state.payment_currency + new Intl.NumberFormat().format(this.state.amount)}</td>
                        </tr>
                      </tbody>
                      <tfoot className="card-footer">
                        <tr>
                          <td colSpan="2" className="text-right"><strong>Sub Total</strong></td>
                          <td className="text-right">{this.state.payment_currency + new Intl.NumberFormat().format(this.state.amount)}</td>
                        </tr>
                        <tr>
                          <td colSpan="2" className="text-right"><strong>Total</strong></td>
                          <td className="text-right"><strong>{this.state.payment_currency + new Intl.NumberFormat().format(this.state.amount)}</strong></td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 mt-4 mt-md-0">
              {
                this.state.payment_date ? (
                  <div className="bg-dark p-4">
                    <Form className="form-payment">
                      <h4 className="text-white"><strong>{GetText() ? "Informasi Pembayaran" : "Payment Information"}</strong></h4>
                      <div className="row mt-4">
                        <div className="col-6"><p className="text-white">Status</p></div>
                        <div className="col-6 text-right"><p className="text-white"><strong>{GetText() ? "LUNAS" : "PAID"}</strong></p></div>
                      </div>
                      <div className="row">
                        <div className="col-6"><p className="text-white">{GetText() ? "Tanggal Bayar" : "Payment Date"}</p></div>
                        <div className="col-6 text-right"><p className="text-white"><strong>{Moment(this.state.payment_date).format("D MMMM Y")}</strong></p></div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-6"><p className="text-white">{GetText() ? "Metode Pembayaran" : "Payment Method"}</p></div>
                        <div className="col-6 text-right"><p className="text-white"><strong>{this.state.payment_method}</strong></p></div>
                      </div>
                      <div className="row">
                        <div className="col-6"><p className="text-white">{GetText() ? "Bayar Ke" : "Payment To"}</p></div>
                        <div className="col-6 text-right"><p className="text-white"><strong>{this.state.payment_account}</strong></p></div>
                      </div>
                      <div className="row">
                        <div className="col-6"><p className="text-white">{GetText() ? "Total Harga" : "Total Amount"}</p></div>
                        <div className="col-6 text-right"><p className="text-white"><strong>{this.state.payment_currency + new Intl.NumberFormat().format(this.state.amount)}</strong></p></div>
                      </div>
                      <p className="my-4 text-white">{GetText() ? "Terima kasih, pembayaran Anda telah kami terima. Anda juga dapat melihat transaksi lainnya di profil Anda." : "Thank you, your payment has been received. You can also check your profile page for past orders."}</p>
                      <a className="btn btn-lg btn-primary w-100" href="/account/mycourses">{GetText() ? "Lihat Profil" : "Go to Profile"}</a>
                    </Form>
                  </div>
                ) : (
                  this.state.confirmation_date ? (
                    <div className="bg-dark p-4">
                      <Form className="form-payment">
                        <h4 className="text-white"><strong>{GetText() ? "Informasi Pembayaran" : "Payment Information"}</strong></h4>
                        <div className="row mt-4">
                          <div className="col-6"><p className="text-white">Status</p></div>
                          <div className="col-6 text-right"><p className="text-white"><strong>{GetText() ? "SEDANG DIVERIFIKASI" : "VERIFYING"}</strong></p></div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-6"><p className="text-white">{GetText() ? "Metode Pembayaran" : "Payment Method"}</p></div>
                          <div className="col-6 text-right"><p className="text-white"><strong>{this.state.payment_method}</strong></p></div>
                        </div>
                        <div className="row">
                          <div className="col-6"><p className="text-white">{GetText() ? "Bayar Ke" : "Payment To"}</p></div>
                          <div className="col-6 text-right"><p className="text-white"><strong>{this.state.payment_account}</strong></p></div>
                        </div>
                        <div className="row">
                          <div className="col-6"><p className="text-white">{GetText() ? "Total Harga" : "Total Amount"}</p></div>
                          <div className="col-6 text-right"><p className="text-white"><strong>{this.state.payment_currency + new Intl.NumberFormat().format(this.state.amount)}</strong></p></div>
                        </div>
                        <p className="my-4 text-white">{GetText() ? "Kami sedang memeriksa pembayaran Anda. Harap menunggu hingga status pembayaran berubah." : "We are currently verifying your payment. Please wait until your order status is updated."}</p>
                        <a className="btn btn-lg btn-primary w-100" href="/account/mycourses">{GetText() ? "Lihat Profil" : "Go to Profile"}</a>
                      </Form>
                    </div>
                  ) : (
                    <div className="bg-dark p-4">
                      <Form className="form-payment" onSubmit={this.confirm}>
                        <h4 className="text-white"><strong>{GetText() ? "Konfirmasi Pembayaran" : "Payment Confirmation"}</strong></h4>
                        <p className="my-4 text-white">{GetText() ? "Harap melakukan pembayaran Bank Transfer ke nomor rekening di bawah ini." : "Please make your payment through Bank Transfer to this account below."}</p>
                        <div className="row mt-4">
                          <div className="col-6"><p className="text-white">Status</p></div>
                          <div className="col-6 text-right"><p className="text-white"><strong>{GetText() ? "BELUM DIBAYAR" : "UNPAID"}</strong></p></div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-6"><p className="text-white">{GetText() ? "Metode Pembayaran" : "Payment Method"}</p></div>
                          <div className="col-6 text-right"><p className="text-white"><strong>{this.state.payment_method}</strong></p></div>
                        </div>
                        <div className="row">
                          <div className="col-6"><p className="text-white">{GetText() ? "Bayar Ke" : "Payment To"}</p></div>
                          <div className="col-6 text-right"><p className="text-white"><strong>{this.state.payment_account}</strong></p></div>
                        </div>
                        <div className="row">
                          <div className="col-6"><p className="text-white">{GetText() ? "Total Harga" : "Total Amount"}</p></div>
                          <div className="col-6 text-right"><p className="text-white"><strong>{this.state.payment_currency + new Intl.NumberFormat().format(this.state.amount)}</strong></p></div>
                        </div>
                        <hr className="bg-white my-4"/>
                        <p className="my-4 text-white">{GetText() ? "Jika Anda telah melakukan pembayaran, harap mengisi formulir di bawah ini agar memudahkan kami melacak pembayaran Anda." : "If you have made payment, please fill the form below so we can track your payment."}</p>
                        <Form.Group>
                          <label className="text-white">{GetText() ? "Tanggal Bayar" : "Payment Date"}</label>
                          {this.state.form_error.some(item => item === 'input_date') ? <span className="error-message">{GetText() ? "Wajib Diisi!" : "Required!"}</span> : ''}
                          <input className="form-control" type="date" name="confirmation_date" onChange={this.inputDate}/>
                        </Form.Group>
                        <Form.Group>
                          <label className="text-white">{GetText() ? "Keterangan" : "Remarks"}</label>
                          {this.state.form_error.some(item => item === 'input_remarks') ? <span className="error-message">{GetText() ? "Wajib Diisi!" : "Required!"}</span> : ''}
                          <textarea className="form-control" name="confirmation_remarks" placeholder={"I have paid " + this.state.payment_currency + new Intl.NumberFormat().format(this.state.amount) + " through " + this.state.payment_account} onChange={this.inputRemarks}></textarea>
                        </Form.Group>
                        <Form.Group>
                          <label className="text-white">{GetText() ? "Resi Transfer" : "Transfer Receipt"} <small>(Optional)</small></label>
                          <div className="input-group mb-3">
                            <div className="custom-file">
                              <input type="file" className="custom-file-input" name="confirmation_photo" onChange={this.inputPhoto}/>
                              <label className="custom-file-label" id="input-photo" htmlFor="photo">{GetText() ? "Pilih Gambar" : "Choose file"}</label>
                            </div>
                          </div>
                        </Form.Group>
                        <Button className="w-100 mt-4" variant="primary" size="lg" type="submit" id="submit">{GetText() ? "Kirim" : "Send"}</Button>
                      </Form>
                    </div>
                  )
                )
              }
            </div>
          </div>
        </Container>
      </div>
    );
  }
}