import React, { Component } from 'react';
import Axios from 'axios';
import JsPdf from "jspdf";
import Html2Canvas from "html2canvas";
import DateTimeFormats from '../../../config/date-time-format';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[31];

class CTCertificate extends Component {
    constructor (props) {
        super(props);
        this.state = {
            stPopTitle: '',
            member_id: '',
            library_id: '',
            certificate_bg: '',
            title: '',
            member_name: '',
            company_name:'',
            date_nw: '',
            contentoth: ''
        }
    }
    uploadCertificate() {
		return new Promise((resolve, reject) => {
            document.getElementById('div-action-1').style.display = 'none'; 
            document.getElementById('loading-area').style.display = 'block'; 
			var certificate = document.getElementById('certificate');
			certificate.style.display = "block";
            var filename = this.state.member_id + this.state.library_id + '.pdf';
			Html2Canvas(certificate, {logging: true, letterRendering: true, useCORS: true}).then(canvas => {
				const data = canvas.toDataURL('img/png');
				certificate.style.display = "none";
				const pdf = new JsPdf({
					orientation: "landscape",
					unit: "px",
					hotfixes: "px_scaling",
					format: [900, 506]
				});
				pdf.addImage(data, 'PNG', 0, 0, 900, 506);	
                pdf.save(filename);
				var blob = pdf.output('blob');
				const form = new FormData();
				form.append('file', blob, filename);
				form.append('source', 'certificate');
				Axios.post(global.config.urlLink+ "/uploadcertificate",
					form, { 
						headers: { 
							'content-type': 'multipart/form-data',
							'Authorization': authKey
						}
					}
				)
				.then(result => {
					if (result.data.values !== undefined) {
                        document.getElementById("div-action-2").innerHTML = '<a href="'+result.data.values+'" target="_blank" rel="noreferrer"><button class="ct-submitButton">Open Cerfificate</button></a>';
                        document.getElementById('loading-area').style.display = 'none'; 
						resolve(result.data.values);
					} else {
						reject('Something happened wrong.');
					}
				});
			});
		});
	}
    componentDidMount() {
        document.getElementById('certificate').style.display = 'block'; 
        var othersContent = JSON.parse(document.getElementById("contentoth").innerText);
        var certificatez_bg = "";
        if(othersContent.content_member.certificateBg !== undefined) { certificatez_bg = othersContent.content_member.certificateBg; }
		if(othersContent.content_member.memberCompany === "aegis.com.bn" || othersContent.content_member.memberCompany === "company.com") { certificatez_bg = process.env.PUBLIC_URL + "/img/bg-certificate-aegis2.png"; }
        this.setState({
            stPopTitle: '',
            member_id: othersContent.content_member.memberUserId,
            library_id: othersContent.content_lib.subcontent.libraryId,
            certificate_bg: certificatez_bg,
            title: othersContent.content_lib.subcontent.libraryTitle,
            member_name: othersContent.member_name,
            company_name: othersContent.content_member.memberCompany,
            date_nw: DateTimeFormats(othersContent.content_lib.subcontent.certificateInfo.fileDate._seconds,"date-long"),
            contentoth: document.getElementById("contentoth").innerText
        })
    }
    render() {
      return (
        <div className="main-container">
            <div className="w100p mgT20">
                <div className="certificate" id="certificate">
                        {
                        (this.state.certificate_bg !== undefined && this.state.certificate_bg !== "") ? (
                            <div className="certificate-bg" style={{backgroundImage: "url('" + this.state.certificate_bg + "')"}}></div>
                        ) : (
                            <div className="certificate-bg" style={{backgroundImage: "url('" + process.env.PUBLIC_URL + "/img/certificate.jpg')"}}></div>
                        )
                        }
                        <div className="certificate-detail">
                            <p className="certificate-text">Certificate of Completion</p>
                            <p className="certificate-course">{this.state.title}</p>
                            <p className="certificate-name">{this.state.member_name}</p>
                        </div>
                        <div className="certificate-date" style={{width:"900px"}} >
                            <p>For course completed on the <strong>{this.state.date_nw}</strong>, conducted by <strong>Novaio Academy</strong></p>
                        </div>
                </div>
            </div>
            <div className="w100p mgT20 mgB30 divCenter" id="div-action-1"><button className="ct-submitButton" onClick={this.uploadCertificate.bind(this)}>PRINT</button></div>
            <div className="w100p mgT20 mgB30 divCenter" id="div-action-2"></div>
            
            <div id="loading-area" className="divHide loading"></div> 
        </div>
      );
    }
 }
  
export default CTCertificate;