import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';
import { GetText } from '../../helper';

export default class Announcement extends Component {
  render() {
    return (
      <Alert className="text-center" variant="info">
        {GetText() ? "Grand Opening: Workshop Gratis. " : "Grand Opening: Free training. "}
        <Alert.Link href="/login">{GetText() ? "Daftar di sini!" : "Register here!"}</Alert.Link>
      </Alert>
    );
  }
}