import React, { Component } from 'react';
import '../ctinc/ct-Style-2.css';
import CTHeader from './ct-fragmentheader';
import CTSidemenu from './ct-fragmentsidemenu';
import CTNA from './ct-fragmentna';
import '../ctinc/popup.css'
import ReplaceAllContent from '../../../config/replace-all';
import EditIcon from '@material-ui/icons/Edit';
import NAimage from '../../assets/na.png';

const authKey = global.ctconfig.authBearerKey+"##"+global.ctconfig.kindcode[35];

class CTTutorDetailEdit extends Component {
  constructor (props) {
    super(props);
    this.state = {
        stTitle: 'Edit Trainer',
        tutor_docid: '',
        tutor_name: '',
        tutor_email: '',
        tutor_linkedin: '',
        tutor_bio: '',
        tutor_picture: ''
    }
    this.loadData = this.loadData.bind(this);
  }

  handleBack = () => {
    // this.props.history.goBack();
    window.location.href=global.ctconfig.urlHost+'/cttutor';
  }

  loadData(id) {    
      document.getElementById('loading-area').style.display = 'block'; 
      fetch(global.ctconfig.urlLink+"/tutorview/"+id, {
          method: 'GET',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey,
          }
        }).then(res => {
          if(res.status === 200) 
              return res.json() 
        }).then( resJson => {
          document.getElementById('loading-area').style.display = 'none'; 
          if(resJson.status === '200') {
              var lName,lEmail,lBio,lLinkedin,lPicture;
              var resultContent = resJson.values[0].content[0].maincontent;
              if(resultContent.tutorName !== undefined) { 
                lName = resultContent.tutorName;
              }
              if(resultContent.tutorEmail !== undefined) { 
                lEmail = resultContent.tutorEmail;
              }
              if(resultContent.tutorBio !== undefined) {
                lBio = ReplaceAllContent('[;]',',',resultContent.tutorBio);
                lBio = ReplaceAllContent('<br/>','\n',lBio);
              }
              if(resultContent.tutorLinkedin !== undefined) { 
                lLinkedin = resultContent.tutorLinkedin;
              }
              if(resultContent.tutorPicture !== undefined && resultContent.tutorPicture !== "") { 
                lPicture = resultContent.tutorPicture;
              } else { 
                lPicture = NAimage; 
              }
              this.setState({
                  stTitle: 'Edit Trainer',
                  tutor_docid: resJson.values[0].docid,
                  tutor_name: lName,
                  tutor_email: lEmail,
                  tutor_bio: lBio,
                  tutor_linkedin: lLinkedin,
                  tutor_picture: lPicture
              }) 
          } else {
              console.log('Something happened wrong');
          }
        }).catch(err => {
          document.getElementById('loading-area').style.display = 'none'; 
        }); 
  }

  componentDidMount() {
    var geturl = window.location; //this.props.location; 
    var arrUrl = [];
    arrUrl = geturl.pathname.split('/');
    this.loadData(arrUrl[3]);
  }

  render() {
   return (
    <div className="main-container">
      <div  className="ct-header-main"><CTHeader/></div>
          <div className="flex-container">
              <div className="sidebar-main"><CTSidemenu/></div>
              <div className="main-content">
                <div className="ct-stTitle">Trainers</div>
                {((localStorage.getItem('ctulevel') === global.ctconfig.userlevel[0]) || (JSON.parse(localStorage.getItem('cturole'))[2] === "ON") || (JSON.parse(localStorage.getItem('cturole'))[3] === "ON")  || (JSON.parse(localStorage.getItem('cturole'))[4] === "ON"))? 
                <div>
                  <div className="stIndiTitle">Trainers { this.state.stTitle !== ''? '> '+this.state.stTitle : '' }</div>
                  <div className="mgT30">
                    {/* ====== */}
                    <div  className="content-container card card-body">
                        <div className="flex-container mgB20">
                            <div className="st2Title w70p"></div>
                            <div className="divRight w30p"><div><button className="backButton" onClick={this.handleBack}>&#9664; back</button></div></div>
                        </div>
                        {/* --- Start - DETAIL INFORMATION ---------- */}
                        <div id="detail-info-area">
                          <div className="flex-container">
                            <div className="w70p st4Title divMiddle">Detail Information</div>
                            <div className="w30p divRight">
                                <div className="linkText cursor-on"><a href={global.ctconfig.urlHost+"/ctedittutor/"+this.state.tutor_docid}><EditIcon className="mgR05" style={{fontSize:"14pt"}} ></EditIcon><span style={{marginTop:"3px"}}>Edit Info &raquo;</span></a></div>
                            </div>
                          </div>
                          <div className="mgT30">
                            <div className="mgT20 flex-container">
                                <div className="w20p"><h6>Name</h6></div>
                                <div className="w80p"><span className="mgR10">:</span>{this.state.tutor_name}</div>
                            </div>
                            <div className="mgT05 flex-container">
                                <div className="w20p"><h6>Email</h6></div>
                                <div className="w80p"><span className="mgR10">:</span>{this.state.tutor_email}</div>
                            </div>
                            <div className="mgT30">
                                <div><h6>Bio :</h6></div>
                                <div>{this.state.tutor_bio}</div>
                            </div>
                            <div className="mgT30 flex-container">
                                <div className="w20p"><h6>Linkedin</h6></div>
                                <div className="w80p"><span className="mgR10">:</span>{this.state.tutor_linkedin}</div>
                            </div>
                            <div className="mgT30">
                                <div><h6>Picture :</h6></div>
                                <div><img width="150" src={this.state.tutor_picture} alt=""/></div>
                            </div>
                          </div>
                        </div>
                        {/* --- End - DETAIL INFORMATION ---------- */}
                    </div>
                    {/* ====== */}
                  </div>  
                </div>
                :   
                <div><CTNA/></div>
                }
              </div>
          </div>
          <div id="loading-area" className="divHide loading"></div>
    </div>
   );
  }
}
 
export default CTTutorDetailEdit;