import React, { Component } from 'react';
import '../ctinc/ct-Style-2.css';
import '../ctinc/ct-Style.css';
import '../ctinc/ct-Global';
import Alert from '@material-ui/lab/Alert';
import DateTimeFormats from '../../../config/date-time-format';
import defMemberPicture from '../../assets/pic-profile.png';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import NoteAdd from '@material-ui/icons/NoteAdd';
import Popup from '../ctinc/popup';
import '../ctinc/popup.css';
import CTCertificate from './ct-pop-manual-certificate';

const authKey = global.ctconfig.authBearerKey + "##" + global.ctconfig.kindcode[13];

class CTUserCompanyDetail extends Component {
  constructor (props) {
    super(props);
    this.state = {
      stPopTitle: '',
      member_name: '',
      member_email: '',
      member_picture: '',
      member_active: false,
      company_name: '',
      member_info:'',
      library_list: [],
      certificate_list: [],
      isOpen: false,
      library_id_c: '',
      otherContent: ''
    }
  }

  togglePopClose() {
    this.setState({
      isOpen: false,
    })
  }
  createCertificate(memberinfo,content) {
    var othContent =JSON.stringify({"member_name": memberinfo.memberName ,"content_member": memberinfo, "content_lib": content }); 
    this.setState({
      isOpen: true,
      library_id_c: content.subcontent.libraryId,
      otherContent: othContent
    }) 
  }
  componentDidMount() {
    var getContent = JSON.parse(document.getElementById("contentoth").innerText);        
    fetch(global.ctconfig.urlLink + "/traininguserview/" + getContent.member_id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey,
      }
    }).then(response => {
      if(response.status === 200) {
        return response.json();
      } else {
        console.log('Something happened wrong');
      }
    }).then(resJson => {
      if(resJson.status === '200') {
        document.getElementById('loading-area').style.display = 'none';
        document.getElementById("errormsg").style.display = 'none';
        var mainContent = resJson.values[0].content[0].maincontent;
        var library_list = resJson.values[0].scontent3;
        var certificate_list = resJson.values[0].scontent4;
        for (var i = 0; i < library_list.length; i++) {
          for (var j = 0; j < certificate_list.length; j++) {
            if (library_list[i].subcontent.libraryId === certificate_list[j].subcontent.courseId) {
              library_list[i].subcontent.certificateUrl = certificate_list[j].subcontent.fileUrl;
              library_list[i].subcontent.certificateInfo = certificate_list[j].subcontent;
            }
          }
        }
        this.setState({
          member_id: getContent.member_id,
          member_name: mainContent.memberName,
          member_email: mainContent.memberEmail,
          member_picture: mainContent.memberPicture ? mainContent.memberPicture : defMemberPicture,
          member_active: mainContent.flagActive,
          company_name: mainContent.memberCompany,
          member_info: mainContent,
          library_list: library_list,
          certificate_list: certificate_list
        });
      } else {
        console.log('Something happened wrong');
      }
    }).catch(err => { 
      document.getElementById('loading-area').style.display = 'none'; 
    })
  }

  render() {
    return (
      <div className="main-container">
        <div className="mgT20">
          <div className="divhide w100p" id="errormsg"><Alert severity="error">{this.state.errmsg}</Alert></div>
          <div className="mgT20 mgL20">
            <div className="content-container card card-body">
              <div id="profile-info" className="col-6">
                <div className="mgB30"><img src={this.state.member_picture} alt="Member Pictures" className="memberPhoto"/></div>
                <div className="row mgT05">
                  <div className="col-12">{this.state.member_name}</div>
                </div>
                <div className="row">
                  <div className="col-12">{this.state.member_email}</div>
                </div>
              </div>
              <div className="mgT30" id="subscribe-area">
                <div className="flex-container">
                  <div className="w70p st3Title divMiddle">Library List</div>
                  <div className="w30p divRight"></div>
                </div>
                <div className="mgT20">
                  <table className="table table-hover">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Library</th>
                        <th scope="col">Last Visited</th>
                        <th scope="col">Last Module</th>
                        <th scope="col">Progress</th>
                        <th scope="col">Certificate</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.library_list.length > 0 ? (
                          this.state.library_list.map((library, i) => {
                            return(
                              <tr key={"library-" + (i + 1)}>
                                <td>{i + 1}</td>
                                <td>{library.subcontent.libraryTitle}</td>
                                <td>{DateTimeFormats(library.subcontent.lastVisit._seconds,"date-long")}</td>
                                <td>{"Module " + library.subcontent.lastLesson}</td>
                                <td>{library.subcontent.completion + "%"}</td>
                                <td>
                                  <div className="flex-container">
                                    <div style={{width:"30px"}}>{library.subcontent.certificateUrl ? <a href={library.subcontent.certificateUrl} className="iconGrey cursor-on" target="_blank" rel="noreferrer"><DownloadIcon/></a> : '-'}</div>
                                    <div style={{width:"30px"}}>{(library.subcontent.certificateUrl && localStorage.getItem('ctulevel') === global.ctconfig.userlevel[0]) ? <NoteAdd onClick={this.createCertificate.bind(this, this.state.member_info, library)} className="iconGrey cursor-on"></NoteAdd> : <></> }</div>
                                  </div>
                                </td>
                              </tr>
                            )
                          })
                        ) : (
                          <tr key={"library-0"}>
                            <td colSpan={6}>No Data</td>
                          </tr>
                        )
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="row-field mgT30 mgB30">
                {
                  this.state.isOpen && <Popup 
                    content={<CTCertificate/>}
                    id={this.state.library_id_c} 
                    title={"Certificate"}
                    others={this.state.otherContent}
                    styles={"950px"}
                    handleClose={this.togglePopClose.bind(this, this.state.library_id_c)}
                  />
                }
              
          </div>
        </div>
        <div id="loading-area" className="divHide loading"></div> 
      </div>
    );
  }
}

export default CTUserCompanyDetail;