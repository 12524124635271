import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Guest from './guest/Index';
import Member from './member/Index';
import CMS from './cms/Index';
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';

const routing = localStorage.getItem('logged_in') !== '1' ? (

  <Router>
    <Switch>
      <Route exact path="/"><Guest/></Route>
      <Route path="/c"><CMS/></Route>
      <Route><Guest/></Route>
    </Switch>
  </Router>

) : (

  <Router>
    <Switch>
      <Route exact path="/"><Member/></Route>
      <Route path="/c"><CMS/></Route>
      <Route><Member/></Route>
    </Switch>
  </Router>
  
);
ReactDOM.render(routing, document.getElementById('root'));
// ReactDOM.render(<React.StrictMode>{routing}</React.StrictMode>, document.getElementById('root'));
