import React, { Component } from 'react';
import '../ctinc/ct-Style-2.css';
import '../ctinc/ct-Style.css';
import '../ctinc/ct-Global';
import CTHeader from './ct-fragmentheader';
import CTSidemenu from './ct-fragmentsidemenu';
//import IUC from './ifragmentuc';
//import EditIcon from '@material-ui/icons/Edit';
import DescriptionIcon from '@material-ui/icons/Description';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
//import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
//import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
//import Pagination from '@material-ui/lab/Pagination';
//import TextField from '@material-ui/core/TextField';
import DateTimeFormats from '../../../config/date-time-format';

const authKey = global.ctconfig.authBearerKey+"##"+global.ctconfig.kindcode[13];

function urladdUserTraining() {
    window.location.href=global.ctconfig.urlHost+'/ctaddusertraining';
}

class CTUserTrainings extends Component {
  constructor(props) {
    super(props);
    this.state = {
        stTitle: '',
        pageShow: '10',
        keyword: '',
        styleTb: 'table',
        data: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    //this.deleteHandler = this.deleteHandler.bind(this);
    this.loadData = this.loadData.bind(this);
  }
  loadData() {
    //console.log("loading...");
    document.getElementById('loading-area').style.display = 'block'; 
    var datatext ='{"keyword":"'+this.state.keyword+'","pageShow":"'+this.state.pageShow+'"}';
    var data = JSON.parse(datatext); 
    fetch(global.ctconfig.urlLink+"/traininguserlist", {
        method: 'POST',
        //mode: 'no-cors', 
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
    }).then(response => {
        if(response.status === 200) {
            // console.log('response');
            // console.log(response);
            return response.json()
        } else {
            console.log('Something happened wrong');
            // console.log(response);
        }
    }).then(resJson => {
        console.log(resJson);
        //resJson = '{status: 200, values: { userEmail: "melia@point-star.com",userFullname: "Melia",userId: "111773733087018575892",userLevel: "Admin",userName: "melia@point-star.com",userPassword: ""}}';
        document.getElementById('loading-area').style.display = 'none'; 
        var styleTb = 'table';
        if(resJson.status === '200' && resJson.values.length > 0) { styleTb = 'table table-striped'; } 
        this.setState({
            styleTb: styleTb,
            data: resJson
        })
    }).catch(err => {
        document.getElementById('loading-area').style.display = 'none'; 
        console.log(err);
    });
  }
  urldelMember(valId) {
    document.getElementById('loading-area').style.display = 'block'; 
    var urlString = global.ctconfig.urlLink+"/traininguserdelete/"+valId;
    fetch(urlString, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }
    }).then(response => {
        document.getElementById('loading-area').style.display = 'none'; 
        if(response.status === 200) {
            window.location.reload();
            //window.location.href='/listevent';
        } else {
            console.log('Something happened wrong delete');
            console.log(response);
        }
    }).catch(err => { 
        document.getElementById('loading-area').style.display = 'none'; 
        console.log(err); 
    });
  }
  handleChange(e) {
    this.setState({ [e.target.name] : e.target.value });
  }
  handleUserSelect = (e) => {
    //alert(this.textInputMailTemplate.value)
    //alert(e.target.value)
    const value = e.target.value;
    //const inputname = e.target.name;
    this.setState( prevState => {
        return {  
            ...prevState.pageShow, pageShow: value
        }
    })
  }
    
  handleSubmit(e) {
    e.preventDefault();
    //console.log(this.state.keyword);
    this.loadData();
  }
  componentDidMount() {
    this.loadData();
    /*
    var resStrJson = '{"status": "200", "values": [{ "docid": "xX0lD0mz0Q1I4U8eTDhh", "content": {"userEmail": "melia@point-star.com","userFullname": "Melia","userId": "111773733087018575892","userLevel": "Admin","userName": "melia@point-star.com","userPassword": "","lastLogin": {"_nanoseconds": "114000000","_seconds": "1607851009"}}},{ "docid": "xX0lD0mz0Q1I4U8eTDhh", "content": {"userEmail": "melia2@point-star.com","userFullname": "Melia2","userId": "111773733087018575892","userLevel": "User","userName": "melia2@point-star.com","userPassword": "","lastLogin": { "_nanoseconds": "114000000", "_seconds": "1607851009" }}}]}';
    console.log(resStrJson);
    var resJson = JSON.parse(resStrJson);
    var styleTb = 'table';
    if(resJson.status === '200' && resJson.values.length > 0) { styleTb = 'table table-striped'; } 
    this.setState({
        styleTb: styleTb,
        data: resJson
    })
    */
 }
 render() {
    var memberList = [];
    //console.log(this.state.data.values);
    //console.log(this.state.data.status);
    if(this.state.data.values.length > 0 && this.state.data.status === '200') {
        // memberList.push(<div className="flex-container">
        //     <div className="tablelistheader cellTable middleCenter" style={{width:'5%'}}>No</div>
        //     <div className="tablelistheader cellTable middleCenter" style={{width:'10%'}}>Member Id</div>
        //     <div className="tablelistheader cellTable middleCenter" style={{width:'30%'}}>Member Name</div>
        //     <div className="tablelistheader cellTable middleCenter" style={{width:'30%'}}>Member Email</div>
        //     <div className="tablelistheader cellTable middleCenter" style={{width:'15%'}}>Member Level</div>
        //     <div className="tablelistheader cellTable middleCenter" style={{width:'10%'}}>Action</div>
            
        // </div>)
        this.state.data.values.sort((a, b) => b.content.registeredDate._seconds - a.content.registeredDate._seconds);
        for(var ls=0; ls < this.state.data.values.length; ls++) {
            var retResult = this.state.data.values[ls];
            //var editURL = global.ctconfig.urlHost+"/cteditusertraining/"+retResult.docid;
            var viewURL = global.ctconfig.urlHost+"/ctviewusertraining/"+retResult.docid;
            var regDatez = "";
            if(retResult.content.registeredDate !== undefined) {
                regDatez = DateTimeFormats(retResult.content.registeredDate._seconds,"datetime-short");
            }
            var memberPlan = "";
            if(retResult.content.memberPlan !== undefined  && retResult.content.memberPlan !== "") {
                memberPlan = retResult.content.memberPlan;
            }
            if(retResult.content.trainingPlan !== undefined && retResult.content.trainingPlan !== "") {
                memberPlan += "\nWorkshop Plan : "+retResult.content.trainingPlan;
            }
            //var regDatez = retResult.content.registeredDate._seconds;
            // var date_ob = new Date(lastLoginz);
            // var date1 = date_ob.getDate();
            // var month1 = date_ob.getMonth() + 1;
            // var year1 = date_ob.getFullYear();
            memberList.push(<tr key={retResult.docid}>
                <th scope="row">{ls+1}</th>
                <td>{regDatez}</td>
                <td>{retResult.content.memberName}</td>
                <td>{retResult.content.memberEmail}</td>
                <td>{memberPlan}</td>
                {/*<td>{DateTimeFormats(lastLoginz)}</td>*/}
                <td>
                    {/*<a href={editURL}><EditIcon className="iconGrey mgR20" /></a>*/}
                    <a href={viewURL}><DescriptionIcon className="iconGrey mgR20" /></a>
                    {/*<DeleteIcon className="iconGrey cursor-on" onClick={this.urldelMember.bind(this, retResult.docid)}/>*/}
                </td>
              </tr>)
            // memberList.push(<div className="flex-container tablelistcontent" key={retResult.docid}>
            //     <div className="textCenter cellTable" style={{width:'5%'}}>{ls+1} </div>
            //     <div className="textCenter cellTable" style={{width:'10%'}}>{retResult.content.userId}</div>
            //     <div className="textCenter cellTable" style={{width:'30%'}}>{retResult.content.userFullname}</div>
            //     <div className="textCenter cellTable" style={{width:'30%'}}>{retResult.content.userEmail}</div>
            //     <div className="textCenter cellTable" style={{width:'15%'}}>{retResult.content.userLevel}</div>
                
            //     <div className="textCenter cellTable" style={{width:'5%'}}><a href={editURL}><EditIcon className="iconGrey" /></a></div>
            //     <div className="textCenter cellTable" style={{width:'5%'}}><a href={viewURL}><DescriptionIcon className="iconGrey" /></a></div>
                
            // </div>)
        }
    } else {
        memberList.push(<tr key="1">
            <td colSpan="7" className="textCenter"><div className="mgT20 mgB20">:: no record ::</div></td>
          </tr>)
        //memberList.push(<div className="mgT50 textCenter fontRed">:: no record ::</div>)
    }
    
   return (
    <div className="main-container">
      <div  className="ct-header-main"><CTHeader/></div>
          <div className="flex-container">
              <div className="sidebar-main"><CTSidemenu/></div>
              <div className="main-content">
                <div className="ct-stTitle">Users</div>
                <div className="stIndiTitle">List of Training Booking Users</div>
                <div className="mgT30">
                    {/*--*/}
                    <div className="content-container card card-body">
                        <div className="row show">
                            <div className="col-6 divMiddle">
                                <form method="post" id="search-form" className="form-inline" onSubmit={this.handleSubmit}>
                                    <div className="flex-container" style={{display:'none'}}>
                                        {/*<TextField id="keyword" name="keyword" label="Search entries.." type="search" variant="outlined" 
                                        value={this.state.keyword}
                                        onChange={this.handleChange.bind(this)}
                                        />*/}
                                        <input type="text" className="inputSearch mr-sm-2" name="keyword" id="keyword"
                                            placeholder="Search entries.."
                                            value={this.state.keyword}
                                            onChange={this.handleChange.bind(this)}/> 
                                        <SearchIcon style={{ fontSize: 35 }} className="iconGrey mgT10 cursor-on" onClick={this.handleSubmit}></SearchIcon>
                                    </div>
                                </form>
                            </div>
                            <div className="col-6 form-inline justify-content-end">
                            <button className="ct-genButton mgL20" onClick={urladdUserTraining}><AddCircleOutlineIcon className="iconWhite mgR05"></AddCircleOutlineIcon>Add New Users</button>
                            </div>
                        </div>
                        <div className="row mgT20">
                            <table className={this.state.styleTb}>
                            <thead>
                                <tr>
                                <th scope="col">#</th>
                                <th scope="col">Register Date</th>
                                <th scope="col">Member Name</th>
                                <th scope="col">Member Email</th>
                                <th scope="col">Status</th>
                                {/*<th scope="col">Last Login</th>*/}
                                <th scope="col" style={{width:"150px"}}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {memberList}
                            </tbody>
                            </table>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <div className="flex-container" style={{display:'none'}}>
                                    <div className="mgR20 divMiddle">Showing</div>
                                    <div className="mgR20 divMiddle show">
                                        <select className="btn btn-secondary" name="pageShow" 
                                            value={this.state.pageShow} 
                                            onChange={this.handleUserSelect}
                                            ref={(input) => this.textInputPageShow = input} >
                                            <option value="1">1</option>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                    <div className="mgR20 divMiddle">list</div>
                                </div>
                            </div>
                            <div className="col-6 form-inline justify-content-end">
                                {/*<Pagination count={10} variant="outlined" shape="rounded" />*/}
                                {/*<nav className="row">
                                    <div className="col-12">
                                    <ul className="pagination justify-content-center">
                                        <li className="page-item">
                                        <a className="page-link" href="#!" aria-label="Previous">
                                            <span aria-hidden="true">&laquo;</span>
                                            <span className="sr-only">Previous</span>
                                        </a>
                                        </li>
                                        <li className="page-item active"><a class="page-link" href="#!">1</a></li>
                                        <li className="page-item"><a class="page-link" href="#!">2</a></li>
                                        <li className="page-item"><a class="page-link" href="#!">3</a></li>
                                        <li className="page-item">
                                        <a className="page-link" href="#!" aria-label="Next">
                                            <span aria-hidden="true">&raquo;</span>
                                            <span className="sr-only">Next</span>
                                        </a>
                                        </li>
                                    </ul>
                                    </div>
                                </nav>*/}
                            </div>
                        </div>
                    </div>
                    {/*--*/}
                </div>
              </div>
          </div>
          <div id="loading-area" className="divHide loading"></div>
    </div>
   );
 }
}
 
export default CTUserTrainings;