import React, { Component } from 'react';
import Moment from 'moment';
import { GetText, ToggleArrow } from '../helper';
import { Container, Form, Button, Accordion, Card, Popover, OverlayTrigger } from 'react-bootstrap';
import { FaCopy, FaChevronRight, FaChevronDown } from 'react-icons/fa';
import '../config/global';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[10];
const authKey2 = global.config.authBearerKey+"##"+global.config.kindcode[32];

export default class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // google_id: localStorage.getItem('google_id'),
      doc_id: localStorage.getItem('doc_id'),
      course_id: '',
      image: '',
      title: '',
      amount: '',
      currency: '',
      payment_method: '',
      payment_bank: '',
      payment_account: '',
      invoice_id: '',
      form_error: []
    }
    this.setMethod = this.setMethod.bind(this);
    this.setBank = this.setBank.bind(this);
    this.checkout = this.checkout.bind(this);
  }

  componentDidMount() {
    var url = window.location.href;
    var id = url.substring(url.lastIndexOf('/') + 1);
    
    fetch(global.config.urlLink+"/courseview/" + id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey
      }
    })
    .then(response => {
      if(response.status === 200) {
        return response.json();
      } else {
        console.log('Something happened wrong');
      }
    })
    .then(resultJson => {
      var content = resultJson.values[0].content[0].maincontent;
      if (content.courseLanguage !== localStorage.getItem('language')) {
        window.location.href = "/course/" + id;
      } else {
        this.setState({
          course_id: id,
          image: content.coursePicture,
          title: content.courseTitle,
          amount: content.coursePrice,
          currency: GetText() ? "IDR" : "USD"
        });
      }      
    });
  }

  setMethod(event) {
    this.setState({
      payment_method: event.target.value,
      form_error: []
    });
  }

  setBank(event) {
    var payment_bank = '';
    var payment_account = '';
    if (event.target.value === 'BCA') {
      payment_bank = 'BCA';
      payment_account = '123456789';
    }
    else if (event.target.value === 'Mandiri') {
      payment_bank = 'Mandiri';
      payment_account = '0012345678900';
    }
    else if (event.target.value === 'BNI') {
      payment_bank = 'BNI';
      payment_account = '987654321';
    }
    else if (event.target.value === 'BRI') {
      payment_bank = 'BRI';
      payment_account = '0098765432100';
    }
    this.setState({
      payment_bank: payment_bank,
      payment_account: payment_account,
      form_error: []
    });
  }

  checkout(event) {
    event.preventDefault();
    document.getElementById('submit').innerText = 'Loading...';
    document.getElementById('submit').setAttribute('disabled', 'true');

    var error = [];
    if (!(this.state.payment_method)) {
      error.push('payment_method');
    }
    if (!(this.state.payment_bank)) {
      error.push('payment_bank');
    }
    if (error.length) {
      this.setState({
        form_error: error
      });
      document.getElementById('submit').innerText = 'Create Order';
      document.getElementById('submit').removeAttribute('disabled');
      return;
    }

    var data = {
      trmember_id: this.state.doc_id,
      payment_method: this.state.payment_method,
      payment_currency: this.state.currency,
      course_id: this.state.course_id,
      course_title: this.state.title,
      course_price: this.state.amount
    };

    fetch(global.config.urlLink+"/trainingaddpurchase", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey2
      }, 
      body: JSON.stringify(data)
    })
    .then(response => {
      if(response.status === 200) {
        return response.json();
      } else {
        console.log('Something happened wrong.');
      }
    })
    .then(resultJson => {
      if (resultJson) {
        this.setState({
          invoice_id: resultJson.values
        });
        window.scrollTo(0, 0);
      }
    });
  }

  render() {
    const popover = (
      <Popover id="popover">
        <Popover.Title as="p">{GetText() ? "Nomor disalin" : "Number Copied"}</Popover.Title>
      </Popover>
    );

    return (
      <div className="checkout">
        {
          this.state.invoice_id ? (
            <Container>
              <div className="row py-5" id="success">
                <div className="col-md-7 mx-auto text-center">
                  <h1><strong>{GetText() ? "Pesanan Anda berhasil diproses!" : "Your order has been created!"}</strong></h1>
                  <div className="py-2">
                    <p className="mt-2">
                      {
                        GetText() ? 
                          "Silakan melakukan pembayaran melalui " + this.state.payment_method + " ke akun berikut. Jika Anda telah selesai, mohon tunggu sampai status pesanan berubah." 
                          : "Please make your payment through " + this.state.payment_method + " to this account below. If you have made payment, please wait until the order status is changed."
                      }
                    </p>
                    <div className="row">
                      <div className="col-md-10 mx-auto">
                        <div className="card card-pop mt-4">
                          <div className="card-body text-left">
                            <div className="row">
                              <div className="col-6">{GetText() ? "Bayar sebelum" : "Complete the payment before"}</div>
                              <div className="col-6">{Moment().add('30', 'minute').format('D MMMM Y HH:mm')}</div>
                            </div>
                            <div className="row">
                              <div className="col-6">{GetText() ? "Jumlah Total" : "Total Amount"}</div>
                              <div className="col-6 text-primary"><strong>{this.state.payment_currency === "IDR" ? "Rp " : "$"} {new Intl.NumberFormat().format(this.state.amount)}</strong></div>
                            </div>
                            <div className="row">
                              <div className="col-6">{GetText() ? "Metode Pembayaran" : "Payment Method"}</div>
                              <div className="col-6"><strong>{this.state.payment_method}</strong></div>
                            </div>
                            <div className="row">
                              <div className="col-6">{GetText() ? "Lakukan Pembayaran Ke" : "Payment To"}</div>
                              <div className="col-6">
                                <strong>
                                  {this.state.payment_bank + ' ' + this.state.payment_account}
                                  <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                                    <a href="#!" className="text-secondary ml-2"  onClick={() => {navigator.clipboard.writeText(this.state.payment_account)}}><FaCopy/></a>
                                  </OverlayTrigger>
                                </strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-10 mx-auto">
                        <div className="bg-light p-4 mt-4">
                          <Form className="form-payment">
                            <h6><strong>{GetText() ? "Instruksi Pembayaran" : "Payment Instruction"}</strong></h6>
                            <Accordion className="card-faq mt-4 text-left">
                              <Card id="accordion-0">
                                <Accordion.Toggle as={Card.Header} eventKey="0" onClick={()=> ToggleArrow(0)}>
                                  m-Banking
                                  <FaChevronRight className="accordion-toggle toggle-close"/>
                                  <FaChevronDown className="accordion-toggle toggle-open d-none"/>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0" id="collapse-0">
                                  <Card.Body>
                                    {
                                      GetText() ? (
                                        <ol>
                                          <li>Buka aplikasi Mobile {this.state.payment_bank} di hp Anda</li>
                                          <li>Pilih m-Transfer &gt; {this.state.payment_method}</li>
                                          <li>Masukkan nomor {this.state.payment_method} <strong>{this.state.payment_account}</strong> dan pilih Kirim</li>
                                          <li>Lihat data yang tampil di layar. Pastikan kembali jumlah tagihan dan akun tujuan, kemudian pilih Ya</li>
                                          <li>Masukkan PIN m-{this.state.payment_bank} Anda dan klik OK</li>
                                        </ol>
                                      ) : (
                                        <ol>
                                          <li>Open your {this.state.payment_bank} Mobile application on your mobile</li>
                                          <li>Choose m-Transfer &gt; {this.state.payment_method}</li>
                                          <li>Input {this.state.payment_method} number <strong>{this.state.payment_account}</strong> and choose Send</li>
                                          <li>Check the data on screen. Ensure the amount shown and the account used are correct, then choose Yes</li>
                                          <li>Input your m-{this.state.payment_bank} PIN number and click OK</li>
                                        </ol>
                                      )
                                    }
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                              <Card id="accordion-1">
                                <Accordion.Toggle as={Card.Header} eventKey="1" onClick={()=> ToggleArrow(1)}>
                                  Internet Banking
                                  <FaChevronRight className="accordion-toggle toggle-close"/>
                                  <FaChevronDown className="accordion-toggle toggle-open d-none"/>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="1" id="collapse-1">
                                  <Card.Body>
                                    {
                                      GetText() ? (
                                        <ol>
                                          <li>Pilih Transfer &gt; Transfer ke {this.state.payment_bank} {this.state.payment_method}</li>
                                          <li>Pilih {this.state.payment_method} Number, lalu masukkan nomor rekening {this.state.payment_method} <strong>{this.state.payment_account}</strong> dan klik Lanjutkan</li>
                                          <li>Lihat data yang tampil di layar. Pastikan kembali jumlah tagihan dan akun tujuan, kemudian pilih Ya</li>
                                          <li>Masukkan PIN key-{this.state.payment_bank} Anda dan klik OK</li>
                                        </ol>
                                      ) : (
                                        <ol>
                                          <li>Choose Fund Transfer &gt; Transfer to {this.state.payment_bank} {this.state.payment_method}</li>
                                          <li>Tick the {this.state.payment_method} Number option, then input {this.state.payment_method} Number <strong>{this.state.payment_account}</strong> and choose Continue</li>
                                          <li>Check the data on screen. Ensure the amount shown and the account used are correct, then choose Yes</li>
                                          <li>input your key-{this.state.payment_bank} Response and click Submit</li>
                                        </ol>
                                      )
                                    }
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                              <Card id="accordion-2">
                                <Accordion.Toggle as={Card.Header} eventKey="2" onClick={()=> ToggleArrow(2)}>
                                  ATM Transfer
                                  <FaChevronRight className="accordion-toggle toggle-close"/>
                                  <FaChevronDown className="accordion-toggle toggle-open d-none"/>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="2" id="collapse-2">
                                  <Card.Body>
                                    {
                                      GetText() ? (
                                        <ol>
                                          <li>Pilih Transaksi Lainnya &gt; Transfer &gt; {this.state.payment_method}</li>
                                          <li>Masukkan nomor rekening {this.state.payment_method} <strong>{this.state.payment_account}</strong> dan pilih OK</li>
                                          <li>Lihat data yang tampil di layar. Pastikan kembali jumlah tagihan dan akun tujuan, kemudian pilih Ya</li>
                                          <li>Simpan resi transfer hingga kami selesai memverifikasi pembayaran Anda</li>
                                        </ol>
                                      ) : (
                                        <ol>
                                          <li>Choose Other Transaction &gt; Transfer &gt; {this.state.payment_method}</li>
                                          <li>Input {this.state.payment_method} Number <strong>{this.state.payment_account}</strong> and choose OK</li>
                                          <li>Check the data on screen. Ensure the amount shown and the account used are correct, then choose Yes</li>
                                          <li>Please keep the receipt until we verified your payment</li>
                                        </ol>
                                      )
                                    }
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            </Accordion>
                          </Form>
                        </div>
                      </div>
                    </div>
                    <a className="btn btn-lg btn-primary mt-4" href={"/invoice/course/" + this.state.invoice_id}>{GetText() ? "Konfirmasi Pembayaran" : "Confirm Payment"}</a>
                    <br/>
                    <a className="btn btn-outline-primary mt-2" href={"/invoice/course/" + this.state.invoice_id}>{GetText() ? "Lihat Invoice" : "View Invoice"}</a>
                  </div>
                </div>
              </div>
            </Container>
          ) : ( 
            <Container>
              <div className="row py-5" id="form">
                <div className="col-md-7 text-center">
                  <h2><strong>Checkout</strong></h2>
                  <div className="py-5">
                    <img className="w-25" src={this.state.image} alt={this.state.title}/>
                    <h4 className="px-5 mt-4"><strong>{this.state.title}</strong></h4>
                    <h4 className="px-5 mt-4 text-primary"><strong>{GetText() ? "Rp " : "$"} {new Intl.NumberFormat().format(this.state.amount)}</strong></h4>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="bg-dark p-4">
                    <Form onSubmit={this.checkout}>
                      <h4 className="text-white"><strong>{GetText() ? "Informasi Pembayaran" : "Payment Information"}</strong></h4>
                      <div className="row">
                        <div className="col-md-12">
                          <Form.Group className="mt-4">
                            <Form.Label className="text-white">{GetText() ? "Metode Pembayaran" : "Payment Method"}</Form.Label>
                            {this.state.form_error.some(item => item === 'payment_method') ? <span className="error-message">{GetText() ? "Wajib diisi!" : "Required!"}</span> : ''}
                            <Form.Control as="select" onChange={(e) => this.setMethod(e)}>
                              <option></option>
                              {GetText() ? <option>Bank Transfer</option> : ''}
                              {GetText() ? '' : <option>Credit Card</option>}
                            </Form.Control>
                          </Form.Group>
                        </div>
                        <div className="col-md-12">
                          <Form.Group className="mt-2 mb-5">
                            <Form.Label className="text-white">{GetText() ? "Pilih Bank" : "Select Bank"}</Form.Label>
                            {this.state.form_error.some(item => item === 'payment_bank') ? <span className="error-message">{GetText() ? "Wajib diisi!" : "Required!"}</span> : ''}
                            <Form.Control as="select" onChange={(e) => this.setBank(e)}>
                              <option></option>
                              <option>BCA</option>
                              <option>Mandiri</option>
                              <option>BNI</option>
                              <option>BRI</option>
                            </Form.Control>
                          </Form.Group>
                        </div>
                        <div className="col-6">
                          <p className="text-white">Grand Total</p>
                        </div>
                        <div className="col-6 text-right">
                          <p className="text-white">{GetText() ? "Rp " : "$"} {new Intl.NumberFormat().format(this.state.amount)}</p>
                        </div>
                        <div className="col-md-12">
                          <p className="mt-5 text-white">{GetText() ? "Dengan membuat pesanan ini, Anda setuju dengan " : "By creating this order, you have read and agree to our"} <a className="text-white" href="/privacy-policy"><strong>{GetText() ? "Syarat dan Ketentuan " : "Terms of Servicce"} </strong></a>.</p>
                          <Button className="w-100" variant="primary" size="lg" type="submit" id="submit">
                          {GetText() ? "Buat Pesanan" : "Create Order"}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </Container>
          )
        }
      </div>
    );
  }
}