import React, { Component } from "react";
import Moment from 'moment';
import Axios from 'axios';
import JsPdf from "jspdf";
import Html2Canvas from "html2canvas";
import Parser from "html-react-parser";
import ReactPlayer from "react-player";
import { GetText, ReplaceText, ShuffleArray } from '../helper';
import { Container, Tab, Nav, Modal, Form, Button } from "react-bootstrap";
import { FaRegFolder, FaCheck, FaArrowRight } from 'react-icons/fa';
import '../config/global';
import ImgPlayButton from '../img/play-button.png';
import ImgPlayActive from '../img/play-active.png';
import Certificate from "../Certificate";

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[9];
const authKey2 = global.config.authBearerKey+"##"+global.config.kindcode[13];
const authKey3 = global.config.authBearerKey+"##"+global.config.kindcode[34];
const authKey4 = global.config.authBearerKey+"##"+global.config.kindcode[31];
const authKey5 = global.config.authBearerKey+"##"+global.config.kindcode[39];

function getUserOrder(libraryId,lenLesson,lenExam) {
	return new Promise((resolve) => {
		fetch(global.config.urlLink+"/traininguserview/" + localStorage.getItem('doc_id'), {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'Authorization': authKey2
			}
		})
		.then(response => {
			if(response.status === 200) {
				return response.json()
			} else {
				console.log('Something happened wrong');
			}
		})
		.then(resultJson => {
			// var fullName = resultJson.values[0].content[0].maincontent.memberName;
			var member_info = resultJson.values[0].content[0].maincontent;
			var content = resultJson.values[0].scontent3;
			var certificate = "";
			var last = false;
			if (resultJson.values[0].scontent4.length > 0) {
				certificate = resultJson.values[0].scontent4[0].subcontent.fileUrl;
			}
			if (content.length > 0) {
				var inum=0;
				content.forEach((enroll) => {
					var subcontent = enroll.subcontent;
					inum++;
					if (subcontent.libraryId === libraryId) {
						last = true;
						var goToTab = 0;
						if (subcontent.lastLesson >= (lenLesson + lenExam)) {
							goToTab = subcontent.lastLesson;
						}	else {
							goToTab = subcontent.lastLesson + 1;
						}
						var enrollSend = {};
						enrollSend.memberInfo = member_info;
						enrollSend.enrollcontent = subcontent;
						enrollSend.enrollcontent.subdocid = enroll.subdocid;
						enrollSend.goToTab = goToTab;
						enrollSend.last = last;
						enrollSend.certificate = certificate;
						resolve(enrollSend);
						
					} else {
						if(inum === content.length) {
							var enrollSend2 = {};
							enrollSend2.memberInfo = member_info;
							enrollSend2.enrollcontent = {};
							enrollSend2.enrollcontent.subdocid = "";
							enrollSend2.goToTab = 1;
							enrollSend2.last = last;
							enrollSend2.certificate = certificate;
							resolve(enrollSend2);
						}
					}
				});
			} else {
				var enrollSend3 = {};
				enrollSend3.memberInfo = member_info;
				enrollSend3.enrollcontent = {};
				enrollSend3.enrollcontent.subdocid = "";
				enrollSend3.goToTab = 1;
				enrollSend3.last = last;
				enrollSend3.certificate = certificate;
				resolve(enrollSend3);
			}
		});

	});
}

/*
async function generateBlob(filevid) {
	return new Promise((resolve) => {
		document.getElementById('loading-area').style.display = 'block'; 
		fetch(filevid, {
			method: 'GET',
			headers: {
				  'Access-Control-Allow-Origin': '*'
			}
		})
		.then(resb => resb.blob())
		.then(resb2 => { 
			var bloburl = URL.createObjectURL(resb2);
			document.getElementById('loading-area').style.display = 'none'; 
			resolve(bloburl);
		}).catch(err => {
			document.getElementById('loading-area').style.display = 'none'; 
			resolve("");
		})
	})
}
*/
async function getLessonsAttach(attachments,fg) {
	var subcontents2 = [];
	if (attachments.length > 0) {
		subcontents2 = await Promise.all(
			attachments.map(async (attachment) => {
				// if(fg==="getblob") {
				// 	if(attachment.subsubcontent.storageUrl !== undefined && attachment.subsubcontent.storageUrl !== "") {
				// 		var response = await generateBlob(attachment.subsubcontent.storageUrl);
				// 		attachment.subsubcontent.urlblob = response;
				// 		return attachment.subsubcontent;	
				// 	} else {
				// 		return attachment.subsubcontent;
				// 	}
				// } else {
					return attachment.subsubcontent;
				// }
			})
		)
	} 
	return subcontents2;
}
async function getLessons(lessons) {
	return new Promise((resolve) => {
		var isIntroduction = 0;
		var arrContents = [];
		if (lessons.length > 0) {
			var i=0;
			lessons.forEach(async lesson => {
				var subcontent = lesson.subcontent;
				subcontent.id = lesson.subdocid;
				subcontent.attachment = await getLessonsAttach(lesson.sub2content,"");
				if (subcontent.lessonNumber === '0') {
					isIntroduction = 1;
				}
				arrContents.push(subcontent);
				i++;
				if(i===lessons.length) {
					var lessonSend = {};
					lessonSend.lessoncontent = arrContents;
					lessonSend.isIntroduction = isIntroduction;
					resolve(lessonSend);
				}
			});                
		} else {
			var lessonSend = {};
			lessonSend.lessoncontent = arrContents;
			lessonSend.isIntroduction = isIntroduction;
			resolve(lessonSend);
		}
	});
}
/*
function getLessons(lessons) {
	return new Promise((resolve) => {
		var isIntroduction = 0;
		var arrContents = [];
		if (lessons.length > 0) {
			var i=0;
			lessons.forEach(lesson => {
				var subcontent = lesson.subcontent;
				subcontent.id = lesson.subdocid;
				subcontent.attachment = [];

				var attachments = lesson.sub2content;
				if (attachments.length > 0) {
					var subcontents2 = [];
					var j=0;
					attachments.forEach(attachment => {
						subcontents2.push(attachment.subsubcontent);
						j++;
						if(j === attachments.length) {
							subcontent.attachment = subcontents2;
						}
					});
				}
				if (subcontent.lessonNumber === '0') {
					isIntroduction = 1;
				}
				arrContents.push(subcontent);
				i++;
				if(i===lessons.length) {
					var lessonSend = {};
					lessonSend.lessoncontent = arrContents;
					lessonSend.isIntroduction = isIntroduction;
					resolve(lessonSend);
				}
			});                
		} else {
			var lessonSend = {};
			lessonSend.lessoncontent = arrContents;
			lessonSend.isIntroduction = isIntroduction;
			resolve(lessonSend);
		}
	});
}
*/
function getExam(exams) {
	return new Promise((resolve) => {
		var examList = exams.filter((sel) =>  {
			return sel.subcontent.examStatus > 0
		  });
		var contents = [];
		if (examList.length > 0) {
			var a=0;
			examList.forEach(exam => {
			  	if (exam.subcontent.examStatus > 0) {
					var content = exam.subcontent;
					content.id = exam.subdocid;
					content.question = [];
					
					if (exam.sub2content.length > 0) {
						var questions = ShuffleArray(exam.sub2content);
						var contents2 = [];
						var b=0;
						questions.forEach(question => {
							if (b < content.numberQuestion) {
								contents2.push(question.subsubcontent);
							}
							b++;
							if(b === questions.length) { content.question = contents2; }
						});
						
					}
					contents.push(content);
			  	}
				a++;
				if(a===examList.length) {
					resolve(contents);
				}
			  
			});                
		} else { resolve(contents); }
	});
}
function getFeedback(feedbacks) {
	return new Promise((resolve) => {
		var sub3contents = [];
		if (feedbacks.length > 0) {
			var c=0;
			feedbacks.forEach(feedback => {
			  	// if (exam.subcontent.examStatus > 0) {
					// sub3content = [];
					
					if (feedback.subcontent.memberId === localStorage.getItem('doc_id')) {
						var sub3content = feedback.subcontent;
						sub3content.id = feedback.subdocid;
						sub3contents.push(sub3content);
					}
					
			  	// }
				c++;
				if(c===feedbacks.length) { 
					if(sub3contents.length > 0) {
						resolve(sub3contents[sub3contents.length-1]);
					} else {
						resolve(sub3contents);
					}
				}
			  
			});                
		} else { resolve(sub3contents); }
	});
}
function getFeedbackList() {
	return new Promise((resolve) => {
		var dataText ='{"keyword":"","pageShow":"10"}';
    	var dataSend = JSON.parse(dataText);
		fetch(global.config.urlLink+"/feedbacklist/", {
		  method: 'POST',
		  headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'Authorization': authKey5
		  }, 
      	  body : JSON.stringify(dataSend)
		}).then(response => {
			if (response.status === 200) {
				return response.json();
			} else {
				console.log('Something happened wrong');
			}
		}).then(resultJson => {
			var resultData = [];
			if(resultJson.values.length > 0) {
				for (var i=0; i<resultJson.values.length; i++) {
					var content = resultJson.values[i].content;
					content.id = resultJson.values[i].docid;
					resultData.push(content);
					if(i===(resultJson.values.length-1)) {
						resolve(resultData);
					}
				}
			} else { resolve(resultData); }
      	});
	});
}
async function getAllDatas(id,lessons,exams,feedbacks) {
	
	  var userorderresult = getUserOrder(id,lessons.length,exams.length);
	  var lessonresult = getLessons(lessons);
	  var examresult = getExam(exams);
	  var feedbackresult = getFeedback(feedbacks);
	  var feedbacklistresult = getFeedbackList();
	  const [thing1,thing2,thing3,thing4,thing5] = await Promise.all([userorderresult,lessonresult,examresult,feedbackresult,feedbacklistresult]);
	  var arrReturnValue = {};
	  arrReturnValue.enrolldata = thing1;
	  arrReturnValue.lessondata = thing2;
	  arrReturnValue.examdata = thing3;
	  arrReturnValue.feedbackdata = thing4;
	  arrReturnValue.feedbacklistdata = thing5;
	  
	  return arrReturnValue;
}


export default class LibraryEnroll extends Component {
	constructor(props) {
		super(props);
		this.state = {
			member_id: localStorage.getItem('doc_id'),
			member_name: "",
			member_company: "",
			certificate_bg: "",
			id: "",
			title: "",
			category: "",
			excerpt: "",
			description: "",
			image: "",
			video: "",
			lesson: [],
			exam: [],
			feedback: [],
			feedbackList: [],
			libraryList: [],
			enroll_id: '',
			last_visit: Moment().format('D MMMM Y'),
			last_lesson: 0,
			completion: 0,
			open_exam: 0,
			open_feedback: false,
			open_pdf: false,
			url_pdf: 0,
			correct: 0,
			incorrect: 0,
			score: 0,
			status: '',
			certificate: "",
			tabKey: '',
			is_introduction: 0,
			random: 0,
			form_error: []
		}
		this.addEnroll = this.addEnroll.bind(this);
		this.editEnroll = this.editEnroll.bind(this);
		this.openLesson = this.openLesson.bind(this);
		this.openExam = this.openExam.bind(this);
		this.openFeedback = this.openFeedback.bind(this);
		this.closeFeedback = this.closeFeedback.bind(this);
		this.addFeedback = this.addFeedback.bind(this);
		this.sendFeedback = this.sendFeedback.bind(this);
		this.getResult = this.getResult.bind(this);
		this.openVideo = this.openVideo.bind(this);
		this.openAttachment = this.openAttachment.bind(this);
		this.openPdf = this.openPdf.bind(this);
		this.closePdf = this.closePdf.bind(this);
		this.videoProgress = this.videoProgress.bind(this);
		this.nextDisabled = this.nextDisabled.bind(this);
		this.addCertificate = this.addCertificate.bind(this);
		this.uploadCertificate = this.uploadCertificate.bind(this);
		// this.getCertificate = this.getCertificate.bind(this);
		// this.resetRandom = this.resetRandom.bind(this);
		this.scrollToTop = React.createRef();
		this.scrollToRef = React.createRef();
	}
	
	componentDidMount() {
		var url = window.location.href;
		var id = url.substring(url.lastIndexOf('/') + 1);
		document.getElementById('loading-area').style.display = 'block'; 
		
		fetch(global.config.urlLink+"/libraryview/" + id, {
		  method: 'GET',
		  headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*',
			'Authorization': authKey
		  }
		}).then(response => {
			if (response.status === 200) {
				return response.json();
			} else {
				console.log('Something happened wrong');
			}
		}).then(resultJson => {
			var content = resultJson.values[0].content[0].maincontent;
			var lessons = resultJson.values[0].scontent;
			var feedbacks = resultJson.values[0].scontent3;
			var exams = resultJson.values[0].scontent2;
			// var sendexams = [];
			// if(exams.length > 0) { 
			// 	if(exams[0].subcontent.examStatus>0) { sendexams = exams; } 
			// }
			getAllDatas(id,lessons,exams,feedbacks)
			.then((response) => {
				document.getElementById('loading-area').style.display = 'none'; 
				var enrollResult = response.enrolldata;
				var lessonResult = response.lessondata;
				var examResult = response.examdata;
				var feedbackResult = response.feedbackdata;
				var feedbackListResult = response.feedbacklistdata;
				var enrollIdz="", lastLessonz=0, completionz=0,scorez=0,statuz="",certificatez="",certificatez_bg="",fullNamez="",companyNamez="";
				var lastVisitz= Moment().format('D MMMM Y');
				if(enrollResult.enrollcontent.subdocid !== "") {
					enrollIdz = enrollResult.enrollcontent.subdocid;
					lastVisitz = Moment.unix(enrollResult.enrollcontent.lastVisit._seconds).format('D MMMM Y');
					lastLessonz = enrollResult.enrollcontent.lastLesson;
					completionz = enrollResult.enrollcontent.completion;
					scorez = enrollResult.enrollcontent.examScore? enrollResult.enrollcontent.examScore : 0;
					statuz = enrollResult.enrollcontent.examStatus ? enrollResult.enrollcontent.examStatus : "";
					certificatez = enrollResult.certificate;
					fullNamez = enrollResult.memberInfo.memberName;
					if(enrollResult.memberInfo.memberCompany !== undefined) { companyNamez = enrollResult.memberInfo.memberCompany; }
					if(enrollResult.memberInfo.certificateBg !== undefined) { certificatez_bg = enrollResult.memberInfo.certificateBg; }
					if(companyNamez === "aegis.com.bn" || companyNamez === "company.com") { certificatez_bg = process.env.PUBLIC_URL + "/img/bg-certificate-aegis2.png"; }
				}
				var videoList = [];
				if(lessonResult.lessoncontent.length) {
					lessonResult.lessoncontent.sort((a, b) => parseInt(a.lessonNumber) - parseInt(b.lessonNumber));
					for(var i = 0; i < lessonResult.lessoncontent.length; i++) {
						var videoItem = [];
						if(lessonResult.lessoncontent[i].attachment.length) {
							for(var j = 0; j < lessonResult.lessoncontent[i].attachment.length; j++) {
								if(lessonResult.lessoncontent[i].attachment[j].fileType === 'video/mp4') {
									videoItem.push({
										title : lessonResult.lessoncontent[i].attachment[j].fileTitle,
										played : 0,
										done: false
									})
								}
							}
						}
						videoList.push(videoItem);
					}
				}	
				this.setState({
					id: resultJson.values[0].docid,
					title: content.libraryTitle,
					category: content.categoryName,
					excerpt: ReplaceText(content.libraryShortDesc),
					description: ReplaceText(content.libraryLongDesc),
					image: content.libraryPicture,
					video: content.libraryVideo,

					member_name: fullNamez,
					member_company: companyNamez,
					enroll_id: enrollIdz,
					last_visit: lastVisitz,
					last_lesson: lastLessonz,
					completion: completionz,
					score: scorez,
					status: statuz,
					tabKey: 'lesson-' + enrollResult.goToTab,
					certificate: certificatez,
					certificate_bg: certificatez_bg,
					lesson: lessonResult.lessoncontent,
					is_introduction: lessonResult.isIntroduction,
					exam: examResult,
					feedback: feedbackResult,
					feedbackList: feedbackListResult,
					videoList: videoList
				});
				if (!enrollResult.last) {
					this.addEnroll();
				} else {
					this.editEnroll();
				}
			});
		});
	}

	addEnroll() {
		var dataSend = {
			trmember_id: this.state.member_id,
			library_id: this.state.id,
			library_title: this.state.title,
			last_lesson: this.state.last_lesson,
			completion: this.state.completion,
			exam_score: 0,
			exam_status: ""
		};
		
		fetch(global.config.urlLink+"/trainingaddenroll", {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'Authorization': authKey3
			}, 
			body : JSON.stringify(dataSend)
		})
		.then(response => {
			if(response.status === 200) {
				return response.json();
			} else {
				console.log('Something happened wrong.');
			}
		})
		.then(resultJson => {
			this.setState({
				enroll_id: resultJson.values
			});
		});
	}

	editEnroll() {
		var dataSend = {
			trmember_id: this.state.member_id,
			enrolldoc_id: this.state.enroll_id,
			last_lesson: this.state.last_lesson,
			completion: this.state.completion,
			exam_score: this.state.score,
			exam_status: this.state.status
		};

		fetch(global.config.urlLink+"/trainingeditenroll", {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'Authorization': authKey3
			}, 
			body : JSON.stringify(dataSend)
		})
		.then(response => {
			if(response.status === 200) {
				return response.json();
			} else {
				console.log('Something happened wrong.');
			}
		})
		.then(resultJson => {
			document.getElementById('loading-area').style.display = 'none'; 
			this.setState({
				enroll_id: resultJson.values
			});
		});
	}

	openLesson(number) {
		this.scrollToRef.current.scrollIntoView();
		var count = this.state.lesson.length + this.state.exam.length;
		var percent = (number / count) * 100;
		this.setState({
			last_lesson: number,
			completion: Number(percent.toFixed(0))
		}, () => {
			this.editEnroll();

			if (number < count) {
				document.getElementById('button-' + (number + 1)).click();
			} else if (number !== count) {
				document.getElementById('introduction').click();
			}
		});
	}

	openExam() {
		this.setState({
			open_exam: 1
		});
	}

	async getResult(event) {
		event.preventDefault();
		document.getElementById('loading-area').style.display = 'block'; 
		var answers = [];
		var formData = new FormData(event.currentTarget);
		for (let [key, value] of formData.entries()) {
			if (key in answers) {
				answers[key] += ", " + value;
			} else {
				answers[key] = value;
			}
		}

		var correct = 0;
		var incorrect = 0;
		var score = 0;
		var total = 0;
		var status = '';

		this.state.exam.forEach((exam, i) => {
			exam.question.forEach((question, j) => {
				total++;
				if (answers['question-' + (j + 1)] === question.questionAnswer) {
					correct++;
				} else {
					incorrect++;
				}
			});
			score = Math.ceil(correct / total * 100);
			status = score >= exam.passingScore ? 'Passed' : 'Failed';
		});

		var count = this.state.lesson.length + this.state.exam.length;
		var percent = (this.state.lesson.length / count) * 100;
		var last_lesson = this.state.lesson.length;
		var certificate = "";
		if (status === 'Passed') {
			percent = 100;
			last_lesson = count;
			certificate = await this.uploadCertificate();
			this.setState({
				open_exam: 0,
				completion: Number(percent.toFixed(0)),
				last_lesson: last_lesson,
				correct: correct,
				incorrect: incorrect,
				score: score,
				status: status,
				certificate: certificate
			}, () => {
				this.addCertificate();
				this.editEnroll();
			});
		} else {
			this.setState({
				open_exam: 0,
				correct: correct,
				incorrect: incorrect,
				score: score,
				status: status
			});
		}
		// var percent = status === 'Passed' ? 100 : (this.state.lesson.length / count) * 100;
		// this.setState({
		// 	last_lesson: status === 'Passed' ? count : this.state.lesson.length,
		// 	completion: Number(percent.toFixed(0)),
		// 	open_exam: 0,
		// 	correct: correct,
		// 	incorrect: incorrect,
		// 	score: score,
		// 	status: status,
		// }, () => {
		// 	if (status === 'Passed') {
		// 		this.editEnroll();
		// 	}
		// });
		document.getElementById('loading-area').style.display = 'none'; 
		document.getElementById('result-table').style.display = 'table';
	}

	addFeedback() {
		this.setState({
			open_feedback: 0
		});
	}

	openFeedback() {
		this.setState({
			open_feedback: 1
		});
	}

	closeFeedback() {
		this.setState({
			open_feedback: 0
		});
	}

	sendFeedback(event) {
		event.preventDefault();
		var formData = new FormData(event.currentTarget);
		var answers = [];
		this.state.feedbackList.forEach((feedback, i) => {
			for (let [key, value] of formData.entries()) {
				if (key === 'feedback-' + feedback.feedbackNumber) {
					feedback.feedbackInput = key;
					feedback.feedbackAnswer = value;
				}
			}
			answers.push(feedback);
		});
		var dataText = '{"member_docid":"' + localStorage.getItem("doc_id") + '","member_name":"' + localStorage.getItem("name") + '","member_email":"' + localStorage.getItem("email") + '","library_docid":"' + this.state.id + '","form_id":"form-feedback"}';
		var dataSend = JSON.parse(dataText);
		fetch(global.config.urlLink+ "/libraryfeedbackadd", {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'Authorization': authKey5
			}, 
			body : JSON.stringify(dataSend)
		})
		.then(response => {
			if(response.status === 200) {
				return response.json();
			} else {
				console.log('Something happened wrong.');
			}
		}).then(resultJson => {
			var counter = 0;
			var feedback_id = resultJson.values;
			answers.forEach(answer => {
				var subdataText = '{"library_docid":"' + this.state.id + '","library_subdocid":"' + feedback_id + '","feedback_docid":"' + answer.id + '","feedback_number":"' + answer.feedbackNumber + '","feedback_text":"' + answer.feedbackText + '","feedback_type":"' + answer.feedbackType + '","feedback_answer":"' + answer.feedbackAnswer + '","form_id":"form-feedback-data"}';
				var subdataSend = JSON.parse(subdataText);
				fetch(global.config.urlLink+ "/libraryfeedbackdataadd", {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'Access-Control-Allow-Origin': '*',
						'Authorization': authKey5
					}, 
					body : JSON.stringify(subdataSend)
				})
				.then(subresponse => {
					if(subresponse.status === 200) {
						return subresponse.json();
					} else {
						console.log('Something happened wrong.');
					}
				}).then(subresultJson => {
					if (subresultJson.values) {
						counter++;
						if (counter === answers.length) {
							window.location.reload();
						}
					}
				});			
			});
		});
	}

	addCertificate() {
		var dataSend = {
			trmember_id: this.state.member_id,
			course_id: this.state.id,
			course_title: this.state.title,
			course_type: 'library',
			file_url: this.state.certificate,
			form_id: 'certificate',
			score: this.state.score
		};
		fetch(global.config.urlLink+"/trainingaddcertificate", {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'Authorization': authKey3
			}, 
			body : JSON.stringify(dataSend)
		})
		.then(response => {
			if(response.status === 200) {
				return response.json();
			} else {
				console.log('Something happened wrong.');
			}
		});
	}

	uploadCertificate() {
		return new Promise((resolve, reject) => {
			var certificate = document.getElementById('certificate');
			certificate.style.display = "block";
			Html2Canvas(certificate, {logging: true, letterRendering: true, useCORS: true}).then(canvas => {
				const data = canvas.toDataURL('img/png');
				certificate.style.display = "none";
				const pdf = new JsPdf({
					orientation: "landscape",
					unit: "px",
					hotfixes: "px_scaling",
					format: [900, 506]
				});
				pdf.addImage(data, 'PNG', 0, 0, 900, 506);	
				var blob = pdf.output('blob');
				var name = this.state.member_id + this.state.id + '.pdf';
				const form = new FormData();
				form.append('file', blob, name);
				form.append('source', 'certificate');
				Axios.post(global.config.urlLink+ "/uploadcertificate",
					form, { 
						headers: { 
							'content-type': 'multipart/form-data',
							'Authorization': authKey4
						}
					}
				)
				.then(result => {
					if (result.data.values !== undefined) {
						resolve(result.data.values);
					} else {
						reject('Something happened wrong.');
					}
				});
			});
		});
	}

	videoProgress(module_id, title, params) {
		var played = params.played;
		var videoList = this.state.videoList;
		var totalDone = 0;
		for (var i = 0; i < videoList[module_id - 1].length; i++) {
			if (videoList[module_id - 1][i].title === title) {
				videoList[module_id - 1][i].played = played;
				if (played >= .75) {
					videoList[module_id - 1][i].done = true;
				}
			}
			if (videoList[module_id - 1][i].done) {
				totalDone++;
			}
		}

		this.setState({
			videoList: videoList
		});
		
		if ((document.getElementById('btn-next-' + module_id) !== null) && (videoList[module_id - 1].length === totalDone)) {
			document.getElementById('btn-next-' + module_id).classList.remove('disabled');
		}
	}

	nextDisabled(module_id) {
		if (document.getElementById('btn-next-' + module_id) !== null) {
			document.getElementById('btn-next-' + module_id).classList.add('disabled');
		}
	}

	openPdf(url) {
		this.setState({
			open_pdf: true,
			url_pdf: url
		});
	}

	closePdf() {
		this.setState({
			open_pdf: false
		});
	}

	renderPdf(attachment) {
		// var name = attachment.storageUrl.split('/').pop().replace('.pdf', '');		
		fetch(global.config.urlLink + "/getstreaming/reading/" + attachment.storageUrl.split('/').pop().replace('.pdf', ''), {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'Authorization': authKey
			}
		})
		.then(response => {
			if(response.status === 200) {
				return response.json();
			} else {
				console.log('Something happened wrong.');
			}
		}).then(json => {	
			return json.values;
		});
	}
	
	openVideo(e) {
		var thumbnail = e.target;
		var video = e.target.previousElementSibling;
		thumbnail.style.display = 'none';
		video.style.display = 'block';
		video.firstChild.play();
	}

	async openAttachment(e,attch,i,j) {
		e.preventDefault();
		var thumbnail = e.target;
		thumbnail.style.display = 'none';
		var video = e.target.previousElementSibling;
		video.style.display = 'block';
		
		// var divName = "div_video_content-"+i+"-"+j;
		// var divContentVid = '<iframe class="embed-content" src="'+attch.fileUrl+'" key="'+this.state.random+'" frameBorder="0" title="'+attch.fileName+'" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen></iframe>';
		// if((this.state.member_company === "aegis.com.bn" || this.state.member_company === "company.com")) {
		// 	if(attch.storageUrl !== undefined && attch.storageUrl !== "") {
		// 		// divContentVid = '<video src="'+attch.storageUrl+'" class="embed-content" autoplay controls="true" controlslist="nodownload" onContextMenu="func(e){ e.preventDefault()}" allowFullScreen></video>';
		// 		var urlBlob = await generateBlob(attch.storageUrl);
		// 		if(urlBlob !== undefined && urlBlob !== "") {
		// 			divContentVid = '<video src="'+urlBlob+'" class="embed-content" autoplay controls="true" controlslist="nodownload" onContextMenu="func(e){ e.preventDefault()}" allowFullScreen></video>';
		// 		}
		// 	}
		// } 
		// document.getElementById(divName).innerHTML = divContentVid;
		// document.getElementById(divName).style.display = 'block';
		
	}
	
	// getCertificate() {
	// 	const certificate = document.getElementById('certificate');
	// 	certificate.style.display = "block";
	// 	Html2Canvas(certificate, {logging: true, letterRendering: 1, useCORS: true}).then(canvas => {
	// 		const data = canvas.toDataURL('img/png');
	// 		certificate.style.display = "none";
	// 		const pdf = new JsPdf({
	// 			orientation: "landscape",
	// 			unit: "px",
	// 			hotfixes: "px_scaling",
	// 			format: [900, 506]
	// 		});
	// 		pdf.addImage(data, 'PNG', 0, 0, 900, 506);
	// 		pdf.save('Certificate.pdf');
	// 	})
	// }

	// resetRandom() {
	// 	var random = Math.random(); 
	// 	this.setState({
	// 		random: random
	// 	});
	// }

	render() {
		// var day = Moment().format('D');
		// var month = Moment().format('MMMM');
		// var year = Moment().format('Y');
		// var date_nw = day + " " + month + " " + year;
		return (
			<div className="library-enroll">
				<div className="bg-dark" style={{marginTop:"-48px"}}>
					<Container>
						<div className="row py-5">
							<div className="col-md-4 text-center">
								<img className="mxw mb-4 br-8" src={this.state.image} alt={this.state.title}/>
							</div>
							<div className="col-md-8">
								<h3 className="text-white mb-4">{this.state.title}</h3>
								<p className="text-white">
									<span className="mr-4">
										<FaRegFolder className="mr-1"/>
										{this.state.category}
									</span>
								</p>
								<p className="text-white mb-5">{this.state.excerpt}</p>
								<ul className="list-group list-group-horizontal library-usage">
									<li className="list-group-item col">
										<h5 className="text-center">{GetText() ? "Terakhir Dilihat" : "Last Visited"}</h5>
										<p className="text-center">{this.state.last_visit}</p>
									</li>
									<li className="list-group-item col">
										<h5 className="text-center">{GetText() ? "Modul Terakhir" : "Last Module"}</h5>
										<p className="text-center">
											{
												this.state.last_lesson > this.state.lesson.length ? (
													GetText() ? "Ujian" : "Exam"
												) : (
													this.state.last_lesson > 0 ? (
														this.state.is_introduction === 1 ? (
															this.state.last_lesson === 1 ? (
																GetText() ? "Pengantar" : "Introduction"
															) : (
																(GetText() ? "Modul " : "Module ") + (this.state.last_lesson - 1)
															)
														) : (
															(GetText() ? "Modul " : "Module ") + this.state.last_lesson
														)
													) : (
														"-"
													)
												)
											}
										</p>
									</li>
									<li className="list-group-item col">
										<h5 className="text-center">{GetText() ? "Pencapaian" : "Progress"}</h5>
										<p className="text-center">{this.state.completion + "%"}</p>
									</li>
								</ul>
							</div>
						</div>
					</Container>
				</div>
				<div className="bg-light">
					<Container>
						{
							this.state.video ? (
								<div className="row py-5 align-items-center">
									<div className="col-md-6">
										<h3 className="mb-4">{GetText() ? 'Deskripsi' : 'Description'}</h3>
										<p>{Parser(this.state.description)}</p>
									</div>
									<div className="col-md-6">
										<div className="embed-wrapper">
											<div className="embed-container" /*style={{display: 'none'}}*/>
												<ReactPlayer
													className="embed-content"
													url={global.config.urlLink+ "/getstreaming/video/" + this.state.video.split('/').pop().replace('.mp4', '')}
													light={this.state.image}
													onContextMenu={(e)=> e.preventDefault()}
													width='100%'
													height='100%'
													controls
													playing
													config={{ 
														file: { 
															attributes: {
																controlsList: 'nodownload'
															} 
														} 
													}}
												/>
												{/* <video className="embed-content" name="video_intro" autoPlay={false} controls={true} controlsList="nodownload" onContextMenu={(e)=> e.preventDefault()} allowFullScreen>
													<source src={this.state.video} type="video/mp4"></source>
												</video> */}
											</div>
											{/* <div className="embed-thumbnail" onClick={(e) => this.openVideo(e)}>
												<img className="image-thumbnail half" src={this.state.image} alt="Video Thumbnail"/>
												<img className="play-button" src={ImgPlayButton} alt="Play Button"/>
												<img className="play-active" src={ImgPlayActive} alt="Play Button"/>
											</div> */}
										</div>
									</div>
								</div>
							) : (
								<div className="py-5">
									<h3 className="mb-4">{GetText() ? 'Deskripsi' : 'Description'}</h3>
									<p>{Parser(this.state.description)}</p>
								</div>
							)
						}
					</Container>
				</div>
				{
					this.state.tabKey ? (
						<Container ref={this.scrollToRef}>
							<div className="row py-5">
								<Tab.Container defaultActiveKey={this.state.tabKey}>
									<div className="col-md-3 p-4">
										<div className="sidebar-tab sticky-top">
											<h3 className="mb-4">{GetText() ? "Daftar Modul" : "Module List"}</h3>
											<Nav variant="pills" className="flex-column">
												{
													this.state.lesson.length > 0 ? (
														this.state.lesson
														.sort((a, b) => a.lessonNumber - b.lessonNumber)
														.map((lesson, i) => {
															return (
																<Nav.Item key={"option-" + (i + 1)}>
																	<Nav.Link eventKey={"lesson-" + (i + 1)} id={"button-" + (i + 1)} /*onClick={this.resetRandom}*/>
																		{
																			lesson.lessonNumber === '0' ? (
																				GetText() ? "Pengantar" : "Introduction"
																			) : (
																				GetText() ? "Modul " + lesson.lessonNumber : "Module " + lesson.lessonNumber
																			)
																		}
																		{
																			this.state.last_lesson >= (i + 1) ? (
																				<FaCheck className="sidebar-checked"/>
																			) : ''
																		}
																	</Nav.Link>
																</Nav.Item>
															);
														})
													) : ''
												}
												{
													this.state.exam.length > 0 ? (
														this.state.exam
														.sort((a, b) => a.examNumber - b.examNumber)
														.map((exam, i) => {
															return (
																<Nav.Item key={"option-" + (this.state.lesson.length + 1)}>
																	<Nav.Link eventKey={"lesson-" + (this.state.lesson.length + 1)} id={"button-" + (this.state.lesson.length + 1)}>
																		{GetText() ? "Ujian" : "Exam"}
																		{
																			this.state.status === "Passed" ? (
																				<FaCheck className="sidebar-checked"/>
																			) : ''
																		}
																	</Nav.Link>
																</Nav.Item>
															);
														})
													) : ''
												}
											</Nav>
										</div>
									</div>
									<div className="col-md-9 bg-light p-4 br-8">
										<Tab.Content>
											{
												this.state.lesson.length > 0 ? (
													this.state.lesson
													.sort((a, b) => a.lessonNumber - b.lessonNumber)
													.map((lesson, i) => {
														return (
															<Tab.Pane eventKey={"lesson-" + (i + 1)} key={"lesson-" + (i + 1)}>
																<h3 className="mb-4">
																	{
																		lesson.lessonNumber === '0' ?
																		(
																			GetText() ? "Pengantar" : "Introduction"
																		) : (
																			GetText() ? "Modul " + lesson.lessonNumber : "Module " + lesson.lessonNumber
																		)
																	}
																</h3>
																<h5 className="mt-4">{lesson.lessonTitle}</h5>
																<div className="mb-3">{Parser(ReplaceText(lesson.lessonDesc))}</div>
																{
																	lesson.attachment.length > 0 ? (
																		lesson.attachment
																		.sort((a, b) => b.fileType - a.fileType)
																		.map((attachment, j) => {
																			return(
																			
																				<div key={"attachment" + j} >
																					{
																								(attachment.storageUrl !== undefined && attachment.storageUrl !== "" && this.state.member_company !== "aegis.com.bn")? 
																									attachment.fileType === 'video/mp4' ? (
																										<div className="embed-wrapper mb-4" key={"attachment-" + (i + 1) + "-" + j}>
																											<div className="embed-container">
																												<ReactPlayer
																													className="embed-content"
																													url={global.config.urlLink+ "/getstreaming/video/" + attachment.storageUrl.split('/').pop().replace('.mp4', '')}
																													light={attachment.fileThumbnail ? attachment.fileThumbnail : false}
																													onContextMenu={(e)=> e.preventDefault()}
																													width='100%'
																													height='100%'
																													controls
																													playing
																													config={{ 
																														file: { 
																															attributes: {
																																controlsList: 'nodownload'
																															} 
																														} 
																													}}
																													onProgress={(e) => this.videoProgress(i + 1, attachment.fileTitle, e)}
																													onReady={(e) => this.nextDisabled(i + 1, e)}
																												/>
																												{(attachment.fileTitle !== undefined && attachment.fileTitle !== "")? <p className="embed-title">{attachment.fileTitle}</p>: <></>}
																											</div>
																										</div>
																									) : (
																										<div className="embed-link mb-4" key={"attachment-" + (i + 1) + "-" + j} onClick={() => this.openPdf(global.config.urlLink + "/getstreaming/reading/" + attachment.storageUrl.split('/').pop().replace('.pdf', '') + "#toolbar=0")}>
																											<img src={process.env.PUBLIC_URL + '/img/file-pdf.png'} alt="Click to open"/>
																											<p>{attachment.fileTitle}</p>
																											<button className="btn btn-primary">Open File</button>
																										</div>
																									)
																								: ((attachment.fileUrl !== undefined && attachment.fileUrl !== "")?
																										attachment.fileType === 'video/mp4' ? (
																											<div className="embed-wrapper mb-4" key={"attachment-" + (i + 1) + "-" + j}>
																												<div className="embed-container" style={{display: attachment.fileThumbnail ? 'none' : 'block'}}  id={"div_video_content-"+(i+1)+"-"+j}>
																													{((this.state.member_company === "aegis.com.bn" || this.state.member_company === "company.com") && (attachment.storageUrl !== undefined && attachment.storageUrl !== ""))? 
																													<><video src={attachment.storageUrl} className="embed-content" name={"video_content"+(i+1)+"-"+j} autoPlay={false} controls={true} controlsList="nodownload" onContextMenu={(e)=> e.preventDefault()} allowFullScreen></video></>
																													: <><iframe className="embed-content" src={attachment.fileUrl} key={this.state.random} frameBorder="0" title={attachment.fileName} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen></iframe></>
																													} 
																												</div>
																												<div className="embed-thumbnail" onClick={(e) => this.openAttachment(e, attachment,(i+1),j)} style={{display: attachment.fileThumbnail ? 'block' : 'none'}}>
																													<img className="image-thumbnail" src={attachment.fileThumbnail} alt="Video Thumbnail"/>
																													<img className="play-button" src={ImgPlayButton} alt="Play Button"/>
																													<img className="play-active" src={ImgPlayActive} alt="Play Button"/>
																												</div>
																											</div>
																										) : (
																											<div className="embed-wrapper mb-4" key={"attachment-" + (i + 1) + "-" + j}>
																												<div className="embed-container">
																													<iframe className="embed-content" src={attachment.fileUrl} frameBorder="0" title={attachment.fileName} allowFullScreen></iframe>
																												</div>
																											</div>
																										)	
																									: <></>		
																								)
																							
																					}
																				</div>
																				
																				// <div key={"attachment" + j}>
																				// 	{
																				// 		attachment.fileType === 'video/mp4' ? (
																				// 			<div className="embed-wrapper mb-4" key={"attachment-" + (i + 1) + "-" + j}>
																				// 				<div className="embed-container" style={{display: attachment.fileThumbnail ? 'none' : 'block'}}  id={"div_video_content-"+(i+1)+"-"+j}>
																				// 					{((this.state.member_company === "aegis.com.bn" || this.state.member_company === "company.com") && (attachment.storageUrl !== undefined && attachment.storageUrl !== ""))? 
																				// 					  <><video src={attachment.storageUrl} className="embed-content" name={"video_content"+(i+1)+"-"+j} autoPlay={false} controls={true} controlsList="nodownload" onContextMenu={(e)=> e.preventDefault()} allowFullScreen></video></>
																				// 					: <><iframe className="embed-content" src={attachment.fileUrl} key={this.state.random} frameBorder="0" title={attachment.fileName} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen></iframe></>
																				// 					} 
																				// 				</div>
																				// 				<div className="embed-thumbnail" onClick={(e) => this.openAttachment(e, attachment,(i+1),j)} style={{display: attachment.fileThumbnail ? 'block' : 'none'}}>
																				// 					<img className="image-thumbnail" src={attachment.fileThumbnail} alt="Video Thumbnail"/>
																				// 					<img className="play-button" src={ImgPlayButton} alt="Play Button"/>
																				// 					<img className="play-active" src={ImgPlayActive} alt="Play Button"/>
																				// 				</div>
																				// 			</div>
																				// 		) : (
																				// 			<div className="embed-wrapper mb-4" key={"attachment-" + (i + 1) + "-" + j}>
																				// 				<div className="embed-container">
																				// 					<iframe className="embed-content" src={attachment.fileUrl} frameBorder="0" title={attachment.fileName} allowFullScreen></iframe>
																				// 				</div>
																				// 			</div>
																				// 		)
																				// 	}
																				// </div>
																				
																			)
																		})
																	) : ''
																}
																<div className="text-center">
																	{
																		this.state.last_lesson >= (i + 1) ? (
																			((this.state.last_lesson === (this.state.lesson.length + this.state.exam.length)) && (this.state.last_lesson === (i + 1))) ? (
																				<div>
																					<p className="text-success mb-2">{GetText() ? "Semua modul telah selesai" : "All modules completed"}</p>
																					{
																						this.state.feedback.length !== 0 ? '' : (
																							<Nav.Link className="btn btn-primary d-inline-block mt-2" onClick={() => this.openFeedback()}>{GetText() ? 'Isi form feedback' : 'Fill feedback form'}</Nav.Link>
																						)
																					}
																					<p className="mt-4">
																						<a href="/libraries" className="link-more-2">{GetText() ? "Lihat library lainnya" : "Browse other library"} <FaArrowRight/></a>
																					</p>
																					{/* <a className="btn btn-primary d-inline-block" href="/libraries">{GetText() ? 'Kembali ke daftar library' : 'Go to library list'}</a> */}
																				</div>
																			) : (
																				(this.state.lesson.length === (i + 1)) && (this.state.exam.length !== 0) ? (
																					<div>
																						<p className="text-success mb-2">{GetText() ? "Modul telah selesai" : "Module completed"}</p>
																						<Nav.Link className="btn btn-primary d-inline-block" onClick={() => document.getElementById('button-' + (i + 2)).click()}>{GetText() ? 'Lanjut ke ujian' : 'Continue to exam'}</Nav.Link>
																					</div>
																				) : (
																					<div>
																						<p className="text-success mb-2">{GetText() ? "Modul telah selesai" : "Module completed"}</p>
																						<Nav.Link className="btn btn-primary d-inline-block" onClick={() => document.getElementById('button-' + (i + 2)).click()}>{GetText() ? 'Lanjut ke modul berikutnya' : 'Continue to next module'}</Nav.Link>
																					</div>
																				)
																			)
																		) : (
																			// this.state.last_lesson === i ? (
																			// 	<Nav.Link className={"btn btn-primary d-inline-block mt-2 " + (this.state.videoList[i].length ? "disabled" : "")} onClick={() => this.openLesson(i + 1)} id={"btn-next-" + (i + 1)}>{GetText() ? "Tandai sebagai selesai" : "Complete this module"}</Nav.Link>
																			// ) : (
																			// 	<Nav.Link className={"btn btn-primary d-inline-block mt-2 disabled"}>{GetText() ? "Tandai sebagai selesai" : "Complete this module"}</Nav.Link>
																			// )
																			this.state.last_lesson < (i + 1) ? (
																				<Nav.Link className={"btn btn-primary d-inline-block mt-2" + (this.state.videoList[i].length ? "disabled" : "")} onClick={() => this.openLesson(i + 1)}>{GetText() ? "Tandai sebagai selesai" : "Complete this module"}</Nav.Link>
																			) : ''
																		)
																	}
																</div>
															</Tab.Pane>
														);
													})
												) : ''
											}
											<Tab.Pane eventKey={"lesson-" + (this.state.lesson.length + 1)}>
												<div className={"exam-opening " + (this.state.status !== "" ? "d-none" : "") + (this.state.open_exam === 1 ? "d-none" : "")}>
													{
														this.state.exam.length > 0 ? (
															this.state.exam
															.sort((a, b) => a.examNumber - b.examNumber)
															.map((exam, i) => {
																return (
																  exam.examStatus === 1 ? (
																	<div key={"exam-" + i}>
																		<h3 className="mb-4">{exam.examTitle}</h3>
																		<p className="mb-4">{exam.examDesc}</p>
																		<table className="mb-4">
																			<tbody>
																				<tr>
																					<td><strong>{GetText() ? "Jenis soal" : "Type of question"}</strong></td>
																					<td className="pl-2">{GetText() ? "Pilihan ganda" : "Multiple choice"}</td>
																				</tr>
																				<tr>
																					<td><strong>{GetText() ? "Jumlah soal" : "Number of question"}</strong></td>
																					<td className="pl-2">{exam.question.length}</td>
																				</tr>
																				<tr>
																					<td><strong>{GetText() ? "Skor minimal" : "Minimum score"}</strong></td>
																					<td className="pl-2">{exam.passingScore}</td>
																				</tr>
																			</tbody>
																		</table>
																		{
																			this.state.last_lesson === this.state.lesson.length ? (
																				<div className="btn btn-primary d-inline-block" onClick={() => this.openExam()}>{GetText() ? 'Lanjut ke soal' : 'Continue to questions'}</div>
																			) : (
																				this.state.status === 'Passed' ? (
																					<div>
																						<p className="text-success">{GetText() ? 'Anda telah menyelesaikan semua modul' : 'You have completed all the modules'}</p>
																						{
																							this.state.feedback.length !== 0 ? '' : (
																								<div className="my-2">
																									<Nav.Link className="btn btn-primary d-inline-block" onClick={() => this.openFeedback()}>{GetText() ? 'Isi form feedback' : 'Fill feedback form'}</Nav.Link>
																								</div>
																							)
																						}
																						{
																							this.state.certificate !== "" ? (
																								<a href={this.state.certificate} className="btn btn-primary" target="_blank" rel="noreferrer" download disabled={this.state.feedback.length !== 0 ? '' : 'disabled'}>{GetText() ? "Unduh sertifikat" : "Download certificate"}</a>
																							) : ''
																						}
																						{
																							this.state.feedback.length !== 0 ? '' : (
																								<p className="text-danger mt-2">{GetText() ? 'Isi form feedback untuk mengunduh sertifikat' : 'Fill feedback form to download certificate'}</p>
																							)
																						}
																						<p className="mt-4">
																							<a href="/libraries" className="link-more-2">{GetText() ? "Lihat library lainnya" : "Browse other library"} <FaArrowRight/></a>
																						</p>
																						{/* <a href="/libraries" className="btn btn-primary">{GetText() ? "Unduh sertifikat" : "Download certificate"}</a>
																						<br/>
																						<a href="/libraries" className="btn btn-outline-primary mt-2">{GetText() ? "Lihat library lainnya" : "Browse other library"}</a> */}
																					</div>
																				) : (
																					<p className="text-danger">{GetText() ? 'Anda harus menyelesaikan semua modul untuk dapat mengerjakan ujian' : 'You have to complete all the modules to do the exam'}</p>
																				)
																			)
																		}
																	</div>
																  ) : ''
																)
															})
														) : ''
													}
												</div>
												<div className={"exam-questions " + (this.state.open_exam === 1 ? "" : "d-none")}>
													<form onSubmit={this.getResult}>
														{
															this.state.exam.length > 0 ? (
																this.state.exam
																.sort((a, b) => a.examNumber - b.examNumber)
																.map((exam, i) => {
																	return (
																	//   exam.examStatus === 1 ? (
																		<div key={"exam-" + i}>
																			<h3 className="mb-4">{exam.examTitle}</h3>
																			<p className="mb-4">{exam.examDesc}</p>
																			{
																				exam.question.length > 0 ? (
																					exam.question.map((question, j) => {
																						return (
																							<div className="mb-2" key={"question-" + (j + 1)}>
																								{
																									question.questionType === "Multiple Choice" ? (
																										<div className="bg-light p-2 br-8">
																											<strong>{(GetText() ? "Soal #" : "Question #") + (j + 1)}</strong>
																											<p>{question.questionText}</p>
																											{
																												question.option1 ? (
																													<div className="form-check">
																														<label>
																															<input className="form-check-input" type="radio" name={"question-" + (j + 1)} value="1" required/>
																															{question.option1}
																														</label>
																													</div>
																												) : ''
																											}
																											{
																												question.option2 ? (
																													<div className="form-check">
																														<label>
																															<input className="form-check-input" type="radio" name={"question-" + (j + 1)} value="2"/>
																															{question.option2}
																														</label>
																													</div>
																												) : ''
																											}
																											{
																												question.option3 ? (
																													<div className="form-check">
																														<label>
																															<input className="form-check-input" type="radio" name={"question-" + (j + 1)} value="3"/>
																															{question.option3}
																														</label>
																													</div>
																												) : ''
																											}
																											{
																												question.option4 ? (
																													<div className="form-check">
																														<label>
																															<input className="form-check-input" type="radio" name={"question-" + (j + 1)} value="4"/>
																															{question.option4}
																														</label>
																													</div>
																												) : ''
																											}
																											{
																												question.option5 ? (
																													<div className="form-check">
																														<label>
																															<input className="form-check-input" type="radio" name={"question-" + (j + 1)} value="5"/>
																															{question.option5}
																														</label>
																													</div>
																												) : ''
																											}
																										</div>
																									) : (
																										<div className="bg-light p-2 br-8">
																											<strong>{(GetText() ? "Soal #" : "Question #") + (j + 1)}</strong>
																											<p>{question.questionText}</p>
																											{
																												question.option1 ? (
																													<div className="form-check">
																														<label>
																															<input className="form-check-input" type="checkbox" name={"question-" + (j + 1)} value="1"/>
																															{question.option1}
																														</label>
																													</div>
																												) : ''
																											}
																											{
																												question.option2 ? (
																													<div className="form-check">
																														<label>
																															<input className="form-check-input" type="checkbox" name={"question-" + (j + 1)} value="2"/>
																															{question.option2}
																														</label>
																													</div>
																												) : ''
																											}
																											{
																												question.option3 ? (
																													<div className="form-check">
																														<label>
																															<input className="form-check-input" type="checkbox" name={"question-" + (j + 1)} value="3"/>
																															{question.option3}
																														</label>
																													</div>
																												) : ''
																											}
																											{
																												question.option4 ? (
																													<div className="form-check">
																														<label>
																															<input className="form-check-input" type="checkbox" name={"question-" + (j + 1)} value="4"/>
																															{question.option4}
																														</label>
																													</div>
																												) : ''
																											}
																											{
																												question.option5 ? (
																													<div className="form-check">
																														<label>
																															<input className="form-check-input" type="checkbox" name={"question-" + (j + 1)} value="5"/>
																															{question.option5}
																														</label>
																													</div>
																												) : ''
																											}
																										</div>
																									)
																								}
																							</div>
																						)
																					})
																				) : ''
																			}
																		</div>
																	//   ) : ''
																	)
																})
															) : ''
														}
														<div className="text-center mt-4">
															<button className="btn btn-success d-inline-block" type="submit">{GetText() ? "Simpan Jawaban" : "Submit Answers"}</button>
														</div>
													</form>
												</div>
												<div className={"exam-result text-center " + (this.state.status !== "" ? "" : "d-none")}>
													<h3 className="mb-4">{GetText() ? "Hasil penilaian" : "Your result"}</h3>
													<table className="mx-auto" id="result-table">
														<thead>
															<tr>
																<th align="center">{GetText() ? "Benar" : "Correct"}</th>
																<th align="center">{GetText() ? "Salah" : "Incorrect"}</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td className="correct"><span id="correct">{this.state.correct}</span></td>
																<td className="incorrect"><span id="incorrect">{this.state.incorrect}</span></td>
															</tr>
														</tbody>
													</table>
													<h5 className="mt-4">{GetText() ? "Skor total" : "Total score"}: <span id="score">{this.state.score}</span></h5>
													<p className="mt-2" id="result">
														{
															this.state.status === 'Passed' ? (
																GetText() ? "Selamat, Anda telah berhasil lulus ujian!" : "Congratulations on passing your exam!"
															) : (
																GetText() ? "Nilai Anda belum mencapai batas kelulusan" : "Your score is below the passing grade"
															)
														}
													</p>
													<div className="text-center mt-4">
														{
															this.state.status === 'Passed' ? (
																<div>
																	{
																		this.state.feedback.length !== 0 ? '' : (
																			<div className="my-2">
																				<Nav.Link className="btn btn-primary d-inline-block" onClick={() => this.openFeedback()}>{GetText() ? 'Isi form feedback' : 'Fill feedback form'}</Nav.Link>
																			</div>
																		)
																	}
																	{
																		this.state.certificate !== "" ? (
																			<a href={this.state.feedback.length !== 0 ? this.state.certificate : '#!'} className="btn btn-primary" target="_blank" rel="noreferrer" download disabled={this.state.feedback.length !== 0 ? '' : 'disabled'}>{GetText() ? "Unduh sertifikat" : "Download certificate"}</a>
																		) : ''
																	}
																	{
																		this.state.feedback.length !== 0 ? '' : (
																			<p className="text-danger mt-2">{GetText() ? 'Isi form feedback untuk mengunduh sertifikat' : 'Fill feedback form to download certificate'}</p>
																		)
																	}
																	<p className="mt-4">
																		<a href="/libraries" className="link-more-2">{GetText() ? "Lihat library lainnya" : "Browse other library"} <FaArrowRight/></a>
																	</p>
																	{/* <a href="#!" className="btn btn-primary" onClick={() => this.getCertificate()}>{GetText() ? "Unduh sertifikat" : "Download certificate"}</a>
																	<br/>
																	<a href="/libraries" className="btn btn-outline-primary mt-2">{GetText() ? "Lihat library lainnya" : "Browse other library"}</a> */}
																</div>
															) : (
																<a href={"/enroll/" + this.state.id} className="btn btn-primary">{GetText() ? "Ulangi ujian" : "Retake exam"}</a>
															)
														}
													</div>
												</div>
											</Tab.Pane>
										</Tab.Content>
									</div>
								</Tab.Container>
								<div className="text-center mx-auto">
									{/* <div className="certificate" id="certificate">
										{
										(this.state.certificate_bg !== undefined && this.state.certificate_bg !== "") ? (
											<div className="certificate-bg" style={{backgroundImage: "url('" + this.state.certificate_bg + "')"}}></div>
										) : (
											<div className="certificate-bg" style={{backgroundImage: "url('" + process.env.PUBLIC_URL + "/img/certificate.jpg')"}}></div>
										)
										}
										<div className="certificate-detail">
											<p className="certificate-text">Certificate of Completion</p>
											<p className="certificate-course">{this.state.title}</p>
											<p className="certificate-name">{this.state.member_name}</p>
										</div>
										<div className="certificate-date" style={{width:"900px"}} >
											<p>For course completed on the <strong>{date_nw}</strong>, conducted by <strong>Novaio Academy</strong></p>
										</div>
									</div> */}
									<Certificate title={this.state.title} name={this.state.member_name} background={this.state.certificate_bg}/>
									
								</div>
								<Modal className="feedback" size="lg" show={this.state.open_feedback} onHide={this.closeFeedback}>
									<Modal.Body className="p-4">
										<h5 className="mb-3">{GetText() ? 'Form Feedback' : 'Feedback Form'}</h5>
                    					<Form onSubmit={this.sendFeedback}>	
											{
												this.state.feedbackList.length !== 0 ? (
													this.state.feedbackList
													.filter(a => a.feedbackStatus === 1)
													.sort((a, b) => a.feedbackNumber - b.feedbackNumber)
													.map((feedback, i) => {
														return (
															<div className="mb-3" key={"feedback-" + i}>
																<p className="mb-2">{feedback.feedbackText}</p>
																{
																	feedback.feedbackType === "Multiple Choice" ? (
																		<div>
																			{ 
																				feedback.option1 ? (
																					<div className="form-check">
																						<label>
																							<input className="form-check-input" type="radio" name={"feedback-" + feedback.feedbackNumber} value={feedback.option1} required />
																							{feedback.option1}
																						</label>
																					</div>
																				) : ''
																			}
																			{ 
																				feedback.option2 ? (
																					<div className="form-check">
																						<label>
																							<input className="form-check-input" type="radio" name={"feedback-" + feedback.feedbackNumber} value={feedback.option2} required />
																							{feedback.option2}
																						</label>
																					</div>
																				) : ''
																			}
																			{ 
																				feedback.option3 ? (
																					<div className="form-check">
																						<label>
																							<input className="form-check-input" type="radio" name={"feedback-" + feedback.feedbackNumber} value={feedback.option3} required />
																							{feedback.option3}
																						</label>
																					</div>
																				) : ''
																			}
																			{ 
																				feedback.option4 ? (
																					<div className="form-check">
																						<label>
																							<input className="form-check-input" type="radio" name={"feedback-" + feedback.feedbackNumber} value={feedback.option4} required />
																							{feedback.option4}
																						</label>
																					</div>
																				) : ''
																			}
																			{ 
																				feedback.option5 ? (
																					<div className="form-check">
																						<label>
																							<input className="form-check-input" type="radio" name={"feedback-" + feedback.feedbackNumber} value={feedback.option5} required />
																							{feedback.option5}
																						</label>
																					</div>
																				) : ''
																			}
																		</div>
																	) : (
																		<div>
																			<textarea className="form-control" name={"feedback-" + feedback.feedbackNumber} rows="3" required></textarea>
																		</div>
																	)
																}
															</div>
														)
													})
												) : ''
											}
											<div className="mt-4">
												<Button variant="primary" type="submit" value="Save" id="submit">{GetText() ? "Kirim" : "Submit"}</Button>
												<a className="btn btn-outline-primary ml-2" href="#!" onClick={this.closeFeedback}>
													{GetText() ? 'Batal' : 'Cancel'}
												</a>
											</div>
										</Form>
									</Modal.Body>
								</Modal>
								<Modal className="pdf" size="xl" show={this.state.open_pdf} onHide={this.closePdf}>
									<Modal.Header closeButton />
									<Modal.Body>
										<iframe 
											className="embed-content" 
											src={this.state.url_pdf}
											title="PDF Attachment"
											allowFullScreen
										/>
									</Modal.Body>
								</Modal>
							</div>
						</Container>
					) : ''
			}
			<div id="loading-area" className="hidden loading"></div>
			</div>
		);
	}
}
