import React, { Component } from 'react';
import Moment from 'moment';
import { GetText, ReplaceText, SearchList } from '../helper';
import { Container, InputGroup, FormControl, Tab, Nav } from 'react-bootstrap';
import { FaSearch, FaArrowRight, FaArrowDown, FaRegCalendarAlt, FaRegClock } from 'react-icons/fa';
import CourseList from './course/List';
import '../config/global';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[10];

export default class CourseBrowse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courseList: [],
      courseFeatured: [],
      courseCategories: []
    }
  }

  componentDidMount() {
    var dataText ='{"keyword":"","pageShow":"10","lang":"'+localStorage.getItem('language')+'","form_id":"pubonly"}';
    var dataSend = JSON.parse(dataText);

    fetch(global.config.urlLink+"/courselist", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey
      }, 
      body : JSON.stringify(dataSend)
    })
    .then(response => {
      if (response.status === 200) {
        return response.json();
      } else {
        console.log('Something happened wrong');
      }
    })
    .then(resultJson => {
      var resultData = [];
      var resultFeatured = [];
      var inum=0;
      for (var i in resultJson.values) {
        var content = resultJson.values[i].content;
          if (this.state.courseCategories.indexOf(content.categoryName) < 0) {
            this.state.courseCategories.push(content.categoryName);
          }
          var tutorNames = content.tutorName.replace(',', ', ');
          content.id = resultJson.values[i].docid;
          content.tutorNames = tutorNames;          
          resultData.push(content);
          if (content.isFeatured === "1" && resultFeatured.length === 0) {
            resultFeatured.push(content);
          }
          inum++;
          if(inum === resultJson.values.length) {
            this.setState({
              courseList: resultData,
              courseFeatured: resultFeatured
            });
          }
      }
    });
  }

  render() {
    return (
      <div className="browse-course">
      {
        this.state.courseFeatured.length > 0 ? (
        <section>
          <Container>
            <h3>{GetText() ? "Workshop Unggulan" : "Featured Workshop"}</h3>
            <div className="row py-4 mb-5">
              <div className="col-md-4 text-center">
                <img className="mxw h-300 br-8 mb-4" src={this.state.courseFeatured[0].coursePicture} alt={this.state.courseFeatured[0].courseTitle}/>
              </div>
              <div className="col-md-8">
                <a href={'/course/' + this.state.courseFeatured[0].id} className="text-dark">
                  <h4 className="mb-3">{this.state.courseFeatured[0].courseTitle}</h4>
                </a>
                <p>{ReplaceText(this.state.courseFeatured[0].courseShortDesc)}</p>
                <div className="information p-4">
                  <div className="row">
                    <div className="col-md-6">
                      <p>
                        <strong>{GetText() ? "Tanggal" : "Date"}</strong>
                        <br/>
                        <span className="mr-2">
                          <FaRegCalendarAlt className="mr-1"/>
                          {this.state.courseFeatured[0].courseDate ? Moment.unix(this.state.courseFeatured[0].courseDate._seconds).format('D MMMM Y') : ''}
                        </span>
                        <span>
                          <FaRegClock className="mr-1"/>
                          {this.state.courseFeatured[0].courseDate ? Moment.unix(this.state.courseFeatured[0].courseStartTime._seconds).format('HH:mm') : ''}
                        </span>
                      </p>
                      <p>
                        <strong>{GetText() ? "Harga" : "Price"}</strong>
                        <br/>
                        {this.state.courseFeatured[0].courseCurrency === "IDR" ? "Rp " : "$"} 
                        {new Intl.NumberFormat().format(this.state.courseFeatured[0].coursePrice)}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p>
                        <strong>{GetText() ? "Kategori" : "Category"}</strong>
                        <br/>
                        {this.state.courseFeatured[0].categoryName}
                      </p>
                      <p>
                        <strong>{GetText() ? "Pembicara" : "Trainer"}</strong>
                        <br/>
                        {this.state.courseFeatured[0].tutorNames}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="py-3 text-right">
                  <a href={'/course/' + this.state.courseFeatured[0].id} className="link-more"><strong>{GetText() ? "Selengkapnya" : "View Details"} <FaArrowRight/></strong></a>
                </div>
              </div>
            </div>
          </Container>
          <div className="separator mt-0"><div className="icon"><FaArrowDown/></div></div>
        </section>
        ) : ''
      }
        <section>
          <Container>
            <div className="row py-4">
              <Tab.Container defaultActiveKey="category-all">
                <div className="col-md-3">
                  <div className="sidebar-tab sticky-top">
                    <h3 className="mb-4">{GetText() ? "Pencarian" : "Search"}</h3>
                    <InputGroup className="mb-5">
                      <FormControl placeholder={GetText() ? "Ketik di sini..." : "Type here..."} aria-label="Course" aria-describedby="search" id="search" onKeyUp={SearchList}/>
                      <InputGroup.Append>
                        <InputGroup.Text><FaSearch/></InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                    <h3 className="mb-4">{GetText() ? "Kategori" : "Category"}</h3>
                    <Nav variant="pills" className="nav-scroll flex-column">
                      <Nav.Item key="all">
                        <Nav.Link eventKey="category-all">{GetText() ? "Semua" : "All"}</Nav.Link>
                      </Nav.Item>
                      {
                        this.state.courseCategories
                        .sort((a, b) => { return (a < b) ? -1 : ((a > b) ? 1 : 0) })
                        .map((courseCategory, i) => {
                          return (
                            <Nav.Item key={i}>
                              <Nav.Link eventKey={"category-" + i}>{courseCategory}</Nav.Link>
                            </Nav.Item>
                          )
                        })
                      }
                    </Nav>
                  </div>
                </div>
                <div className="col-md-1"></div>
                <div className="col-md-8 bg-light p-5 br-8">
                  <h3 className="mb-4">{GetText() ? "Daftar Workshop" : "Workshop List"}</h3>
                  <Tab.Content>
                    <Tab.Pane eventKey="category-all" key="all">
                      {
                        this.state.courseList
                          .sort((a, b) => b.courseDate._seconds - a.courseDate._seconds)
                          .map((courseItem, i) => {
                          return (
                            <CourseList value={courseItem} key={i}/>
                          )
                        })
                      }
                    </Tab.Pane>
                    {
                      this.state.courseCategories.map((courseCategory, i) => {
                        return (
                          <Tab.Pane eventKey={"category-" + i} key={i}>
                            {
                              this.state.courseList
                              .sort((a, b) => b.courseDate._seconds - a.courseDate._seconds)
                              .filter(courseItem => courseItem.categoryName === courseCategory)
                              .map((courseItem, j) => {
                                return (
                                  <CourseList value={courseItem} key={j}/>
                                )
                              })
                            }
                          </Tab.Pane>
                        )
                      })
                    }
                  </Tab.Content>
                </div>
              </Tab.Container>
            </div>
          </Container>
        </section>
      </div>
    );
  }
}