import React, { Component } from 'react';
import Moment from 'moment';
import Axios from 'axios';
import QRCode from 'react-qr-code';
import html2canvas from 'html2canvas';
import { GetText, ToggleArrow } from '../helper';
import { Container, Form, Button, Accordion, Card, Modal } from 'react-bootstrap';
import { FaPrint, FaChevronRight, FaChevronDown, FaDownload } from 'react-icons/fa';
import '../config/global';
import Error204 from '../Error204';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[13];
const authKey2 = global.config.authBearerKey+"##"+global.config.kindcode[31];

export default class InvoiceSubscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      memberId: localStorage.getItem('doc_id'),
      name: '',
      email: '',
      subdoc_id: '',
      order_id: '',
      subscribe_id: '',
      order_date: '',
      order_type: '',
      amount: '',
      payment_status: '',
      payment_status_msg: ["",""],
      payment_method: '',
      payment_currency: '',
      payment_account: '',
      payment_date: '',
      payment_info: '',
      payment_bank: '',
      confirmation_date: '',
      confirmation_remarks: '',
      confirmation_photo: null,
      input_date: '',
      input_remarks: '',
      input_photo: null,
      payment_voucher_code: '',
      payment_voucher_amount: 0,
      grand_total: 0,
      expired_date: '',
      modal_show: false,
      form_error: []
    }
    this.inputDate = this.inputDate.bind(this);
    this.inputRemarks = this.inputRemarks.bind(this);
    this.inputPhoto = this.inputPhoto.bind(this);
    this.checkPayment = this.checkPayment.bind(this);
    this.cancelOrder = this.cancelOrder.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.confirm = this.confirm.bind(this);
  }

  componentDidMount() {
    var url = window.location.pathname; 
    var subscribe_id = url.split("/")[3].substring(0,20); 
    if(subscribe_id !== "") {
        fetch(global.config.urlLink+ "/trainingdetailsubscribe/" + this.state.memberId + "/" + subscribe_id, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey
          }
        })
        .then(response => {
          if(response.status === 200) {
            return response.json();
          } else {
            console.log('Something happened wrong');
          }
        })
        .then(resultJson => {
          if(resultJson.values.length > 0) {
              var member = resultJson.values[0].content[0].maincontent;
              var subscriptions = resultJson.values[0].scontent;
              subscriptions.forEach((subscription) => {
                if(subscription.subcontent !== undefined) {
                  var content = subscription.subcontent;
                  var paystatus_msg = ["",""];
                  if(content.subscribeStatus === global.config.subscribeStatus[3]) { paystatus_msg = ["DIBATALKAN","CANCELED"]; }
                  else if(content.subscribeStatus === global.config.subscribeStatus[2]) { paystatus_msg = ["EXPIRED","EXPIRED"]; }
                  else if(content.subscribeStatus === global.config.subscribeStatus[1]) { paystatus_msg = ["LUNAS","PAID"]; }
                  else {
                    if(content.paymentConfirmationDate) { paystatus_msg = ["MEMVERIFIKASI","VERIFYING"]; } 
                    else { paystatus_msg = ["BELUM DIBAYAR", "UNPAID"]; }
                  }
                  var payment_bankz = ""; if(content.paymentBank !== undefined) { payment_bankz = content.paymentBank; }
                  this.setState({
                    name: member.memberName,
                    email: member.memberEmail,
                    subdoc_id: subscription.subdocid,
                    order_id: content.orderId,
                    subscribe_id: subscription.subdocid,
                    order_date: Moment.unix(content.requestSubscribeDate._seconds).format('D MMMM Y'),
                    order_type: content.subscribePlan,
                    amount: content.subscribePrice,
                    payment_status: content.subscribeStatus,
                    payment_status_msg: paystatus_msg,
                    payment_method: content.paymentMethod,
                    payment_currency: content.paymentCurrency === global.config.subscribePlan[0].currency[0] ? global.config.subscribePlan[0].symbolCurrency[0] : global.config.subscribePlan[0].symbolCurrency[1],
                    // payment_account: content.paymentInfo.replace('<br/>', ' '),
                    payment_account: content.paymentAccount,
                    payment_date: content.paymentReceivedDate ? Moment.unix(content.paymentReceivedDate._seconds).format('D MMMM Y') : "",
                    payment_info: content.paymentInfo.replace('<br/>', ', '),
                    payment_bank: payment_bankz,
                    confirmation_date: content.paymentConfirmationDate ? Moment.unix(content.paymentConfirmationDate._seconds).format('D MMMM Y') : "",
                    confirmation_remarks: content.paymentConfirmationRemarks ? content.paymentConfirmationRemarks : "",
                    confirmation_photo: content.paymentConfirmationPhoto ? content.paymentConfirmationPhoto : "",

                    payment_voucher_code: content.subscribeVoucherCode,
                    payment_voucher_amount: content.subscribevoucheramount,
                    grand_total: content.subscribeGTotal,
                    expired_date: content.dateEnd ? Moment.unix(content.dateEnd._seconds).format('D MMMM Y') : "",
                  });
                  if (content.paymentReceivedDate === undefined) {
                    setInterval(() => {
                      this.checkPayment();
                      // if (this.state.payment_date) {
                      //   window.location.reload();
                      // }
                    }, 5 * 60 * 1000); //5 mins
                  }
                } else {
                  this.setState({
                    subscribe_id: "none"
                  })
                }
              });
          } else {
            this.setState({
              subscribe_id: "none"
            })
          }
        });
    } else {
      this.setState({
        subscribe_id: "none"
      })
    }
  }
 
  checkPayment() {
    // fetch(global.config.urlLink+ "/traininguserview/" + this.state.memberId, {
    fetch(global.config.urlLink+ "/trainingdetailsubscribe/" + this.state.memberId + "/" + this.state.subscribe_id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey
      }
    })
    .then(response => {
      if(response.status === 200) {
        return response.json();
      } else {
        console.log('Something happened wrong');
      }
    })
    .then(resultJson => {
      if(resultJson.values.length > 0) {
        var subscriptions = resultJson.values[0].scontent;
        subscriptions.forEach((subscription) => {
          if(subscription.subcontent !== undefined) {
            var content = subscription.subcontent;
            if(content.paymentReceivedDate !== undefined) {
              this.setState({
                payment_date: content.paymentReceivedDate ? Moment.unix(content.paymentReceivedDate._seconds).format('D MMMM Y') : "",
              });
              window.location.reload();
            }            
          }
        })
      }
    });
  }

  cancelOrder(e) {
    e.preventDefault();
    document.getElementById('cancel').innerText = 'Loading...';
    document.getElementById('cancel').setAttribute('disabled', 'true');
    
    var datatext ='{"docid":"' + this.state.memberId + '","subdocid":"' + this.state.subdoc_id + '","form_id":"form-cancel-subscribe"}';
    var data = JSON.parse(datatext); 

    fetch(global.config.urlLink+ "/trainingcancelsubscribe", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey,
      }, body : JSON.stringify(data)
    }).then(result => {
      if(result.status === 200) return result.json() 
    }).then(resJson => {
      window.location.reload();
    });
  }
 
  downloadQr = () => {
    const qrContainer = document.getElementById("qris");
    html2canvas(qrContainer).then(function (canvas) {
      const image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
      const downloadLink = document.createElement("a");
      downloadLink.setAttribute("download", "qr-code.png");
      downloadLink.setAttribute("href", image);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  }
  inputDate(event) {
    this.setState({ 
      input_date: event.target.value,
      form_error: [] 
    });
  }

  inputRemarks(event) {
    this.setState({ 
      input_remarks: event.target.value,
      form_error: []
    });
  }

  inputPhoto(event) {
    if (event.target.files[0] !== null) {
      var value = event.target.files[0];
      if (value.type.indexOf('image') > -1) {
        this.setState({
          input_photo: value,
          form_error: []
        }, () => {
          document.getElementById('input-photo').innerText = this.state.input_photo.name;
        });
      }
    }
  }

  async confirmData(datasend) {
      var dataSend = JSON.parse(datasend);

      fetch(global.config.urlLink+ "/trainingconfirmsubscribe", {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': authKey2
        }, 
        body : JSON.stringify(dataSend)
      })
      .then(response => {
        if(response.status === 200) {
          return response.json();
        } else {
          console.log('Something happened wrong.');
        }
      }).then(resultJson => {
        window.scrollTo(0, 0);
        window.location.reload();
      });
  }
  confirm(event) {
    event.preventDefault();
    document.getElementById('submit').innerText = 'Loading...';
    document.getElementById('submit').setAttribute('disabled', 'true');
    
    var error = [];
    if (!(this.state.input_date)) {
      error.push('input_date');
    }
    if (!(this.state.input_remarks)) {
      error.push('input_remarks');
    }
    if (error.length) {
      this.setState({
        form_error: error
      });
      if(GetText() ) {
        document.getElementById('submit').innerText = "Kirim";
      } else {
        document.getElementById('submit').innerText = "Send";
      }
      document.getElementById('submit').removeAttribute('disabled');
      return;
    } else {
      var dataparse = {
        "memberdoc_id": localStorage.getItem('doc_id'),
        "subscribedoc_id": this.state.subscribe_id,
        "confirmation_date": this.state.input_date,
        "confirmation_remarks": this.state.input_remarks
      }
      if(this.state.input_photo !== "" && this.state.input_photo !== null) {

        const data = new FormData();
        data.append('file', this.state.input_photo);
        data.append('source', 'confirmation');
        Axios.post(global.config.urlLink+ "/uploadconfirmsubscribe", 
          data, { 
            headers: { 
              'content-type': 'multipart/form-data',
              'Authorization': authKey2
            }
          }
        )
        .then(result => {
          var photo = result.data.values;
          dataparse.confirmation_photo = photo;
          var dataText = JSON.stringify(dataparse);
          this.confirmData(dataText);
        });
    
      } else {
        dataparse.confirmation_photo = "";
        var dataText2 = JSON.stringify(dataparse);
        this.confirmData(dataText2);
      }
    }

  }
  openModal(e) {
    e.preventDefault();
    this.setState({
      modal_show: true
    });
  }

  closeModal(e) {
    e.preventDefault();
    this.setState({
      modal_show: false
    });
  }

  render() {
    return (
      <div className="invoice-subscription">
        {
        (this.state.subscribe_id !== "none" && this.state.subscribe_id !==  "")? 
        <Container>
          <div className="row visible-print">
            <div className="col-md-12">
              <div className="card card-invoice">
                <div className="card-header p-0 bg-white">
                  <h4 className="mb-0 p-4 bg-dark text-white"><strong>NOVAIO ACADEMY</strong></h4>
                  <div className="p-4">
                    <h1 className="mb-0 my-4">INVOICE</h1>
                    <p className="mb-0"><strong>{GetText() ? "Nomor Invoice" : "Invoice No"}</strong> {this.state.order_id}</p>
                    <p className="mb-0"><strong>{GetText() ? "Tanggal Pesanan" : "Order Date"}</strong> {this.state.order_date}</p>
                  </div>
                </div>
                <div className="card-body p-4">
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead className="card-header bg-dark">
                        <tr>
                          <th width="60%" className="col-3 text-white border-0"><strong>{GetText() ? "Deskripsi" : "Description"}</strong></th>
                          <th width="10%" className="col-1 text-white text-center border-0"><strong>{GetText() ? "Jml" : "Qty"}</strong></th>
                          <th width="30%" className="col-2 text-white text-right border-0"><strong>{GetText() ? "Harga" : "Amount"}</strong></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="col-3 border-0">
                            {GetText() ? 
                              <>
                              {`Langganan Keanggotaan ${(this.state.order_type === global.config.subscribePlan[0].type)? 'Perbulan' : 'Pertahun'}`}<br/>
                              {(this.state.expired_date !== "" && this.state.expired_date !== null && this.state.expired_date !== undefined)?
                                <><br/><small><strong>{`Berakhir pada : ${this.state.expired_date}`}</strong></small></>
                                : '' }
                              </>
                              :
                              <>
                              {`${this.state.order_type} Membership Subscription`}
                              {(this.state.expired_date !== "" && this.state.expired_date !== null && this.state.expired_date !== undefined)?
                                <><br/><small><strong>{`Expired on : ${this.state.expired_date}`}</strong></small></>
                                : '' }
                              </>
                            }
                          </td>
                          <td className="col-1 text-center border-0">1</td>
                          <td className="col-2 text-right border-0">{this.state.payment_currency + new Intl.NumberFormat().format(this.state.amount)}</td>
                        </tr>
                        <tr>
                          <td colSpan="2" className="text-right"><strong>Sub Total</strong></td>
                          <td className="text-right">{this.state.payment_currency + new Intl.NumberFormat().format(this.state.amount)}</td>
                        </tr>
                        {(this.state.payment_voucher_amount > 0)?
                        <tr>
                          <td colSpan="2" className="text-right">Voucher</td>
                          <td className="text-right">({this.state.payment_currency + new Intl.NumberFormat().format(this.state.payment_voucher_amount)})</td>
                        </tr>
                        : ''
                        }
                      </tbody>
                      <tfoot className="card-footer">
                        <tr>
                          <td colSpan="2" className="text-right"><strong>Total</strong></td>
                          <td className="text-right"><strong>{this.state.payment_currency + new Intl.NumberFormat().format(this.state.grand_total)}</strong></td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <div className="row mt-5 mb-4">
                    <div className="col-md-4">
                      <h4>{GetText() ? "TAGIHAN KEPADA" : "INVOICED TO"}</h4>
                      <address><strong>{this.state.name}</strong><br/>{this.state.email}</address>
                    </div>
                    {
                      this.state.payment_method === 'Virtual Account' ? (
                    <div className="col-md-4">
                      <h4>{GetText() ? "BAYAR KE" : "PAYMENT TO"}</h4>
                      <address><strong>{this.state.payment_bank} {this.state.payment_method}</strong><br/>{(this.state.payment_account) ? 'VA No. '+ this.state.payment_account : ''}</address>
                      {/* <address><strong>{this.state.payment_method}</strong><br/>{(this.state.payment_method !== this.state.payment_account) ? this.state.payment_account : ''}</address> */}
                    </div>
                      ) : ''
                    }
                    <div className="col-md-4 text-center">
                      <h4>{GetText() ? "STATUS PEMBAYARAN" : "PAYMENT STATUS"}</h4>
                      <h4 className="p-2 bg-primary text-white"><strong>{GetText() ? this.state.payment_status_msg[0] : this.state.payment_status_msg[1]}</strong></h4>
                      {
                        this.state.payment_date ? (
                          <address><strong>{GetText() ? "Tanggal Bayar" : "Payment Date"}</strong> {Moment(this.state.payment_date).format("D MMMM Y")}</address>
                        ) : ''
                      }
                    </div>
                  </div>
                  <hr/>
                  <div className="row mt-4 mb-0">
                    {
                      GetText() ? (
                        <div className="col-md-12">
                          <h4>SYARAT &amp; KETENTUAN</h4>
                          <address>Dalam kondisi apa pun Novaio Academy tidak bertanggung jawab atas konsekuensi khusus, atau kerusakan yang diakibatkan oleh pelanggaran garansi, keterlambatan atau kinerja Novaio Academy atau wanprestasi lainnya berdasarkan perjanjian ini.</address>
                        </div>
                      ) : (
                        <div className="col-md-12">
                          <h4>TERMS &amp; CONDITIONS</h4>
                          <address>In no event shall Seller be liable for any special consequential, or contingent damages resulting from Seller's Breach of Warranty, delay or performance or any other default hereunder.</address>
                        </div>                        
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row py-5 hidden-print">
            <div className="col-md-7">
              { 
                // this.state.payment_date ? '' : (
                (this.state.confirmation_date === "" && this.state.payment_status === global.config.subscribeStatus[0])? 
                  this.state.payment_method === 'Virtual Account' ? (
                    <div className="bg-light p-4 mb-4">
                      <Form className="form-payment">
                        <h4><strong>{GetText() ? "Instruksi Pembayaran" : "Payment Instruction"}</strong></h4>
                        <Accordion className="card-faq mt-4 text-left">
                          <Card id="accordion-0">
                            <Accordion.Toggle as={Card.Header} eventKey="0" onClick={()=> ToggleArrow(0)}>
                              m-Banking
                              <FaChevronRight className="accordion-toggle toggle-close"/>
                              <FaChevronDown className="accordion-toggle toggle-open d-none"/>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0" id="collapse-0">
                              <Card.Body>
                                {
                                  GetText() ? (
                                    <ol>
                                      <li>Buka aplikasi Mobile {this.state.payment_bank} di hp Anda</li>
                                      <li>Pilih m-Transfer &gt; {this.state.payment_method}</li>
                                      <li>Masukkan nomor {this.state.payment_method} <strong>{this.state.payment_account}</strong> dan pilih Kirim</li>
                                      <li>Lihat data yang tampil di layar. Pastikan kembali jumlah tagihan dan akun tujuan, kemudian pilih Ya</li>
                                      <li>Masukkan PIN m-{this.state.payment_bank} Anda dan klik OK</li>
                                    </ol>
                                  ) : (
                                    <ol>
                                      <li>Open your {this.state.payment_bank} Mobile application on your mobile</li>
                                      <li>Choose m-Transfer &gt; {this.state.payment_method}</li>
                                      <li>Input {this.state.payment_method} number <strong>{this.state.payment_account}</strong> and choose Send</li>
                                      <li>Check the data on screen. Ensure the amount shown and the account used are correct, then choose Yes</li>
                                      <li>Input your m-{this.state.payment_bank} PIN number and click OK</li>
                                    </ol>
                                  )
                                }
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                          <Card id="accordion-1">
                            <Accordion.Toggle as={Card.Header} eventKey="1" onClick={()=> ToggleArrow(1)}>
                              Internet Banking
                              <FaChevronRight className="accordion-toggle toggle-close"/>
                              <FaChevronDown className="accordion-toggle toggle-open d-none"/>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1" id="collapse-1">
                              <Card.Body>
                                {
                                  GetText() ? (
                                    <ol>
                                      <li>Pilih Transfer &gt; Transfer ke {this.state.payment_bank} {this.state.payment_method}</li>
                                      <li>Pilih {this.state.payment_method} Number, lalu masukkan nomor rekening {this.state.payment_method} <strong>{this.state.payment_account}</strong> dan klik Lanjutkan</li>
                                      <li>Lihat data yang tampil di layar. Pastikan kembali jumlah tagihan dan akun tujuan, kemudian pilih Ya</li>
                                      <li>Masukkan PIN key-{this.state.payment_bank} Anda dan klik OK</li>
                                    </ol>
                                  ) : (
                                    <ol>
                                      <li>Choose Fund Transfer &gt; Transfer to {this.state.payment_bank} {this.state.payment_method}</li>
                                      <li>Tick the {this.state.payment_method} Number option, then input {this.state.payment_method} Number <strong>{this.state.payment_account}</strong> and choose Continue</li>
                                      <li>Check the data on screen. Ensure the amount shown and the account used are correct, then choose Yes</li>
                                      <li>input your key-{this.state.payment_bank} Response and click Submit</li>
                                    </ol>
                                  )
                                }
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                          <Card id="accordion-2">
                            <Accordion.Toggle as={Card.Header} eventKey="2" onClick={()=> ToggleArrow(2)}>
                              ATM Transfer
                              <FaChevronRight className="accordion-toggle toggle-close"/>
                              <FaChevronDown className="accordion-toggle toggle-open d-none"/>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="2" id="collapse-2">
                              <Card.Body>
                                {
                                  GetText() ? (
                                    <ol>
                                      <li>Pilih Transaksi Lainnya &gt; Transfer &gt; {this.state.payment_method}</li>
                                      <li>Masukkan nomor rekening {this.state.payment_method} <strong>{this.state.payment_account}</strong> dan pilih OK</li>
                                      <li>Lihat data yang tampil di layar. Pastikan kembali jumlah tagihan dan akun tujuan, kemudian pilih Ya</li>
                                      <li>Simpan resi transfer hingga kami selesai memverifikasi pembayaran Anda</li>
                                    </ol>
                                  ) : (
                                    <ol>
                                      <li>Choose Other Transaction &gt; Transfer &gt; {this.state.payment_method}</li>
                                      <li>Input {this.state.payment_method} Number <strong>{this.state.payment_account}</strong> and choose OK</li>
                                      <li>Check the data on screen. Ensure the amount shown and the account used are correct, then choose Yes</li>
                                      <li>Please keep the receipt until we verified your payment</li>
                                    </ol>
                                  )
                                }
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </Form>
                    </div>
                  ) : this.state.payment_method === 'QRIS' ? (
                    <div className="bg-light p-4 mb-4">
                      <Form className="form-payment">
                        <div className="row">
                          <div className="col-6">
                            <h4><strong>{GetText() ? "Pindai QR" : "Scan QR"}</strong></h4>
                          </div>
                          <div className="col-6 text-right">
                            <span onClick={this.downloadQr} className="btn btn-sm btn-secondary"><FaDownload/> Download</span>
                          </div>
                        </div>
                        <div id="qris" className="text-center">
                          <div className="qr-container">
                            <QRCode className="qr-code" value={this.state.payment_account}/>
                          </div>
                        </div>
                      </Form>
                    </div>
                  ) : ''
                : <></>
              }
              <div className="card card-pop">
                <div className="card-body p-4">
                  <div className="header">
                    <div className="row">
                      <div className="col-6">
                        <h4 className="mt-1 mb-0">Invoice</h4>
                      </div>
                      <div className="col-6 text-right">
                        <button onClick={() => window.print()} className="btn btn-sm btn-secondary"><FaPrint/> Print</button>
                      </div>
                    </div>
                    <hr/>
                  </div>
                  <div className="row">
                    <div className="col-6 col-md-4"><strong>{GetText() ? "Tanggal Pesanan" : "Order Date"}</strong></div>
                    <div className="col-6 col-md-8 text-right text-md-left">{this.state.order_date}</div>
                  </div>
                  <div className="row">
                    <div className="col-6 col-md-4"><strong>{GetText() ? "Nomor Invoice" : "Invoice No"}</strong></div>
                    <div className="col-6 col-md-8 text-right text-md-left">{this.state.order_id}</div>
                  </div>
                  <div className="row">
                    <div className="col-6 col-md-4"><strong>{GetText() ? "Status Pembayaran" : "Payment Status"}</strong></div>
                    <div className="col-6 col-md-8 text-right text-md-left">{GetText() ? this.state.payment_status_msg[0] : this.state.payment_status_msg[1]}</div>
                  </div>
                  <hr/>
                  <div className="row">
                    <div className="col-md-12">
                      <strong>{GetText() ? "Tagihan Kepada" : "Invoiced To"}</strong>
                      <address>{this.state.name}<br/>{this.state.email}</address>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead className="card-header">
                        <tr>
                          <td className="col-3 border-0"><strong>{GetText() ? "Deskripsi" : "Description"}</strong></td>
                          <td className="col-1 text-center border-0"><strong>{GetText() ? "Jumlah" : "Qty"}</strong></td>
                          <td className="col-2 text-right border-0"><strong>{GetText() ? "Harga" : "Amount"}</strong></td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="col-3 border-0">
                          {GetText() ? 
                              <>
                              {`Langganan Keanggotaan ${(this.state.order_type === global.config.subscribePlan[0].type)? 'Perbulan' : 'Pertahun'}`}<br/>
                              {(this.state.expired_date !== "" && this.state.expired_date !== null && this.state.expired_date !== undefined)?
                                <><br/><small><strong>{`Berakhir pada : ${this.state.expired_date}`}</strong></small></>
                                : '' }
                              </>
                              :
                              <>
                              {`${this.state.order_type} Membership Subscription`}
                              {(this.state.expired_date !== "" && this.state.expired_date !== null && this.state.expired_date !== undefined)?
                                <><br/><small><strong>{`Expired on : ${this.state.expired_date}`}</strong></small></>
                                : '' }
                              </>
                          }
                          </td>
                          <td className="col-1 text-center border-0">1</td>
                          <td className="col-2 text-right border-0">{this.state.payment_currency + new Intl.NumberFormat().format(this.state.amount)}</td>
                        </tr>
                        <tr>
                          <td colSpan="2" className="text-right"><strong>Sub Total</strong></td>
                          <td className="text-right">{this.state.payment_currency + new Intl.NumberFormat().format(this.state.amount)}</td>
                        </tr>
                        {(this.state.payment_voucher_amount > 0)?
                        <tr>
                          <td colSpan="2" className="text-right">Voucher</td>
                          <td className="text-right">({this.state.payment_currency + new Intl.NumberFormat().format(this.state.payment_voucher_amount)})</td>
                        </tr>
                        : ''
                        }
                      </tbody>
                      <tfoot className="card-footer">
                        <tr>
                          <td colSpan="2" className="text-right"><strong>Total</strong></td>
                          <td className="text-right"><strong>{this.state.payment_currency + new Intl.NumberFormat().format(this.state.grand_total)}</strong></td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 mt-4 mt-md-0">
              <div className="bg-dark p-4">
                  <div>
                    {
                      (this.state.payment_status === global.config.subscribeStatus[3])? 
                        <h4 className="text-white"><strong>{GetText() ? "Status Pemesanan" : "Order Status"}</strong></h4>
                      : (this.state.payment_status === global.config.subscribeStatus[2])? 
                        <h4 className="text-white"><strong>{GetText() ? "Status Pemesanan" : "Order Status"}</strong></h4>
                      : (this.state.payment_status === global.config.subscribeStatus[1])? 
                        <h4 className="text-white"><strong>{GetText() ? "Informasi Pembayaran" : "Payment Information"}</strong></h4>
                      : (this.state.payment_status === global.config.subscribeStatus[0] && this.state.confirmation_date !== "" && this.state.confirmation_date !== undefined)?
                        <h4 className="text-white"><strong>{GetText() ? "Konfirmasi Pembayaran" : "Payment Confirmation"}</strong></h4>
                      : <>
                        <h4 className="text-white"><strong>{GetText() ? "Konfirmasi Pembayaran" : "Payment Confirmation"}</strong></h4>
                        <p className="my-4 text-white">{GetText() ? "Harap melakukan pembayaran ke nomor rekening di bawah ini." : "Please make your payment to this account below."}</p>
                      </>
                    }
                      <div className="row mt-4">
                        <div className="col-6"><p className="text-white">Status</p></div>
                        <div className="col-6 text-right"><p className="text-white"><strong>{GetText() ? this.state.payment_status_msg[0] : this.state.payment_status_msg[1]}</strong></p></div>
                      </div>
                    {
                      (this.state.payment_date !== "" && this.state.payment_date !== undefined)? 
                      <div className="row">
                        <div className="col-6"><p className="text-white">{GetText() ? "Tanggal Bayar" : "Payment Date"}</p></div>
                        <div className="col-6 text-right"><p className="text-white"><strong>{Moment(this.state.payment_date).format("D MMMM Y")}</strong></p></div>
                      </div>
                      : ''
                    }
                      <div className="row">
                        <div className="col-6"><p className="text-white">{GetText() ? "Metode Pembayaran" : "Payment Method"}</p></div>
                        <div className="col-6 text-right"><p className="text-white"><strong>{this.state.payment_method === "Virtual Account" ? this.state.payment_bank : '' } {this.state.payment_method}</strong></p></div>
                      </div>
                      {
                        this.state.payment_method === "Virtual Account" ? (
                          <div className="row">
                            <div className="col-6"><p className="text-white">{GetText() ? "Bayar Ke" : "Payment To"}</p></div>
                            <div className="col-6 text-right"><p className="text-white"><strong>{this.state.payment_account}</strong></p></div>
                          </div>
                        ) : ''
                      }
                      <div className="row">
                        <div className="col-6"><p className="text-white">{GetText() ? "Total Harga" : "Total Amount"}</p></div>
                        <div className="col-6 text-right"><p className="text-white"><strong>{this.state.payment_currency + new Intl.NumberFormat().format(this.state.grand_total)}</strong></p></div>
                      </div>
                    {
                      (this.state.payment_status === global.config.subscribeStatus[1])? 
                      <div>
                        <p className="my-4 text-white">{GetText() ? "Terima kasih, pembayaran Anda telah kami terima. Anda juga dapat melihat transaksi lainnya di profil Anda." : "Thank you, your payment has been received. You can also check your profile page for past orders."}</p>
                        <a className="btn btn-lg btn-primary w-100" href="/account/subscription">{GetText() ? "Lihat Profil" : "Go to Profile"}</a>
                      </div>
                      : ''
                    }
                  </div>
                      {
                        (this.state.payment_status === global.config.subscribeStatus[0] &&  this.state.confirmation_date === "") ? 
                          <div>
                          <hr className="bg-white my-4"/>
                          <p className="my-4 text-white">{GetText() ? "Jika Anda telah melakukan pembayaran, harap mengisi formulir di bawah ini agar memudahkan kami melacak pembayaran Anda." : "If you have made payment, please fill the form below so we can track your payment."}</p>
                          <Form className="form-payment" onSubmit={this.confirm}>
                            <Form.Group>
                              <label className="text-white">{GetText() ? "Tanggal Bayar" : "Payment Date"}</label>
                              {this.state.form_error.some(item => item === 'input_date') ? <span className="error-message">{GetText() ? "Wajib Diisi!" : "Required!"}</span> : ''}
                              <input className="form-control" type="date" name="confirmation_date" onChange={this.inputDate}/>
                            </Form.Group>
                            <Form.Group>
                              <label className="text-white">{GetText() ? "Keterangan" : "Remarks"}</label>
                              {this.state.form_error.some(item => item === 'input_remarks') ? <span className="error-message">{GetText() ? "Wajib Diisi!" : "Required!"}</span> : ''}
                              <textarea className="form-control" name="confirmation_remarks" placeholder={"I have paid " + this.state.payment_currency + new Intl.NumberFormat().format(this.state.grand_total) + " through " + this.state.payment_account} onChange={this.inputRemarks}></textarea>
                            </Form.Group>
                            <Form.Group>
                              <label className="text-white">{GetText() ? "Resi Transfer" : "Transfer Receipt"} <small>(Optional)</small></label>
                              <div className="input-group mb-3">
                                <div className="custom-file">
                                  <input type="file" className="custom-file-input" name="confirmation_photo" onChange={this.inputPhoto}/>
                                  <label className="custom-file-label" id="input-photo" htmlFor="photo">{GetText() ? "Pilih Gambar" : "Choose file"}</label>
                                </div>
                              </div>
                            </Form.Group>
                            <Button className="w-100 mt-4" variant="primary" size="lg" type="submit" id="submit">{GetText() ? "Kirim" : "Send"}</Button>
                            <hr className="bg-white mt-5"/>
                            <Button className="btn-gray w-100 mt-2 text-primary btn-outline-secondary" variant="outlined" size="sm" type="submit" onClick={this.openModal}>{GetText() ? "Batalkan Pesanan" : "Cancel Order"}</Button>
                          </Form>
                          <Modal animation={false} show={this.state.modal_show}>
                            <Modal.Header>
                              <Modal.Title>{GetText() ? 'Batalkan Pesanan?' : 'Cancel Order?'}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              {GetText() ? 'Anda yakin ingin membatalkan pesanan dengan Order ID ' : 'Are you sure you want to cancel an order with an Order ID '}
                              <strong>{this.state.order_id}</strong>?
                            </Modal.Body>
                            <Modal.Footer>
                              <a className="btn btn-outline-primary" href="#" onClick={this.closeModal}>{GetText() ? "Kembali" : "Back"}</a>
                              <a className="btn btn-primary" href="#" onClick={this.cancelOrder} id="cancel">{GetText() ? "Batalkan pesanan" : "Cancel order"}</a>
                            </Modal.Footer>
                          </Modal>
                        </div> 
                        : (this.state.payment_status === global.config.subscribeStatus[0] &&  this.state.confirmation_date !== "") ? 
                        <div>
                            <hr className="bg-white mt-5"/>
                            <Button className="btn-gray w-100 mt-2 text-primary btn-outline-secondary" variant="outlined" size="sm" type="submit" onClick={this.openModal}>{GetText() ? "Batalkan Pesanan" : "Cancel Order"}</Button>
                            <Modal animation={false} show={this.state.modal_show}>
                              <Modal.Header>
                                <Modal.Title>{GetText() ? 'Batalkan Pesanan?' : 'Cancel Order?'}</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                {GetText() ? 'Anda yakin ingin membatalkan pesanan dengan Order ID ' : 'Are you sure you want to cancel an order with an Order ID '}
                                <strong>{this.state.order_id}</strong>?
                              </Modal.Body>
                              <Modal.Footer>
                                <a className="btn btn-outline-primary" href="#" onClick={this.closeModal}>{GetText() ? "Kembali" : "Back"}</a>
                                <a className="btn btn-primary" href="#" onClick={this.cancelOrder} id="cancel">{GetText() ? "Batalkan pesanan" : "Cancel order"}</a>
                              </Modal.Footer>
                          </Modal>
                        </div>
                        : ''
                      }
              </div>
            </div>
          </div>
        </Container>
      : (this.state.subscribe_id === "none") ? 
        <Error204/>
      : <></>
      
      }
      </div>
    );
  }
}