import React, { Component } from 'react';
import '../ctinc/ct-Style-2.css';
import '../ctinc/ct-Style.css';
import '../ctinc/ct-Global';
import DateTimeFormats from '../../../config/date-time-format';
import { FormErrors } from '../ctinc/error-form';
import Alert from '@material-ui/lab/Alert';
import PostAddIcon from '@material-ui/icons/PostAdd';
import DatePicker from 'react-date-picker';
import NAimage from '../../assets/na.png';

const authKey = global.ctconfig.authBearerKey+"##"+global.ctconfig.kindcode[13];
const authKey2 = global.ctconfig.authBearerKey+"##"+global.ctconfig.kindcode[34];
class CTPurchaseDetail extends Component {
  constructor (props) {
    super(props);
      this.state = {
      stPopTitle: '',
      memberdoc_id: '',
      purchasedoc_id: '',
      member_fullname: '',
      member_email: '',
      member_plan: '',
      member_status_subscribe: '',
      member_expired_subscribe: '',
      course_title: '',
      purchase_status: '',
      purchase_date: '',
      purchase_price: '',
      payment_method: '',
      payment_info: '',
      member_purchase_verified_date: '',
      member_purchase_verified_byid: '',
      member_purchase_verified_byname: '',
      member_purchase_received_date: '',
      member_purchase_received_price: '',
      purchase_payment_received_date: '',
      purchase_payment_received_price: '',
      purchase_payment_verified_comment: '',
      formErrors: {purchase_payment_received_date: '',purchase_payment_received_price: '',purchase_payment_verified_comment: ''},
      paymentReceivedDateValid: false,
      paymentReceivedPriceValid: false,
      formValid: false,
      errmsg: '',
      member_purchase_confirmation_date: '',
      member_purchase_confirmation_remarks: '',
      member_purchase_confirmation_photo: ''
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadFormPurchase = this.loadFormPurchase.bind(this);
  }
    
  loadFormPurchase() {
    var othersContent = JSON.parse(document.getElementById("contentoth").innerText);
    var memberId = othersContent.member_id;
    var purchaseId = othersContent.purchase_id;
    fetch(global.ctconfig.urlLink+"/traininguserview/" + memberId, {
      method: 'GET',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': authKey,
      }
    }).then(res => {
      if(res.status === 200) return res.json() 
    }).then(resJson => {
      document.getElementById('loading-area').style.display = 'none'; 
      if(resJson.status === '200') {
        var memberPlan='Guest', memberStatusSubscribe='',expiredSubscribeDate='';
        var courseTitle = '', purchaseStatus = '', purchaseDate='', purchasePrice='', paymentMethod='', paymentInfo='';

        var memberPurchaseVerifiedDate='', memberPurchaseVerifidById='', memberPurchaseVerifiedByName=''; 
        var memberPurchaseReceivedDate= '', memberPurchaseReceivedPrice='';

        if(resJson.values[0].content[0].maincontent.memberPlan !== undefined) { memberPlan = resJson.values[0].content[0].maincontent.memberPlan; }
        if(resJson.values[0].content[0].maincontent.statusSubscribeMember !== undefined) { memberStatusSubscribe = resJson.values[0].content[0].maincontent.statusSubscribeMember; }
        if(resJson.values[0].content[0].maincontent.expiredSubscribeMember !== undefined) { expiredSubscribeDate = DateTimeFormats(resJson.values[0].content[0].maincontent.expiredSubscribeMember._seconds,"date-long"); }
        
        resJson.values[0].scontent2.map((purchase) => {
          if(purchase.subdocid === purchaseId) {
            if(purchase.subcontent.courseTitle !== undefined) { courseTitle = purchase.subcontent.courseTitle; }
            if(purchase.subcontent.purchaseStatus !== undefined) { purchaseStatus = purchase.subcontent.purchaseStatus; }
            if(purchase.subcontent.purchaseDate !== undefined) { purchaseDate = DateTimeFormats(purchase.subcontent.purchaseDate._seconds,"datetime-long"); }
            if(purchase.subcontent.purchasePrice !== undefined) { purchasePrice = purchase.subcontent.purchasePrice; }
            if(purchase.subcontent.paymentMethod !== undefined) { paymentMethod = purchase.subcontent.paymentMethod; }
            if(purchase.subcontent.paymentInfo !== undefined) { paymentInfo += 'Transfer to : '+purchase.subcontent.paymentInfo.replace('<br/>', ' '); }
            if(purchase.subcontent.verifiedPurchaseDate !== undefined) { memberPurchaseVerifiedDate = DateTimeFormats(purchase.subcontent.verifiedPurchaseDate._seconds,"datetime-long"); }
            if(purchase.subcontent.verifiedPurchaseById !== undefined) { memberPurchaseVerifidById = purchase.subcontent.verifiedPurchaseById; }
            if(purchase.subcontent.verifiedPurchaseByName !== undefined) { memberPurchaseVerifiedByName = purchase.subcontent.verifiedPurchaseByName; }
            if(purchase.subcontent.paymentReceivedDate !== undefined) { memberPurchaseReceivedDate = DateTimeFormats(purchase.subcontent.paymentReceivedDate._seconds,"datetime-long"); }
            if(purchase.subcontent.paymentReceivedPrice !== undefined) { memberPurchaseReceivedPrice = purchase.subcontent.paymentReceivedPrice; }

            this.setState({
              stPopTitle: '',
              memberdoc_id: memberId,
              purchasedoc_id: purchaseId,              
              member_fullname: othersContent.member_fullname,
              member_email: othersContent.member_email,
              member_plan: memberPlan,
              member_status_subscribe: memberStatusSubscribe,
              member_expired_subscribe: expiredSubscribeDate,
              course_title: courseTitle,
              purchase_status: purchaseStatus,
              purchase_date: purchaseDate,
              purchase_price: purchasePrice,
              payment_method: paymentMethod,
              payment_info: paymentInfo,
              member_purchase_verified_date: memberPurchaseVerifiedDate,
              member_purchase_verified_byid: memberPurchaseVerifidById,
              member_purchase_verified_byname: memberPurchaseVerifiedByName,
              member_purchase_received_date: memberPurchaseReceivedDate,
              member_purchase_received_price: memberPurchaseReceivedPrice,
              purchase_payment_received_date: '',
              purchase_payment_received_price: '',
              purchase_payment_verified_comment: '',
              formErrors: {purchase_payment_received_date: '',purchase_payment_received_price: '',purchase_payment_verified_comment: ''},
              paymentReceivedDateValid: false,
              paymentReceivedPriceValid: false,
              formValid: false,
              errmsg: '',
              member_purchase_confirmation_date: purchase.subcontent.paymentConfirmationDate,
              member_purchase_confirmation_remarks: purchase.subcontent.paymentConfirmationRemarks,
              member_purchase_confirmation_photo: purchase.subcontent.paymentConfirmationPhoto
            });
          }
          return true;
        })
      } else {
        console.log('Something happened wrong');
      }
    }).catch(err => {
      document.getElementById('loading-area').style.display = 'none'; 
    });
  }
  urlcancelForm(valId,divId) {
    document.getElementById(divId).style.display = "none";
    document.getElementById("purchase-detail-info").style.display = "block";
  }
  urladdPayment(valId) {
    document.getElementById("purchase-payment-form").style.display = "block";
    document.getElementById("purchase-detail-info").style.display = "none";
    this.loadFormPurchase();
  }
  handlePaymentDate = date => {
    this.setState( prevState => {
      return {  
        ...prevState.purchase_payment_received_date, purchase_payment_received_date: date
      }
    }, () => { 
      this.validateField('purchase_payment_received_date', date)
    }) 
  }
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value
    }, () => { 
      this.validateField(name, value)
    });
  } 
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let paymentReceivedDateValid = this.state.paymentReceivedDateValid;
    let paymentReceivedPriceValid = this.state.paymentReceivedPriceValid;
        
    switch(fieldName) {
      case 'purchase_payment_received_date': 
        paymentReceivedDateValid = (value !== null && value !== '');
        fieldValidationErrors.purchase_payment_received_date = paymentReceivedDateValid ? '' : 'payment received date need to be filled';
        break;
      case 'purchase_payment_received_price': 
        if(value.trim().length > 0) {
          paymentReceivedPriceValid = Number(value.trim()) >= Number(this.state.purchase_price);
          fieldValidationErrors.purchase_payment_received_price = paymentReceivedPriceValid ? '' : 'payment price received need to be paid neither less than '+this.state.purchase_price;
        } else {
          paymentReceivedPriceValid = value.trim().length > 0;
          fieldValidationErrors.purchase_payment_received_price = paymentReceivedPriceValid ? '' : 'payment price received need to be filled';
        }
        break;
      default:
        break;
    }
    this.setState({
      formErrors: fieldValidationErrors,
      paymentReceivedDateValid: paymentReceivedDateValid,
      paymentReceivedPriceValid: paymentReceivedPriceValid,
    }, this.validateForm);
  }
  validateForm() {
    this.setState({formValid: this.state.paymentReceivedDateValid && this.state.paymentReceivedPriceValid });
  }
  errorClass(error) {
    return(error.length === 0 ? '' : 'has-error');
  }
  handleSubmit(e) {
    e.preventDefault();
    if(e.target.id === "form-purchase-payment") { 
      if(this.state.purchase_payment_received_date !== "" && (this.state.purchase_payment_received_price !== "" && this.state.purchase_payment_received_price >= this.state.purchase_price)) {
        document.getElementById('loading-area').style.display = 'block'; 
        var urlFetch2, datatext2;
        var purchasePaymentReceivedDate = this.state.purchase_payment_received_date;
        var purchasePaymentReceivedPrice = this.state.purchase_payment_received_price;
        var purchasePaymentVerifiedComment = this.state.purchase_payment_verified_comment; 
        if(this.textInputPaymentReceivedPrice.value !== '') { purchasePaymentReceivedPrice = this.textInputPaymentReceivedPrice.value; } 
        if(this.textInputPaymentVerifiedComment.value !== '') { purchasePaymentVerifiedComment = this.textInputPaymentVerifiedComment.value; } 
        
        datatext2 ='{"purchase_payment_received_date":"'+purchasePaymentReceivedDate+'","purchase_payment_received_price":"'+purchasePaymentReceivedPrice+'","purchase_payment_verified_comment":"'+purchasePaymentVerifiedComment+'","memberdoc_id":"'+this.state.memberdoc_id+'","purchasedoc_id":"'+this.state.purchasedoc_id+'","form_id":"'+e.target.id+'"}';
        urlFetch2 = global.ctconfig.urlLink+"/trainingpurchaseaddpayment";
        var data2 = JSON.parse(datatext2);
    
        fetch(urlFetch2, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey2,
          }, body : JSON.stringify(data2)
        }).then(res => {
          if(res.status === 200) return res.json()
        }).then( resJson => {
          document.getElementById('loading-area').style.display = 'none'; 
          if(resJson.status === '200') {
            document.getElementById("purchase-payment-form").style.display = "none";
            document.getElementById("purchase-detail-info").style.display = "block";
            this.loadFormPurchase();
          } else {
            console.log('Something happened wrong');
          }
        }).catch(err => err);
      } else {
        var arrfieldName2 = ['purchase_payment_received_date','purchase_payment_received_price'];
        var arrvalue2 = [this.state.purchase_payment_received_date,this.textInputPaymentReceivedPrice.value];
        for(var ck2 in arrfieldName2) {
          this.validateField(arrfieldName2[ck2], arrvalue2[ck2]);
        }
      }
    }
  }
  componentDidMount() {
    document.getElementById('loading-area').style.display = 'block'; 
    document.getElementById("errormsg").style.display = 'none';
    this.loadFormPurchase();
  }
  render() {
    return (
      <div className="main-container">
        <div className="mgT20">
        <div className="divhide w100p" id="errormsg"><Alert severity="error">{this.state.errmsg}</Alert></div>
          <div className="mgL20">
            <div className="st3Title">{this.state.member_fullname}</div>
            <div><h5 className="detailJoined">{this.state.member_email}</h5></div>
          </div>
          <div className="mgT20 mgL20">
            <div>
            <div className="mgT05 flex-container">
              <div className="w30p">Status Membership </div>
              <div className="mgL10"> <span className={this.state.member_plan === "Member"? "fontGreen textBold" : "textBold"}>{this.state.member_plan}</span> </div>
            </div>
            {
              this.state.member_expired_subscribe !== '' ? ( 
                <div className="mgT05 flex-container">
                    <div className="w30p">Expired On </div>
                    <div className="mgL10"> {this.state.member_expired_subscribe} </div>
                </div>
              ) : ''
            }
            <div className="mgT30 lineBottom">
              <div className="flex-container">
                <div className="w30p textBold">Detail Information</div>
                { 
                  // this.state.member_purchase_confirmation_date && (this.state.member_purchase_verified_date === '') ? (
                  (this.state.member_purchase_verified_date === '') ? (
                    <div className="w70p divRight">
                      <div className="mgR05 linkText cursor-on" onClick={this.urladdPayment.bind(this, this.state.purchasedoc_id)}><PostAddIcon className="mgR05" style={{fontSize:"15pt"}}></PostAddIcon><span style={{marginTop:"3px"}}>Verify Payment &raquo;</span></div>
                    </div>
                  ) : ('')
                }
              </div>
            </div>
            
            { /* --------- */ }
            <div className="mgT20 divHide" id="purchase-payment-form">
              <form id="form-purchase-payment" onSubmit={this.handleSubmit}>
                <div className="smallForm">
                  <div className="flex-container mgT10">
                    <div className="w40p"><label className="mgT10" htmlFor="purchase_payment_received_date">Payment Received On</label></div>
                    <div className="w60p">
                      <DatePicker
                        onChange={this.handlePaymentDate}
                        value={this.state.purchase_payment_received_date}
                        customStyles={{dateInput:{borderWidth: 0}}}
                      />
                      <div className="mgT05">{<FormErrors formErrors={this.state.formErrors.purchase_payment_received_date}/>}</div>
                      <div className="mgT05 notesText">
                        Input date when the payment has been received in the bank account.
                      </div>
                    </div>
                  </div>
                  <div className="flex-container mgT10">
                    <div className="w40p"><label className="mgT05" htmlFor="purchase_payment_received_price">Payment Received</label></div>
                    <div className="w60p flex-container">
                      <div className="mgR20 mgT10">IDR </div>
                      <div>
                        <input type="number" className="form-control" name="purchase_payment_received_price"
                          placeholder={this.state.purchase_payment_received_price}
                          value={this.state.purchase_payment_received_price}
                          onChange={this.handleUserInput}  
                          ref={(input) => this.textInputPaymentReceivedPrice = input}/> 
                        <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.purchase_payment_received_price}/>  }</div>
                        <div className="mgT05 notesText">
                          Input the amount of payment which has been received in the bank account.
                        </div>
                      </div>                                    
                    </div>
                  </div>
                  <div className="mgT30 textBold">Remarks</div>
                  <div className="mgT05">
                    <textarea name="purchase_payment_verified_comment" className="form-control" 
                      rows={4} cols={30} 
                      value={this.state.purchase_payment_verified_comment} 
                      onChange={this.handleUserInput} 
                      ref={(input) => this.textInputPaymentVerifiedComment = input}
                    />                                
                    <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.purchase_payment_verified_comment}/>  }</div>
                  </div>
                  <div className="mgT30 divCenter">
                    <div className="flex-container">
                    <button type="submit" className="ct-submitButton mgR20">Submit</button>
                    <div className="ct-submitButton cursor-on" onClick={this.urlcancelForm.bind(this, this.state.purchasedoc_id, "purchase-payment-form")}>Cancel</div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            { /* --------- */ }
                
            { /* --------- */ }
            <div className="mgT20" id="purchase-detail-info">
              <div className="mgT20">
                <div className="mgT10 flex-container">
                  <div className="w30p">Purchase Status</div>
                  <div className="mgL10">      
                    {
                      this.state.purchase_status === 'New Purchase' ? (
                        this.state.member_purchase_confirmation_date ? 'Verifying' : 'New Purchase'
                      ) : 'Completed'
                    }
                  </div>
                </div>
                <div className="mgT05 flex-container">
                  <div className="w30p">Request On </div>
                  <div className="mgL10 "> {this.state.purchase_date} </div>
                </div>
                <div className="mgT10 flex-container">
                  <div className="w30p">Workshop Name </div>
                  <div className="mgL10"> {this.state.course_title} </div>
                </div>                  
              </div>
              <div className="mgT30 textBold">Payment Information</div>
              <div className="mgL10">
                <div className="mgT10 flex-container">
                  <div className="w30p">Status Payment </div>
                  <div className="mgL10"> 
                    {
                      this.state.member_purchase_verified_byid !== '' ? (
                        <span className="textBold">Verified</span>
                      ) : 'Not Verified'
                    }
                  </div>
                </div>
                {
                  this.state.member_purchase_verified_byid !== '' ? (
                    <div className="mgT05 flex-container">
                      <div className="w30p">Verified On </div>
                      <div className="mgL10"> {this.state.member_purchase_verified_date} <span className="mgL10">by.</span> {this.state.member_purchase_verified_byname} </div>
                    </div>
                  ) : ''
                }
                <div className="mgT05 flex-container">
                  <div className="w30p">Total Price </div>
                  <div className="mgL10"> IDR {this.state.purchase_price.toLocaleString('en-US',{maximumFractionDigits:2})} </div>
                </div>
                <div className="mgT05 flex-container">
                  <div className="w30p">Payment Method </div>
                  <div className="mgL10"> 
                    <span>{this.state.payment_method} </span>
                    <br/><span>{this.state.payment_info}</span>
                    {(this.state.member_purchase_received_date !== '')?<span className="mgT10"><br/>Payment Received On : {this.state.member_purchase_received_date}</span>: ''}
                    {(this.state.member_purchase_received_price !== '')? <span><br/>with amount of payment is IDR {this.state.member_purchase_received_price}</span>: ''}
                  </div>
                </div>
              </div>
              {
                this.state.member_purchase_confirmation_date ? (
                  <>
                    <div className="mgT30 textBold">Payment Confirmation</div>
                    <div className="mgL10">
                      <div className="mgT05 flex-container">
                        <div className="w30p">Confirmation Date </div>
                        <div className="mgL10"> {DateTimeFormats(this.state.member_purchase_confirmation_date._seconds,"date-long")} </div>
                      </div>
                      <div className="mgT05 flex-container">
                        <div className="w30p">Remarks </div>
                        <div className="mgL10"> {this.state.member_purchase_confirmation_remarks} </div>
                      </div>
                      <div className="mgT05 flex-container">
                        <div className="w30p">Photo </div>
                        <div className="mgL10">
                          {
                            this.state.member_purchase_confirmation_photo && (this.state.member_purchase_confirmation_photo !== "Please upload a file") ?  (
                              <a href={this.state.member_purchase_confirmation_photo} target="_blank" rel="noreferrer">
                                <img width="150" src={this.state.member_purchase_confirmation_photo} alt=""/>
                              </a>
                            ) : (
                                <img width="150" src={NAimage} alt=""/>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </>
                ) : ''
              }
              </div>
            </div>
            {/* ---- */}

              </div>
            <div className="row-field mgT30 mgB30">
          </div>
        </div>
        <div id="loading-area" className="divHide loading"></div> 
      </div>
    );
  }
}
  
export default CTPurchaseDetail;