import React, { Component } from 'react';
import '../ctinc/ct-Style-2.css';
import CTHeader from './ct-fragmentheader';
import CTSidemenu from './ct-fragmentsidemenu';
import CTNA from './ct-fragmentna';
import EditIcon from '@material-ui/icons/Edit';
import DescriptionIcon from '@material-ui/icons/Description';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const authKey = global.ctconfig.authBearerKey+"##"+global.ctconfig.kindcode[35];
  
function urladdTutor() {
    window.location.href=global.ctconfig.urlHost+'/ctaddtutor';
}

class CTTutor extends Component {
  constructor(props) {
    super(props);
    this.state = {
        stTitle: '',
        pageShow: '10',
        keyword: '',
        styleTb: 'table',
        data: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadData = this.loadData.bind(this);
  }
  loadData() {
    document.getElementById('loading-area').style.display = 'block'; 
    var datatext ='{"keyword":"'+this.state.keyword+'","pageShow":"'+this.state.pageShow+'"}';
    var data = JSON.parse(datatext); 
    fetch(global.ctconfig.urlLink+"/tutorlist", {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
    }).then(response => {
        if(response.status === 200) {
            return response.json()
        } else {
            console.log('Something happened wrong');
        }
    }).then(resJson => {
        document.getElementById('loading-area').style.display = 'none'; 
        var styleTb = 'table';
        if(resJson.status === '200' && resJson.values.length > 0) { styleTb = 'table table-striped'; } 
        this.setState({
            styleTb: styleTb,
            data: resJson
        })
    }).catch(err => {
        document.getElementById('loading-area').style.display = 'none'; 
    });
  }
  handleChange(e) {
    this.setState({ [e.target.name] : e.target.value });
  }
  handleUserSelect = (e) => {
    const value = e.target.value;
    this.setState( prevState => {
        return {  
            ...prevState.pageShow, pageShow: value
        }
    })
  }    
  handleSubmit(e) {
    e.preventDefault();
    this.loadData();
  }
  componentDidMount() {
    this.loadData();
  }

  render() {
    var tutorList = [];
    if(this.state.data.values.length > 0 && this.state.data.status === '200') {
      for(var ls=0; ls < this.state.data.values.length; ls++) {
          var retResult = this.state.data.values[ls];
          var editURL = global.ctconfig.urlHost+"/cteditdetailtutor/"+retResult.docid;
          var viewURL = global.ctconfig.urlHost+"/ctviewtutor/"+retResult.docid;
          var iconEdit = <EditIcon className="iconGreyDis mgR20" title="edit" />;
          if(retResult.content.createById === localStorage.getItem('ctutk')) { iconEdit = <a href={editURL}><EditIcon className="iconGrey mgR20" title="edit" /></a>; }
          tutorList.push(<tr key={retResult.docid}>
              <th scope="row-field">{ls+1}</th>
              <td>
                <img className="header-picprofile" src={retResult.content.tutorPicture} alt=""/>
              </td>
              <td>{retResult.content.tutorName}</td>
              <td>{retResult.content.tutorEmail}</td>
              <td>
                  {iconEdit}
                  <a href={viewURL}><DescriptionIcon className="iconGrey mgR20" /></a>
              </td>
            </tr>)
      }
    } else {
        tutorList.push(<tr key="1">
            <td colSpan="5" className="textCenter"><div className="mgT20 mgB20">:: no record ::</div></td>
          </tr>)
    }
   return (
    <div className="main-container">
      <div  className="ct-header-main"><CTHeader/></div>
          <div className="flex-container">
              <div className="sidebar-main"><CTSidemenu/></div>
              <div className="main-content">
                <div className="ct-stTitle">Trainers</div>
                {((localStorage.getItem('ctulevel') === global.ctconfig.userlevel[0]) || (JSON.parse(localStorage.getItem('cturole'))[2] === "ON") || (JSON.parse(localStorage.getItem('cturole'))[3] === "ON") || (JSON.parse(localStorage.getItem('cturole'))[4] === "ON"))? 
                <div>
                  <div className="stIndiTitle">List of Trainers</div>
                  <div className="mgT30">
                      {/*--*/}
                      <div className="content-container card card-body">
                          <div className="row show">
                              <div className="col-6 divMiddle">
                              </div>
                              <div className="col-6 form-inline justify-content-end">
                                  <button className="ct-genButton mgL20" onClick={urladdTutor}><AddCircleOutlineIcon className="iconWhite mgR05"></AddCircleOutlineIcon>Add New Trainer</button>
                              </div>
                          </div>
                          <div className="row-field mgT20">
                              <table className={this.state.styleTb}>
                              <thead>
                                  <tr key="0">
                                  <th scope="col">#</th>
                                  <th scope="col">Picture</th>
                                  <th scope="col">Name</th>
                                  <th scope="col">Email</th>
                                  <th scope="col" style={{width:"180px"}}>Action</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  {tutorList}
                              </tbody>
                              </table>
                          </div>
                          <div className="row mt-3">
                              
                          </div>
                      </div>
                      {/*--*/}
                  </div>
                </div>
                :   
                <div><CTNA/></div>
                }
              </div>
          </div>
          <div id="loading-area" className="divHide loading"></div>
    </div>
   );
  }
}
 
export default CTTutor;