import React, { Component } from 'react';
import { GetText, SearchList } from '../helper';
import { Container, InputGroup, FormControl, Tab, Nav } from 'react-bootstrap';
import { FaSearch, FaArrowDown } from 'react-icons/fa';
import LibraryBlock from './library/Block';
import LibraryList from './library/List';
import '../config/global';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[9];

export default class LibraryBrowse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      libraryList: [],
      libraryFeatured: [],
      libraryCategories: []
    }
  }

  componentDidMount() {
    var dataText ='{"keyword":"","pageShow":"10","lang":"'+localStorage.getItem('language')+'","form_id":"pubonly"}';
    var dataSend = JSON.parse(dataText);

    fetch(global.config.urlLink+"/librarylist", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey
      }, 
      body : JSON.stringify(dataSend)
    })
    .then(response => {
      if (response.status === 200) {
        return response.json();
      } else {
        console.log('Something happened wrong');
      }
    })
    .then(resultJson => {
      var resultData = [];
      var inum=0;
      for (var i in resultJson.values) {
        var content = resultJson.values[i].content;
          content.id = resultJson.values[i].docid;
          resultData.push(content);
          if (this.state.libraryCategories.indexOf(content.categoryName) < 0) {
            this.state.libraryCategories.push(content.categoryName);
          }
          inum++;
          if(inum === resultJson.values.length) {
            this.setState({
              libraryList: resultData
            });
          }
      }
    });
  }

  render() {
    return (
      <div className="browse-library">
        {
          this.state.libraryList.length > 0 ? (
            <section>
              <Container>
                <h3>{GetText() ? "Library Terbaru" : "Latest Libraries"}</h3>
                <div className="row py-4 mb-5">
                  {
                    this.state.libraryList
                    .sort((a, b) => b.createDate._seconds - a.createDate._seconds)
                    .slice(0,4)
                    .map((libraryItem, i) => {
                      return (
                        <div className="col-md-3" key={i}>
                          <LibraryBlock value={libraryItem}/>
                        </div>
                      )
                    })
                  }
                </div>
              </Container>
              <div className="separator mt-0"><div className="icon"><FaArrowDown/></div></div>
            </section>
          ) : ''
        }
        {
          this.state.libraryCategories.length > 0 ? (
            <section>
              <Container>
                <div className="row py-4">
                  <Tab.Container defaultActiveKey="category-all">
                    <div className="col-md-3">
                      <div className="sidebar-tab sticky-top">
                        <h3 className="mb-4">{GetText() ? "Pencarian" : "Search"}</h3>
                        <InputGroup className="mb-5">
                          <FormControl
                            placeholder={GetText() ? "Ketik di sini..." : "Type here..."}
                            aria-label="Library"
                            aria-describedby="search"
                            id="search"
                            onKeyUp={SearchList}
                         />
                          <InputGroup.Append>
                            <InputGroup.Text><FaSearch/></InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                        <h3 className="mb-4">{GetText() ? "Kategori" : "Category"}</h3>
                        <Nav variant="pills" className="nav-scroll flex-column">
                          <Nav.Item key="all">
                            <Nav.Link eventKey="category-all">{GetText() ? "Semua" : "All"}</Nav.Link>
                          </Nav.Item>
                          {
                            this.state.libraryCategories
                            .sort((a, b) => { return (a < b) ? -1 : ((a > b) ? 1 : 0) })
                            .map((libraryCategory, i) => {
                              return (
                                <Nav.Item key={i}>
                                  <Nav.Link eventKey={"category-" + i}>{libraryCategory}</Nav.Link>
                                </Nav.Item>
                              )
                            })
                          }
                        </Nav>
                      </div>
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-8 bg-light p-5 br-8">
                      <h3 className="mb-4">{GetText() ? "Daftar Library" : "Library List"}</h3>
                      <Tab.Content>
                        <Tab.Pane eventKey="category-all" key="all">
                          {
                            this.state.libraryList
                            .sort((a, b) => b.createDate._seconds - a.createDate._seconds)
                            .map((libraryItem, i) => {
                              return (
                                <LibraryList value={libraryItem} key={i}/>
                              )
                            })
                          }
                        </Tab.Pane>
                        {
                          this.state.libraryCategories
                          .sort((a, b) => { return (a < b) ? -1 : ((a > b) ? 1 : 0) })
                          .map((libraryCategory, i) => {
                            return (
                              <Tab.Pane eventKey={"category-" + i} key={i}>
                                {
                                  this.state.libraryList
                                  .sort((a, b) => b.createDate._seconds - a.createDate._seconds)
                                  .filter(libraryItem => libraryItem.categoryName === libraryCategory)
                                  .map((libraryItem, j) => {
                                    return (
                                      <LibraryList value={libraryItem} key={j}/>
                                    )
                                  })
                                }
                              </Tab.Pane>
                            )
                          })
                        }
                      </Tab.Content>
                    </div>
                  </Tab.Container>
                </div>
              </Container>
            </section>
          ) : ''
        }
      </div>
    );
  }
}