import React, { Component } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { GetText } from '../../helper';

var sticky = '';

export default class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: ''
    }
    this.setLanguage = this.setLanguage.bind(this);
  }
  setLanguage(language) {
    this.setState({
      language: language
    }, () => {
      localStorage.setItem('language', language);
      var divs = document.getElementsByClassName('nav-language');
      for (var i = 0; i < divs.length; i++) {
        if (divs[i].id === 'nav-' + language.toLowerCase()) {
          divs[i].classList.add('active');
        } else {
          divs[i].classList.remove('active');
        }
      }
    });
    if (language !== localStorage.getItem('language')) {
      window.location.reload();
    }
  }

  componentDidMount() {
    sticky = document.getElementById('main-menu').offsetTop;
    window.addEventListener('scroll', this.showShadow);
  
    var language = localStorage.getItem('language');
    if(language === undefined || language === "undefined" || language === null || language === "null") { language = ""; }
    if (language !== "") {
      this.setLanguage(language);
    } else {
      fetch('https://ipapi.co/json/', {
        method: "GET",
      }).then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          console.log("Something happened wrong");
        }
      }).then((resultJson) => {
        var result = resultJson.country_name === 'Indonesia' ? 'Indonesian' : 'English';
        this.setLanguage(result);
      });
    }
  }

  showShadow = () => {
    if (window.pageYOffset > sticky) {
      document.getElementById('main-menu').style.boxShadow = "1px 6px 5px 2px rgba(99,99,99,0.12)";
      document.getElementById('main-menu').style.webkitBoxShadow = "1px 6px 5px 2px rgba(99,99,99,0.12)";
      document.getElementById('main-menu').style.mozBoxShadow = "1px 6px 5px 2px rgba(99,99,99,0.12)";
    }
    else {
      document.getElementById('main-menu').style.boxShadow = "none";
      document.getElementById('main-menu').style.webkitBoxShadow = "none";
      document.getElementById('main-menu').style.mozBoxShadow = "none";
    }
  }

  render() {
    return (
      <section className="menu-guest">
        <Navbar collapseOnSelect expand="lg" bg="white" variant="light" sticky="top" id="main-menu">
          <Container>
            <Navbar.Brand href="/">
              <img src={process.env.PUBLIC_URL + "/img/logo-novaio.png"} width="120" alt="Novaio Academy"/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">&nbsp;</Nav>
              <Nav className="navbar-language">
                <Nav.Link className="nav-language" href="#!" onClick={() => this.setLanguage('English')} id="nav-english">
                  <img src={process.env.PUBLIC_URL + "/img/flag-en.png"} className="br-4" alt="English"/>
                  <label>
                    <strong>EN</strong>
                  </label>
                </Nav.Link>
                <Nav.Link className="nav-language" href="#!" onClick={() => this.setLanguage('Indonesian')} id="nav-indonesian">
                  <img src={process.env.PUBLIC_URL + "/img/flag-id.png"} className="br-4" alt="Indonesia"/>
                  <label>
                    <strong>ID</strong>
                  </label>
                </Nav.Link>
              </Nav>
              <Nav className="d-flex justify-content-center">
                <a href="/login" className="btn btn-sm btn-light mr-2">{GetText() ? "Masuk" : "Login"}</a>
                <a href="/login" className="btn btn-sm btn-primary">{GetText() ? "Daftar Gratis!" : "Free Membership!"}</a>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </section>
    );
  }
}