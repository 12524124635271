import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

export default class PrivacyPolicy extends Component {
  
  render() {
    return (
      <div className="privacy-policy">
        <Container>
          <h1 className="mb-4">Privacy Policy</h1>
          <p>Welcome to Novaio Academy's Privacy Policy. This policy describes how we collect, use, and protect your personal information when you use our website or any of our services. By using our website or any of our services, you agree to the terms of this policy.</p>
          <h4 className="my-2">Personal Information We Collect</h4>
          <p>We collect personal information when you create an account, enroll in a course, or contact us for support. This information may include your name, email address, phone number, and payment information.</p>
          <h4 className="my-2">How We Use Your Personal Information</h4>
          <p>We use your personal information to provide our services, process payments, and communicate with you. We may also use your information for marketing purposes, such as sending you promotional emails or newsletters.</p>
          <h4 className="my-2">How We Protect Your Personal Information</h4>
          <p>We take the security of your personal information seriously and use industry-standard encryption to protect it. We also regularly monitor our systems for any suspicious activity and have strict data protection policies in place.</p>
          <h4 className="my-2">Sharing Your Personal Information</h4>
          <p>We may share your personal information with third-party service providers, such as payment processors or email marketing platforms, to help us provide our services. We do not sell or rent your personal information to third parties.</p>
          <h4 className="my-2">Cookies and Other Tracking Technologies</h4>
          <p>We use cookies and other tracking technologies to improve your user experience and to analyze how our website is being used. You can opt-out of cookies by changing your browser settings.</p>
          <h4 className="my-2">Links to Third-Party Websites</h4>
          <p>Our website may contain links to third-party websites that are not owned or controlled by Novaio Academy. We are not responsible for the privacy practices of these websites and encourage you to read their privacy policies.</p>
          <h4 className="my-2">Children's Privacy</h4>
          <p>Our website and services are not intended for children under the age of 13. We do not knowingly collect personal information from children under 13.</p>
          <h4 className="my-2">Changes to This Privacy Policy</h4>
          <p>We may update this privacy policy from time to time to reflect changes in our practices or to comply with legal requirements. We will notify you of any significant changes by email or by posting a notice on our website.</p>
          <h4 className="my-2">Your Rights</h4>
          <p>You have the right to access, modify, or delete your personal information at any time. You can do this by logging into your account or by contacting us directly.</p>
          <h4 className="my-2">Contact Us</h4>
          <p>If you have any questions or concerns about our privacy policy, please contact us.</p>
          <p>Last updated: 12 August 2021</p>
        </Container>
      </div>
    )
  }
}