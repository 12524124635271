import React, { Component } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import '../ctinc/ct-Style-2.css';
import '../ctinc/ct-Global';
import CTHeader from './ct-fragmentheader';
import CTSidemenu from './ct-fragmentsidemenu';
import defCompanyLogo from '../../assets/pic-company.png';
import DateTimeFormats from '../../../config/date-time-format';
import Popup from '../ctinc/popup';
import '../ctinc/popup.css';
import CTUserCompanyDetail from './ct-popusercompany-detail';
import CTUserCompanyEdit from './ct-popusercompany-edit';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DescriptionIcon from '@material-ui/icons/Description';
import EditIcon from '@material-ui/icons/Edit';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { ToggleArrow } from '../../../helper';

const authKey = global.ctconfig.authBearerKey+"##"+global.ctconfig.kindcode[45];

function getCompanyDetail(id) {
  return new Promise((resolve) => {
    fetch(global.ctconfig.urlLink+"/trainingcompanyview/"+id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey
      }
    }).then(response => {
      if(response.status === 200) {
        return response.json();
      } else {
        console.log('Something happened wrong');
      }
    }).then(resultJson => {
      if(resultJson.status === '200') { 
        resolve(resultJson.values);
      } else {
        resolve([]);
      }
    });
  });
}
function getCompanyListUser(val) {
  return new Promise((resolve) => {
    var dataSend = {
      "companydomain": val
    }
    fetch(global.ctconfig.urlLink+"/traininguserclient/", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey
      }, body: JSON.stringify(dataSend),
    }).then(response => {
      if(response.status === 200) {
        return response.json();
      } else {
        console.log('Something happened wrong');
      }
    }).then(resultJson2 => {
      if(resultJson2.status === '200') { 
        var userList = [];
        var libraryList = [];
        var totalUsers = 0, activeUsers = 0, takenUsers = 0;
            
        var userJson = resultJson2.values.memberListData;
        if(userJson.length > 0) {
          for (var u = 0; u < userJson.length; u++) {
            var userResult = userJson[u].content[0].maincontent;
            if(userResult.flagActive === true) { totalUsers++; }
            if(userResult.flagActive === true && userResult.lastLogin !== undefined) { activeUsers++; }
            if(userResult.flagActive === true && userResult.lastLogin !== undefined && userJson[u].subcontent3.length > 0) { takenUsers++; }
            userList.push(
              {
                docId: userJson[u].docid,
                memberName: userResult.memberName,
                memberEmail: userResult.memberEmail,
                memberCompany: userResult.memberCompany,
                expiredDate: userResult.expiredSubscribeMember,
                lastLogin: userResult.lastLogin,
                registeredDate: userResult.registeredDate,
                userLevel: userResult.userLevel,
                flagActive: userResult.flagActive,
              }
            );
            var objIndex = "";
            var libraryResult = userJson[u].subcontent3;
            if(libraryResult.length > 0) {
              for(var lr=0; lr<libraryResult.length; lr++) {
                var enroll = libraryResult[lr].subcontent;
                objIndex = libraryList.findIndex((el) => el.libId === enroll.libraryId);
                
                if(objIndex < 0) {
                  libraryList.push({
                    libId: enroll.libraryId,
                    title: enroll.libraryTitle,
                    countEnroll: 1,
                    countComplete: enroll.completion === 100? 1 : 0
                  })
                } else {
                  var currLibrary = libraryList[objIndex];
                  libraryList[objIndex].countEnroll = Number(currLibrary.countEnroll)+1;
                  if(enroll.completion === 100) { 
                    libraryList[objIndex].countComplete = Number(currLibrary.countComplete)+1;
                  }
                }
              }
            }

            if(u === (userJson.length-1)) {
              resolve([{
                totalUsers: totalUsers,
                activeUsers: activeUsers,
                takenUsers: takenUsers,
                userList: userList,
                libraryList: libraryList
              }])
            }
          }
        } else {
          resolve([{
            totalUsers: totalUsers,
            activeUsers: activeUsers,
            takenUsers: takenUsers,
            userList: userList,
            libraryList: libraryList
          }])
        }
      } else {
        resolve([]);
      }
    });
  });
}

class CTCompanyTrainingsDetail extends Component {
  constructor (props) {
    super(props);
    this.state = {
      stTitle: "",      
      company_id: "",
      company_name: "",
      company_domain: "",
      company_logo: "",
      company_logo_shape: "",
      expired_date: "",
      is_expired: true,
      created_on: "",
      created_by: "",
      userList: [],
      detailList: [],
      detailRows: [],
      totalUsers: 0,
      activeUsers: 0,
      takenUsers: 0,
      isOpen: false,
      otherContent: "",
      isOpen2: false,
      otherContent2: "",
    }
		this.urleditCompany = this.urleditCompany.bind(this);		
  }

  async componentDidMount() {
    document.getElementById('loading-area').style.display = 'block';
    
    var arrString = window.location.pathname;
    var arrUrl = [];
    arrUrl = arrString.split('/');
    var company_id = arrUrl[3];
    /* ======== */
    var userList = [], detailList = [];
		var companyLogo = '', expiredDate = '', companyLogoShape = '';
    var companyId = '', companyName = '', companyDomain = '', companyCreateOn = '', companyCreateBy = '';
    var totalUsers = 0, activeUsers = 0, takenUsers = 0;
    var result1 = await getCompanyDetail(company_id);
    if(result1) {
      var isExpired = true;
        if(result1[0].content[0].maincontent.companyLogo !== undefined && result1[0].content[0].maincontent.companyLogo !== '') {
          companyLogo = result1[0].content[0].maincontent.companyLogo;
        } else { 
          companyLogo = defCompanyLogo; 
        }
        if(result1[0].content[0].maincontent.companyLogoShape !== undefined && result1[0].content[0].maincontent.companyLogoShape !== '') {
          companyLogoShape = result1[0].content[0].maincontent.companyLogoShape;
        } 
        if(result1[0].content[0].maincontent.expiredSubscription !== undefined && result1[0].content[0].maincontent.expiredSubscription !== "") {
          var today = new Date().getTime() / 1000;
          if (parseInt(result1[0].content[0].maincontent.expiredSubscription._seconds) > Math.floor(parseInt(today))) {
            isExpired = false;
          }
          expiredDate = DateTimeFormats(result1[0].content[0].maincontent.expiredSubscription._seconds,"date-short");
        }
        companyId = result1[0].docid;
        companyName = result1[0].content[0].maincontent.companyName; 
        companyDomain = result1[0].content[0].maincontent.companyDomain;
        companyCreateOn = DateTimeFormats(result1[0].content[0].maincontent.createDate._seconds,"date-long");
        companyCreateBy = result1[0].content[0].maincontent.createByName;

        /* ======== */
        var result2 = await getCompanyListUser(companyDomain);
        if(result2 && result2.length > 0) {
          userList=result2[0].userList;
          detailList=result2[0].libraryList;
          totalUsers=result2[0].totalUsers;
          activeUsers=result2[0].activeUsers;
          takenUsers=result2[0].takenUsers;
        }
    }
    
    /* ======== */
    if(result1 && result2) {
      this.setState({
        stTitle: 'Detail Company',
        company_id: companyId,
        company_name: companyName,
        company_domain: companyDomain,
        company_logo: companyLogo,
        company_logo_shape: companyLogoShape,
        expired_date: expiredDate,
        is_expired: isExpired,
        created_on: companyCreateOn,
        created_by: companyCreateBy,
        userList: userList,
        detailList: detailList,
        totalUsers: totalUsers,
        activeUsers: activeUsers,
        takenUsers: takenUsers,        
        isOpen: false,
        otherContent: ''
      })
      document.getElementById('loading-area').style.display = 'none';
    } else {
      document.getElementById('loading-area').style.display = 'none';
    }
  }

  handleBack = () => {
    window.location.href=global.ctconfig.urlHost+'/ctcompanytraining';
  }

	urleditCompany(company_id) {
		window.location.href = global.ctconfig.urlHost + "/cteditcompanytraining/" + company_id;
	}

  urladdMember() {
    window.location.href=global.ctconfig.urlHost+'/ctaddusertraining';
  }

  urldetailUser(member_id) {
    this.setState({
      isOpen: true,
      otherContent: '{"company_id":"' + this.state.company_id + '","member_id":"' + member_id + '"}',
    })  
  }

  urleditUser(member_id) {
    this.setState({
      isOpen2: true,
      otherContent2: '{"company_id":"' + this.state.company_id + '","member_id":"' + member_id + '"}',
    })  
  }

  togglePopClose() {
    this.setState({
      isOpen: false,
    })
  }

  togglePopClose2() {
    this.setState({
      isOpen2: false,
    })
  }

  render() {
    var userList = [];
    this.state.userList.sort((a, b) => b.registeredDate._seconds - a.registeredDate._seconds);
    if(this.state.userList !== undefined && this.state.userList.length > 0) {
      var number = 0
      for(var ls=0; ls < this.state.userList.length; ls++) {
        var userData = this.state.userList[ls];
        if (userData.memberCompany === this.state.company_domain) {
          number++;
          var expired_date = userData.expiredDate !== undefined ? DateTimeFormats(userData.expiredDate._seconds,"date-long") : '';
          var has_joined = userData.lastLogin !== undefined ? true : false;
          userList.push(
            <tr key={number}>
              <th scope="row">{number}</th>
              <td>
                {userData.memberName}
                {userData.userLevel === 'Admin' && (<label className="subs-block-status bg-info ml-2 mb-0">Admin</label>)}
              </td>
              <td>{userData.memberEmail}</td>
              <td>{expired_date}</td>
              <td>{has_joined === true ? DateTimeFormats(userData.lastLogin._seconds,"date-long") : '-'}</td>
              <td>{userData.flagActive === true ? (<label className="subs-block-status bg-success ml-2 mb-0">Active</label>) : (<label className="subs-block-status bg-danger ml-2 mb-0">Inactive</label>)}</td>
              <td>
                <DescriptionIcon className="iconGrey cursor-on mgR20" onClick={this.urldetailUser.bind(this, userData.docId)}></DescriptionIcon>
                <EditIcon className="iconGrey cursor-on" onClick={this.urleditUser.bind(this, userData.docId)}></EditIcon>
              </td>
            </tr>
          )
        }
      }
    } else {
      userList.push(
        <tr key="0">
          <td colSpan="7" className="textCenter"><div className="mgT20 mgB20">:: no record ::</div></td>
        </tr>
      )
    }
  
    // =======
    var detailList = [];
    this.state.detailList.sort((a, b) => b.title - a.title);
    if(this.state.detailList !== undefined && this.state.detailList.length > 0) {
      for(var ls2=0; ls2 < this.state.detailList.length; ls2++) {
        var detailData = this.state.detailList[ls2];
        var percentage = Math.round((detailData.countComplete/detailData.countEnroll)*100);
        detailList.push(
            <tr key={ls2}>
              <th scope="row">{ls2+1}</th>
              <td>{detailData.title}</td>
              <td>{detailData.countEnroll}</td>
              <td>{detailData.countComplete}</td>
              <td>{percentage}%</td>
            </tr>
          )
        
      }
    } else {
      detailList.push(
        <tr key="0">
          <td colSpan="5" className="textCenter"><div className="mgT20 mgB20">:: no record ::</div></td>
        </tr>
      )
    }
    // =======
    return (
    <div className="main-container">
      <div className="ct-header-main"><CTHeader/></div>
        <div className="flex-container">
          <div className="sidebar-main"><CTSidemenu/></div>
          <div className="main-content">
            <div className="ct-stTitle">Companies</div>
            <div className="stIndiTitle">Training Booking Company Detail Information</div>
            <div className="mgT30">
              <div className="content-container card card-body">
                <div className="flex-container">
                  <div className="w70p">
                    
                  </div>
                  <div className="divRight w30p"><div><button className="backButton" onClick={this.handleBack}>&#9664; back</button></div></div>
                </div>
                <div id="profile-detail" className="mgT20">
                  <div className="row">
                    <div id="profile-info" className="col-4">
                      {
                        this.state.company_logo !== '' ? (
                          this.state.company_logo_shape === 'round'? 
                            <div className="mgB30"><img src={this.state.company_logo} alt={this.state.company_name} className="memberPhoto"/></div>
                          : <div className="mgB30"><img src={this.state.company_logo} alt={this.state.company_name} style={{maxWidth:"250px"}}/></div> 
                          
                        ) : ''
                      }
                    </div>
                    <div className="col-8">
                      <div><h4>{this.state.company_name}</h4></div>
                      <div><label className="detailJoined">Created On {this.state.created_on}, by { this.state.created_by }</label></div>
                      <div className="mgT20 flex-container">
                          <div className="w20p">Company Domain</div>
                          <div className="w80p"><span className="mgR10">:</span>{this.state.company_domain}</div>
                      </div>
                      <div className="mgT05 flex-container">
                          <div className="w20p">Expired On </div>
                          <div className="w80p"><span className="mgR10">:</span>{this.state.expired_date}</div>
                      </div>
                      
                      <div className="mgT10 flex-container">
                        <div className="w70p">
                          {
                              this.state.expired_date === true ? (
                                <label className="subs-block-status bg-danger">Expired</label> 
                              ) : (
                                <label className="subs-block-status bg-success">Active</label> 
                              )
                          }
                          </div>
                          <div className="w30p divRight">
                                <div className="linkText cursor-on" onClick={() => this.urleditCompany(this.state.company_id)}><EditIcon className="mgR05" style={{fontSize:"14pt"}} ></EditIcon><span style={{marginTop:"3px"}}>Edit Info &raquo;</span></div>
                          </div>
                      </div>
                    </div>
                  </div>
                  {/* ======= */}
                  <div className="mgT30" id="summary-area">
                    <div className="flex-container">
                      <div className="w70p st3Title divMiddle">Enroll Summary</div>
                      <div className="w30p divRight"></div>
                    </div>
                    <div className="mgT20">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="card card-progress blue">
                            <div className="card-header text-center">Total Member</div>
                            <div className="card-body">
                              <div className="progress progress100">
                                <span className="progress-left"><span className="progress-bar"></span></span>
                                <span className="progress-right"><span className="progress-bar"></span></span>
                                <div className="progress-value">{this.state.totalUsers}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="card card-progress green">
                            <div className="card-header text-center">Active Member</div>
                            <div className="card-body">
                              <div className={"progress progress" + (Math.floor(this.state.activeUsers/this.state.totalUsers*10)*10)}>
                                <span className="progress-left"><span className="progress-bar"></span></span>
                                <span className="progress-right"><span className="progress-bar"></span></span>
                                <div className="progress-value">{Math.floor(this.state.activeUsers/this.state.totalUsers*100)}%</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="card card-progress red">
                            <div className="card-header text-center">Member Progress</div>
                            <div className="card-body">
                              {/* <div className={"progress progress" + (Math.floor(this.state.takenUsers/this.state.activeUsers*10)*10)}> */}
                              <div className={"progress progress" + Math.floor(this.state.takenUsers/10)*10}>
                                <span className="progress-left"><span className="progress-bar"></span></span>
                                <span className="progress-right"><span className="progress-bar"></span></span>
                                {/* <div className="progress-value">{Math.floor(this.state.takenUsers/this.state.activeUsers*100)}%</div> */}
                                <div className="progress-value">{this.state.takenUsers}%</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ======= */}
                  <div className="mgT30" id="detailinfo-area">
                  <Accordion className="card-toggle mt-4 text-left">
                    <Card id="accordion-0">
                      <Accordion.Toggle as={Card.Header} eventKey="0" onClick={()=> ToggleArrow(0)}>
                        <h6>Library List</h6>
                        <KeyboardArrowRightIcon className="accordion-toggle toggle-close"/>
                        <KeyboardArrowDownIcon className="accordion-toggle toggle-open d-none"/>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0" id="collapse-0">
                        <Card.Body>
                          <div id="library-area">
                            <div className="mgT20">
                              <table className="table table-hover">
                                <thead className="thead-light">
                                  <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Library Name</th>
                                    <th scope="col">Total Enrolled</th>
                                    <th scope="col">Total Completed</th>
                                    <th scope="col">Completion</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {detailList}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card id="accordion-1">
                      <Accordion.Toggle as={Card.Header} eventKey="1" onClick={()=> ToggleArrow(1)}>
                        <h6>User List</h6>
                        <KeyboardArrowRightIcon className="accordion-toggle toggle-close"/>
                        <KeyboardArrowDownIcon className="accordion-toggle toggle-open d-none"/>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="1" id="collapse-1">
                        <Card.Body>
                          <div id="user-area">
                            <div className="flex-container">
                              <div className="w70p st3Title divMiddle"></div>
                              <div className="w30p divRight">
                                <button className="ct-subgenButton mgL20" onClick={this.urladdMember}><AddCircleOutlineIcon className="iconWhite mgR05"></AddCircleOutlineIcon>add new user</button>
                              </div>
                            </div>
                            <div className="mgT20">
                              <table className="table table-hover">
                                <thead className="thead-light">
                                  <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">User</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Expired Date</th>
                                    <th scope="col">Last Login</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {userList}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                  </div>
                 
                </div>
              </div>
              <div>
                {
                  this.state.isOpen && <Popup 
                    content={<CTUserCompanyDetail/>}
                    id={this.state.company_id} 
                    title={"History User Enrollment"}
                    others={this.state.otherContent}
                    styles={"70%"}
                    handleClose={this.togglePopClose.bind(this, this.state.company_id)}
                  />
                }
                {
                  this.state.isOpen2 && <Popup 
                    content={<CTUserCompanyEdit/>}
                    id={this.state.company_id} 
                    title={"Edit User Access"}
                    others={this.state.otherContent2}
                    styles={"70%"}
                    handleClose={this.togglePopClose2.bind(this, this.state.company_id)}
                  />
                }
              </div>
            </div>
          </div>
        </div>
        <div id="loading-area" className="divHide loading"></div>
      </div>
    );
  }
}

export default CTCompanyTrainingsDetail;