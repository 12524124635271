import React, { Component } from 'react';
import '../ctinc/ct-Style.css';
import CTHeader from './ct-fragmentheader';
import CTSidemenu from './ct-fragmentsidemenu';
import CTUC from './ct-fragmentuc';


class CTDashboard extends Component {
 constructor(props) {
   super(props);
   this.state = {
     done: undefined
   };
 }
 
 render() {
   return (
    <div className="main-container">
      <div  className="ct-header-main"><CTHeader/></div>
      <div className="flex-container">
          <div className="sidebar-main"><CTSidemenu/></div>
          <div className="main-content">
            <div className="ct-stTitle"></div>
            <div><CTUC/></div>
          </div>
      </div>
    </div>
    
   );
 }
}
 
export default CTDashboard;