import React from "react";
import CancelIcon from '@material-ui/icons/Close';

const Popup = props => {
  return (
    <div className="popup-box">
      <div className="box" style={{width:props.styles}}>
        <div className="header-pop">
          <div className="w90p"><h4>{props.title}</h4></div>
          <div className="w10p divRight"><CancelIcon className="close-icon cursor-on" style={{fontSize:"22px"}} onClick={props.handleClose}></CancelIcon></div>
        </div>
        <div>{props.content} </div>
        <div className="divHide" id="contentid">{props.id}</div>
        <div className="divHide" id="contentoth">{props.others}</div>
      </div>
    </div>
  );
};
 
export default Popup;