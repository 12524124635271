import React, { Component } from "react";
import "../ctinc/ct-Style-2.css";
import "../ctinc/ct-Style.css";
import "../ctinc/ct-Global";
import CTHeader from "./ct-fragmentheader";
import CTSidemenu from "./ct-fragmentsidemenu";
import DescriptionIcon from "@material-ui/icons/Description";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import EditIcon from '@material-ui/icons/Edit';
import DateTimeFormats from "../../../config/date-time-format";

const authKey =	global.ctconfig.authBearerKey + "##" + global.ctconfig.kindcode[13];

class CTCompanyTrainings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			stTitle: "",
			pageShow: "10",
			styleTb: "table",
			userList: [],
			companyList: [],
		};
		this.loadData = this.loadData.bind(this);
		this.urladdCompany = this.urladdCompany.bind(this);
		this.urleditCompany = this.urleditCompany.bind(this);
	}

	async componentDidMount() {
		var userText = '{"keyword":"","pageShow":""}';
		var userSend = JSON.parse(userText);
		var userGet = await fetch(global.ctconfig.urlLink + "/traininguserlist", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "*",
				Authorization: authKey,
			},
			body: JSON.stringify(userSend),
		});
    var userJson = await userGet.json();
		if ((userJson.status === "200") && (userJson.values.length > 0)) {
			var userList = [];
			for (var ls = 0; ls < userJson.values.length; ls++) {
				var userResult = userJson.values[ls];
				userList.push(
					{
						docId: userResult.docId,
						memberName: userResult.content.memberName,
						memberEmail: userResult.content.memberEmail,
						memberCompany: userResult.content.memberCompany,
						expiredDate: userResult.content.expiredSubscribeMember,
            lastLogin: userResult.content.lastLogin,
					}
				)
			}
			this.setState({
				userList: userList,
			});
		}
		this.loadData();
	}

	loadData() {
		document.getElementById("loading-area").style.display = "block";
		var companyText = '{"keyword":"company","pageShow":"' + this.state.pageShow + '"}';
		var companySend = JSON.parse(companyText);
		fetch(global.ctconfig.urlLink + "/trainingcompanylist", {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "*",
				Authorization: authKey,
			},
			body: JSON.stringify(companySend),
		})
    .then((companyResponse) => {
      if (companyResponse.status === 200) {
        return companyResponse.json();
      } else {
        console.log("Something happened wrong");
      }
    })
    .then((companyJson) => {
      document.getElementById("loading-area").style.display = "none";
      var styleTb = "table";
      if (companyJson.status === "200" && companyJson.values.length > 0) {
        styleTb = "table table-striped";
      }			
			var companyList = [];
			if (companyJson.values.length > 0 && companyJson.status === "200") {
				companyJson.values.sort((a, b) => b.content.createDate._seconds - a.content.createDate._seconds);
				for (var ls = 0; ls < companyJson.values.length; ls++) {
					var companyResult = companyJson.values[ls];
					var users = 0;
					for (var us = 0; us < this.state.userList.length; us++) {
						if (this.state.userList[us].memberCompany === companyResult.content.companyDomain) {
							users++;
						}
					}
					companyList.push(
						{
							docId: companyResult.docid,
							companyName: companyResult.content.companyName,
							companyDomain: companyResult.content.companyDomain,
							expiredDate: DateTimeFormats(companyResult.content.expiredSubscription._seconds, "date-long"),
							users: users
						}
					);
				}
			}			
      this.setState({
        styleTb: styleTb,
        companyList: companyList,
      });
    })
    .catch((err) => {
      document.getElementById("loading-area").style.display = "none";
      console.log(err);
    });
	}

	urladdCompany() {
		window.location.href = global.ctconfig.urlHost + "/ctaddcompanytraining";
	}

	urlviewCompany(company_id) {
		window.location.href = global.ctconfig.urlHost + "/ctviewcompanytraining/" + company_id;
	}

	urleditCompany(company_id) {
		window.location.href = global.ctconfig.urlHost + "/cteditcompanytraining/" + company_id;
	}

	render() {
		return (
			<div className="main-container">
				<div className="ct-header-main"><CTHeader /></div>
				<div className="flex-container">
					<div className="sidebar-main"><CTSidemenu /></div>
					<div className="main-content">
						<div className="ct-stTitle">Companies</div>
						<div className="stIndiTitle">List of Training Booking Companies</div>
						<div className="mgT30">
							<div className="content-container card card-body">
								<div className="row show">
									<div className="col-6 divMiddle"></div>
									<div className="col-6 form-inline justify-content-end">
										<button className="ct-genButton mgL20" onClick={this.urladdCompany}><AddCircleOutlineIcon className="iconWhite mgR05"></AddCircleOutlineIcon> Add New Company</button>
									</div>
								</div>
								<div className="row mgT20">
									<table className={this.state.styleTb}>
										<thead>
											<tr>
												<th scope="col">#</th>
												<th scope="col">Company Name</th>
												<th scope="col">Company Domain</th>
												<th scope="col">Expiry Date</th>
												<th scope="col">Users</th>
												<th scope="col" style={{width: "150px"}}>Action</th>
											</tr>
										</thead>
										<tbody>
											{
												this.state.companyList.length > 0 ? (
													<>
														{
															this.state.companyList.map((companyItem, i) => {
																return(
																	<tr key={companyItem.docId}>
																		<th scope="row">{i + 1}</th>
																		<td>{companyItem.companyName}</td>
																		<td>{companyItem.companyDomain}</td>
																		<td>{companyItem.expiredDate}</td>
																		<td>{companyItem.users}</td>
																		<td>
																			<a href="#!" onClick={() => this.urlviewCompany(companyItem.docId)}><DescriptionIcon className="iconGrey mgR20" /></a>
																			<a href="#!" onClick={() => this.urleditCompany(companyItem.docId)}><EditIcon className="iconGrey mgR20" /></a>
																		</td>
																	</tr>
																)
															})
														}
													</>
												) : (
													<tr key="1">
														<td colSpan="7" className="textCenter">
															<div className="mgT20 mgB20">:: no record ::</div>
														</td>
													</tr>
												)
											}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div id="loading-area" className="divHide loading"></div>
			</div>
		);
	}
}

export default CTCompanyTrainings;
