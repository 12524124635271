import React, { Component } from 'react';
import '../ctinc/ct-Style.css';
import '../ctinc/ct-Global';
import { FormErrors } from '../ctinc/error-form';
import ReplaceAllContent from '../../../config/replace-all';

const authKey = global.ctconfig.authBearerKey+"##"+global.ctconfig.kindcode[12];

class CTLibraryExamEdit extends Component {
    constructor (props) {
        super(props);
        this.state = {
            library_id: '',
            library_title: '',
            libexam_id: '',
            libexam_desc: '',
            libexam_pass_score: 0,
            libexam_num_question: 0,
            libexam_status: '',
            formErrors: { libexam_desc: '', libexam_pass_score: '', libexam_num_question: '', libexam_status: '' },
            libexamdescValid: false,
            libexampassscoreValid: false,
            libexamnumquestionValid: false,
            libexamstatusValid: false,
            formValid: false,
            input_disabled: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value},
            () => { this.validateField(name, value)});
    }
    
    handleUserSelect = (e) => {
        const value = e.target.value;
        const inputname = e.target.name; 
        this.setState( prevState => {
            return {  
                ...prevState.libexam_status, libexam_status: value
            }
        }, () => { this.validateField(inputname, value)
        })   
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let libexamdescValid = this.state.libexamdescValid;
        let libexampassscoreValid = this.state.libexampassscoreValid;
        let libexamnumquestionValid = this.state.libexamnumquestionValid;
        let libexamstatusValid = this.state.libexamstatusValid;
        
        switch(fieldName) {
            case 'libexam_desc':
                libexamdescValid = value.trim().length > 0;
                fieldValidationErrors.libexam_desc = libexamdescValid ? '' : 'exam description need to be filled';
                break;
            case 'libexam_pass_score':
                libexampassscoreValid = value.trim().length > 0;
                fieldValidationErrors.libexam_pass_score = libexampassscoreValid ? '' : 'minimum score need to be filled';
                break;
            case 'libexam_num_question':
                libexamnumquestionValid = value.trim().length > 0;
                fieldValidationErrors.libexam_num_question = libexamnumquestionValid ? '' : 'number of question need to be filled';
                break;
            case 'libexam_status':
                libexamstatusValid = value.trim().length > 0;
                fieldValidationErrors.libexam_status = libexamstatusValid ? '' : 'status need to be filled';
                break;
            default:
                break;
        }
        
        this.setState({formErrors: fieldValidationErrors,
            libexamdescValid: libexamdescValid,
            libexampassscoreValid: libexampassscoreValid,
            libexamnumquestionValid: libexamnumquestionValid,
            libexamstatusValid: libexamstatusValid
        }, this.validateForm);
    }

    validateForm() {
        this.setState({formValid: this.state.libexamdescValid && this.state.libexampassscoreValid && this.state.libexamnumquestionValid && this.state.libexamstatusValid});
    }

    errorClass(error) {
        return(error.length === 0 ? '' : 'has-error');
    }

    handleSubmit(e) {
        e.preventDefault();
        if(this.state.libexam_desc !== '' && this.state.libexam_pass_score !== '' && this.state.libexam_num_question !== '' && this.state.libexam_status !== '') {
            document.getElementById('loading-area').style.display = 'block'; 
            var libexamDesc, libexamPassScore, libexamNumQuestion, libexamStatus;
            if(this.textInputLibExamDesc.value === '') { libexamDesc = this.state.libexam_desc === undefined ? "" : this.state.libexam_desc } else { libexamDesc = this.textInputLibExamDesc.value; }
            libexamDesc = ReplaceAllContent('\n','<br/>',ReplaceAllContent(',','[;]',libexamDesc));
            if(this.textInputLibExamPassScore.value === '') { libexamPassScore = this.state.libexam_pass_score === undefined ? 0 : this.state.libexam_pass_score } else { libexamPassScore = this.textInputLibExamPassScore.value; }
            if(this.textInputLibExamNumQuestion.value === '') { libexamNumQuestion = this.state.libexam_num_question === undefined ? 0 : this.state.libexam_num_question } else { libexamNumQuestion = this.textInputLibExamNumQuestion.value; }
            if(this.textInputLibExamStatus.value === '') { libexamStatus = this.state.libexam_status === undefined ? 0 : this.state.libexam_num_question } else { libexamStatus = this.textInputLibExamStatus.value; }

            if(this.state.libexam_id !== '') {
                var datatext ='{"library_id":"'+this.state.library_id+'","libexam_id":"'+this.state.libexam_id+'","libexam_title":"Exam","libexam_number":0,"libexam_desc":"'+libexamDesc+'","libexam_pass_score":"'+libexamPassScore+'","libexam_num_question":"'+libexamNumQuestion+'","libexam_status":"'+libexamStatus+'","form_id":"form-editexam"}';
                var data = JSON.parse(datatext);
                
                fetch(global.ctconfig.urlLink+"/libraryexamedit", {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': authKey,
                    }, body : JSON.stringify(data)
                }).then(res => {
                    if(res.status === 200) 
                        return res.json() 
                }).then( resJson => {
                    document.getElementById('loading-area').style.display = 'none'; 
                    if(resJson.status === '200') {
                        window.location.href=global.ctconfig.urlHost+'/cteditdetaillibrary/'+this.state.library_id;
                    } else {
                        console.log('Something happened wrong');
                    }
                }).catch(err => {
                    document.getElementById('loading-area').style.display = 'none'; 
                });
            } else {
                var datatext2 ='{"library_id":"'+this.state.library_id+'","libexam_title":"Exam","libexam_number":0,"libexam_desc":"'+libexamDesc+'","libexam_pass_score":"'+libexamPassScore+'","libexam_num_question":"'+libexamNumQuestion+'","libexam_status":"'+libexamStatus+'","form_id":"form-addexam"}';
                var data2 = JSON.parse(datatext2);
                
                fetch(global.ctconfig.urlLink+"/libraryexamadd", {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': authKey,
                    }, body : JSON.stringify(data2)
                }).then(res => {
                    if(res.status === 200) 
                        return res.json() 
                }).then( resJson => {
                    document.getElementById('loading-area').style.display = 'none'; 
                    if(resJson.status === '200') {
                        window.location.href=global.ctconfig.urlHost+'/cteditdetaillibrary/'+this.state.library_id;
                    } else {
                        console.log('Something happened wrong');
                    }
                }).catch(err => {
                    document.getElementById('loading-area').style.display = 'none'; 
                });
            }
        } else {
            var arrfieldName = ['libexam_desc', 'libexam_pass_score', 'libexam_num_question','libexam_status'];
            var arrvalue = [this.textInputLibExamDesc.value, this.textInputLibExamNumQuestion.value, this.textInputLibExamPassScore.value, this.textInputLibExamStatus.value];
            for(var ck in arrfieldName) {
                this.validateField(arrfieldName[ck], arrvalue[ck]);
            }
        }
    }

    componentDidMount() {
        var othersContent = JSON.parse(document.getElementById("contentoth").innerText);
        document.getElementById('loading-area').style.display = 'block';
        if(othersContent.examId) {
            this.setState({
                libexam_id: othersContent.examId,
                libexam_desc: othersContent.examDesc,
                libexam_pass_score: othersContent.examPassScore,
                libexam_num_question: othersContent.examNumQuestion,
                libexam_status: othersContent.examStatus
            });
        }
        this.setState({
            library_id: othersContent.libraryId,
            library_title: othersContent.libraryTitle,
            formErrors: { libexam_desc: '', libexam_pass_score: '', libexam_num_question: '', libexam_status: '' },
            libexamDescValid: false,
            libexampassscoreValid: false,
            libexamnumquestionValid: false,
            libexamstatusValid: false,
            formValid: false,
            input_disabled: false
        });
        document.getElementById('loading-area').style.display = 'none';
    }

    render() {
        return (
            <div className="main-container">
                <div className="mgT30">
                <form onSubmit={this.handleSubmit}>
                    <div>
                        <input type="hidden" id="library_id" name="library_id" value={this.state.library_id} />
                        <input type="hidden" id="libexam_id" name="libexam_id" value={this.state.libexam_id} />
                    </div>
                    <div className="row-field mgT20">
                        <h6>{this.state.library_title}</h6>
                    </div>
                    <div className="row-field mgT20">
                        <label className="w30p textMiddle" htmlFor="libexam_desc">Description</label>
                        <div className="w70p">
                            <textarea name="libexam_desc" className="form-control" 
                                rows={2} cols={30} 
                                value={this.state.libexam_desc} 
                                onChange={this.handleUserInput} 
                                ref={(input) => this.textInputLibExamDesc = input}
                                disabled={this.state.input_disabled}/>
                            <div className="mgT05">
                                { <FormErrors formErrors={this.state.formErrors.libexam_desc}/>  }
                            </div>
                        </div>
                    </div>
                    <div className="row-field mgT20">
                        <label className="w30p textMiddle" htmlFor="libexam_pass_score">Passing Score</label>
                        <div className="w70p">
                            <input type="number" className="form-control" name="libexam_pass_score"
                                placeholder={this.state.libexam_pass_score}
                                value={this.state.libexam_pass_score}
                                onChange={this.handleUserInput} 
                                ref={(input) => this.textInputLibExamPassScore = input}
                                disabled={this.state.input_disabled}/>
                            <div className="mgT05">
                                { <FormErrors formErrors={this.state.formErrors.libexam_pass_score}/>  }
                            </div>
                        </div>
                    </div>
                    <div className="row-field mgT20">
                        <label className="w30p textMiddle" htmlFor="libexam_num_question">Num. of Question</label>
                        <div className="w70p">
                            <input type="number" className="form-control" name="libexam_num_question"
                                placeholder={this.state.libexam_num_question}
                                value={this.state.libexam_num_question}
                                onChange={this.handleUserInput} 
                                ref={(input) => this.textInputLibExamNumQuestion = input}
                                disabled={this.state.input_disabled}/>
                            <div className="mgT05">
                                { <FormErrors formErrors={this.state.formErrors.libexam_num_question}/>  }
                            </div>
                        </div>
                    </div>
                    <div className="row-field mgT20">
                        <label className="w30p textMiddle" htmlFor="libexam_status">Status</label>
                        <div className="w70p">
                            
                            <select name="libexam_status" className="form-control" 
                                value={this.state.libexam_status} 
                                onChange={this.handleUserSelect}
                                ref={(input) => this.textInputLibExamStatus = input}
                                disabled={this.state.input_disabled}
                            >
                                <option value="" key="">-- please select --</option>
                                <option value="1" key="status_active">Active</option>
                                <option value="0" key="status_inactive">Inactive</option>
                            </select>
                            <div className="mgT05">
                                { <FormErrors formErrors={this.state.formErrors.libexam_status}/>  }
                            </div>
                        </div>
                    </div>
                    <div className="row-field mgT30 mgB30">
                        <div className="w100p"><button id="submit-button"  type="submit" className="w100p ct-submitButton">Submit</button></div>
                    </div>
                </form>
                </div>
                <div id="loading-area" className="divHide loading"></div> 
            </div>
          );
        }
     }
      
export default CTLibraryExamEdit;
