import React, { Component } from 'react';
import '../ctinc/ct-Style-2.css';
import '../ctinc/ct-Global';
import { FormErrors } from '../ctinc/error-form';
import CTHeader from './ct-fragmentheader';
import CTSidemenu from './ct-fragmentsidemenu';
import CTNA from './ct-fragmentna';
import defMemberPicture from '../../assets/pic-profile.png';
import CheckBox from '../../../config/checkbox';
//import IUC from './ifragmentuc';
 
const authKey = global.ctconfig.authBearerKey+"##"+global.ctconfig.kindcode[0];

class CTMemberForm extends Component {
  constructor (props) {
    super(props);
    this.state = {
        stTitle: '',
        memberdoc_id: '',
        member_email: '',
        member_position: '',
        member_level: '',
        member_fullname: '',
        member_picture: '',
        member_role: [],
        member_region: [],
        formErrors: {member_email: '', member_position: '', member_level: ''},
        memberemailValid: false,
        memberpositionValid: false,
        memberlevelValid: false,
        formValid: false,
        input_disabled: true
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    //this.handleStartDate = this.handleStartDate.bind(this);
  }
  handleBack = () => {
    window.location.href=global.ctconfig.urlHost+'/ctmembers';
    // this.props.history.goBack();
  }
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value},
        () => { this.validateField(name, value)});
  }
  handleInputChecked = (e) => {
    const roles = this.state.member_role;
    roles.forEach(role => {
       if (role.value === e.target.value)
       role.isChecked =  e.target.checked
    })
    this.setState({member_role: roles})

    /*
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
        member_role: this.state.member_role.concat(name)
      });
    */
    //this.setState({[name]: value},
    //    () => { this.validateField(name, value)});
  }
  handleInputChecked2 = (e) => {
    const regions = this.state.member_region;
    regions.forEach(region => {
       if (region.value === e.target.value)
       region.isChecked =  e.target.checked
    })
    this.setState({member_region: regions})
    console.log(regions);
    console.log("aa");
  }
  
  handleUserSelect = (e) => {
    //alert(this.textInputMailTemplate.value)
    //alert(e.target.name)
    const value = e.target.value;
    const inputname = e.target.name;
    // console.log(value);
    // console.log(inputname);
    if(value !== '') {
        this.setState( prevState => {
            return {  
                ...prevState.member_level, member_level: value
            }
        }, () => { this.validateField(inputname, value)
        }) 
    } else {
        this.setState( prevState => {
            return {  
                ...prevState.member_level, member_level: value
            }
        })
    }
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let memberemailValid = this.state.memberemailValid;
    let memberpositionValid = this.state.memberpositionValid;
    let memberlevelValid = this.state.memberlevelValid;
    
    switch(fieldName) {
        case 'member_email': 
            memberemailValid = value.trim().length > 0;
            fieldValidationErrors.member_email = memberemailValid ? '' : 'email need to be filled';
            break;
        case 'member_position': 
            memberpositionValid = value.trim().length > 0;
            fieldValidationErrors.member_position = memberpositionValid ? '' : 'position need to be filled';
            break;
        case 'member_level': 
            memberlevelValid = value.trim().length > 0;
            fieldValidationErrors.member_level = memberlevelValid ? '' : 'level need to be filled';
            break;
        default:
            break;
    }
    //alert(fieldName + ':' + JSON.stringify(fieldValidationErrors));
    
    this.setState({formErrors: fieldValidationErrors,
                    memberemailValid: memberemailValid,
                    memberpositionValid: memberpositionValid,
                    memberlevelValid: memberlevelValid,
                }, this.validateForm);
}
  validateForm() {
    this.setState({formValid: this.state.memberemailValid && this.state.memberpositionValid && this.state.memberlevelValid});
  }

  errorClass(error) {
    return(error.length === 0 ? '' : 'has-error');
  }
  handleSubmit(e) {
    e.preventDefault();
    //alert(JSON.stringify(this.state));
    //alert(this.event_enddate)
    
    if(this.state.member_email !== '' && this.state.member_position !== '' && this.state.member_level !== '') {
        document.getElementById('loading-area').style.display = 'block'; 
        var memberRole = '';
        for(var r=0; r<this.state.member_role.length; r++) {
            if(this.state.member_role[r].isChecked === true) { 
                if(memberRole !== '') { memberRole += "$$"; }
                memberRole += this.state.member_role[r].value; 
            }
        }
        //if(memberRole !== '') { memberRole = '{'+memberRole+'}'; }
        var memberRegion = '';
        for(var s=0; s<this.state.member_region.length; s++) {
            if(this.state.member_region[s].isChecked === true) { 
                if(memberRegion !== '') { memberRegion += "$$"; }
                memberRegion += this.state.member_region[s].value; 
            }
        }
        var urlFetch, datatext;
        if(this.state.memberdoc_id !== null && this.state.memberdoc_id !== ''  && this.state.memberdoc_id !== undefined) { 
            var memberFullname,memberEmail,memberPosition,memberLevel;
            if(this.textInputMemberFullName.value === '') { memberFullname = this.state.member_fullname; } else { memberFullname = this.textInputMemberFullName.value; }
            if(this.textInputMemberEmail.value === '') { memberEmail = this.state.member_email; } else { memberEmail = this.textInputMemberEmail.value; }
            if(this.textInputMemberPosition.value === '') { memberPosition = this.state.member_position; } else { memberPosition = this.textInputMemberPosition.value; }
            if(this.textInputMemberLevel.value === '') { memberLevel = this.state.member_level; } else { memberLevel = this.textInputMemberLevel.value; }
            //var member_role = JSON.stringify(this.state.member_role);
            //console.log(memberRole);
            datatext ='{"user_fullname":"'+memberFullname+'","user_email":"'+memberEmail+'","user_position":"'+memberPosition+'","user_level":"'+memberLevel+'","user_role":"'+memberRole+'","user_region":"'+memberRegion+'","user_docid":"'+this.state.memberdoc_id+'","form_id":"form-edit"}';
            //console.log(datatext);
            urlFetch = global.ctconfig.urlLink+"/useredit";
        } else {
            datatext ='{"user_fullname":"'+this.state.member_fullname+'","user_email":"'+this.state.member_email+'","user_position":"'+this.state.member_position+'","user_level":"'+this.state.member_level+'","user_role":"'+memberRole+'","user_region":"'+memberRegion+'","form_id":"form-add"}';
            urlFetch = global.ctconfig.urlLink+"/useradd";
        }
        var data = JSON.parse(datatext); 
        
        fetch(urlFetch, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': authKey,
            }, body : JSON.stringify(data)
          }).then(res => {
            if(res.status === 200) 
                return res.json() 
          }).then( resJson => {
            console.log(resJson);
            console.log(resJson.status);
            document.getElementById('loading-area').style.display = 'none'; 
            if(resJson.status === '200') {
                window.location.href=global.ctconfig.urlHost+'/ctmembers';
            } else {
                console.log('Something happened wrong');
                console.log(resJson);
            }
          }).catch(err => {
            document.getElementById('loading-area').style.display = 'none'; 
            console.log(err);
          }) ;

    } else {
        var arrfieldName = ['member_email','member_position','member_level'];
        var arrvalue = [this.textInputMemberEmail.value,this.textInputMemberPosition.value,this.textInputMemberLevel.value];
        for(var ck in arrfieldName) {
            this.validateField(arrfieldName[ck], arrvalue[ck]);
        }
    }
  }
  componentDidMount() {
    // var geturl = this.props.location; console.log(geturl);
    // var geturl = window.location.pathname; console.log(geturl);
    // var arrString = geturl.pathname;
    var arrString = window.location.pathname;
    var arrUrl = [];
    arrUrl = arrString.split('/');
    console.log(arrUrl[1]);
    var typelink = arrUrl[2];
    var docid = arrUrl[3];
    if(typelink === 'cteditmembers' || typelink === 'ctviewmembers') {
        if(typelink === 'cteditmembers') {
            document.getElementById('submit-button').style.display = 'block'; 
        } else {
            document.getElementById('submit-button').style.display = 'none'; 
        }
        if(docid !== '') { 
            document.getElementById('loading-area').style.display = 'block'; 
            fetch(global.ctconfig.urlLink+"/userview/"+docid, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }
              }).then(res => {
                if(res.status === 200) 
                    return res.json() 
              }).then( resJson => {
                console.log(resJson);
                console.log(resJson.status);
                document.getElementById('loading-area').style.display = 'none'; 
                if(resJson.status === '200') { 
                    var inputdisable = false;
                    if(typelink === 'ctviewmembers') { inputdisable = true; }

                    var memberPicture = '';
                    if(resJson.values[0].content[0].maincontent.userPicture !== undefined && resJson.values[0].content[0].maincontent.userPicture !== '') {
                        memberPicture = resJson.values[0].content[0].maincontent.userPicture;
                    } else { memberPicture = defMemberPicture; }

                    var arrMemberRole = [];
                    if(global.ctconfig.userrole.length > 0) {
                        if(resJson.values[0].content[0].maincontent.userRole !== undefined) {
                            var arrUserRole = resJson.values[0].content[0].maincontent.userRole.split("$$");
                            for(var v in global.ctconfig.userrole) { 
                                var resuroles = false;
                                for(var q in arrUserRole) {
                                    if(global.ctconfig.userrole[v] === arrUserRole[q]) {
                                        resuroles = true; break;
                                    } else { resuroles = false; }
                                }
                                arrMemberRole = arrMemberRole.concat({'id':v,'fieldId':'uroles_'+v,'value':global.ctconfig.userrole[v],'label':global.ctconfig.userrole[v],'isChecked': resuroles,'isDisable': inputdisable});
                            }
                        } else {
                            for(var v2 in global.ctconfig.userrole) { 
                                var resuroles2 = false;
                                arrMemberRole = arrMemberRole.concat({'id':v2,'fieldId':'uroles_'+v2,'value':global.ctconfig.userrole[v2],'label':global.ctconfig.userrole[v2],'isChecked': resuroles2,'isDisable': inputdisable});
                            }
                        }
                    }
                    var arrMemberRegion = [];
                    if(global.ctconfig.userregion.length > 0) {
                        if(resJson.values[0].content[0].maincontent.userRegion !== undefined) {
                            var arrUserRegion = resJson.values[0].content[0].maincontent.userRegion.split("$$");
                            for(var w in global.ctconfig.userregion) { 
                                var resuregions = false;
                                for(var p in arrUserRegion) {
                                    if(global.ctconfig.userregion[w] === arrUserRegion[p]) {
                                        resuregions = true; break;
                                    } else { resuregions = false; }
                                }
                                arrMemberRegion = arrMemberRegion.concat({'id':w,'fieldId':'uregions_'+w,'value':global.ctconfig.userregion[w],'label':global.ctconfig.userregion[w],'isChecked': resuregions,'isDisable': inputdisable});
                            }
                        } else {
                            for(var w2 in global.ctconfig.userregion) { 
                                var resuregions2 = false;
                                arrMemberRegion = arrMemberRegion.concat({'id':w2,'fieldId':'uregions_'+w2,'value':global.ctconfig.userregion[w2],'label':global.ctconfig.userregion[w2],'isChecked': resuregions2,'isDisable': inputdisable});
                            }
                        }
                    }
                    if(typelink === 'cteditmembers') {
                        this.setState({
                            stTitle: 'Edit Member',
                            memberdoc_id: resJson.values[0].docid,
                            member_email: resJson.values[0].content[0].maincontent.userEmail,
                            member_position: resJson.values[0].content[0].maincontent.userPosition,
                            member_level: resJson.values[0].content[0].maincontent.userLevel,
                            member_fullname: resJson.values[0].content[0].maincontent.userFullname,
                            member_picture: '',
                            member_role: arrMemberRole,
                            member_region: arrMemberRegion,
                            formErrors: {member_email: '', member_position: '', member_level: ''},
                            memberemailValid: false,
                            memberpositionValid: false,
                            memberlevelValid: false,
                            formValid: false,
                            input_disabled: inputdisable
                        }) 
                    } else if(typelink === 'ctviewmembers') {
                        this.setState({
                            stTitle: 'Detail Member',
                            memberdoc_id: resJson.values[0].docid,
                            member_email: resJson.values[0].content[0].maincontent.userEmail,
                            member_position: resJson.values[0].content[0].maincontent.userPosition,
                            member_level: resJson.values[0].content[0].maincontent.userLevel,
                            member_fullname: resJson.values[0].content[0].maincontent.userFullname,
                            member_picture: memberPicture,
                            member_role: arrMemberRole,
                            member_region: arrMemberRegion,
                            formErrors: {member_email: '', member_position: '', member_level: ''},
                            memberemailValid: false,
                            memberpositionValid: false,
                            memberlevelValid: false,
                            formValid: false,
                            input_disabled: inputdisable
                        }) 
                    }
                } else {
                    console.log('Something happened wrong');
                    console.log(resJson);
                }
              }).catch(err => { 
                document.getElementById('loading-area').style.display = 'none'; 
                console.log(err);
              });
        }
    } else if(typelink === 'ctaddmembers') {
        var arrMemberRole = [];
        if(global.ctconfig.userrole.length > 0) {
            for(var v in global.ctconfig.userrole) {
                arrMemberRole = arrMemberRole.concat({'id':v,'fieldId':'uroles_'+v,'value':global.ctconfig.userrole[v],'label':global.ctconfig.userrole[v],'isChecked': false,'isDisable': false});
            }
        }
        var arrMemberRegion = [];
        if(global.ctconfig.userregion.length > 0) {
            for(var w in global.ctconfig.userregion) {
                arrMemberRegion = arrMemberRegion.concat({'id':w,'fieldId':'uregions_'+w,'value':global.ctconfig.userregion[w],'label':global.ctconfig.userregion[w],'isChecked': false,'isDisable': false});
            }
        }
        document.getElementById('submit-button').style.display = 'block'; 
        this.setState( prevState => {
            return {  
                ...prevState.stTitle, stTitle: 'New Member',
                ...prevState.input_disabled, input_disabled: false,
                ...prevState.member_role, member_role: arrMemberRole,
                ...prevState.member_region, member_region: arrMemberRegion
            }
        })
    }// end if arrUrl[1]
  }

  render() {
    //console.log(this.state.member_role);
    var userLevelList = [];
    if(global.ctconfig.userlevel.length > 0) {
        for(var u in global.ctconfig.userlevel) {
            userLevelList.push(<option value={global.ctconfig.userlevel[u]}>{global.ctconfig.userlevel[u]}</option>)
        }
    }
    /*
    var userRoleList = [];
    if(global.ctconfig.userrole.length > 0) {
        for(var v in global.ctconfig.userrole) {
            var nmc = "member_role_"+v;
            userRoleList.push(<div className="flex-container mgT10"><div><input name={nmc} type="checkbox" checked={this.state.member_role} onChange={this.handleInputChecked} /> </div><div className="mgL20">{global.ctconfig.userrole[v]}</div></div>)
        }
    }
    */
   return (
    <div className="main-container">
      <div  className="ct-header-main"><CTHeader/></div>
        <div className="flex-container">
            <div className="sidebar-main"><CTSidemenu/></div>
            <div className="main-content">
            <div className="ct-stTitle">Member</div>
            {(localStorage.getItem('ctulevel') === global.ctconfig.userlevel[0])? 
            <div>
                <div className="stIndiTitle">Member { this.state.stTitle !== ''? '> '+this.state.stTitle : '' }</div>
                        
                <div className="mgT30">
                    {/* ====== */}
                    <div  className="content-container card card-body">
                        <div className="flex-container">
                            <div className="st2Title w70p">{ this.state.stTitle.includes("New")? 'Add ' : '' } {this.state.stTitle}</div>
                            <div className="divRight w30p"><div><button className="backButton" onClick={this.handleBack}>&#9664; back</button></div></div>
                        </div>
                        <div id="FormCenter" className="mgT20">
                            <form onSubmit={this.handleSubmit}>
                                {/* <div className="panel panel-default">
                                    <FormErrors2 formErrors={this.state.formErrors}/>
                                </div> */}
                                <div className="flex-container">
                                    <input type="hidden" required name="memberdoc_id" value={this.state.memberdoc_id} />
                                </div>
                                { this.state.member_picture !== ''? 
                                    <div className="mgB30"><img src={this.state.member_picture} alt={this.state.member_fullname} className="memberPhoto"/></div>
                                    : ''
                                } 
                                {/* <div className={`mgB20 ${this.errorClass(this.state.formErrors.event_name)}`}></div> */}
                                
                                <div className="row-field mgT20">
                                    <label className="labelFormL textMiddle" htmlFor="member_fullname">Name</label>
                                    <div className="w70p">
                                        <input type="text" className="form-control" name="member_fullname"
                                            placeholder={this.state.member_fullname}
                                            value={this.state.member_fullname}
                                            onChange={this.handleUserInput}  
                                            ref={(input) => this.textInputMemberFullName = input}
                                            disabled={this.state.input_disabled}/>
                                        
                                    </div>
                                </div>
                                <div className="row-field mgT20">
                                    <label className="labelFormL textMiddle" htmlFor="member_email">Email Address</label>
                                    <div className="w70p">
                                        <input type="text" className="form-control" name="member_email"
                                            placeholder={this.state.member_email}
                                            value={this.state.member_email}
                                            onChange={this.handleUserInput}  
                                            ref={(input) => this.textInputMemberEmail = input}
                                            disabled={this.state.input_disabled}/>
                                        <div className="mgT05">
                                            { <FormErrors formErrors={this.state.formErrors.member_email}/>  }
                                        </div>
                                    </div>
                                </div>
                                
                                { /* <div className={`FormField2 ${this.errorClass(this.state.formErrors.event_description)}`}> */ }
                                
                                <div className="row-field mgT20">
                                    <label className="labelFormL textMiddle" htmlFor="member_position">Member Position</label>
                                    <div className="w70p">
                                        <input type="text" className="form-control" name="member_position"
                                            placeholder={this.state.member_position}
                                            value={this.state.member_position}
                                            onChange={this.handleUserInput}  
                                            ref={(input) => this.textInputMemberPosition = input}
                                            disabled={this.state.input_disabled}/>
                                        <div className="mgT05">
                                            { <FormErrors formErrors={this.state.formErrors.member_position}/>  }
                                        </div>
                                    </div>
                                </div>
                                
                                
                                <div className="row-field mgT20">
                                    <label className="labelFormL textMiddle" htmlFor="member_level">Member Level</label>
                                    <div className="w70p">
                                        <select name="member_level" className="form-control"
                                                value={this.state.member_level} 
                                                onChange={this.handleUserSelect}
                                                ref={(input) => this.textInputMemberLevel = input}
                                                disabled={this.state.input_disabled}>
                                            <option value="">-- please select --</option>
                                            {userLevelList}
                                        </select>
                                        <div className="mgT05">
                                            { <FormErrors formErrors={this.state.formErrors.member_level}/>  }
                                        </div>
                                    </div>
                                </div>
                                <div className="row-field mgT30">
                                    <div className="w70p st3Title divMiddle mgB20">Region</div>
                                    <div className="w100p">
                                        {
                                        this.state.member_region.map((region, index2) => {
                                            return (<CheckBox key={"B"+index2} handleInputChecked={this.handleInputChecked2}  {...region} />)
                                        })
                                        }
                                    </div>
                                </div>
                                <div className="row-field mgT30">
                                    <div className="w70p st3Title divMiddle mgB20">Member Roles</div>
                                    <div className="w100p">
                                        {
                                        this.state.member_role.map((role, index) => {
                                            return (<CheckBox key={"A"+index} handleInputChecked={this.handleInputChecked}  {...role} />)
                                        })
                                        }
                                    </div>
                                </div>
                                <div className="row-field mgT30 mgB30">
                                    <div className="w100p"><button id="submit-button"  type="submit" className="w100p ct-submitButton">Submit</button></div>
                                </div>
                                <div className="flex-container mgT30 mgL250 mgB30">
                                { /* <button type="submit" className="FormField2__Button mr-20" disabled={!this.state.formValid}>SUBMIT</button> */ }
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* ====== */}
                </div>
            </div>
            :   
            <div><CTNA/></div>
            }
        </div>
      </div>
      <div id="loading-area" className="divHide loading"></div> 
    </div>
   );
 }
}
 
export default CTMemberForm;