import React, { Component } from 'react';
import '../ctinc/ct-Style-2.css';

 
class CTUC extends Component {
 constructor(props) {
   super(props);
   this.state = {
     done: undefined
   };
   //this.handleClick = this.handleClick.bind(this);
 }
 /*handleClick(ln) {    
   if(ln==='logout') {
    //console.log(gapi.auth2);
    var auth2 = gapi.auth2.getAuthInstance();
    auth2.signOut().then(function () {
      auth2.disconnect();
      localStorage.clear();
      alert('User signed out.');
      window.location.reload();
    }); 
   }
  //document.location.href=ln;
 }
 componentDidMount() {
  window.gapi.load('auth2', () => {
      this.auth2 = gapi.auth2.init({
        //client_id: 'YOUR_CLIENT_ID.apps.googleusercontent.com',
        client_id: Credentials.web.client_id,
      })
  }); 
}*/
 render() {
  //localStorage.clear();
  //let key;
  //let sessionJSON = JSON.parse(localStorage.getItem(key));
  //let sessionUID = sessionJSON.uid;
  //let sessionUTK = sessionJSON.utk;
  //console.log("LS:"+localStorage.getItem(key));
  //console.log("LS:"+sessionUID);
  //console.log("TK:"+sessionUTK);
   return (
    <div className="divCenter mgT50">
      <div className="heading1 fontGrey">Under Construction</div>
      <div className="mgT20 fontGrey textCenter">Hey! Thank you for checking out our app.</div>
      <div className="mgT05 fontGrey textCenter">It’s not quite ready yet, but we are working hard and it will be ready in approximately.</div>
    </div>
   );
 }
}
 
export default CTUC;