import React, { Component } from 'react';
import '../ctinc/ct-Style.css';
import '../ctinc/ct-Global';
import Popup from '../ctinc/popup';
import '../ctinc/popup.css'
import CTSubCategoryForm from './ct-categorysub-form';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const authKey = global.ctconfig.authBearerKey+"##"+global.ctconfig.kindcode[11];

class CTCategoryDetail extends Component {
    constructor (props) {
        super(props);
        this.state = {
            category_id: '',
            category_name: '',
            usage_for: '',
            subcategory_list: [],
            st3PopTitle: '',
            styleTb: 'table',
            isOpen3: false,
            otherContent3: ''
        }
        this.loadDetailData = this.loadDetailData.bind(this);
    }

    togglePopClose() {
        this.setState( prevState => {
          return {  
              ...prevState.isOpen3, isOpen3: false
          }
        })
    }  
    urlformSubCat(used,catid,subcatid,action) {
        var st3PopTitle = "";
        if(used === 'course') { st3PopTitle = action+" Sub Categories Workshop"; }
        else if(used === 'library') { st3PopTitle = action+" Sub Categories Library"; }
        this.setState( prevState => {
          return {  
              ...prevState.st3PopTitle, st3PopTitle: st3PopTitle,
              ...prevState.isOpen3, isOpen3: true,
              ...prevState.otherContent3, otherContent3 : '{"usageFor":"'+used.toLowerCase()+'","catId":"'+catid+'","categoryName":"'+this.state.category_name+'","subcatId":"'+subcatid+'"}'
          }
        })
    }
    urldeleteSubCat(used,catid,subcatid) {
        var stat = false;
        stat = window.confirm("Are you sure you want to delete this data?");
        if(stat === true) {
            document.getElementById('loading-area').style.display = 'block'; 
            var urlString = global.ctconfig.urlLink+"/categorydelete";
            var datatext ='{"docid":"'+catid+'","subdocid":"'+subcatid+'","numchild":0,"usageFor":"'+used.toLowerCase()+'"}';
            var data = JSON.parse(datatext); 
            fetch(urlString, {
                //method: 'DELETE',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }, body : JSON.stringify(data)
            }).then(res => {
                if(res.status === 200) 
                    return res.json() 
            }).then( resJson => {
                console.log(resJson);
                console.log(resJson.status);
            //}).then(response => {
                // console.log("response");
                // console.log(response);
                document.getElementById('loading-area').style.display = 'none'; 
                // if(response.status === 200) {
                if(resJson.status === "200") {
                    this.loadDetailData();
                    //window.location.reload();
                    //window.location.href='/listevent';
                } else {
                    console.log('Something happened wrong delete');
                    //console.log(response);
                }
            }).catch(err => err);
        }
    }
    loadDetailData() {
        document.getElementById('loading-area').style.display = 'block'; 
        var othersContent3 = JSON.parse(document.getElementById("contentoth").innerText);
        if(othersContent3.catId !== '') {
            fetch(global.ctconfig.urlLink+"/categoryview/"+othersContent3.usageFor+"/"+othersContent3.catId, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }
              }).then(res => {
                if(res.status === 200) 
                    return res.json() 
              }).then( resJson => {
                console.log(resJson);
                console.log(resJson.status);
                document.getElementById('loading-area').style.display = 'none'; 
                if(resJson.status === '200') { 
                    this.setState({
                        category_id: resJson.values[0].docid,
                        category_name: resJson.values[0].content[0].maincontent.categoryName,
                        usage_for: othersContent3.usageFor,
                        subcategory_list: resJson.values[0].scontent,
                        styleTb: 'table',
                        isOpen: false
                    }); 
                } else {
                    console.log('Something happened wrong');
                    console.log(resJson);
                }
              }).catch(err => { 
                document.getElementById('loading-area').style.display = 'none'; 
                console.log(err);
              });
        } else {
            this.setState({
                category_id: othersContent3.catId,
                category_name: '',
                usage_for: othersContent3.usageFor,
                subcategory_list: [],
                styleTb: 'table',
                isOpen: false
            }); 
        }
    }
    componentDidMount() {
        this.loadDetailData();
    }

    render() {
        var subcategoryList = [];
        if(this.state.subcategory_list.length > 0) {
            for(var ls=0; ls < this.state.subcategory_list.length; ls++) {
                var retResult3 = this.state.subcategory_list[ls];
                var trcls = '';
                subcategoryList.push(<tr key={ls+1} className={trcls}>
                    <th scope="row">{ls+1}</th>
                    <td>{retResult3.subcontent.subcategoryName}</td>
                    <td>
                        <EditIcon className="iconGrey cursor-on mgR20" title="edit" onClick={this.urlformSubCat.bind(this, this.state.usage_for,this.state.category_id,retResult3.subdocid,'Edit')}/>
                        <DeleteIcon className="iconGrey cursor-on" onClick={this.urldeleteSubCat.bind(this, this.state.usage_for, this.state.category_id, retResult3.subdocid)}/> 
                    </td>
                  </tr>)
            }
          } else {
            subcategoryList.push(<tr key="0">
                  <td colSpan="3" className="textCenter"><div className="mgT20 mgB20">:: no record ::</div></td>
                </tr>)
              //memberList.push(<div className="mgT50 textCenter fontRed">:: no record ::</div>)
          }
        return (
            <div className="main-container">
                <div className="mgT30">
                    <div className="row-field mgT20">
                        <label className="w30p textMiddle" htmlFor="category_name">Category Name :</label>
                        <div className="w70p">{this.state.category_name} </div>
                    </div>
                    
                    <div className="mgT30" id="licence-area">
                        <div className="flex-container">
                            <div className="w50p st3Title divMiddle">Sub Category</div>
                            <div className="w50p divRight">
                            <button className="ct-genButton mgL20" onClick={this.urlformSubCat.bind(this, this.state.usage_for, this.state.category_id,'','Add')}><AddCircleOutlineIcon className="iconWhite mgR05"></AddCircleOutlineIcon>Add Sub Category</button>
                            </div>
                        </div>
                        <div className="mgT20">
                            <table className="table table-hover">
                            <thead className="thead-light">
                                <tr>
                                <th scope="col">#</th>
                                <th scope="col">Sub Category</th>
                                <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {subcategoryList}
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div>
                        {this.state.isOpen3 && <Popup
                        content={<><CTSubCategoryForm/></>}
                        id={this.state.category_id} 
                        title={this.state.st3PopTitle}
                        others={this.state.otherContent3}
                        styles={"50%"}
                        handleClose={this.togglePopClose.bind(this)}
                        />}
                        
                </div>
                <div id="loading-area" className="divHide loading"></div> 
            </div>
          );
        }
     }
      
export default CTCategoryDetail;
