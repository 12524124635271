import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { GetText } from './helper';

export default class About extends Component {

  render() {
    return (
      <div className="about">
        <Container>
          <h1 className="mb-4">{GetText() ? "Tentang Kami" : "About Us"}</h1>
          <p>{GetText() ? "Selamat datang di Novaio Academy! Kami adalah platform pembelajaran online yang menyediakan pelatihan berkualitas untuk membantu individu dan bisnis dalam meningkatkan keterampilan dan tetap relevan di dunia digital yang terus berkembang saat ini. Kami memiliki tim instruktur ahli yang berasal dari berbagai latar belakang dan membawa pengalaman selama bertahun-tahun untuk memberikan materi yang tidak hanya informatif tetapi juga menarik dan praktis. Kami percaya bahwa pembelajaran online ini harus dapat diakses oleh semua orang, terlepas dari lokasi geografis atau kendala keuangan mereka. Oleh karena itu, kami menawarkan pelatihan dengan harga terjangkau dan memastikan bahwa platform kami ramah pengguna dan mudah dimengerti." : "Welcome to Novaio Academy! We are an online learning platform that provides high-quality courses in various domains to help individuals and businesses to upskill and stay relevant in this ever-evolving digital world. Our team of expert instructors comes from diverse backgrounds and brings in years of industry experience to deliver courses that are not only informative but also engaging and practical. We believe that learning should be accessible to everyone, irrespective of their geographical location or financial constraints. Hence, we offer courses at affordable prices and ensure that our platform is user-friendly and easy to navigate."}</p>
          <p>{GetText() ? "Di Novaio Academy, kami sangat aktif dalam membantu individu dan bisnis dalam mendigitalkan bisnis mereka dan tetap menjadi yang terdepan. Pelatihan kami mencakup berbagai topik, termasuk pemrograman, analitik data, pemasaran digital, desain grafis, dan banyak lagi. Kami juga menawarkan pelatihan yang secara khusus berfokus pada platform cloud seperti AWS, Azure, dan Google Cloud. Kami memahami bahwa Cloud Platform adalah tulang punggung transformasi digital, dan oleh karena itu, kami berkomitmen untuk mengedukasi tentang cara menggunakan Cloud Platform secara maksimal. Tujuan kami adalah untuk memberdayakan pengguna dengan pengetahuan dan keterampilan yang mereka butuhkan untuk dapat lebih maju dalam karier dan bisnis. Untuk itu, bergabunglah dengan kami dalam perjalanan dan pembelajaran yang menyenangkan ini!" : "At Novaio Academy, we are passionate about helping individuals and businesses digitalize their activities and stay ahead of the curve. Our courses cover a wide range of topics, including programming, data analytics, digital marketing, graphic design, and much more. We also offer courses that specifically focus on cloud platforms such as AWS, Azure, and Google Cloud. We understand that cloud computing is the backbone of digital transformation, and hence, we are committed to educating our learners on how to use cloud platforms to their fullest potential. Our goal is to empower our learners with the knowledge and skills they need to succeed in their careers and businesses. So, come and join us on this journey of learning and growth!"}</p>
        </Container>
      </div>
    );
  }
}