import React from 'react';
import PropTypes from 'prop-types';
import '../ctinc/ct-Style.css';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';

import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
// import MailIcon from '@material-ui/icons/Mail';
import ListAltIcon from '@material-ui/icons/ListAlt';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Label from '@material-ui/icons/Label';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import PeopleIcon from '@material-ui/icons/People';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import SchoolIcon from '@material-ui/icons/School';
import BuildingIcon from '@material-ui/icons/Business';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
//import InfoIcon from '@material-ui/icons/Info';
//import DeleteIcon from '@material-ui/icons/Delete';
//import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
//import ForumIcon from '@material-ui/icons/Forum';
//import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
//import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      /*backgroundColor: theme.palette.action.hover,*/
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[300]})`,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[300]})`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    flexDirection: 'row-reverse',
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" className={classes.labelIcon} />
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 280,
  },
});

 
function onNodeSelect(url) {
  //console.log("Aa : "+url);
  document.location.href=url;
} 
export default function CTSidemenu() {
  const classes = useStyles();
  /*constructor(props) {
    super(props);
    this.onNodeSelect = this.onNodeSelect.bind(this);
  }
  onNodeSelect(node) {
    this.props.selectedTreeElement({nodeName: node});
  }*/
  return (
    <div className="sidebar-content">
      <div className="smLeftMenu">MAIN MENU</div>
      <div>
      <TreeView
        className={classes.root}
        defaultExpanded={[]}
        //defaultExpanded={[]}
        defaultCollapseIcon={<KeyboardArrowDownIcon />}
        defaultExpandIcon={<KeyboardArrowRightIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
      >
        <StyledTreeItem nodeId="1" labelText="Dashboard" labelIcon={HomeIcon} onLabelClick={node => onNodeSelect(global.ctconfig.urlHost+"/")}/>

        <StyledTreeItem nodeId="2" labelText="Category" labelIcon={LocalOfferIcon} onLabelClick={node => onNodeSelect(global.ctconfig.urlHost+"/ctcategory")}/>
        <StyledTreeItem nodeId="3" labelText="Library" labelIcon={FeaturedPlayListIcon} onLabelClick={node => onNodeSelect(global.ctconfig.urlHost+"/ctlibrary")}/>
        <StyledTreeItem nodeId="4" labelText="Workshops" labelIcon={AccessTimeIcon} onLabelClick={node => onNodeSelect(global.ctconfig.urlHost+"/ctcourse")}/>
        <StyledTreeItem nodeId="5" labelText="Trainers" labelIcon={AssignmentLateIcon} onLabelClick={node => onNodeSelect(global.ctconfig.urlHost+"/cttutor")}/>
        <StyledTreeItem nodeId="6" labelText="Payment" labelIcon={ListAltIcon} onLabelClick={node => onNodeSelect(global.ctconfig.urlHost+"/ctpayment")}/>
        <StyledTreeItem nodeId="7" labelText="Users" labelIcon={SchoolIcon} onLabelClick={node => onNodeSelect(global.ctconfig.urlHost+"/ctusertraining")}/>
        <StyledTreeItem nodeId="8" labelText="Companies" labelIcon={BuildingIcon} onLabelClick={node => onNodeSelect(global.ctconfig.urlHost+"/ctcompanytraining")}/>
        <StyledTreeItem nodeId="9" labelText="Feedback" labelIcon={Label} onLabelClick={node => onNodeSelect(global.ctconfig.urlHost+"/ctfeedback")}/>
        <StyledTreeItem nodeId="10" labelText="My Profile" labelIcon={PersonIcon} onLabelClick={node => onNodeSelect(global.ctconfig.urlHost+"/ctmyprofile")}/>
      
        {(localStorage.getItem('ctulevel') === global.ctconfig.userlevel[0])? 
        <StyledTreeItem nodeId="11" labelText="Member" labelIcon={PeopleIcon} onLabelClick={node => onNodeSelect(global.ctconfig.urlHost+"/ctmembers")}/>
        : ''}
        
      </TreeView>
      </div>
      <div><p><br/><br/></p></div>
    </div>
  );
}
