import React, { Component } from 'react';
import Moment from 'moment';
import { FaRegCalendar, FaRegFolder } from 'react-icons/fa';

export default class Purchased extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      title: '',
      category: '',
      date: '',
      image: '',
      link: ''
    }
  }

  componentDidMount() {
    var content = this.props.value;
    var catName="",coursePic="",courseDatez="";
    if(content.categoryName !== undefined) { catName = content.categoryName; }
    if(content.coursePicture !== undefined) { coursePic = content.coursePicture; }
    if(content.courseDate !== undefined) { courseDatez = Moment.unix(content.courseDate._seconds).format("D MMMM Y"); }
    this.setState({
      id: content.id,
      title: content.courseTitle,
      category: catName,
      date: courseDatez,
      image: coursePic,
      link: '/course/' + content.id
    });
  }

  render() {
    return (
      <a href={this.state.link}>
        <div className="course-list">
          <div className="row">
          {this.state.image !== ""? 
            <div className="col-md-3">
              <img className="w-100" src={this.state.image} alt={this.state.title}/>
            </div>
             : '' 
          }
            <div className="col-md-9">
              <h5 className="list-title">{this.state.title}</h5>
              <p>
              {this.state.date !== ""? 
                <span className="mr-4">
                  <FaRegCalendar className="mr-1"/>
                  {this.state.date}
                </span>
                : ''
              }
              {this.state.category !== ""? 
                <span className="mr-4">
                  <FaRegFolder className="mr-1"/>
                  {this.state.category}
                </span>
                : ''
              }
              </p>
            </div>
          </div>
        </div>
      </a>
    )
  }
}