import React, { Component } from 'react';
import '../ctinc/ct-Style-2.css';
import '../ctinc/ct-Global';
import { FormErrors } from '../ctinc/error-form';
import CTHeader from './ct-fragmentheader';
import CTSidemenu from './ct-fragmentsidemenu';
import CTNA from './ct-fragmentna';
import ReplaceAllContent from '../../../config/replace-all';

const authKey = global.ctconfig.authBearerKey+"##"+global.ctconfig.kindcode[39];

class CTFeedbackForm extends Component {
  constructor (props) {
    super(props);
    this.state = {
        stTitle: '',
        feedback_docid: '',
        feedback_text: '',
        feedback_number: '',
        feedback_type: '',
        feedback_status: '',
        option1: '',
        option2: '',
        option3: '',
        option4: '',
        option5: '',
        selectedFileDocument: null,
        formErrors: {feedback_text: '', feedback_number: '', feedback_type: '', feedback_status: '',  option1: '', option2: '', option3: '', option4: '', option5: ''},
        feedbacktextValid: false,
        feedbacknumberValid: false,
        feedbacktypeValid: false,
        feedbackstatusValid: false,
        option1Valid: false,
        option2Valid: false,
        option3Valid: false,
        option4Valid: false,
        option5Valid: false,
        formValid: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.submitData = this.submitData.bind(this);
  }
  handleBack = () => {
    // this.props.history.goBack();
    window.location.href=global.ctconfig.urlHost+'/ctfeedback';
  }
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
        [name]: value
    }, () => {
        this.validateField(name, value)
    });
  }    
  handleUserSelect = (e) => {
    const name = e.target.name; 
    const value = e.target.value;
    this.setState(prevState => {
        return {  
            ...prevState[name], [name]: value
        }
    }, () => { 
        this.validateField(name, value)
    });
  }
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let feedbacktextValid = this.state.feedbacktextValid;
    let feedbacknumberValid = this.state.feedbacknumberValid;
    let feedbacktypeValid = this.state.feedbacktypeValid;
    let feedbackstatusValid = this.state.feedbackstatusValid;
    switch(fieldName) {
        case 'feedback_text': 
            feedbacktextValid = value.trim().length > 0;
            fieldValidationErrors.feedback_text = feedbacktextValid ? '' : 'question need to be filled';
            break;
        case 'feedback_number': 
            feedbacknumberValid = value.trim().length > 0;
            fieldValidationErrors.feedback_number = feedbacknumberValid ? '' : 'number need to be filled';
            break;
        case 'feedback_type': 
            feedbacktypeValid = value.trim().length > 0;
            fieldValidationErrors.feedback_type = feedbacktypeValid ? '' : 'type need to be filled';
            break;
        case 'feedback_status': 
            feedbackstatusValid = value.trim().length > 0;
            fieldValidationErrors.feedback_status = feedbackstatusValid ? '' : 'status need to be filled';
            break;
        default:
            break;
    }    
    this.setState({
        formErrors: fieldValidationErrors,
        feedbacktextValid: feedbacktextValid,
        feedbacknumberValid: feedbacknumberValid,
        feedbacktypeValid: feedbacktypeValid,
        feedbackstatusValid: feedbackstatusValid
    }, this.validateForm);
  }
  validateForm() {
    this.setState({
        formValid: this.state.feedbacktextValid && this.state.feedbacknumberValid && this.state.feedbacktypeValid && this.state.feedbackstatusValid
    });
  }
  errorClass(error) {
    return(error.length === 0 ? '' : 'has-error');
  }

  submitData() {
    var urlFetch, datatext;
    var feedbackText,feedbackNumber,feedbackType,feedbackStatus;
    var option1 = '',option2 = '',option3 = '',option4 = '',option5 = '';
    if(this.state.feedback_docid !== null && this.state.feedback_docid !== ''  && this.state.feedback_docid !== undefined) {
        if(this.textInputFeedbackText.value === '') { feedbackText = this.state.feedback_text; } else { feedbackText = this.textInputFeedbackText.value; }
        if(this.textInputFeedbackNumber.value === '') { feedbackNumber = this.state.feedback_number; } else { feedbackNumber = this.textInputFeedbackNumber.value; }
        if(this.textInputFeedbackType.value === '') { feedbackType = this.state.feedback_type; } else { feedbackType = this.textInputFeedbackType.value; }
        if(this.textInputFeedbackStatus.value === '') { feedbackStatus = this.state.feedback_status; } else { feedbackStatus = this.textInputFeedbackStatus.value; }
        if(this.textInputFeedbackType.value === 'Multiple Choice') {
            if(this.textInputOption1.value === '') { option1 = this.state.option1; } else { option1 = this.textInputOption1.value; }
            if(this.textInputOption2.value === '') { option2 = this.state.option2; } else { option2 = this.textInputOption2.value; }
            if(this.textInputOption3.value === '') { option3 = this.state.option3; } else { option3 = this.textInputOption3.value; }
            if(this.textInputOption4.value === '') { option4 = this.state.option4; } else { option4 = this.textInputOption4.value; }
            if(this.textInputOption5.value === '') { option5 = this.state.option5; } else { option5 = this.textInputOption5.value; }
        }
        feedbackStatus = feedbackStatus === 'Active' ? 1 : 0;
        feedbackText = ReplaceAllContent('\n','<br/>',ReplaceAllContent(',','[;]',feedbackText));
        datatext ='{"feedback_text":"'+feedbackText+'","feedback_number":"'+feedbackNumber+'","feedback_type":"'+feedbackType+'","feedback_status":"'+feedbackStatus+'","option1":"'+option1+'","option2":"'+option2+'","option3":"'+option3+'","option4":"'+option4+'","option5":"'+option5+'","feedback_docid":"'+this.state.feedback_docid+'","form_id":"form-edit"}';
        urlFetch = global.ctconfig.urlLink+"/feedbackedit";
    } else {
        feedbackStatus = this.state.feedback_status === 'Active' ? 1 : 0;
        feedbackText = ReplaceAllContent('\n','<br/>',ReplaceAllContent(',','[;]',this.state.feedback_text));
        datatext ='{"feedback_text":"'+feedbackText+'","feedback_number":"'+this.state.feedback_number+'","feedback_type":"'+this.state.feedback_type+'","feedback_status":"'+feedbackStatus+'","option1":"'+this.state.option1+'","option2":"'+this.state.option2+'","option3":"'+this.state.option3+'","option4":"'+this.state.option4+'","option5":"'+this.state.option5+'","form_id":"form-add"}';
        urlFetch = global.ctconfig.urlLink+"/feedbackadd";
    }
    var data = JSON.parse(datatext);
    fetch(urlFetch, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
    }).then(res => {
        if(res.status === 200)
            return res.json() 
    }).then( resJson => {
        if(resJson.status === '200') {
            var docId = resJson.values;
            window.location.href=global.ctconfig.urlHost+'/ctviewfeedback/'+docId;
        } else {
            console.log('Something happened wrong');
        }
        document.getElementById('loading-area').style.display = 'none'; 
        if(resJson.status === '200') {
        } else {
            console.log('Something happened wrong');
        }
    }).catch(err => { 
        document.getElementById('loading-area').style.display = 'none'; 
    });
  }
  handleSubmit(e) {
    e.preventDefault();
    if(this.state.feedback_text !== '' && this.state.feedback_number !== '' && this.state.feedback_type !== '' && this.state.feedback_status !== '') {
        this.submitData();
    } else {
        var arrfieldName = ['feedback_text','feedback_number','feedback_type','feedback_status'];
        var arrvalue = [this.textInputFeedbackText.value,this.textInputFeedbackNumber.value,this.textInputFeedbackType.value,this.textInputFeedbackStatus.value];
        for(var ck in arrfieldName) {
            this.validateField(arrfieldName[ck], arrvalue[ck]);
        }
    }
  }
  componentDidMount() {
    document.getElementById('loading-area').style.display = 'block'; 
    document.getElementById('loading-area').style.display = 'none'; 
    // var geturl = this.props.location; 
    // var arrString = geturl.pathname;
    var arrString = window.location.pathname;
    var arrUrl = [];
    arrUrl = arrString.split('/');
    var typelink = arrUrl[2];
    var docid = arrUrl[3];
    if(typelink === 'cteditfeedback') {
        if(docid !== '') { 
            fetch(global.ctconfig.urlLink+"/feedbackview/"+docid, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }
                }).then(res => {
                if(res.status === 200) 
                    return res.json() 
                }).then( resJson => {
                if(resJson.status === '200') {
                    var resultContent = resJson.values[0].content[0].maincontent;
                    var lText="",lNumber="",lType="",lStatus="";
                    var lOption1="",lOption2="",lOption3="",lOption4="",lOption5="";
                    
                    if(resultContent.feedbackText !== undefined) {
                        lText = ReplaceAllContent('<br/>','\n',ReplaceAllContent('[;]',',',resultContent.feedbackText));
                    }
                    if(resultContent.feedbackNumber !== undefined) { lNumber = resultContent.feedbackNumber; }
                    if(resultContent.feedbackType !== undefined) { lType = resultContent.feedbackType; }
                    if(resultContent.feedbackStatus !== undefined) { lStatus = resultContent.feedbackStatus === 1 ? 'Active' : 'Inactive'; }
                    if(resultContent.option1 !== undefined) { lOption1 = resultContent.option1; }
                    if(resultContent.option2 !== undefined) { lOption2 = resultContent.option2; }
                    if(resultContent.option3 !== undefined) { lOption3 = resultContent.option3; }
                    if(resultContent.option4 !== undefined) { lOption4 = resultContent.option4; }
                    if(resultContent.option5 !== undefined) { lOption5 = resultContent.option5; }
                    this.setState({
                        stTitle: 'Edit Question',
                        feedback_docid: resJson.values[0].docid,
                        feedback_text: lText,
                        feedback_number: lNumber,
                        feedback_type: lType,
                        feedback_status: lStatus,
                        option1: lOption1,
                        option2: lOption2,
                        option3: lOption3,
                        option4: lOption4,
                        option5: lOption5,
                        formErrors: {feedback_text: '', feedback_number: '', feedback_type: '', feedback_status: '', option1: '', option2: '', option3: '', option4: '', option5: ''},
                        feedbacktextValid: false,
                        feedbacknumberValid: false,
                        feedbacktypeValid: false,
                        feedbackstatusValid: false,
                        option1Valid: false,
                        option2Valid: false,
                        option3Valid: false,
                        option4Valid: false,
                        option5Valid: false,
                        formValid: false,
                    }) 
                } else {
                    console.log('Something wrong happened');
                }
                }).catch(err => err);
        }
    } else if(typelink === 'ctaddfeedback') {
        this.setState( prevState => {
            return {
                ...prevState.stTitle, stTitle: 'New Question'
            }
        })
    }    
  }
  render() {
   return (
    <div className="main-container">
      <div  className="ct-header-main"><CTHeader/></div>
          <div className="flex-container">
              <div className="sidebar-main"><CTSidemenu/></div>
              <div className="main-content">
                <div className="ct-stTitle">Feedback</div>
                {((localStorage.getItem('ctulevel') === global.ctconfig.userlevel[0]) || (JSON.parse(localStorage.getItem('cturole'))[2] === "ON") || (JSON.parse(localStorage.getItem('cturole'))[3] === "ON") || (JSON.parse(localStorage.getItem('cturole'))[4] === "ON"))? 
                <div>
                <div className="stIndiTitle">Feedback { this.state.stTitle !== ''? '> '+this.state.stTitle : '' }</div>
                <div className="mgT30">
                    {/* ====== */}
                    <div  className="content-container card card-body">
                        <div className="flex-container">
                            <div className="st2Title w70p">{ this.state.stTitle.includes("New")? 'Add ' : '' } {this.state.stTitle}</div>
                            <div className="divRight w30p"><div><button className="backButton" onClick={this.handleBack}>&#9664; back</button></div></div>
                        </div>
                        <div id="FormCenter" className="mgT20">
                            <div id="FormCourseInfo">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="flex-container">
                                        <input type="hidden" required name="feedback_docid" value={this.state.feedback_docid}/>
                                    </div>
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="feedback_number">Number</label>
                                        <div className="w70p">
                                            <input type="number" className="form-control" name="feedback_number"
                                                placeholder={this.state.feedback_number}
                                                value={this.state.feedback_number}
                                                onChange={this.handleUserInput}  
                                                ref={(input) => this.textInputFeedbackNumber = input}/>
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.feedback_number}/>  }</div>
                                        </div>
                                    </div>
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="feedback_text">Question</label>
                                        <div className="w70p">
                                            <textarea name="feedback_text" className="form-control" 
                                                    rows={5} cols={30} 
                                                    value={this.state.feedback_text} 
                                                    onChange={this.handleUserInput} 
                                                    ref={(input) => this.textInputFeedbackText = input}/>
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.feedback_text}/>  }</div>
                                        </div>
                                    </div>
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="feedback_type">Type</label>
                                        <div className="w70p">
                                            <select name="feedback_type" className="form-control" 
                                                value={this.state.feedback_type} 
                                                onChange={this.handleUserSelect}
                                                ref={(input) => this.textInputFeedbackType = input}
                                                disabled={this.state.input_disabled}
                                            >
                                                <option value="" key="">-- please select --</option>
                                                <option value="Multiple Choice" key="multiple_choice">Multiple Choice</option>
                                                <option value="Essay" key="multiple_answer">Essay</option>
                                            </select>
                                        </div>
                                    </div>
                                    { 
                                        this.state.feedback_type === 'Multiple Choice' ? (
                                            <div>
                                                <div className="row-field mgT20">
                                                    <label className="labelFormL" htmlFor="option1">Option 1</label>
                                                    <div className="w70p">
                                                        <input type="text" className="form-control" name="option1"
                                                            placeholder={this.state.option1}
                                                            value={this.state.option1}
                                                            onChange={this.handleUserInput} 
                                                            ref={(input) => this.textInputOption1 = input}
                                                            disabled={this.state.input_disabled}
                                                            required/>
                                                        <div className="mgT05">
                                                            { <FormErrors formErrors={this.state.formErrors.option1}/>  }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row-field mgT20">
                                                    <label className="labelFormL" htmlFor="option2">Option 2</label>
                                                    <div className="w70p">
                                                        <input type="text" className="form-control" name="option2"
                                                            placeholder={this.state.option2}
                                                            value={this.state.option2}
                                                            onChange={this.handleUserInput} 
                                                            ref={(input) => this.textInputOption2 = input}
                                                            disabled={this.state.input_disabled}
                                                            required/>
                                                        <div className="mgT05">
                                                            { <FormErrors formErrors={this.state.formErrors.option2}/>  }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row-field mgT20">
                                                    <label className="labelFormL" htmlFor="option3">Option 3</label>
                                                    <div className="w70p">
                                                        <input type="text" className="form-control" name="option3"
                                                            placeholder={this.state.option3}
                                                            value={this.state.option3}
                                                            onChange={this.handleUserInput} 
                                                            ref={(input) => this.textInputOption3 = input}
                                                            disabled={this.state.input_disabled}/>
                                                        <div className="mgT05">
                                                            { <FormErrors formErrors={this.state.formErrors.option3}/>  }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row-field mgT20">
                                                    <label className="labelFormL" htmlFor="option4">Option 4</label>
                                                    <div className="w70p">
                                                        <input type="text" className="form-control" name="option4"
                                                            placeholder={this.state.option4}
                                                            value={this.state.option4}
                                                            onChange={this.handleUserInput} 
                                                            ref={(input) => this.textInputOption4 = input}
                                                            disabled={this.state.input_disabled}/>
                                                        <div className="mgT05">
                                                            { <FormErrors formErrors={this.state.formErrors.option4}/>  }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row-field mgT20">
                                                    <label className="labelFormL" htmlFor="option5">Option 5</label>
                                                    <div className="w70p">
                                                        <input type="text" className="form-control" name="option5"
                                                            placeholder={this.state.option5}
                                                            value={this.state.option5}
                                                            onChange={this.handleUserInput} 
                                                            ref={(input) => this.textInputOption5 = input}
                                                            disabled={this.state.input_disabled}/>
                                                        <div className="mgT05">
                                                            { <FormErrors formErrors={this.state.formErrors.option5}/>  }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : ''
                                    }
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="feedback_status">Status</label>
                                        <div className="w70p">
                                            <select name="feedback_status" className="form-control" 
                                                value={this.state.feedback_status} 
                                                onChange={this.handleUserSelect}
                                                ref={(input) => this.textInputFeedbackStatus = input}
                                                disabled={this.state.input_disabled}
                                            >
                                                <option value="" key="">-- please select --</option>
                                                <option value="Active" key="status_active">Active</option>
                                                <option value="Inactive" key="status_inactive">Inactive</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row-field mgT30 mgB30">
                                        <div className="labelFormL"></div>
                                        <div className="w70p"><button type="submit" className="w100p ct-submitButton">Submit</button></div>
                                    </div>
                                </form>
                            </div>
                           
                        </div>
                    </div>
                    {/* ====== */}
                  </div>  
                </div>
                :   
                <div><CTNA/></div>
                }
              </div>
          </div>
          <div id="loading-area" className="divHide loading"></div> 
    </div>
   );
  }
}
 
export default CTFeedbackForm;