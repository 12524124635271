import React, { Component } from 'react';
import '../ctinc/ct-Style-2.css';
import '../ctinc/ct-Global';
import CTHeader from './ct-fragmentheader';
import CTSidemenu from './ct-fragmentsidemenu';
import CTNA from './ct-fragmentna';
import EditIcon from '@material-ui/icons/Edit';
import DescriptionIcon from '@material-ui/icons/Description';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';

const authKey = global.ctconfig.authBearerKey+"##"+global.ctconfig.kindcode[39];
  
function urladdFeedback() {
    window.location.href=global.ctconfig.urlHost+'/ctaddfeedback';
}

class CTFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
        stTitle: '',
        pageShow: '10',
        keyword: '',
        styleTb: 'table',
        data: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadData = this.loadData.bind(this);
  }
  loadData() {
    document.getElementById('loading-area').style.display = 'block'; 
    var datatext ='{"keyword":"'+this.state.keyword+'","pageShow":"'+this.state.pageShow+'"}';
    var data = JSON.parse(datatext); 
    fetch(global.ctconfig.urlLink+"/feedbacklist", {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
    }).then(response => {
        if(response.status === 200) {
            return response.json()
        } else {
            console.log('Something happened wrong');
        }
    }).then(resJson => {
        document.getElementById('loading-area').style.display = 'none'; 
        var styleTb = 'table';
        if(resJson.status === '200' && resJson.values.length > 0) { styleTb = 'table table-striped'; } 
        this.setState({
            styleTb: styleTb,
            data: resJson
        })
    }).catch(err => {
        document.getElementById('loading-area').style.display = 'none'; 
    });
  }
  handleChange(e) {
    this.setState({ [e.target.name] : e.target.value });
  }
  handleUserSelect = (e) => {
    const value = e.target.value;
    this.setState( prevState => {
        return {  
            ...prevState.pageShow, pageShow: value
        }
    })
  }    
  handleSubmit(e) {
    e.preventDefault();
    this.loadData();
  }
  handlePublish(valId,content,nextstat) {
    document.getElementById('loading-area').style.display = 'block';
    var datatext ='{"feedback_text":"'+content.feedbackText+'","feedback_number":"'+content.feedbackNumber+'","feedback_type":"'+content.feedbackType+'","feedback_status":"'+nextstat+'","option1":"'+content.option1+'","option2":"'+content.option2+'","option3":"'+content.option3+'","option4":"'+content.option4+'","option5":"'+content.option5+'","feedback_docid":"'+valId+'","form_id":"form-edit"}';
    var urlFetch = global.ctconfig.urlLink+"/feedbackedit";
    var data = JSON.parse(datatext);
    fetch(urlFetch, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
    }).then(res => {
        if(res.status === 200)
            return res.json() 
    }).then( resJson => {
        if(resJson.status === '200') {
            // var docId = resJson.values;
            window.location.href=global.ctconfig.urlHost+'/ctfeedback/';
        } else {
            console.log('Something happened wrong');
        }
        document.getElementById('loading-area').style.display = 'none'; 
        if(resJson.status === '200') {
        } else {
            console.log('Something happened wrong');
        }
    }).catch(err => { 
        document.getElementById('loading-area').style.display = 'none'; 
    });
  }
  componentDidMount() {
    this.loadData();
  }

  render() {
    var feedbackList = [];
    if(this.state.data.values.length > 0 && this.state.data.status === '200') {
      this.state.data.values.sort((a, b) => a.content.feedbackNumber - b.content.feedbackNumber);
      for(var ls=0; ls < this.state.data.values.length; ls++) {
          var retResult = this.state.data.values[ls];
          var editURL = global.ctconfig.urlHost+"/cteditfeedback/"+retResult.docid;
          var viewURL = global.ctconfig.urlHost+"/ctviewfeedback/"+retResult.docid;
          var iconPub = <ToggleOffIcon className="iconGreyOff cursor-on mgR20" onClick={this.handlePublish.bind(this, retResult.docid, retResult.content, 1)}/>;
          if(retResult.content.feedbackStatus === 1 ) { iconPub =  <ToggleOnIcon className="iconGrey cursor-on mgR20" onClick={this.handlePublish.bind(this, retResult.docid, retResult.content, 0)}/> }

          feedbackList.push(<tr key={retResult.docid}>
              <th scope="row-field">{retResult.content.feedbackNumber}</th>
              <td>
                {retResult.content.feedbackText}
                <ol className="list-alpha">
                  {retResult.content.option1 ? <li>{retResult.content.option1}</li> : ''}
                  {retResult.content.option2 ? <li>{retResult.content.option2}</li> : ''}
                  {retResult.content.option3 ? <li>{retResult.content.option3}</li> : ''}
                  {retResult.content.option4 ? <li>{retResult.content.option4}</li> : ''}
                  {retResult.content.option5 ? <li>{retResult.content.option5}</li> : ''}
                </ol>
              </td>
              <td>{retResult.content.feedbackType}</td>
              <td>
                <a href={editURL}><EditIcon className="iconGrey mgR20" title="edit" /></a>
                <a href={viewURL}><DescriptionIcon className="iconGrey mgR20" title="view" /></a>
                {iconPub}
              </td>
            </tr>)
      }
    } else {
        feedbackList.push(<tr key="1">
            <td colSpan="5" className="textCenter"><div className="mgT20 mgB20">:: no record ::</div></td>
          </tr>)
    }
   return (
    <div className="main-container">
      <div  className="ct-header-main"><CTHeader/></div>
          <div className="flex-container">
              <div className="sidebar-main"><CTSidemenu/></div>
              <div className="main-content">
                <div className="ct-stTitle">Feedback</div>
                {((localStorage.getItem('ctulevel') === global.ctconfig.userlevel[0]) || (JSON.parse(localStorage.getItem('cturole'))[2] === "ON") || (JSON.parse(localStorage.getItem('cturole'))[3] === "ON") || (JSON.parse(localStorage.getItem('cturole'))[4] === "ON"))? 
                <div>
                  <div className="stIndiTitle">List of Questions</div>
                  <div className="mgT30">
                      {/*--*/}
                      <div className="content-container card card-body">
                          <div className="row show">
                              <div className="col-6 divMiddle">
                              </div>
                              <div className="col-6 form-inline justify-content-end">
                                  <button className="ct-genButton mgL20" onClick={urladdFeedback}><AddCircleOutlineIcon className="iconWhite mgR05"></AddCircleOutlineIcon>Add New Question</button>
                              </div>
                          </div>
                          <div className="row-field mgT20">
                              <table className={this.state.styleTb}>
                              <thead>
                                  <tr key="0">
                                  <th scope="col">#</th>
                                  <th scope="col">Question</th>
                                  <th scope="col">Type</th>
                                  <th scope="col" style={{width:"180px"}}>Action</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  {feedbackList}
                              </tbody>
                              </table>
                          </div>
                          <div className="row mt-3">
                              
                          </div>
                      </div>
                      {/*--*/}
                  </div>
                </div>
                :   
                <div><CTNA/></div>
                }
              </div>
          </div>
          <div id="loading-area" className="divHide loading"></div>
    </div>
   );
  }
}
 
export default CTFeedback;