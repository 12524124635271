import React, { Component } from "react";
import GoogleLogin from "react-google-login";
import { GetText, MD5, RandomNumber } from '../helper';
import { Container, Card, Form, Button } from "react-bootstrap";
import { FaInfoCircle } from "react-icons/fa";
import '../config/global';

const authKey = global.config.authBearerKey;

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
	  user_id: '',
	  fullname: '',
	  email: '',
	  phone: '',
	  username: '',
	  password: '',
	  password_confirm: '',
	  otp: '',
	  expiry: '',
	  language: localStorage.getItem('language'),
      redirect: '',
	  tab_form: 'login',
	  form_error: []
    }
	this.inputFullname = this.inputFullname.bind(this);
    this.inputEmail = this.inputEmail.bind(this);
    this.inputPhone = this.inputPhone.bind(this);
    this.inputUsername = this.inputUsername.bind(this);
    this.inputPassword = this.inputPassword.bind(this);
    this.inputPasswordConfirm = this.inputPasswordConfirm.bind(this);
    this.inputOtp = this.inputOtp.bind(this);
    this.login = this.login.bind(this);
	this.register = this.register.bind(this);
    this.activate = this.activate.bind(this);
    this.forgot = this.forgot.bind(this);
    this.reset = this.reset.bind(this);
    this.confirmOtp = this.confirmOtp.bind(this);
    this.toggleForm = this.toggleForm.bind(this);
  }

  getLanguage() {
	fetch('https://ipapi.co/json/', {
			method: "GET",
	}).then((response) => {
		if (response.status === 200) {
			return response.json();
		} else {
			console.log("Something happened wrong");
		}
	}).then((resultJson) => {
		let language = '';
		if (resultJson.country_name === 'Indonesia') { language = 'Indonesian'; } 
		else { language = 'English'; }

		let params = new URLSearchParams(window.location.search);
		let redirect = params.get('redirect');
		if (redirect) {
			this.setState({
				language: language,
				redirect: redirect
			});
		} else {
			this.setState({
				language: language
			});
		}
	}); 
  }

  setTab() {
	var url = window.location.href;
    var tabKey = url.substring(url.lastIndexOf('/') + 1);
		var split = tabKey.split('?');

    this.setState({
		tab_form: split[0]
	});

	var token = '';
	var docid = '';
	
	if (split[0] === 'activation') {
		token = split[1].substring(6);
		docid = token.substring(1,3) + token.substring(5,9) + token.substring(12,17) + token.substring(23,28) + token.substring(token.length - 4);
		this.activate(docid);					
	}
	
	if (split[0] === 'reset') {
		token = split[1].substring(6);
		docid = token.substring(1,3) + token.substring(5,9) + token.substring(12,17) + token.substring(23,28) + token.substring(token.length - 4);
		
		var key = token.substring(0,1);
		var hash = token.substring(3,5) + token.substring(9,12) + token.substring(17,23) + token.substring(28,29);
		var expiry = Math.floor(hash / key * 100).toString().substring(0,13);
		var current = new Date().getTime();

		this.setState({
			user_id: docid,
			expiry: expiry,
			tab_form: (parseInt(expiry) < parseInt(current) ? 'reset-expired' : 'reset')
		});
	}
  }
  activate(docid) {
	var dataText = '{"docid":"' + docid + '","form_id":"activation"}';
	var dataSend = JSON.parse(dataText);

	fetch(global.config.urlLink+"/activation", {
		method: "POST",
		headers: {
			'Accept': "application/json",
			'Content-Type': "application/json",
			'Access-Control-Allow-Origin': "*",
			'Authorization': authKey
		},
		body: JSON.stringify(dataSend),
	})
	.then((result) => {
		if (result.status === 200) {
			return result.json();
		} else {
			console.log("Something happened wrong");
		}
	})
	.then((resultJson) => {
		var error = [];
		if (resultJson.values[0].error) {
			error.push('wrong_token');
			this.setState({
				form_error: error
			});
		} else if (resultJson.values[0].docid) {
			// console.log(resultJson.values[0]);
		}
	});
  }

  inputFullname(event) {
	var error = [];
	if (event.target.value.length > 80) {
		document.getElementById('submit').setAttribute('disabled', 'true');
		error.push('fullname_max');
	} else {
		document.getElementById('submit').removeAttribute('disabled');
	}
	this.setState({ 
		fullname: event.target.value,
		form_error: error
	});
  }
  inputEmail(event) {
    this.setState({ 
      email: event.target.value,
      form_error: []
    });
  }

  inputPhone(event) {
    this.setState({ 
      phone: event.target.value,
      form_error: []
    });
  }
  inputUsername(event) {
    this.setState({ 
      username: event.target.value,
      form_error: []
    });
  }

  inputPassword(event) {
    this.setState({ 
      password: event.target.value,
      form_error: []
    });
  }
  inputPasswordConfirm(event) {		
    var error = [];
    if (this.state.password !== event.target.value) {
      document.getElementById('submit').setAttribute('disabled', 'true');
      error.push('password_same');
      this.setState({
        form_error: error
      });
    } else {
      document.getElementById('submit').removeAttribute('disabled');
      this.setState({
        form_error: []
      });
    }
    this.setState({ 
      password_confirm: event.target.value
    });
  }

  inputOtp(event) {
    this.setState({ 
      otp: event.target.value,
      form_error: []
    });
  }
  login(event) {
    event.preventDefault();
    document.getElementById('submit').innerText = 'Loading...';
    document.getElementById('submit').setAttribute('disabled', 'true');

    var error = [];
    if (!(this.state.username)) {
      error.push('username');
    }
    if (!(this.state.password)) {
      error.push('password');
    }
    if (error.length) {
      this.setState({
        form_error: error
      });
      document.getElementById('submit').innerText = 'Login';
      document.getElementById('submit').removeAttribute('disabled');
      return;
    } else {
	
		var dataText = '{"user_email":"' + this.state.username + '", "user_username":"' + this.state.username + '","user_password":"' + MD5(this.state.password) + '","user_language":"' + this.state.language + '","form_id":"login-email"}';
		var dataSend = JSON.parse(dataText);

		fetch(global.config.urlLink+"/ceklogin", {
			method: "POST",
			headers: {
				'Accept': "application/json",
				'Content-Type': "application/json",
				'Access-Control-Allow-Origin': "*",
				'Authorization': authKey
			},
			body: JSON.stringify(dataSend),
		})
		.then((result) => {
			if (result.status === 200) {
				return result.json();
			} else {
				console.log("Something happened wrong");
			}
		})
		.then((resultJson) => {
			var error = [];
			if (resultJson.values[0].error) {
				error.push('invalid');
				this.setState({
					form_error: error
				});
				document.getElementById('submit').innerText = 'Login';
				document.getElementById('submit').removeAttribute('disabled');
				if(resultJson.values[0].error === "OTP required") { 
					window.location.href = "/confirm-otp";
				}
			} else if (resultJson.values[0].docid) {
				var member = resultJson.values[0];
				var picavatar = "";
				var setlangz = this.state.language;
				var companyDomain = "";
				if (member.memberPicture !== undefined && member.memberPicture !== "") { picavatar = member.memberPicture; }
				if(member.memberLanguage !== undefined && member.memberLanguage !== "undefined" && member.memberLanguage !== "") { setlangz = member.memberLanguage; }
				if(member.companyName !== undefined && member.companyName !== "undefined" && member.companyName !== "") { companyDomain = member.companyName; }

				localStorage.setItem("logged_in", 1);
				//localStorage.setItem("google_id", member.memberUserId);
				localStorage.setItem("doc_id", member.docid);
				localStorage.setItem("name", member.memberName);
				localStorage.setItem("email", member.memberEmail);
				localStorage.setItem("status", member.memberPlan);
				localStorage.setItem("ulvl", member.userLevel);
				localStorage.setItem("avatar", picavatar);
				localStorage.setItem("language", setlangz);
				localStorage.setItem("token", member.uniqueToken);
				localStorage.setItem("mcomp", companyDomain);
				window.location.href = "/" + this.state.redirect;
			}
			
		})
		.catch((err) => {
			alert(err);
			window.location.href = "/" + this.state.redirect;
		});
	}
  }
  register(event) {
    event.preventDefault();
    document.getElementById('submit').innerText = 'Loading...';
    document.getElementById('submit').setAttribute('disabled', 'true');

    var error = [];
    if (!(this.state.fullname)) {
      error.push('fullname');
    }
    if (this.state.fullname.length > 80) {
      error.push('fullname_max');
    }
    if (!(this.state.email)) {
      error.push('email');
    }
    if (!(this.state.password)) {
      error.push('password');
    }
    if (!(this.state.password_confirm)) {
      error.push('password_confirm');
    }
		if (this.state.password !== this.state.password_confirm) {
      error.push('password_same');
    }
    if (error.length) {
      this.setState({
        form_error: error
      });
      document.getElementById('submit').innerText = GetText() ? "Daftar" : "Register";
      document.getElementById('submit').removeAttribute('disabled');
      return;
    }
		
		// var dataText = '{"user_id":"' + RandomNumber(21) + '","user_fullname":"' + this.state.fullname + '","user_email":"' + this.state.email + '","user_phone":"' + this.state.phone + '","user_password":"' + MD5(this.state.password) + '","user_language":"' + this.state.language + '","form_id":"register"}';
		var dataText = '{"user_id":"","user_fullname":"' + this.state.fullname + '","user_email":"' + this.state.email + '","user_phone":"' + this.state.phone + '","user_password":"' + MD5(this.state.password) + '","user_language":"' + this.state.language + '","form_id":"register"}';
		var dataSend = JSON.parse(dataText);

		fetch(global.config.urlLink+"/register", {
			method: "POST",
			headers: {
				'Accept': "application/json",
				'Content-Type': "application/json",
				'Access-Control-Allow-Origin': "*",
				'Authorization': authKey
			},
			body: JSON.stringify(dataSend),
		})
		.then((result) => {
			if (result.status === 200) {
				return result.json();
			} else {
				console.log("Something happened wrong");
			}
		})
		.then((resultJson) => {
			var error = [];
			if (resultJson.values[0].error) {
				error.push('inuse');
				this.setState({
					form_error: error
				});
				document.getElementById('submit').innerText = 'Login';
				document.getElementById('submit').removeAttribute('disabled');
			} else {
				window.location.href = "/register-success";				
			}
		})
		.catch((err) => {
			alert(err);
			window.location.href = "/" + this.state.redirect;
		});
  }

  forgot(event) {
    event.preventDefault();
    document.getElementById('submit').innerText = 'Loading...';
    document.getElementById('submit').setAttribute('disabled', 'true');

    var error = [];
    if (!(this.state.email)) {
      error.push('email');
    }
    if (error.length) {
      this.setState({
        form_error: error
      });
      document.getElementById('submit').innerText = GetText() ? "Kirim Link" : "Send Link";
      document.getElementById('submit').removeAttribute('disabled');
      return;
    }
		
		var dataText = '{"user_email":"' + this.state.email + '","form_id":"forgot"}';
		var dataSend = JSON.parse(dataText);

		fetch(global.config.urlLink+"/forgot", {
			method: "POST",
			headers: {
				'Accept': "application/json",
				'Content-Type': "application/json",
				'Access-Control-Allow-Origin': "*",
				'Authorization': authKey
			},
			body: JSON.stringify(dataSend),
		})
		.then((result) => {
			if (result.status === 200) {
				return result.json();
			} else {
				console.log("Something happened wrong");
			}
		})
		.then((resultJson) => {
			var error = [];
			if (resultJson.values[0].error) {
				error.push('invalid');
				this.setState({
					form_error: error
				});
				document.getElementById('submit').innerText = GetText() ? "Kirim Link" : "Send Link";
				document.getElementById('submit').removeAttribute('disabled');
			} else {
				window.location.href = "/forgot-success";
				
			}
		})
		.catch((err) => {
			alert(err);
			window.location.href = "/" + this.state.redirect;
		});
  }

  reset(event) {
    event.preventDefault();
    document.getElementById('submit').innerText = 'Loading...';
    document.getElementById('submit').setAttribute('disabled', 'true');

    var error = [];
    if (!(this.state.password)) {
      error.push('password');
    }
    if (!(this.state.password_confirm)) {
      error.push('password_confirm');
    }
		if (this.state.password !== this.state.password_confirm) {
      error.push('password_same');
    }
    if (error.length) {
      this.setState({
        form_error: error
      });
      document.getElementById('submit').innerText = GetText() ? "Simpan" : "Save";
      document.getElementById('submit').removeAttribute('disabled');
      return;
    }
		
		var dataText = '{"user_id":"' + this.state.user_id + '","user_password":"' + MD5(this.state.password) + '","form_id":"reset"}';
		var dataSend = JSON.parse(dataText);

		fetch(global.config.urlLink+"/passwordreset", {
			method: "POST",
			headers: {
				'Accept': "application/json",
				'Content-Type': "application/json",
				'Access-Control-Allow-Origin': "*",
				'Authorization': authKey
			},
			body: JSON.stringify(dataSend),
		})
		.then((result) => {
			if (result.status === 200) {
				return result.json();
			} else {
				console.log("Something happened wrong");
			}
		})
		.then((resultJson) => {
			if (resultJson.values[0].error) {
				document.getElementById('submit').innerText = (GetText() ? 'Simpan' : 'Save');
				document.getElementById('submit').removeAttribute('disabled');
			} else {
				window.location.href = "/reset-success";				
			}
		})
		.catch((err) => {
			alert(err);
			window.location.href = "/" + this.state.redirect;
		});
	}

  confirmOtp(event) {
    event.preventDefault();
    document.getElementById('submit').innerText = 'Loading...';
    document.getElementById('submit').setAttribute('disabled', 'true');

    var error = [];
    if (!(this.state.otp)) {
      error.push('otp');
    }
    if (error.length) {
      this.setState({
        form_error: error
      });
      document.getElementById('submit').innerText = GetText() ? "Kirim" : "Submit";
      document.getElementById('submit').removeAttribute('disabled');
      return;
    }
		
		var dataText = '{"otpcode":"' + this.state.otp + '","form_id":"confirm-otp"}';
		var dataSend = JSON.parse(dataText);

		fetch(global.config.urlLink+"/ceklogin", {
			method: "POST",
			headers: {
				'Accept': "application/json",
				'Content-Type': "application/json",
				'Access-Control-Allow-Origin': "*",
				'Authorization': authKey
			},
			body: JSON.stringify(dataSend),
		})
		.then((result) => {
			if (result.status === 200) {
				return result.json();
			} else {
				console.log("Something happened wrong");
			}
		})
		.then((resultJson) => {
			document.getElementById('submit').innerText = (GetText() ? 'Kirim' : 'Submit');
			document.getElementById('submit').removeAttribute('disabled');
			if (resultJson.values[0].error) {
				var error = [];
				error.push('invalid');
				this.setState({
					form_error: error
				});
			} else {
				var member = resultJson.values[0];
				var picavatar = "";
				var setlangz = this.state.language;
				var companyDomain = "";
				if (member.memberPicture !== undefined && member.memberPicture !== "") { picavatar = member.memberPicture; }
				if(member.memberLanguage !== undefined && member.memberLanguage !== "undefined" && member.memberLanguage !== "") { setlangz = member.memberLanguage; }
				if(member.companyName !== undefined && member.companyName !== "undefined" && member.companyName !== "") { companyDomain = member.companyName; }
				localStorage.setItem("logged_in", 1);
				//localStorage.setItem("google_id", member.memberUserId);
				localStorage.setItem("doc_id", member.docid);
				localStorage.setItem("name", member.memberName);
				localStorage.setItem("email", member.memberEmail);
				localStorage.setItem("status", member.memberPlan);
				localStorage.setItem("ulvl", member.userLevel);
				localStorage.setItem("avatar", picavatar);
				localStorage.setItem("language", setlangz);
				localStorage.setItem("token", member.uniqueToken);
				localStorage.setItem("mcomp", companyDomain);
				window.location.href = "/" + this.state.redirect;			
			}
		})
		.catch((err) => {
			alert(err);
			window.location.href = "/" + this.state.redirect;
		});
	}
	error = (response) => {
		console.log("Something happened wrong");
		document.getElementsByClassName("err-area")[0].style.display = "block";
	}

	success = (response) => {
		document.getElementsByClassName("err-area")[0].style.display = "none";
		var dataText = '{"user_email":"' + response.profileObj.email + '","user_id":"' + response.profileObj.googleId + '","user_fullname":"' + response.profileObj.name + '","user_picture":"' + response.profileObj.imageUrl + '","user_language":"' + this.state.language + '"}';
		var dataSend = JSON.parse(dataText);

		fetch(global.config.urlLink+"/ceklogin", {
			method: "POST",
			headers: {
				'Accept': "application/json",
				'Content-Type': "application/json",
				'Access-Control-Allow-Origin': "*",
				'Authorization': authKey
			},
			body: JSON.stringify(dataSend),
		})
		.then((response) => {
			if (response.status === 200) {
				return response.json();
			} else {
				console.log("Something happened wrong");
			}
		})
		.then((resultJson) => {
			var udocid = response.profileObj.docid;
			var ufullname = response.profileObj.name;
			var uemailname = response.profileObj.email;
			var picavatar = response.profileObj.imageUrl;
			var setlangz = this.state.language;
			var mplan = "Guest";
			var ulevel = "User";
			var uniqueToken = "", companyDomain="";
			if (resultJson.values[0].docid) {
				var member = resultJson.values[0];
				if(member.docid !== undefined && member.docid !== "") { udocid = member.docid; }
				if(member.memberPicture !== undefined && member.memberPicture !== "") { picavatar = member.memberPicture; }
				if(member.memberName !== undefined && member.memberName !== "") { ufullname = member.memberName; }
				if(member.memberEmail !== undefined && member.memberEmail !== "") { uemailname = member.memberEmail; }
				if(member.memberLanguage !== undefined && member.memberLanguage !== "undefined" && member.memberLanguage !== "") { setlangz = member.memberLanguage; }
				if(member.memberPlan !== undefined && member.memberPlan !== "") { mplan = member.memberPlan; }
				if(member.userLevel !== undefined && member.userLevel !== "") { ulevel = member.userLevel; }
				if(member.uniqueToken !== undefined && member.uniqueToken !== "") { uniqueToken = member.uniqueToken; }
				if(member.companyName !== undefined && member.companyName !== "") { companyDomain = member.companyName; }
			}
			localStorage.setItem("logged_in", 1);
			//localStorage.setItem("google_id", response.profileObj.googleId);
			localStorage.setItem("doc_id", udocid);
			localStorage.setItem("avatar", picavatar);
			localStorage.setItem("name", ufullname);
			localStorage.setItem("email", uemailname);
			localStorage.setItem("language", setlangz);
			localStorage.setItem("status", mplan);
			localStorage.setItem("ulvl", ulevel);
			localStorage.setItem("token", uniqueToken);
			localStorage.setItem("mcomp", companyDomain);
			window.location.href = "/" + this.state.redirect;

		})
		.catch((err) => {
			alert(err);
			window.location.href = "/" + this.state.redirect;
		});
	}
	toggleForm(tab) {		
		window.location.href = "/" + tab;
  	}

	componentDidMount() {
		document.getElementsByClassName("err-area")[0].style.display = "none";
		if(localStorage.getItem('language') === undefined || localStorage.getItem('language') === "") {
			this.getLanguage();
		}
		this.setTab();
	}
	
	render() {
		return (
			<div className="Login">
				<Container>
					<div className="row justify-content-center p-5">
						<div className="col-lg-5 text-center">
						{ this.state.tab_form === 'login' && <h5 className="mb-3">{GetText() ? "Masuk ke" : "Login to"}</h5> }
							{ this.state.tab_form === 'register' && <h5 className="mb-3">{GetText() ? "Daftar ke" : "Register"}</h5> }
							{ this.state.tab_form === 'forgot' && <h5 className="mb-3">{GetText() ? "Lupa Password" : "Forgot Password"}</h5> }
							{ this.state.tab_form === 'forgot-success' && <h5 className="mb-3">{GetText() ? "Lupa Password" : "Forgot Password"}</h5> }
							{ this.state.tab_form === 'register-success' && <h5 className="mb-3">{GetText() ? "Pendaftaran Berhasil" : "Register Success"}</h5> }
							{ this.state.tab_form === 'activation' && <h5 className="mb-3">{GetText() ? "Aktivasi Berhasil" : "Activation Success"}</h5> }
							{ this.state.tab_form === 'reset' && <h5 className="mb-3">{GetText() ? "Reset Password" : "Reset Password"}</h5> }
							{ this.state.tab_form === 'reset-success' && <h5 className="mb-3">{GetText() ? "Reset Password" : "Reset Password"}</h5> }
							{ this.state.tab_form === 'reset-expired' && <h5 className="mb-3">{GetText() ? "Reset Password" : "Reset Password"}</h5> }
							{ this.state.tab_form === 'confirm-otp' && <h5 className="mb-3">{GetText() ? "Konfirmasi OTP" : "Confirm OTP"}</h5> }
							<h2 className="mb-5 text-red text-800">NOVAIO ACADEMY</h2>
							<div className="err-area" style={{display: "none"}}>
								<div className="d-flex justify-content-center">
									<div><FaInfoCircle className="text-danger"/></div>
									<div className="ml-2 text-danger">{GetText() ? "tidak berhasil login" : "failed to login"}</div>
								</div>
							</div>
							{
								this.state.tab_form === 'login' && (
									<Card className="card-pop">
										<Card.Body className="text-left">
											<Form onSubmit={this.login}>
												<Form.Group>
													<label className="font-weight-bold">{GetText() ? "Username" : "Username"}</label>
													{this.state.form_error.some(item => item === 'username') ? <span className="error-message">{GetText() ? 'Wajib diisi!' : 'Required!'}</span> : ''}
													<input className="form-control" type="text" name="username" value={this.state.username} onChange={this.inputUsername} autoComplete="username"/>
												</Form.Group>
												<Form.Group>
													<label className="font-weight-bold">{GetText() ? "Password" : "Password"}</label>
													{this.state.form_error.some(item => item === 'password') ? <span className="error-message">{GetText() ? 'Wajib diisi!' : 'Required!'}</span> : ''}
													<input className="form-control" type="password" name="password" value={this.state.password} onChange={this.inputPassword} autoComplete="current-password"/>
												</Form.Group>
												{/* <p className="text-link text-right mb-0" onClick={() => this.toggleForm('forgot')}>{GetText() ? "Lupa password?" : "Forgot password?"}</p> */}
												{this.state.form_error.some(item => item === 'invalid') ? <p className="error-message d-block mb-2 float-none text-center">{GetText() ? "Username atau password tidak ditemukan" : "Invalid username or password"}</p> : ''}
												<Button className="d-block mt-2 mb-4 mx-auto" variant="primary" size="lg" type="submit" value="Login" id="submit">{GetText() ? "Login" : "Login"}</Button>
											</Form>
											<p className="text-center mb-4">{GetText() ? "atau gunakan akun Google" : "or use you Google account"}</p>
											<GoogleLogin
												clientId= {global.config.clientLoginId} 
												className="btn-google"
												onSuccess={this.success}
												onFailure={this.error}
												cookiePolicy={"single_host_origin"}
												onClick={() => {
													document.head.append(
														<meta httpEquiv="Cross-Origin-Opener-Policy" content="same-origin" />
													);
												}}
											>	
												<img src="https://1000logos.net/wp-content/uploads/2016/11/New-Google-Logo.jpg" alt="Google Login Button"/>
												{GetText() ? "Masuk dengan akun Google" : "Sign in with Google"}
											</GoogleLogin>
											
											{/*
												GetText() ? (
													<p className="text-link text-center mt-3" onClick={() => this.toggleForm('register')}>
													Belum memiliki akun? <span className="text-primary">Register</span>
													</p>
												) : (
													<p className="text-link text-center mt-3" onClick={() => this.toggleForm('register')}>
														Not having an account yet? <span class='text-primary'>Register</span>
													</p>
												)
											*/
											}
										</Card.Body>
									</Card>
								)
							}
							{
								this.state.tab_form === 'register' && (
									<Card className="card-pop">
										<Card.Body className="text-left">
											<Form onSubmit={this.register}>
												<Form.Group>
													<label className="font-weight-bold">{GetText() ? "Nama Lengkap" : "Full Name"}</label>
													{this.state.form_error.some(item => item === 'fullname') ? <span className="error-message">{GetText() ? 'Wajib diisi!' : 'Required!'}</span> : ''}
													{this.state.form_error.some(item => item === 'fullname_max') ? <span className="error-message">{GetText() ? 'Maksimal 80 karakter!' : 'Max 80 characters!'}</span> : ''}
													<input className="form-control" type="text" name="fullname" value={this.state.fullname} onChange={this.inputFullname}/>
												</Form.Group>
												<Form.Group>
													<label className="font-weight-bold">{GetText() ? "Email" : "Email"}</label>
													{this.state.form_error.some(item => item === 'email') ? <span className="error-message">{GetText() ? 'Wajib diisi!' : 'Required!'}</span> : ''}
													<input className="form-control" type="email" name="email" value={this.state.email} onChange={this.inputEmail} autoComplete="username"/>
												</Form.Group>
												<Form.Group>
													<label className="font-weight-bold">{GetText() ? "Nomor HP" : "Phone Number"}</label>
													<input className="form-control" type="tel" name="phone" value={this.state.phone} onChange={this.inputPhone}/>
												</Form.Group>
												<Form.Group>
													<label className="font-weight-bold">{GetText() ? "Password" : "Password"}</label>
													{this.state.form_error.some(item => item === 'password') ? <span className="error-message">{GetText() ? 'Wajib diisi!' : 'Required!'}</span> : ''}
													<input className="form-control" type="password" name="password" value={this.state.password} onChange={this.inputPassword} autoComplete="new-password"/>
												</Form.Group>
												<Form.Group>
													<label className="font-weight-bold">{GetText() ? "Konfirmasi Password" : "Confirm Password"}</label>
													{this.state.form_error.some(item => item === 'password_confirm') ? <span className="error-message">{GetText() ? 'Wajib diisi!' : 'Required!'}</span> : ''}
													{this.state.form_error.some(item => item === 'password_same') ? <span className="error-message">{GetText() ? 'Password tidak cocok!' : 'Passwords don\'t match!'}</span> : ''}
													<input className="form-control" type="password" name="password_confirm" value={this.state.password_confirm} onChange={this.inputPasswordConfirm} autoComplete="confirm-password"/>
												</Form.Group>
												{this.state.form_error.some(item => item === 'inuse') ? <p className="error-message d-block mb-2 float-none text-center">{GetText() ? "Email sudah digunakan" : "Email already in use"}</p> : ''}
												<Button className="d-block mt-2 mb-4 mx-auto" variant="primary" size="lg" type="submit" value="Register" id="submit">{GetText() ? "Daftar" : "Register"}</Button>
											</Form>
											<p className="text-center mb-4">{GetText() ? "atau gunakan akun Google" : "or use you Google account"}</p>
											<GoogleLogin
												clientId= {global.config.clientLoginId} 
												className="btn-google"
												onSuccess={this.success}
												onFailure={this.error}
												cookiePolicy={"single_host_origin"}
											>
												<img src="https://1000logos.net/wp-content/uploads/2016/11/New-Google-Logo.jpg" alt="Google Login Button"/>
												{GetText() ? "Masuk dengan akun Google" : "Sign in with Google"}
											</GoogleLogin>
											{
												GetText() ? (
													<p className="text-link text-center mt-3" onClick={() => this.toggleForm('login')}>
													Sudah memiliki akun? <span className="text-primary">Login</span>
													</p>
												) : (
													<p className="text-link text-center mt-3" onClick={() => this.toggleForm('login')}>
														Already have an account? <span class='text-primary'>Login</span>
													</p>
												)
											}
										</Card.Body>
									</Card>
								)
							}
							{
								this.state.tab_form === 'register-success' && (
									<Card className="card-pop">
										<Card.Body className="text-left">
											<p className="text-center mt-3">{GetText() ? 'Silakan cek email anda untuk melanjutkan' : 'Please check your email to continue'}</p>
											{
												GetText() ? (
													<p className="text-link text-center mt-3" onClick={() => this.toggleForm('login')}>
														Kembali ke halaman <span className="text-primary">Login</span>
													</p>
												) : (
													<p className="text-link text-center mt-3" onClick={() => this.toggleForm('login')}>
														Back to <span class='text-primary'>Login</span> page
													</p>
												)
											}
										</Card.Body>
									</Card>
								)
							}
							{
								this.state.tab_form === 'activation' && (
									<Card className="card-pop">
										<Card.Body className="text-left">
											<p className="text-center mt-3">{GetText() ? 'Selamat, akun anda sudah berhasil diaktifkan' : 'Congratulations, your account has been activated'}</p>
											{
												GetText() ? (
													<p className="text-link text-center mt-3" onClick={() => this.toggleForm('login')}>
														Silakan <span className="text-primary">Login</span> untuk melanjutkan
													</p>
												) : (
													<p className="text-link text-center mt-3" onClick={() => this.toggleForm('login')}>
														Please <span class='text-primary'>Login</span> to continue
													</p>
												)
											}
										</Card.Body>
									</Card>
								)
							}
							{
								((this.state.tab_form === 'forgot') || (this.state.tab_form === 'forgot-password')) && (
									<Card className="card-pop">
										<Card.Body className="text-left">
											<Form onSubmit={this.forgot}>
												<Form.Group>
													<label className="font-weight-bold">{GetText() ? "Email" : "Email"}</label>
													{this.state.form_error.some(item => item === 'email') ? <span className="error-message">{GetText() ? 'Wajib diisi!' : 'Required!'}</span> : ''}
													<input className="form-control" type="text" name="email" value={this.state.email} onChange={this.inputEmail} autoComplete="username"/>
												</Form.Group>
												{this.state.form_error.some(item => item === 'invalid') ? <p className="error-message d-block mb-2 float-none text-center">{GetText() ? "Email tidak ditemukan" : "Invalid email"}</p> : ''}
												<Button className="d-block mt-2 mb-4 mx-auto" variant="primary" size="lg" type="submit" value="Forgot" id="submit">{GetText() ? "Kirim Link" : "Send Link"}</Button>
											</Form>
											{
												GetText() ? (
													<p className="text-link text-center mt-3" onClick={() => this.toggleForm('login')}>
														Kembali ke halaman <span className="text-primary">Login</span>
													</p>
												) : (
													<p className="text-link text-center mt-3" onClick={() => this.toggleForm('login')}>
														Back to <span class='text-primary'>Login</span> page
													</p>
												)
											}
										</Card.Body>
									</Card>
								)
							}
							{
								this.state.tab_form === 'forgot-success' && (
									<Card className="card-pop">
										<Card.Body className="text-left">
											<p className="text-center mt-3">{GetText() ? 'Silakan cek email anda untuk mereset password' : 'Please check your email to reset the password'}</p>
											{
												GetText() ? (
													<p className="text-link text-center mt-3" onClick={() => this.toggleForm('login')}>
														Kembali ke halaman <span className="text-primary">Login</span>
													</p>
												) : (
													<p className="text-link text-center mt-3" onClick={() => this.toggleForm('login')}>
														Back to <span class='text-primary'>Login</span> page
													</p>
												)
											}
										</Card.Body>
									</Card>
								)
							}
							{
								this.state.tab_form === 'reset' && (
									<Card className="card-pop">
										<Card.Body className="text-left">
											<Form onSubmit={this.reset}>
												<Form.Group>
													<label className="font-weight-bold">{GetText() ? "Password Baru" : "New Password"}</label>
													{this.state.form_error.some(item => item === 'password') ? <span className="error-message">{GetText() ? 'Wajib diisi!' : 'Required!'}</span> : ''}
													<input className="form-control" type="password" name="password" value={this.state.password} onChange={this.inputPassword} autoComplete="new-password"/>
												</Form.Group>
												<Form.Group>
													<label className="font-weight-bold">{GetText() ? "Konfirmasi Password" : "Confirm Password"}</label>
													{this.state.form_error.some(item => item === 'password_confirm') ? <span className="error-message">{GetText() ? 'Wajib diisi!' : 'Required!'}</span> : ''}
													{this.state.form_error.some(item => item === 'password_same') ? <span className="error-message">{GetText() ? 'Password tidak cocok!' : 'Passwords don\'t match!'}</span> : ''}
													<input className="form-control" type="password" name="password_confirm" value={this.state.password_confirm} onChange={this.inputPasswordConfirm} autoComplete="confirm-password"/>
												</Form.Group>
												<Button className="d-block mt-2 mb-4 mx-auto" variant="primary" size="lg" type="submit" id="submit">{GetText() ? "Simpan" : "Save"}</Button>
											</Form>
										</Card.Body>
									</Card>
								)
							}
							{
								this.state.tab_form === 'reset-success' && (
									<Card className="card-pop">
										<Card.Body className="text-left">
											<p className="text-center mt-3">{GetText() ? 'Reset password berhasil!' : 'Reset password success!'}</p>
											{
												GetText() ? (
													<p className="text-link text-center mt-3" onClick={() => this.toggleForm('login')}>
														Kembali ke halaman <span className="text-primary">Login</span>
													</p>
												) : (
													<p className="text-link text-center mt-3" onClick={() => this.toggleForm('login')}>
														Back to <span class='text-primary'>Login</span> page
													</p>
												)
											}
										</Card.Body>
									</Card>
								)
							}
							{
								this.state.tab_form === 'reset-expired' && (
									<Card className="card-pop">
										<Card.Body className="text-left">
											<p className="text-center mt-3">{GetText() ? 'Batas tanggal untuk reset password telah kedaluwarsa!' : 'The password reset date has expired!'}</p>
											{
												GetText() ? (
													<p className="text-link text-center mt-3" onClick={() => window.location.href = '/forgot'}>
														Silakan lakukan <span className="text-primary">Reset Password</span> kembali
													</p>
												) : (
													<p className="text-link text-center mt-3" onClick={() => window.location.href = '/forgot'}>
														Please do <span class='text-primary'>Reset Password</span> again
													</p>
												)
											}
											{
												GetText() ? (
													<div>
														<p className="text-link text-center mt-3">atau</p>
														<p className="text-link text-center mt-3" onClick={() => this.toggleForm('login')}>
															Kembali ke halaman <span className="text-primary">Login</span>
														</p>
													</div>
												) : (
													<div>
														<p className="text-link text-center mt-3">or</p>
														<p className="text-link text-center mt-3" onClick={() => this.toggleForm('login')}>
															Back to <span class='text-primary'>Login</span> page
														</p>
													</div>
												)
											}
										</Card.Body>
									</Card>
								)
							}
							{
								(this.state.tab_form === 'confirm-otp') && (
									<Card className="card-pop">
										<Card.Body className="text-left">
											<Form onSubmit={this.confirmOtp}>
												<Form.Group>
													<p className="text-center">{GetText() ? "Kami telah mengirimkan 6 digit kode OTP ke email Anda" : "We have sent a 6 digits OTP Code to your email"}</p>
													<label className="font-weight-bold">{GetText() ? "Kode OTP" : "OTP Code"}</label>
													{this.state.form_error.some(item => item === 'otp') ? <span className="error-message">{GetText() ? 'Wajib diisi!' : 'Required!'}</span> : ''}
													<input className="form-control text-center" type="text" name="otp" value={this.state.otp} onChange={this.inputOtp} autoComplete="otp"/>
												</Form.Group>
												{this.state.form_error.some(item => item === 'invalid') ? <p className="error-message d-block mb-2 float-none text-center">{GetText() ? "OTP tidak ditemukan" : "Invalid email"}</p> : ''}
												<Button className="d-block mt-2 mb-4 mx-auto" variant="primary" size="lg" type="submit" value="Submit" id="submit">{GetText() ? "Kirim" : "Submit"}</Button>
											</Form>
											{
												GetText() ? (
													<p className="text-link text-center mt-3" onClick={() => this.toggleForm('login')}>
														Kembali ke halaman <span className="text-primary">Login</span>
													</p>
												) : (
													<p className="text-link text-center mt-3" onClick={() => this.toggleForm('login')}>
														Back to <span class='text-primary'>Login</span> page
													</p>
												)
											}
										</Card.Body>
									</Card>
								)
							}
							<div className="mt-5">
								<a className="text-dark" href="/privacy-policy">{GetText() ? "Kebijakan Pribadi" : "Privacy Policy"}</a>
							</div>
						</div>
					</div>
				</Container>
			</div>
		);
	}
}
