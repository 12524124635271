import React, { Component } from 'react';
import Moment from 'moment';
import { GetText, ReplaceText, ToggleArrow } from '../helper';
import { Container, Tabs, Tab, Accordion, Card, Modal } from 'react-bootstrap';
import { FaLinkedin, FaExternalLinkAlt, FaRegCalendar, FaRegClock, FaChevronRight, FaChevronDown} from 'react-icons/fa';
import CourseBlock from './course/Block';
import ImgCertificate from '../img/certificate.png';
import '../config/global';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[10];
const authKey2 = global.config.authBearerKey+"##"+global.config.kindcode[13];
const authKey3 = global.config.authBearerKey+"##"+global.config.kindcode[35];

function getCourseRelated(courseId,tags) {
  return new Promise((resolve) => {
    var dataText ='{"keyword":"","pageShow":"10","category":"'+tags+'","lang":"'+localStorage.getItem('language')+'","form_id":"pubonly"}';
    var dataSend = JSON.parse(dataText);

    fetch(global.config.urlLink+"/courselist", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey
      }, 
      body : JSON.stringify(dataSend)
    })
    .then(response => {
      if(response.status === 200) {
        return response.json()
      } else {
        console.log('Something happened wrong');
      }
    })
    .then(resultJson => {
      var resultData = [];
      var inum=0;
      for (var i in resultJson.values) {
        var content = resultJson.values[i].content;
        content.id = resultJson.values[i].docid;
        if (content.id !== courseId) {
          resultData.push(content);
        }
        inum++;
        if(inum === resultJson.values.length) {
          resolve(resultData);
        }
      }
    });
  });
}
function getUserOrder(courseId) {
  return new Promise((resolve) => {
    fetch(global.config.urlLink+"/traininguserview/" + localStorage.getItem('doc_id'), {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey2
      }
    }).then(response => {
      if(response.status === 200) {
        return response.json();
      } else {
        console.log('Something happened wrong.');
      }
    }).then(resJson => {
      var arrCoursePurchase = [];
      var purchases = resJson.values[0].scontent2;
      if (purchases.length > 0) {
        purchases
        .sort((a,b) => b.subcontent.purchaseDate._seconds - a.subcontent.purchaseDate._seconds)
        .forEach((cPurchaseItem, j) => {
          if(cPurchaseItem.subcontent.courseId === courseId) {
            arrCoursePurchase.push(cPurchaseItem);
          }
          if((j+1) === purchases.length) {
            resolve(arrCoursePurchase);
          }
        })
      } else {
        resolve(arrCoursePurchase);
      }
    });
  });
}
function getTutorInfo(tutors) {
  return new Promise((resolve) => {
    var tutorIds = tutors.split(',');
    var arrTutors = [];
    if (tutorIds.length > 0) {
      tutorIds.forEach((tutorId, i) => {
        fetch(global.config.urlLink+"/tutorview/" + tutorId, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey3
          }
        })
        .then(response => {
          if (response.status === 200) {
            return response.json();
          } else {
            console.log('Something happened wrong');
          }
        })
        .then(resultJson => {
          var tutor = resultJson.values[0].content[0].maincontent;
          tutor.tutorBio = ReplaceText(tutor.tutorBio);
          arrTutors.push(tutor);
          if((i+1) === tutorIds.length) {
            resolve(arrTutors);
          }
        });
      });
    } else {
      resolve(arrTutors);
    }
  });
}
function getAgendaInfo(dataAgenda,courseDatez) {
  return new Promise((resolve) => {
    var agendas = dataAgenda;
    var contents = [];
    var sTimez = "", eTimez = "";
      
    if (agendas.length > 0) {
      agendas.forEach((agenda, i) => {
        var content2 = agenda.subcontent;
        var date = Moment.unix(courseDatez._seconds).format('D MMMM Y');
        var time = Moment.unix(content2.agendaTime._seconds).format('HH:mm');
        var last = Moment.unix(content2.agendaTime._seconds + (content2.agendaDuration * 60)).format('HH:mm');
        content2.id = agenda.subdocid;
        contents.push(content2);
        if (i === 0) {
          sTimez = Moment(date + ' ' + time).format('YYYYMMDD[T]HHmmss[Z]');
        }
        if (i === (agendas.length - 1)) {
          eTimez = Moment(date + ' ' + last).format('YYYYMMDD[T]HHmmss[Z]');
        }
        if((i+1) === agendas.length) {
          var agendaSend = {};
          agendaSend.agendacontent = contents;
          agendaSend.startTime = sTimez;
          agendaSend.endTime = eTimez;
          resolve(agendaSend);
        }
      });
    } else {
      var agendaSend = {};
      agendaSend.agendacontent = contents;
      agendaSend.startTime = sTimez;
      agendaSend.endTime = eTimez;
      resolve(agendaSend);
    }
  });
}

async function getAllDatas(id,tutors,agendas,courseDatez,category) {
    const userorderresult = getUserOrder(id);
    const tutorsresult = getTutorInfo(tutors);
    const agendaresult = getAgendaInfo(agendas,courseDatez);
    const relatedresult = getCourseRelated(id,category);

    const [thing1, thing2, thing3, thing4] = await Promise.all([userorderresult,tutorsresult,agendaresult,relatedresult]);
    var arrReturnValue = {};
    arrReturnValue.purchasedata = thing1;
    arrReturnValue.tutordata = thing2;
    arrReturnValue.agendadata = thing3;
    arrReturnValue.relateddata = thing4;
    
    return arrReturnValue;
}

export default class MemberClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      title: '',
      category: '',
      description: '',
      currency: '',
      price: 0,
      date: '',
      time: '',
      seconds: '',
      image: '',
      video: '',
      recording: '',
      tutor: [],
      tutor_name: '',
      agenda: [],
      purchase_id: '',
      purchase_status: '',
      purchase_confirmation: '',
      meet_link: '',
      meet_code: [],
      start_time: '',
      end_time: '',
      courseRelated: [],
      modal_show: false
    }
  }

  componentDidMount() {
    var url = window.location.href;
    var id = url.substring(url.lastIndexOf('/') + 1);
    
    fetch(global.config.urlLink+"/courseview/" + id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey
      }
    })
    .then(response => {
      if (response.status === 200) {
        return response.json();
      } else {
        console.log('Something happened wrong');
      }
    })
    .then(resultJson => {
      var id = resultJson.values[0].docid;
      var content = resultJson.values[0].content[0].maincontent;
      var language = localStorage.getItem('language');
      
      if (language !== content.courseLanguage) {
        this.setState({
          modal_show: true
        });
      }


      getAllDatas(id,content.tutorId,resultJson.values[0].scontent,content.courseDate,content.categoryName)
      .then((response) => {
        var purchaseIdz="", purchaseStatuz="", purchaseConfDatez="";
        if(response.purchasedata.length > 0) {
          purchaseIdz = response.purchasedata[0].subdocid;
          purchaseStatuz = response.purchasedata[0].subcontent.purchaseStatus;
          purchaseConfDatez = response.purchasedata[0].subcontent.paymentConfirmationDate;
        }
        this.setState({
          id: id,
          category: content.categoryName,
          title: content.courseTitle,
          meet_link: content.courseMeetLink,
          meet_code: content.courseMeetLink ? content.courseMeetLink.split("/") : '',
          excerpt: ReplaceText(content.courseShortDesc),
          description: ReplaceText(content.courseLongDesc),
          image: content.coursePicture,
          video: content.courseVideo,
          recording: content.courseRecording,
          date: Moment.unix(content.courseDate._seconds).format('D MMMM Y'),
          time: Moment.unix(content.courseStartTime._seconds).format('HH:mm'),
          seconds: content.courseDate._seconds,
          currency: content.courseCurrency,
          price: new Intl.NumberFormat().format(content.coursePrice),
          link: '/course/' + id,

          agenda: response.agendadata.agendacontent,
          start_time: response.agendadata.startTime,
          end_time: response.agendadata.endTime,
          tutor: response.tutordata,
          purchase_id: purchaseIdz,
          purchase_status: purchaseStatuz,
          purchase_confirmation: purchaseConfDatez,
          courseRelated: response.relateddata
        }) 
      });
    });
  }
  changeLanguage() {
    var currentLanguage = localStorage.getItem('language');
    var newLanguage = currentLanguage === "Indonesian" ? "English" : "Indonesian";
    localStorage.setItem('language', newLanguage);
    window.location.reload();
  }
  
  render() {    
    return (
      <div className="detail-course">
        <section className="bg-dark">
        <div className="bg-dark mt--4">
          <Container>
            <div className="row py-5 mb-5">
              <div className="col-md-4 text-center">
                <img className="mxw mb-4 br-8" src={this.state.image} alt={this.state.title}/>
              </div>
              <div className="col-md-8 mt-4 mt-md-0">
                <h4 className="mb-4 text-white">{this.state.title}</h4>
                <p className="text-white">{this.state.excerpt}</p>
                <div className="separator line"></div>
                <div className="row">
                  <div className="col-md-6">
                    <p className="text-white">
                      <strong>{GetText() ? "Tanggal" : "Date"}</strong>
                      <br/>
                      <span className="mr-2">
                        <FaRegCalendar className="mr-1"/>
                        {this.state.date}
                      </span>
                      <span>
                        <FaRegClock className="mr-1"/>
                        {this.state.time}
                      </span>
                    </p>
                    <p className="text-white">
                      <strong>{GetText() ? "Harga" : "Price"}</strong>
                      <br/>
                      {this.state.currency === "IDR" ? "Rp " : "$"}
                      {this.state.price}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p className="text-white">
                      <strong>{GetText() ? "Kategori" : "Category"}</strong>
                      <br/>
                      {this.state.category}
                    </p>
                    <p className="text-white">
                      <strong>{GetText() ? "Pembicara" : "Trainer"}</strong>
                      <br/>                      
                      {this.state.tutor_name}
                    </p>
                  </div>
                </div>
                <div className="separator line"></div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="mt-2" id="order-button">
                      {
                        this.state.purchase_id ? (
                          this.state.purchase_status === 'Active' ? (
                            ((Moment.unix(new Date())._i / 1000000) > Number(this.state.seconds)) && this.state.recording ? (
                              <a href={this.state.recording} className="btn btn-lg btn-primary mb-2" target="_blank" rel="noreferrer">
                                {GetText() ? "Lihat Rekaman" : "View Recording"}
                              </a>
                            ) : (
                              <div className="row">
                                <div className="col-md-6">
                                  <p className="text-white">
                                    <strong>Google Meet Link</strong>
                                    <br/>
                                    <a className="text-white" href={this.state.meet_link} target="_blank" rel="noreferrer">{this.state.meet_code[3].toUpperCase()}&nbsp;&nbsp;<FaExternalLinkAlt/></a>
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <a 
                                    className="btn btn-outline-light btn-calendar"
                                    href={"http://www.google.com/calendar/event?action=TEMPLATE&dates=" + this.state.start_time + "%2F" + this.state.end_time + "&text=" + encodeURIComponent(this.state.title) + "&details=Welcome%20to%20Novaio%20Academy!%0AYou%20are%20invited%20to%20attend%20our%20class%20" + encodeURIComponent(this.state.title) + "%20on%20" + encodeURIComponent(this.state.date) + ".%0A%0A" + encodeURIComponent(this.state.excerpt) + "%0A%0AAttend%20the%20class%20by%20opening%20this%20link%20" + encodeURIComponent(this.state.meet_link) + ".%0A%0AThank%20you."}
                                    target="_blank" rel="noreferrer">
                                    {GetText() ? "Tambahkan ke Calendar" : "Add to Calendar"}
                                  </a>
                                </div>
                              </div>
                            )
                          ) : (
                            <div className="row">
                              <div className="col-md-6">
                                <p className="text-white">
                                  <strong>{GetText() ? "Status Pesanan" : "Order Status"}</strong>
                                  <br/>
                                  {this.state.purchase_confirmation ? "VERIFYING" : "UNPAID"}
                                </p>
                              </div>
                              <div className="col-md-6">
                                <a className="btn btn-outline-light btn-calendar" href={"/invoice/course/" + this.state.purchase_id}>{GetText() ? "Lihat Invoice" : "View Invoice"}</a>
                              </div>
                            </div>
                          )
                        ) : (
                          <a href={'/checkout/' + this.state.id} className="btn btn-lg btn-primary mb-2">{GetText() ? "Pesan Tiket" : "Book Ticket"}</a>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
          
        </section>
        <Modal animation={false} show={this.state.modal_show}>
          <Modal.Header>
            <Modal.Title>{GetText() ? "Konten tidak tersedia!" : "Content not found!"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{GetText() ? "Konten ini hanya tersedia dalam bahasa Inggris. Ubah bahasa atau kembali ke Beranda." : "This content is only available on Indonesian version. Change language or back to Home."}</Modal.Body>
          <Modal.Footer>
            <a className="btn btn-primary" href="#!" onClick={this.changeLanguage}>{GetText() ? "Ubah bahasa" : "Change language"}</a>
            <a className="btn btn-outline-primary" href="/">{GetText() ? "Kembali ke Beranda" : "Back to Home"}</a>
          </Modal.Footer>
        </Modal>
        <section id="content-detail">
          <Container>
            <div className="row m-0">
              <div className="col-md-6">
                <div className="bg-light p-3 br-8">
                  <h4 className="text-left mb-4">{GetText() ? "Pengantar" : "Introduction"}</h4>
                  {
                    this.state.video ? (
                      <div className="embed-wrapper mb-4">
                        <div className="embed-container">
                          <video className="embed-content" name="video_intro" autoPlay={false} controls={true} controlsList="nodownload" onContextMenu={(e)=> e.preventDefault()}>
                            <source src={this.state.video} type="video/mp4"></source>
                          </video>
                        </div>
                      </div>
                    ) : ''
                  }
                  <p>{this.state.description}</p>
                </div>
              </div>
              <div className="col-md-6 p-4">
                <Tabs className="justify-content-between nav-scroll" defaultActiveKey="agenda" id="tab">
                  <Tab className="pt-4" eventKey="agenda" title="Agenda">
                    {
                      this.state.agenda.length > 0 ? (
                        this.state.agenda
                        .sort((a,b) => a.agendaTime._seconds - b.agendaTime._seconds)
                        .map((agenda, i) => {
                          return (
                            <div className="row" key={"agenda-" + i}>
                              <div className="col-md-3">
                                <label className="label-rounded-2">{Moment.unix(agenda.agendaTime._seconds).format('HH:mm')}</label>
                              </div>
                              <div className="col-md-9 text-left">
                                <h5>{agenda.agendaTitle}</h5>
                                <p>{ReplaceText(agenda.agendaDesc)}</p>
                              </div>
                            </div>
                          )
                        })
                      ) : 'No Agenda.'
                    }
                  </Tab>
                  <Tab className="pt-4" eventKey="tutor" title="Trainer">
                    {
                      this.state.tutor.map((tutor, i) => {
                        return(
                          <div className="row mb-4" key={'tutor-' + i}>
                            <div className="col-md-3 mb-4 mb-md-0">
                              <img className="wh-120" src={tutor.tutorPicture} alt={tutor.tutorName}/>
                            </div>
                            <div className="col-md-9 text-left">
                              <h5>{tutor.tutorName}</h5>
                              <p>{tutor.tutorBio}</p>
                              <div>
                                {tutor.tutorLinkedin.trim() ? <a href={tutor.tutorLinkedin} className="btn btn-dark btn-icon-circle mr-4" target="_blank" rel="noreferrer"><FaLinkedin/></a> : ""}
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </Tab>
                  <Tab className="pt-4" eventKey="certificate" title="Certificate">
                    <img className="w-100 mb-4" src={ImgCertificate} alt="Certificate"/>
                    <p>Professional Certificate programs are series of courses designed by industry leaders and top universities to build and enhance critical professional skills needed to succeed in today's most in-demand fields. Find the program that meets your specific needs. Stand out and succeed at work.</p>
                  </Tab>
                  <Tab className="pt-4" eventKey="faq" title="FAQ">
                    <Accordion className="card-faq">
                      <Card id="accordion-0">
                        <Accordion.Toggle as={Card.Header} eventKey="0" onClick={()=> ToggleArrow(0)}>
                          What do the courses include?
                          <FaChevronRight className="accordion-toggle toggle-close"/>
                          <FaChevronDown className="accordion-toggle toggle-open d-none"/>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0" id="collapse-0">
                          <Card.Body>
                            <p>Each course is created, owned and managed by the instructor(s). The foundation of each course are its lectures, which can include videos, slides, and text. In addition, instructors can add resources and various types of practice activities, as a way to enhance the learning experience of students. .</p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      <Card id="accordion-1">
                        <Accordion.Toggle as={Card.Header} eventKey="1" onClick={()=> ToggleArrow(1)}>
                          How do I take a course?
                          <FaChevronRight className="accordion-toggle toggle-close"/>
                          <FaChevronDown className="accordion-toggle toggle-open d-none"/>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1" id="collapse-1">
                          <Card.Body>
                            <p>Courses are entirely on-demand. You can begin the course whenever you like, and there are no deadlines to complete it.</p>
                            <p>After you enroll in a course, you can access it by clicking on the course link you will receive in your confirmation email (provided you’re logged into your account). You can also begin the course by logging in and navigating to your My learning page.</p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      <Card id="accordion-2">
                        <Accordion.Toggle as={Card.Header} eventKey="2" onClick={()=> ToggleArrow(2)}>
                          Do I have to start my course at a certain time? And how long do I have to complete it?
                          <FaChevronRight className="accordion-toggle toggle-close"/>
                          <FaChevronDown className="accordion-toggle toggle-open d-none"/>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="2" id="collapse-2">
                          <Card.Body>
                          <p>As noted above, there are no deadlines to begin or complete the course. Even after you complete the course you will continue to have access to it, provided that your account’s in good standing, and we continues to have a license to the course.</p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      <Card id="accordion-3">
                        <Accordion.Toggle as={Card.Header} eventKey="3" onClick={()=> ToggleArrow(3)}>
                          Where can I go for help?
                          <FaChevronRight className="accordion-toggle toggle-close"/>
                          <FaChevronDown className="accordion-toggle toggle-open d-none"/>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="3" id="collapse-3">
                          <Card.Body>
                          <p>If you find you have a question about a paid course while you’re taking it, you can search for answers to your question in the Q&A or ask the instructor.</p>
                          <p>Our Help Center has extensive information regarding our various features, and articles with troubleshooting steps to help you quickly resolve any issues you encounter. Our Support Team is also happy to help.</p>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </Tab>
                </Tabs>  
              </div>
              {
                this.state.courseRelated.length > 0 ? (
                  <div className="col-md-12 mt-5">                      
                    <div className="bg-light p-4 br-8">
                      <h4 className="text-left mb-4">{GetText() ? "Workshops Terkait" : "Related Workshops"}</h4>
                      <div className="row">
                        {
                          this.state.courseRelated
                          .sort((a,b) => b.courseDate._seconds - a.courseDate._seconds)
                          .slice(0, 4)
                          .map((related, i) => {
                            return (
                              <div className="col-md-3" key={"block-" + i}>
                                <CourseBlock value={related} key={"related-" + i}/>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
                ) : ''
              }
            </div>
          </Container>
        </section>
      </div>
    );
  }
}
