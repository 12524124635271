import React, { Component } from 'react';
import Moment from 'moment';
import { GetText, ReplaceText } from '../helper';
import { Container } from 'react-bootstrap';
import { FaRegCalendarAlt, FaRegClock } from 'react-icons/fa';
import '../config/global';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[10];

export default class ClassDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courseList: [],
      id: '',
      title: '',
      category: '',
      date: '',
      time: '',
      excerpt: '',
      video: '',
      image: '',
      price: '',
      link: '',
      agenda: []
    }
  }

  componentDidMount() {
    var url = window.location.href;
    var id = url.substring(url.lastIndexOf('/') + 1);
    
    fetch(global.config.urlLink+"/courseview/" + id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey
      }
    })
    .then(response => {
      if (response.status === 200) {
        return response.json();
      } else {
        console.log('Something happened wrong');
      }
    })
    .then(resultJson => {
      var content = resultJson.values[0].content[0].maincontent;
      var agendas = resultJson.values[0].scontent;
      var contentAgenda = [];
      if (agendas.length > 0) {
        agendas.forEach(agenda => {
          var contentAg = agenda.subcontent;
          contentAg.id = agenda.subdocid;
          contentAgenda.push(contentAg);
        });                
      }

      this.setState({
        id: resultJson.values[0].docid,
        title: content.courseTitle,
        category: content.categoryName,
        date: Moment.unix(content.courseDate._seconds).format('D MMMM Y'),
        time: Moment.unix(content.courseStartTime._seconds).format('HH:mm'),
        excerpt: ReplaceText(content.courseShortDesc),
        video: content.courseVideo,
        image: content.coursePicture,
        currency: content.courseCurrency,
        price: "Rp " + (new Intl.NumberFormat().format(content.coursePrice)),
        link: '/course/' + resultJson.values[0].docid,
        agenda: contentAgenda
      });
    });
  }
  
  render() {
    return (
      <div className="course-detail">
        <section>
          <Container>
            <div className="row align-items-start mx-0">
              <div className="col-md-5 bg-light p-5 br-8">
                <div className="row">
                  <div className="col">
                    <h4>Workshop</h4>                
                  </div>
                  <div className="col-auto text-right">              
                    <p className="text-red mt-1"><strong>{this.state.category}</strong></p>
                  </div>
                </div>
                <div className="text-center">
                  <img className="mxw br-8" src={this.state.image} alt={this.state.title}/>
                  <h5 className="my-4">{this.state.title}</h5>
                  <p>
                    <FaRegCalendarAlt className="mr-1"/>
                    {this.state.date}
                    <FaRegClock className="ml-3 mr-1"/>
                    {this.state.time}
                  </p>
                  <p>{this.state.currency === "IDR" ? "Rp " : "$"} <strong>{this.state.price}</strong></p>
                  <a href={"/login/?redirect=course/" + this.state.id} className="btn btn-lg btn-primary mb-3">{GetText() ? "Pesan Tiket" : "Book Ticket"}</a>
                </div>
              </div>
              <div className="col-md-7 agenda p-5 br-8">
                <h4 className="mb-4">{GetText() ? "Pengantar" : "Introduction"}</h4>
                {
                  this.state.video ? (
                    <div className="embed-wrapper mb-3">
                      <div className="embed-container">
                        <video className="embed-content" name="video_intro" autoPlay={false} controls={true} controlsList="nodownload" onContextMenu={(e)=> e.preventDefault()}>
                          <source src={this.state.video} type="video/mp4"></source>
                        </video>
                      </div>
                    </div>
                  ) : ''
                }
                <p className="mb-5">{this.state.excerpt}</p>
                {
                  this.state.agenda.length > 0 ? (
                    <div>
                      <h4 className="mb-4">Agenda</h4>
                      {
                        this.state.agenda
                        .sort((a, b) => a.agendaTime._seconds - b.agendaTime._seconds)
                        .map((agenda, i) => {
                          return (
                            <div className="row agenda-item" key={i}>
                              <div className="col-md-3">
                                <label className="label-rounded-2">{Moment.unix(agenda.agendaTime._seconds).format('HH:mm')}</label>
                              </div>
                              <div className="col-md-9 text-left">
                                <h5>{agenda.agendaTitle}</h5>
                                <p className="clamp-3">{ReplaceText(agenda.agendaDesc)}</p>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  ): ''
                }
              </div>
            </div>
          </Container>
        </section>
      </div>
    );
  }
}