import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Menu from './inc/Menu';
import Footer from './inc/Footer';
import Announcement from './inc/Announcement';
import Home from './Home';
import Login from './Login';
import CourseBrowse from './CourseBrowse';
import CourseDetail from './CourseDetail';
import LibraryBrowse from './LibraryBrowse';
import LibraryDetail from './LibraryDetail';
import Invoice from '../Invoice';
import About from '../About';
import Help from '../Help';
import Success from '../Success';
import PrivacyPolicy from '../PrivacyPolicy';
import Error403 from '../Error403';
import Error404 from '../Error404';

export default class Index extends Component {
  render() {
    return (
      <Router>
        <Menu/>
        <Announcement/>
        <div className="main">
          <Switch>
            <Route exact path='/'><Home/></Route>
            <Route path="/login"><Login/></Route>
            <Route path="/register"><Login/></Route>
            <Route path="/register-success"><Login/></Route>
            <Route path="/activation"><Login/></Route>
            <Route path="/forgot"><Login/></Route>
            <Route path="/forgot-success"><Login/></Route>
            <Route path="/reset"><Login/></Route>
            <Route path="/reset-success"><Login/></Route>
            <Route path="/reset-expired"><Login/></Route>
            <Route path="/confirm-otp"><Login/></Route>
            <Route path='/courses'><CourseBrowse/></Route>
            <Route path='/course/:slug'><CourseDetail/></Route>
            <Route path='/libraries'><LibraryBrowse/></Route>
            <Route path='/library/:slug'><LibraryDetail/></Route>
            <Route path='/invoice/:slug'><Invoice/></Route>
            <Route path='/about'><About/></Route>
            <Route path='/help'><Help/></Route>
            <Route path='/success'><Success/></Route>
            <Route path='/privacy-policy'><PrivacyPolicy/></Route>
            <Route path="/error-403"><Error403/></Route>
            <Route path="*"><Error404/></Route>
          </Switch>
        </div>
        <Footer/>
      </Router>
    );
  }
}