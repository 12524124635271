import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
// import Tab from 'react-bootstrap/Tab';
// import Tabs from 'react-bootstrap/Tabs';
import '../ctinc/ct-Style-2.css';
import '../ctinc/ct-Style.css';
import '../ctinc/popup.css'
import Popup from '../ctinc/popup';
import CTHeader from './ct-fragmentheader';
import CTSidemenu from './ct-fragmentsidemenu';
//import IUC from './ifragmentuc';
import CTNA from './ct-fragmentna';
import CTCategoryForm from './ct-category-form';
import CTCategoryDetail from './ct-category-detail';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';

const authKey = global.ctconfig.authBearerKey+"##"+global.ctconfig.kindcode[11];

class CTCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
        stTitle: '',
        pageShow: '10',
        keyword: '',
        styleTb: 'table',
        data: [],
        stPopTitle: '',
        isOpen: false,
        isOpen2: false,
        otherContent: '',
        defTabIndex: 0,
        usageFor: ''
    };
    //this.handleChange = this.handleChange.bind(this);
    //this.handleSubmit = this.handleSubmit.bind(this);
    //this.deleteHandler = this.deleteHandler.bind(this);
    this.loadData = this.loadData.bind(this);
    this.handleSelectTab = this.handleSelectTab.bind(this);
  }
  togglePopClose() {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: false,
          ...prevState.isOpen2, isOpen2: false,
      }
    })
  }  
  urlviewCat(used,id,action) {
    var stPopTitle = "";
    if(used === 'course') { stPopTitle = action+" Categories Workshop"; }
    else if(used === 'library') { stPopTitle = action+" Categories Library"; }
    this.setState( prevState => {
      return {  
        ...prevState.stPopTitle, stPopTitle: stPopTitle,  
          ...prevState.isOpen2, isOpen2: true,
          ...prevState.otherContent, otherContent : '{"usageFor":"'+used.toLowerCase()+'","catId":"'+id+'"}'
      }
    })
  }
  urlformCat(used,id,action) {
    console.log("form:"+used);
    var stPopTitle = "";
    if(used === 'course') { stPopTitle = action+" Categories Workshop"; }
    else if(used === 'library') { stPopTitle = action+" Categories Library"; }
    console.log("form:"+stPopTitle);
    this.setState( prevState => {
      return {  
          ...prevState.stPopTitle, stPopTitle: stPopTitle,
          ...prevState.isOpen, isOpen: true,
          ...prevState.otherContent, otherContent : '{"usageFor":"'+used.toLowerCase()+'","catId":"'+id+'"}'
      }
    })
  }
  urldeleteCat(used,valId) {
    var stat = false;
    stat = window.confirm("Are you sure you want to delete this data?");
    if(stat === true) {
      document.getElementById('loading-area').style.display = 'block'; 
      //var urlString = global.ctconfig.urlLink+"/categorydelete/"+valId;
      var urlString = global.ctconfig.urlLink+"/categorydelete";
      var datatext ='{"docid":"'+valId+'","numchild":1,"usageFor":"'+used.toLowerCase()+'"}';
      var data = JSON.parse(datatext); 
      fetch(urlString, {
          //method: 'DELETE',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
      }).then(res => {
        if(res.status === 200) 
            return res.json() 
      }).then( resJson => {
          console.log(resJson);
          console.log(resJson.status);
          document.getElementById('loading-area').style.display = 'none'; 
          if(resJson.status === "200") {
              window.location.reload();
              //window.location.href='/listevent';
          } else {
              console.log('Something happened wrong delete');
              console.log(resJson);
          }
      }).catch(err => {
        document.getElementById('loading-area').style.display = 'none'; 
        console.log(err);
      });
    }
  }
  handleSelectTab(idx) {
    console.log(idx);
    var used = "library";
    if(idx===1) { used = "course"; }
    this.setState( prevState => {
      return {  
          ...prevState.usageFor, usageFor: used
      }
    })
    this.loadData(used);
  }
  loadData(usedfor) {
    //document.getElementById('loading-area').style.display = 'block'; 
    var datatext ='{"keyword":"'+this.state.keyword+'","pageShow":"'+this.state.pageShow+'","usageFor":"'+usedfor+'"}';
    console.log(datatext);
    var data = JSON.parse(datatext); 
    fetch(global.ctconfig.urlLink+"/categorylist", {
        method: 'POST',
        //mode: 'no-cors', 
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
    }).then(response => {
        if(response.status === 200) {
            console.log('response');
            console.log(response);
            return response.json()
        } else {
            console.log('Something happened wrong');
            console.log(response);
        }
    }).then(resJson => {
        console.log(resJson);
        // document.getElementById('loading-area').style.display = 'none'; 
        //resJson = '{status: 200, values: { userEmail: "melia@point-star.com",userFullname: "Melia",userId: "111773733087018575892",userLevel: "Admin",userName: "melia@point-star.com",userPassword: ""}}';
        var styleTb = 'table';
        if(resJson.status === '200' && resJson.values.length > 0) { styleTb = 'table table-striped'; } 
        this.setState({
            styleTb: styleTb,
            data: resJson
        })
    }).catch(err => {
        // document.getElementById('loading-area').style.display = 'none'; 
        console.log(err);
    });
  }
  componentDidMount() {
    var currentURL = window.location.pathname;
    var arrPath = currentURL.split('/');
    //console.log(currentURL);
    // console.log(arrPath);
    // console.log(arrPath[2]);
    var usageFor = 'library'
    var  defindex = 0;
    if(arrPath[2] !== undefined && arrPath[2].toLowerCase() === "ctcategorycourse") { defindex = 1; usageFor = "course"; }
    this.setState({
      defTabIndex: defindex,
      usageFor: usageFor
    })
    //console.log("did:"+usageFor);
    this.loadData(usageFor);
  }

  contentTabs(param) {
    var stLabel = "Library"; if(param === 'course') { stLabel = "Workshop"; }
    
    var categoryList = [];
    if(this.state.data.values.length > 0 && this.state.data.status === '200') {
      for(var ls=0; ls < this.state.data.values.length; ls++) {
          var retResult = this.state.data.values[ls];
          categoryList.push(<tr key={retResult.docid}>
              <th scope="row-field">{ls+1}</th>
              <td>{retResult.content.categoryName}</td>
              <td>
                  <EditIcon className="iconGrey cursor-on mgR20" title="edit" onClick={this.urlformCat.bind(this, param,retResult.docid,'Edit')}/>
                  <DescriptionIcon className="iconGrey cursor-on mgR20" onClick={this.urlviewCat.bind(this, param,retResult.docid,'View')}/>
                  <DeleteIcon className="iconGrey cursor-on" onClick={this.urldeleteCat.bind(this, this.state.usageFor, retResult.docid)}/>
              </td>
            </tr>)
        }
    } else {
      categoryList.push(<tr key="1">
            <td colSpan="5" className="textCenter"><div className="mgT20 mgB20">:: no record ::</div></td>
          </tr>)
    }
  
    return(<div>
              <div className="row show">
                  <div className="col-6 divMiddle"></div>
                  <div className="col-6 form-inline justify-content-end">
                      <button className="ct-genButton mgL20" onClick={this.urlformCat.bind(this, param.toLowerCase(),'','Add')}><AddCircleOutlineIcon className="iconWhite mgR05"></AddCircleOutlineIcon>Add Categories {stLabel}</button>
                  </div>
              </div>
              <div className="row-field mgT20">
                  <table className={this.state.styleTb}>
                  <thead>
                      <tr key="0">
                      <th scope="col">#</th>
                      <th scope="col">Category</th>
                      <th scope="col">Action</th>
                      </tr>
                  </thead>
                  <tbody>
                      {categoryList}
                  </tbody>
                  </table>
              </div>
           </div>
    )
  }  

  render() {
    if(this.state.usageFor === '') {
      console.log("test");
      return (<div className="main-container"></div>);
    } else {
      //console.log("abc");
      var currentURL = window.location.pathname;
      var arrPath = currentURL.split('/');
      // console.log(currentURL);
      // console.log(arrPath);
      // console.log(arrPath[2]);
      // var  defindex = 0;
      // if(arrPath[2] === "course") { defindex = 1; }
      // this.setState({
      //   defTabIndex: defindex,
      //   usageFor: arrPath[2]
      // })
      var  defindex = 0;
      if(arrPath[2] !== undefined && arrPath[2].toLowerCase() === "ctcategorycourse") { defindex = 1;  }
      
      return (
        <div className="main-container">
          <div  className="ct-header-main"><CTHeader/></div>
              <div className="flex-container">
                  <div className="sidebar-main"><CTSidemenu/></div>
                  <div className="main-content">
                    <div className="ct-stTitle">Category</div>
                    {((localStorage.getItem('ctulevel') === global.ctconfig.userlevel[0]) || (JSON.parse(localStorage.getItem('cturole'))[2] === "ON") || (JSON.parse(localStorage.getItem('cturole'))[3] === "ON") || (JSON.parse(localStorage.getItem('cturole'))[4] === "ON"))? 
                    <div>
                      <div className="stIndiTitle">List of Category for Training Booking System</div>
                      <div className="mgT30">
                        {/* <Tabs
                          id="controlled-tab-example"
                          // activeKey={this.state.defTabIndex}
                          defaultActiveKey={this.state.defTabIndex}
                          onSelect={(k) => this.handleSelectTab(k)}
                          className="mb-3"
                        >
                          <Tab eventKey="library" title="Library">
                            {this.contentTabs("library")}
                          </Tab>
                          <Tab eventKey="course" title="Course">
                            {this.contentTabs("course")}
                          </Tab>
                          <Tab eventKey="test" title="Test">
                            <div>aaaa</div>
                          </Tab>
                        </Tabs> */}
                        {/*<div className="content-container card card-body"> */}
                          <Tabs defaultIndex={defindex} onSelect={this.handleSelectTab}>
                            <TabList className="tabs-class__tab-list">
                              <Tab  className="tabs-class__tab" disabledClassName="tabs-class__tab--disabled" selectedtabclassname="tabs-class__tab--selected">Library</Tab>
                              <Tab  className="tabs-class__tab" disabledClassName="tabs-class__tab--disabled" selectedtabclassname="tabs-class__tab--selected">Workshop</Tab>
                            </TabList>

                            <TabPanel className="tabs-class__tab-panel" selectedClassName="tabs-class__tab-panel--selected">{this.contentTabs("library")}</TabPanel>
                            <TabPanel className="tabs-class__tab-panel" selectedClassName="tabs-class__tab-panel--selected">{this.contentTabs("course")}</TabPanel>
                          </Tabs>
                        {/*</div>*/}
                      </div>
                      <div>
                              {this.state.isOpen && <Popup
                                content={<><CTCategoryForm/></>}
                                title={this.state.stPopTitle}
                                others={this.state.otherContent}
                                styles={"50%"}
                                handleClose={this.togglePopClose.bind(this)}
                              />}
                              {this.state.isOpen2 && <Popup
                                content={<><CTCategoryDetail/></>}
                                title={this.state.stPopTitle}
                                others={this.state.otherContent}
                                styles={"70%"}
                                handleClose={this.togglePopClose.bind(this)}
                              />}
                        </div>
                    </div>
                    :   
                    <div><CTNA/></div>
                    }
                  </div>
              </div>
              <div id="loading-area" className="divHide loading"></div>
        </div>
      );
    }
  }
}
 
export default CTCategory;