/* global gapi */
import React, { Component } from 'react';
import '../ctinc/ct-Global';
import '../ctinc/ct-Style-2.css';
import logoPS from '../../assets/logo-ps.png';
//import Credentials from '../iinc/iOACredentials.json';
import CTDashboard from './ct-homepage';
import Alert from '@material-ui/lab/Alert';

import GoogleLogin from "react-google-login";


const authKey = global.ctconfig.authBearerKey;

// class CTLogin extends React.Component {
class CTLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSignedIn: false,
            statLoad: 0
        }
        // this.handleLoad = this.handleLoad.bind(this);
        this.clearSession = this.clearSession.bind(this);
    }
    clearSession() {
        localStorage.clear();
        window.location.reload();
    }
    /*
    clearSession() {
        var auth2 = gapi.auth2.getAuthInstance();
        auth2.signOut().then(function () {
            auth2.disconnect();
            localStorage.clear();
            console.log('User signed out.');
            window.location.reload();
        });
    }
    */
    onSuccess = (res) => {
        //console.log('on success');
        console.log(res);
        //console.log(res.wt.BT);
        
        //let key;
        //let mySession = { uid: res.wt.cu, utk: res.wt.BT };
        /*var auth2 = gapi.auth2.getAuthInstance();
         auth2.signOut().then(function () {
           auth2.disconnect();
           localStorage.clear();
           alert('User signed out.');
           window.location.reload();
         }); 
         */
        //localStorage.setItem(key, JSON.stringify(mySession));
        
        /*
        const option = new gapi.auth2.SigninOptionsBuilder();
            option.setScope('https://www.googleapis.com/auth/drive');

        var googleUser = auth2.currentUser.get();
            googleUser.grant(option).then(
                function(success){
                console.log(JSON.stringify({message: "success", value: success}));
                },
                function(fail){
                alert(JSON.stringify({message: "fail", value: fail}));
                });
        */
        console.log("AuthResponse");
        console.log(res.getAuthResponse());
        console.log("BasicProf");
        console.log(res.getBasicProfile());
        console.log(res.getBasicProfile().getId());
        console.log(res.getBasicProfile().getName());
        console.log(res.getBasicProfile().getImageUrl());
        console.log(res.getBasicProfile().getEmail());        
        // var datatext = '';
        // var uidValue, utkValue;
        var datatext ='{"user_email":"'+res.getBasicProfile().getEmail()+'","user_id":"'+res.getBasicProfile().getId()+'","user_fullname":"'+res.getBasicProfile().getName()+'","user_picture":"'+res.getBasicProfile().getImageUrl()+'"}';
        console.log(datatext);
        var uidValue = res.getBasicProfile().getEmail();
        var utkValue = res.getBasicProfile().getId();
        var upicUrl = res.getBasicProfile().getImageUrl();
        /*
        if(res.wt !== undefined) {
            datatext ='{"user_email":"'+res.wt.cu+'","user_id":"'+res.wt.BT+'","user_fullname":"'+res.wt.Ad+'"}';
            uidValue = res.wt.cu;
            utkValue = res.wt.BT;
        } else {
            // Uw {
            //     Ea: "111773733087018575892", 
            //     Bc: {
            //         "access_token": "ya29.a0AfH6SMC_1GybZasaP4WyI9ChY0yfclzDADFyO2KjwRKwETrOlWZU4xb35H-t7fTL6KgvQsoT4egbyR8qXf_56dG05jrm2R0PhFTkxM7QDKis2WTukjuCrVoFdqIy_r1oYSEfQtZd5i4xJuE9KWJ9fZFcYp8uJY_MttSvmwuBLpZ_SQ"
            //         "expires_at": 1610103455954
            //         "expires_in": 3599
            //         "first_issued_at": 1610099856954
            //         "id_token": "eyJhbGciOiJSUzI1NiIsImtpZCI6IjI1MmZjYjk3ZGY1YjZiNGY2ZDFhODg1ZjFlNjNkYzRhOWNkMjMwYzUiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiODA0ODk3Nzg4OTEwLW5zOTJrcWJzMGxhdGZzajY0MGk0Y3F0djJpZzE3Y3JqLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiODA0ODk3Nzg4OTEwLW5zOTJrcWJzMGxhdGZzajY0MGk0Y3F0djJpZzE3Y3JqLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTExNzczNzMzMDg3MDE4NTc1ODkyIiwiaGQiOiJwb2ludC1zdGFyLmNvbSIsImVtYWlsIjoibWVsaWFAcG9pbnQtc3Rhci5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiYXRfaGFzaCI6IklfbGxLd29PdXJaLVlBRGo2Nks1RFEiLCJuYW1lIjoiTWVsaWEgV2luYXRhIiwicGljdHVyZSI6Imh0dHBzOi8vbGgzLmdvb2dsZXVzZXJjb250ZW50LmNvbS9hLS9BT2gxNEdpLVB3cWl1T2pvd3FUT3E3V3VLMld2QnpLcHR4ZnQ4MVU3YW1TdGJnPXM5Ni1jIiwiZ2l2ZW5fbmFtZSI6Ik1lbGlhIiwiZmFtaWx5X25hbWUiOiJXaW5hdGEiLCJsb2NhbGUiOiJlbiIsImlhdCI6MTYxMDA5OTg1NywiZXhwIjoxNjEwMTAzNDU3LCJqdGkiOiJjNTMyNGVhY2UwZWI1N2VkZWQ0YTU1MzYwYjk0MTViMDk4NmUwMjU4In0.ozuiAmc0QxHW7rOF-1ujxZYntn1tamGjef92MBH-YkhGm6oMm6XshhDpUFF0dtgOk7j_eSdYToHJv78jYX-qtRHbr1xVP9OF1LT9ODCHjLjV4PXcgLqFq21Yq-GY1GXgSAPk-9nSVVvbOkoV1T1bMHrIN-fghqc-tIy6h50_Tth3ZgE50DFO3bhYi0tm6Tx5nLwIiX8JTahACOWL0fpEOl1yU33FKipwOpQiZtd2Gy0l3oIjP-8snZhsivbONaf_ynTXolilV-lf9JAUtaZgqITtTlPrNkWHRiKv3MAtTGPRHSHZAlbXIVtabG9i8StG8DZYryVVaHQIQe7G2RzXzw"
            //         "idpId": "google"
            //         "login_hint": "AJDLj6JUa8yxXrhHdWRHIV0S13cACZrV_yNc4RmMSMPqiUVhTxGznjBqnrWjlMCq3HeQA8wx-m-215u-DD_IrumPbYvZxTdChA"
            //         "scope": "email profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid"
            //         "session_state": {extraQueryParams: {…}}
            //         "token_type": "Bearer"
            //     }, 
            //     Mt: {
            //         "Ed": "Melia Winata"
            //         "OU": "111773733087018575892"
            //         "PK": "3.googleusercontent.com/a-/AOh14Gi-PwqiuOjowqTOq7WuK2WvBzKptxft81U7amStbg=s96-c"
            //         "qW": "Melia"
            //         "tu": "melia@point-star.com"
            //         "vU": "Winata"
            //     }
            // }
            console.log("Aa");
            
            datatext ='{"user_email":"'+res.Mt.tu+'","user_id":"'+res.Mt.OU+'","user_fullname":"'+res.Mt.Ed+'"}';
            console.log(datatext);
            uidValue = res.Mt.tu;
            utkValue = res.Mt.OU;
        }
        */
        // document.getElementById("loginButton").style.display = "none";
        // document.getElementById("logoutButton").style.display = "block";
        var data = JSON.parse(datatext); 
        fetch(global.ctconfig.urlLink+"/ceklogin", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                //'Authorization': Credentials.web.client_secret,
                'Authorization': authKey,
            }, body : JSON.stringify(data)
          }).then(res2 => {
            if(res2.status === 200) 
                return res2.json() 
          }).then( resJson => {
            console.log(resJson);
            if(resJson.status === '200') {
                console.log("result");
                console.log(resJson.values[0]);
                var uroles = [];
                if(resJson.values[0].userRole !== '') {
                    var arrUserRole = resJson.values[0].userRole.split("$$");
                    for(var ur=0; ur<global.ctconfig.userrole.length; ur++) {
                        var resuroles = "";
                        for(var ir=0; ir<arrUserRole.length; ir++) {
                            console.log(global.ctconfig.userrole[ur]+" - "+arrUserRole[ir]);
                            if(global.ctconfig.userrole[ur] === arrUserRole[ir]) {
                                resuroles = "ON"; break;
                            } else { resuroles = "OFF"; }
                        }
                        //uroles = uroles.concat(resuroles);
                        uroles.push(resuroles);
                    }
                } 
                
                localStorage.setItem('ctuid', uidValue);
                localStorage.setItem('ctutk', utkValue);
                localStorage.setItem('ctudocid', resJson.values[0].docid);
                localStorage.setItem('ctuposition', resJson.values[0].userPosition);
                localStorage.setItem('ctulevel', resJson.values[0].userLevel);
                localStorage.setItem('ctufullname', resJson.values[0].userFullname);
                localStorage.setItem('cturole', JSON.stringify(uroles));
                localStorage.setItem('ctupicture', upicUrl);
                this.setState( prevState => {
                    return {  
                        ...prevState.isSignedIn, isSignedIn: true,
                        ...prevState.statLoad, statLoad: 1
                    }
                })
                window.location.reload();
                //window.location.href='/listevent';
            } else {
                console.log('Something happened wrong');
                console.log(resJson);
                document.getElementById("loginButton").style.display = "block";
                document.getElementById("errormsg").style.display = "block"; 
                this.setState( prevState => {
                    return {  
                        ...prevState.isSignedIn, isSignedIn: false,
                        ...prevState.statLoad, statLoad: 1
                    }
                })
                var auth2 = gapi.auth2.getAuthInstance();
                auth2.signOut().then(function () {
                    auth2.disconnect();
                    localStorage.clear();
                }); 
            }
          }).catch(err => err);
        
        
        /*let sessionJSON = JSON.parse(localStorage.getItem(key));
        let sessionUID = sessionJSON.uid;
        let sessionUTK = sessionJSON.utk;
        
        console.log("LS:"+sessionUID);
        console.log("TK:"+sessionUTK);
        */
        
        /*this.setState({
            isSignedIn: true,
            err: null
        }) */
    }

    onLoginFailed = (err) => {
        document.getElementById("loginButton").style.display = "block";
        document.getElementById("errormsg").style.display = "block";
        this.setState( prevState => {
            return {  
                ...prevState.isSignedIn, isSignedIn: false,
                ...prevState.statLoad, statLoad: 1
            }
        })
        /*this.setState({
            isSignedIn: false,
            error: err,
        })*/
    }
    /*
    handleLoad() {
        //console.log("Aa");
        const successCallback = this.onSuccess.bind(this);
        const failureCallback = this.onLoginFailed.bind(this);
        //try{
            gapi.load('auth2', () => {
              this.auth2 = gapi.auth2.init({
                //client_id: 'YOUR_CLIENT_ID.apps.googleusercontent.com',
                //client_id: Credentials.web.client_id,
                client_id: global.ctconfig.credClientId,
                scope: ('profile https://www.googleapis.com/auth/drive.readonly')
              })
            //   this.auth2.then((res) => {
            //     console.log('on init');
            //     //console.log(res.currentUser.get().getBasicProfile());
            //     this.setState({
            //         isSignedIn: this.auth2.isSignedIn.get(),
            //         statLoad: 1
            //     });
            //   });
              //attachSignin(this.auth2,document.getElementById('customBtn'));

            });    
        
            gapi.load('signin2', function() {
              // Method 3: render a sign in button
              // using this method will show Signed In if the user is already signed in
              var opts = {
                width: 220,
                height: 50,
                longtitle: true,
                theme: 'dark', 
                //client_id: 'YOUR_CLIENT_ID.apps.googleusercontent.com',
                //client_id: Credentials.web.client_id,
                client_id: global.ctconfig.credClientId,
                onsuccess: successCallback,
                onfailure: failureCallback
              }
              gapi.signin2.render('loginButton', opts)
              
            })
            
            
            //this.clearSession();
            localStorage.clear();
            
        // } catch (e) {
        //     console.log("aa"+e);
        //     window.location.reload();
        // }
    }
    */
    componentDidMount() {
        // window.addEventListener('load',this.handleLoad);
        //window.addEventListener('load',this.clearSession);
        document.getElementById("errormsg").style.display = 'none';
    }

    getContent() {
        //console.log("aaa:"+this.state.isSignedIn+" ** "+this.state.statLoad);
        if (this.state.isSignedIn === true) {
            return <div><CTDashboard/></div>
        } else {
            //if(this.state.statLoad === 0) {
            //    return <div>loading</div> 
            //} else {
              return (
                <div className="main-container center-screen">
                    <div className="login-area divMiddle">
                        <div className="mgT30 divCenter"><img src={logoPS} alt="pointstar" width="150"></img></div>
                        <div className="mgT20 mgB30 divCenter textBold">Sign in with your PointStar Intranet Account</div>
                        <div className="mgT20 mgB20 divCenter"><div className="divhide w70p" id="errormsg"><Alert severity="error">You are not successfully login.<br/>Please contact administrator for more information</Alert></div></div>
                        <div className="mgT30 mgB50 divCenter">
                            {/* <div id="loginButton"></div> */}
                            <div className="w50p">
                                <GoogleLogin
										clientId= {global.ctconfig.credClientId} 
										className="btn-google"
										onSuccess={this.onSuccess}
										onFailure={this.onLoginFailed}
										cookiePolicy={"single_host_origin"}
									>
										{/* <img src={process.env.PUBLIC_URL + "/img/ic_ggle.jpg"} alt="Google Login Button"/> */}
										Sign in with Google
									</GoogleLogin>
                            </div>
                            <div id="logoutButton" className="divHide">
                            <button className="ct-genButton mgL20" onClick={() => this.clearSession()}>clear</button>
                            </div>
                        </div>
                    </div>
                </div>
              )
            //}
        }
    
    }
    

    render() {
        return (
            <div>{this.getContent()}</div>
           
        )
    }
}
export default CTLogin;
