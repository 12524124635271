import React, { Component } from 'react';
import '../ctinc/ct-Style.css';
import '../ctinc/ct-Global';
import { FormErrors } from '../ctinc/error-form';

const authKey = global.ctconfig.authBearerKey+"##"+global.ctconfig.kindcode[0];

class CTProfileForm extends Component {
    constructor (props) {
        super(props);
        this.state = {
            member2_id: '',
            member2_email: '',
            member2_position: '',
            member2_name: '',
            member2_phone: '',
            formErrors: { member2_position: ''},
            member2positionValid: false,
            formValid: false,
            input_disabled: true
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value},
            () => { this.validateField(name, value)});
    }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let member2positionValid = this.state.member2positionValid;
        
        switch(fieldName) {
            case 'member2_position': 
                member2positionValid = value.trim().length > 0;
                fieldValidationErrors.member2_position = member2positionValid ? '' : 'position need to be filled';
                break;
            default:
                break;
        }
        
        this.setState({formErrors: fieldValidationErrors,
                        member2positionValid: member2positionValid,
                    }, this.validateForm);
    }
    validateForm() {
        this.setState({formValid: this.state.member2positionValid});
    }

    errorClass(error) {
        return(error.length === 0 ? '' : 'has-error');
    }
    handleSubmit(e) {
        e.preventDefault();
        //alert(JSON.stringify(this.state));
        //alert(this.event_enddate)
        if(this.state.member2_position !== '' && this.state.member2_id !== null && this.state.member2_id !== ''  && this.state.member2_id !== undefined) {
            document.getElementById('loading-area').style.display = 'block'; 
            var member2Position,member2Phone;
            if(this.textInputMember2Position.value === '') { member2Position = this.state.member2_position; } else { member2Position = this.textInputMember2Position.value; }
            if(this.textInputMember2Phone.value === '') { member2Phone = this.state.member2_phone; } else { member2Phone = this.textInputMember2Phone.value; }
            
            var datatext ='{"user_position":"'+member2Position+'","user_phone":"'+member2Phone+'","user_docid":"'+this.state.member2_id+'","form_id":"form-edit-profile"}';
            var urlFetch = global.ctconfig.urlLink+"/memberedit";
            
            var data = JSON.parse(datatext); 
            
            fetch(urlFetch, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }, body : JSON.stringify(data)
            }).then(res => {
                if(res.status === 200) 
                    return res.json() 
            }).then( resJson => {
                console.log(resJson);
                console.log(resJson.status);
                document.getElementById('loading-area').style.display = 'none'; 
                if(resJson.status === '200') {
                    localStorage.setItem('uposition', member2Position);
                    window.location.href=global.ctconfig.urlHost+'/ctmyprofile';
                } else {
                    console.log('Something happened wrong');
                    console.log(resJson);
                }
            }).catch(err => { 
                document.getElementById('loading-area').style.display = 'none'; 
                console.log(err);
            });

        } else {
            var arrfieldName = ['member2_position'];
            var arrvalue = [this.textInputMember2Position.value];
            for(var ck in arrfieldName) {
                this.validateField(arrfieldName[ck], arrvalue[ck]);
            }
        }
    }
    componentDidMount() {
        var memberId = document.getElementById("contentid").innerText;
        var othersContent = JSON.parse(document.getElementById("contentoth").innerText);
        this.setState({
            member2_id: memberId,
            member2_email: othersContent.member_email,
            member2_position: othersContent.member_position,
            member2_name: othersContent.member_fullname,
            member2_phone: othersContent.member_phone,
            formErrors: {member2_position: ''},
            member2positionValid: false,
            formValid: false,
            input_disabled: false
        }); 
    }

    render() {
        return (
            <div className="main-container">
                <div className="mgT30">
                <form onSubmit={this.handleSubmit}>
                    <div>
                        <input type="hidden" name="member2_id" value={this.state.member2_id} />
                    </div>
                    <div className="row-field mgT20">
                        <label className="w30p textMiddle" htmlFor="member2_name">Name</label>
                        <div className="w70p">{this.state.member2_name}</div>
                    </div>
                    
                    <div className="row-field mgT20">
                        <label className="w30p textMiddle" htmlFor="member2_email">Email Address</label>
                        <div className="w70p">{this.state.member2_email} </div>
                    </div>
                    
                    <div className="row-field mgT20">
                        <label className="w30p textMiddle" htmlFor="member2_position">Position</label>
                        <div className="w70p">
                            <input type="text" className="form-control" name="member2_position"
                                placeholder={this.state.member2_position}
                                value={this.state.member2_position}
                                onChange={this.handleUserInput}  
                                ref={(input) => this.textInputMember2Position = input}
                                disabled={this.state.input_disabled}/>
                            <div className="mgT05">
                                { <FormErrors formErrors={this.state.formErrors.member2_position}/>  }
                            </div>
                        </div>
                    </div>
                    
                    <div className="row-field mgT20">
                        <label className="w30p textMiddle" htmlFor="member2_phone">Phone Number</label>
                        <div className="w70p">
                            <input type="text" className="form-control" name="member2_phone"
                                placeholder={this.state.member2_phone}
                                value={this.state.member2_phone}
                                onChange={this.handleUserInput}  
                                ref={(input) => this.textInputMember2Phone = input}
                                disabled={this.state.input_disabled}/>
                            
                        </div>
                    </div>
                    
                    
                    
                    <div className="row-field mgT30 mgB30">
                        <div className="w100p"><button id="submit-button"  type="submit" className="w100p ct-submitButton">Submit</button></div>
                    </div>
                </form>
                </div>
                <div id="loading-area" className="divHide loading"></div> 
            </div>
          );
        }
     }
      
export default CTProfileForm;
