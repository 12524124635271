import React, { Component } from 'react';
import Moment from 'moment';
// import ImgLogo from './img/certificate-logo-blue.png';

export default class Certificate extends Component {

  render() {
    var day = Moment().format('D');
    var month = Moment().format('MMMM');
    var year = Moment().format('Y');
    var date = day + " " + month + " " + year;

//  var backgroundImg = this.props.background ? this.props.background : process.env.PUBLIC_URL + "/img/certificate.jpg";
    var backgroundImg = process.env.PUBLIC_URL + "/img/certificate.jpg";
    var memberNamez = localStorage.getItem('name');
    var cssNamez = "certificate-name";
    
    if(this.props.background !== undefined && this.props.background !== "") { backgroundImg = this.props.background; } 
    if(this.props.name !== undefined && this.props.name !== "") { memberNamez = this.props.name; }

    if(localStorage.getItem('mcomp') !== undefined && localStorage.getItem('mcomp') !== "" && localStorage.getItem('mcomp') === "aegis.com.bn") {
      backgroundImg = process.env.PUBLIC_URL + "/img/bg-certificate-aegis2.png"; 
      cssNamez = "certificate-name-o";
    }


    // if (day > 3 && day < 21) {
    //   date = day + 'th of ' + month + " " + year;
    // }
    // switch (day) {
    //   case 1:  date = day + 'st of ' + month + " " + year; break;
    //   case 2:  date = day + 'nd of ' + month + " " + year; break;
    //   case 3:  date = day + 'rd of ' + month + " " + year; break;
    //   default: date = day + 'th of ' + month + " " + year; break;
    // }
    return (
      <div className="certificate" id="certificate">
        {/* {
          this.props.background !== undefined ? (
            <div className="certificate-bg" style={{backgroundImage: "url('" + this.props.background + "')"}}></div>
          ) : (
            <div className="certificate-bg" style={{backgroundImage: "url('" + process.env.PUBLIC_URL + "/img/certificate.jpg')"}}></div>
          )
        } */}
        <div className="certificate-bg" style={{backgroundImage: "url('" + backgroundImg + "')"}}>
          <div className="certificate-detail">
            <p className="certificate-text">Certificate of Completion</p>
            <p className="certificate-course">{this.props.title}</p>
            <p className={cssNamez}>{memberNamez}</p>
          </div>
          <div className="certificate-date">
            <p>For course completed on the <strong>{date}</strong>, conducted by <strong>Novaio Academy</strong></p>
          </div>
        </div>
      </div>
    );
  }
}