import React, { Component } from 'react';
import '../ctinc/ct-Style-2.css';
import '../ctinc/ct-Style.css';
import '../ctinc/ct-Global';
import Alert from '@material-ui/lab/Alert';
import defMemberPicture from '../../assets/pic-profile.png';
import DatePicker from 'react-date-picker';

const authKey = global.ctconfig.authBearerKey + "##" + global.ctconfig.kindcode[13];

class CTUserCompanyEdit extends Component {
  constructor (props) {
    super(props);
    this.state = {
      stPopTitle: '',
      member_name: '',
      member_email: '',
      member_picture: '',
      user_level: 'User',
      user_active: 'Active',
      expiry_date: new Date()
    }
		this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    var getContent = JSON.parse(document.getElementById("contentoth").innerText);
    fetch(global.ctconfig.urlLink + "/traininguserview/" + getContent.member_id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey,
      }
    }).then(response => {
      if(response.status === 200) {
        return response.json();
      } else {
        console.log('Something happened wrong');
      }
    }).then(resJson => {
      if(resJson.status === '200') {
        document.getElementById('loading-area').style.display = 'none';
        document.getElementById("errormsg").style.display = 'none';
        var mainContent = resJson.values[0].content[0].maincontent;
        this.setState({
          company_id: getContent.company_id,
          member_id: getContent.member_id,
          member_name: mainContent.memberName,
          member_email: mainContent.memberEmail,
          member_picture: mainContent.memberPicture ? mainContent.memberPicture : defMemberPicture,
          user_level: mainContent.userLevel,
          user_active: mainContent.flagActive === true ? 'Active' : 'Inactive',
          expiry_date: mainContent.expiredSubscribeMember ? (new Date(mainContent.expiredSubscribeMember._seconds * 1000)) : null
        });
      } else {
        console.log('Something happened wrong');
      }
    }).catch(err => { 
      document.getElementById('loading-area').style.display = 'none'; 
    })
  }

  handleSubmit(e) {
    e.preventDefault();
    if((this.state.member_id !== '') && (this.state.member_id !== null) && (this.state.member_id !== undefined)) {
      document.getElementById('loading-area').style.display = 'block';
      var user_active = this.state.user_active === 'Active' ? true : false;
      var datatext ='{"member_docid":"' + this.state.member_id + '","user_level":"' + this.state.user_level + '","flag_active":"' + user_active + '","expired_subscribe_member":"' + this.state.expiry_date + '","form_id":"user-access"}';
      var urlFetch = global.ctconfig.urlLink + "/traininguseredit";          
      var data = JSON.parse(datatext);           
      fetch(urlFetch, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': authKey,
        }, body : JSON.stringify(data)
      }).then(res => {
        if(res.status === 200) {
          return res.json();
        }
      }).then(resJson => {
        console.log(resJson);
        document.getElementById('loading-area').style.display = 'none'; 
        if(resJson.status === '200') {
          window.location.href = global.ctconfig.urlHost + '/ctviewcompanytraining/' + this.state.company_id;
        } else {
          console.log('Something happened wrong');
          console.log(resJson);
        }
      }).catch(err => { 
        console.log(err);
      });
    } else {
      console.log('Something happened wrong');
    }
  }

  handleDateInput = date => {
    this.setState({
      expiry_date: date
    });
  }
  
  handleUserSelect = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  render() {
    return (
      <div className="main-container">
        <div className="mgT20">
          <div className="divhide w100p" id="errormsg"><Alert severity="error">{this.state.errmsg}</Alert></div>
          <div className="mgT20 mgL20">
            <div className="content-container card card-body">
              <div id="profile-info" className="col-6">
                <div className="mgB30"><img src={this.state.member_picture} alt="Member Pictures" className="memberPhoto"/></div>
                <div className="row mgT05">
                  <div className="col-12">{this.state.member_name}</div>
                </div>
                <div className="row">
                  <div className="col-12">{this.state.member_email}</div>
                </div>
              </div>
              <div className="mgT30" id="subscribe-area">
                <div id="FormCenter" className="mgT20">
                  <div id="FormCourseInfo">
                    <form onSubmit={this.handleSubmit}>
                      <div className="row-field mgT20">
                        <label className="labelFormL" htmlFor="user_level">Level</label>
                        <div className="w70p">
                          <select name="user_level" className="form-control"
                              value={this.state.user_level} 
                              onChange={this.handleUserSelect}
                              ref={(input) => this.textInputUserRole = input}
                              disabled={this.state.input_disabled}
                          >
                            <option value="Admin" key="level-admin">Admin</option>
                            <option value="User" key="level-user">User</option>
                          </select>
                        </div>
                      </div>
                      <div className="row-field mgT20">
                        <label className="labelFormL" htmlFor="expiry_date">Expiry Date</label>
                        <div className="w70p">
                          <DatePicker
                            onChange={this.handleDateInput}
                            value={this.state.expiry_date}
                            customStyles={{dateInput:{borderWidth: 0}}}
                          />
                        </div>
                      </div>
                      <div className="row-field mgT20">
                        <label className="labelFormL" htmlFor="user_active">Status</label>
                        <div className="w70p">
                          <select name="user_active" className="form-control" 
                              value={this.state.user_active} 
                              onChange={this.handleUserSelect}
                              ref={(input) => this.textInputUserStatus = input}
                              disabled={this.state.input_disabled}
                          >
                            <option value="Active" key="status-active">Active</option>
                            <option value="Inactive" key="status-inactive">Inactive</option>
                          </select>
                        </div>
                      </div>
                      <div className="row-field mgT30 mgB30">
                        <div className="labelFormL"></div>
                        <div className="w70p"><button type="submit" className="w100p ct-submitButton">Submit</button></div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row-field mgT30 mgB30">
          </div>
        </div>
        <div id="loading-area" className="divHide loading"></div> 
      </div>
    );
  }
}
  
export default CTUserCompanyEdit;