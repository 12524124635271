import React, { Component } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import CTLogin from './cptraining/ctpages/ct-login';
import CTDashboard from './cptraining/ctpages/ct-homepage';
import CTMyProfiles from './cptraining/ctpages/ct-myprofile';

import CTCategory from './cptraining/ctpages/ct-category-list';
import CTMember from './cptraining/ctpages/ct-member-list';
import CTMemberForm from './cptraining/ctpages/ct-member-form';
// import CTMemberSetForm from './cptraining/ctpages/ct-member-set-form';
import CTLibrary from './cptraining/ctpages/ct-library-list';
import CTLibraryForm from './cptraining/ctpages/ct-library-form';
import CTLibraryDetailEdit from './cptraining/ctpages/ct-library-detail-edit';
import CTLibraryDetail from './cptraining/ctpages/ct-library-detail';
import CTCourse from './cptraining/ctpages/ct-course-list';
import CTCourseForm from './cptraining/ctpages/ct-course-form';
import CTCourseDetailEdit from './cptraining/ctpages/ct-course-detail-edit';
import CTCourseDetail from './cptraining/ctpages/ct-course-detail';
import CTTutor from './cptraining/ctpages/ct-tutor-list';
import CTTutorForm from './cptraining/ctpages/ct-tutor-form';
import CTTutorDetail from './cptraining/ctpages/ct-tutor-detail';
import CTTutorDetailEdit from './cptraining/ctpages/ct-tutor-detail-edit';
import CTUserTrainings from './cptraining/ctpages/ct-usertraining-list';
import CTUserTrainingsForm from './cptraining/ctpages/ct-usertraining-form';
import CTUserTrainingsDetail from './cptraining/ctpages/ct-usertraining-detail';
import CTCompanyTrainings from './cptraining/ctpages/ct-companytraining-list';
import CTCompanyTrainingsForm from './cptraining/ctpages/ct-companytraining-form';
import CTCompanyTrainingsDetail from './cptraining/ctpages/ct-companytraining-detail';
import CTPayment from './cptraining/ctpages/ct-payment-list';
import CTFeedback from './cptraining/ctpages/ct-feedback-list';
import CTFeedbackForm from './cptraining/ctpages/ct-feedback-form';
import CTFeedbackDetail from './cptraining/ctpages/ct-feedback-detail';

const subfold = "/c";
export default class Index extends Component {
  render() {
    return (
    <>
      { (localStorage.getItem('ctuid') !== null && localStorage.getItem('ctuid') !== "")? 
        <Router>
          <div>
          <Switch>
            <Route exact path={`${subfold}/`}><CTDashboard/></Route>
            <Route path={`${subfold}/ctlibrary`} ><CTLibrary/></Route>
            <Route path={`${subfold}/ctaddlibrary`} ><CTLibraryForm/></Route>
            <Route path={`${subfold}/cteditlibrary`} ><CTLibraryForm/></Route>
            <Route path={`${subfold}/cteditdetaillibrary`} ><CTLibraryDetailEdit/></Route>
            <Route path={`${subfold}/ctviewlibrary`} ><CTLibraryDetail/></Route>
            
            <Route path={`${subfold}/ctcourse`} ><CTCourse/></Route>
            <Route path={`${subfold}/ctaddcourse`} ><CTCourseForm/></Route>
            <Route path={`${subfold}/cteditcourse`} ><CTCourseForm/></Route>
            <Route path={`${subfold}/cteditdetailcourse`} ><CTCourseDetailEdit/></Route>
            <Route path={`${subfold}/ctviewcourse`} ><CTCourseDetail/></Route>
            
            <Route path={`${subfold}/ctcategory`} ><CTCategory/></Route>
            <Route path={`${subfold}/ctcategorylibrary`} ><CTCategory/></Route>
            <Route path={`${subfold}/ctcategorycourse`} ><CTCategory/></Route>
            
            <Route path={`${subfold}/cttutor`} ><CTTutor/></Route>
            <Route path={`${subfold}/ctaddtutor`} ><CTTutorForm/></Route>
            <Route path={`${subfold}/ctedittutor`} ><CTTutorForm/></Route>
            <Route path={`${subfold}/cteditdetailtutor`}><CTTutorDetailEdit/></Route>
            <Route path={`${subfold}/ctviewtutor`} ><CTTutorDetail/></Route>
            
            <Route path={`${subfold}/ctusertraining`} ><CTUserTrainings/></Route>
            <Route path={`${subfold}/ctaddusertraining`} ><CTUserTrainingsForm/></Route>
            <Route path={`${subfold}/ctviewusertraining`} ><CTUserTrainingsDetail/></Route>
            
            <Route path={`${subfold}/ctcompanytraining`} ><CTCompanyTrainings/></Route>
            <Route path={`${subfold}/ctaddcompanytraining`} ><CTCompanyTrainingsForm/></Route>
            <Route path={`${subfold}/cteditcompanytraining`} ><CTCompanyTrainingsForm/></Route>
            <Route path={`${subfold}/ctviewcompanytraining`} ><CTCompanyTrainingsDetail/></Route>
            
            <Route path={`${subfold}/ctpayment`} ><CTPayment/></Route>

            <Route path={`${subfold}/ctfeedback`} ><CTFeedback/></Route>
            <Route path={`${subfold}/ctaddfeedback`} ><CTFeedbackForm/></Route>
            <Route path={`${subfold}/cteditfeedback`}><CTFeedbackForm/></Route>
            <Route path={`${subfold}/ctviewfeedback`} ><CTFeedbackDetail/></Route>
            
            <Route path={`${subfold}/ctmembers`} ><CTMember/></Route>
            <Route path={`${subfold}/ctaddmembers`}><CTMemberForm/></Route>
            <Route path={`${subfold}/cteditmembers`}><CTMemberForm/></Route>
            <Route path={`${subfold}/ctviewmembers`}><CTMemberForm/></Route>
            {/* <Route path={`${subfold}/ctsetmembers`}><CTMemberSetForm/></Route> */}

            <Route path={`${subfold}/ctmyprofile`}><CTMyProfiles/></Route>
            <Route path={`${subfold}/ctlogin`}><CTLogin/></Route> 
          </Switch>
        </div>
        </Router>
      : 
      <Router>
        <div>
        <Switch>
          <Route exact path={`${subfold}/`}><CTLogin/></Route>
          <Route path={`${subfold}/ctlibrary`} ><CTLogin/></Route>
          <Route path={`${subfold}/ctaddlibrary`} ><CTLogin/></Route>
          <Route path={`${subfold}/cteditlibrary`} ><CTLogin/></Route>
          <Route path={`${subfold}/cteditdetaillibrary`} ><CTLogin/></Route>
          <Route path={`${subfold}/ctviewllibrary`} ><CTLogin/></Route>
          
          <Route path={`${subfold}/ctcourse`} ><CTLogin/></Route>
          <Route path={`${subfold}/ctaddcourse`} ><CTLogin/></Route>
          <Route path={`${subfold}/cteditcourse`} ><CTLogin/></Route>
          <Route path={`${subfold}/cteditdetailcourse`} ><CTLogin/></Route>
          <Route path={`${subfold}/ctviewcourse`} ><CTLogin/></Route>
          
          <Route path={`${subfold}/ctcategory`} ><CTLogin/></Route>
          <Route path={`${subfold}/ctcategorylibrary`} ><CTLogin/></Route>
          <Route path={`${subfold}/ctcategorycourse`} ><CTLogin/></Route>
          
          <Route path={`${subfold}/cttutor`} ><CTLogin/></Route>
          <Route path={`${subfold}/ctaddtutor`} ><CTLogin/></Route>
          <Route path={`${subfold}/ctedittutor`} ><CTLogin/></Route>
          <Route path={`${subfold}/cteditdetailtutor`}><CTLogin/></Route>
          <Route path={`${subfold}/ctviewtutor`} ><CTLogin/></Route>
          
          <Route path={`${subfold}/ctusertraining`} ><CTLogin/></Route>
          <Route path={`${subfold}/ctaddusertraining`} ><CTLogin/></Route>
          <Route path={`${subfold}/ctviewusertraining`} ><CTLogin/></Route>
          
          <Route path={`${subfold}/ctcompanytraining`} ><CTLogin/></Route>
          <Route path={`${subfold}/ctaddcompanytraining`} ><CTLogin/></Route>
          <Route path={`${subfold}/cteditcompanytraining`} ><CTLogin/></Route>
          <Route path={`${subfold}/ctviewcompanytraining`} ><CTLogin/></Route>
          
          <Route path={`${subfold}/ctpayment`} ><CTLogin/></Route>

          <Route path={`${subfold}/ctfeedback`} ><CTLogin/></Route>
          <Route path={`${subfold}/ctaddfeedback`} ><CTLogin/></Route>
          <Route path={`${subfold}/cteditfeedback`}><CTLogin/></Route>
          <Route path={`${subfold}/ctviewfeedback`} ><CTLogin/></Route>
          
          <Route path={`${subfold}/ctmembers`} ><CTLogin/></Route>
          <Route path={`${subfold}/ctaddmembers`}><CTLogin/></Route>
          <Route path={`${subfold}/cteditmembers`}><CTLogin/></Route>
          <Route path={`${subfold}/ctviewmembers`}><CTLogin/></Route>
          <Route path={`${subfold}/ctsetmembers`}><CTLogin/></Route>

          <Route path={`${subfold}/ctmyprofile`}><CTLogin/></Route>
          <Route path={`${subfold}/ctlogin`}><CTLogin/></Route> 
        </Switch>
       </div>
      </Router>
      }
    </>
    );
  }
}
