import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Menu from './inc/Menu';
import Footer from './inc/Footer';
import Home from './Home';
import Account from './Account';
import Setting from './Settings';
import CourseBrowse from './CourseBrowse';
import CourseDetail from './CourseDetail';
import Checkout from './Checkout';
import LibraryBrowse from './LibraryBrowse';
import LibraryDetail from './LibraryDetail';
import LibraryEnroll from './LibraryEnroll';
import Subscribe from './Subscribe';
import InvoiceSubscription from './InvoiceSubscription';
import InvoiceCourse from './InvoiceCourse';
import Certificate from '../Certificate';
import About from '../About';
import Help from '../Help';
import Success from '../Success';
import PrivacyPolicy from '../PrivacyPolicy';
import Error403 from '../Error403';
import Error404 from '../Error404';

export default class Index extends Component {
  render() {
    return (
      <Router>
        <Menu/>
        <div className="main">
          <Switch>
            <Route exact path='/'><Home/></Route>
            <Route path='/courses'><CourseBrowse/></Route>
            <Route path='/course/:slug'><CourseDetail/></Route>
            <Route path='/certificate'><Certificate/></Route>
            <Route path='/checkout/:slug'><Checkout/></Route>
            <Route path='/libraries'><LibraryBrowse/></Route>
            <Route path='/library/:slug'><LibraryDetail/></Route>
            <Route path='/enroll/:slug'><LibraryEnroll/></Route>
            <Route path='/subscribe'><Subscribe/></Route>
            <Route path='/invoice/subscription'><InvoiceSubscription/></Route>
            <Route path='/invoice/course'><InvoiceCourse/></Route>
            <Route path='/invoice'><InvoiceSubscription/></Route>
            <Route path='/about'><About/></Route>
            <Route path='/help'><Help/></Route>
            <Route path='/success'><Success/></Route>
            <Route path='/privacy-policy'><PrivacyPolicy/></Route>
            <Route path='/account'><Account/></Route>
            <Route path='/settings'><Setting/></Route>
            <Route path="/na"><Error404/></Route>
            <Route path="/error-403"><Error403/></Route>
            <Route path="*"><Error404/></Route>
          </Switch>
        </div>
        <Footer/>
      </Router>
    );
  }
}