import React, { Component } from 'react';
import Moment from 'moment';
import { GetText, ReplaceText, SearchList } from '../helper';
import { Container, InputGroup, FormControl, Tab, Nav, Card } from 'react-bootstrap';
import { FaSearch, FaArrowRight, FaArrowDown, FaRegCalendarAlt, FaRegClock } from 'react-icons/fa';
import CourseList from './course/List';
import '../config/global';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[10];

export default class CourseBrowse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courseList: [],
      courseFeatured: [],
      courseFeaturedAgenda: [],
      courseCategories: [],
      isLoad: false,
    }
  }

  componentDidMount() {
    var dataText ='{"keyword":"","pageShow":"10","lang":"'+localStorage.getItem('language')+'","form_id":"pubonly"}';
    var dataSend = JSON.parse(dataText);

    fetch(global.config.urlLink+"/courselist", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey
      }, 
      body : JSON.stringify(dataSend)
    })
    .then(response => {
      if (response.status === 200) {
        return response.json();
      } else {
        console.log('Something happened wrong');
      }
    })
    .then(resultJson => {
      var resultDataCourse = [];
      var resultDataCourseFeatured = [];
      var resultDataCourseFeaturedAgenda = [];
      
      var inum = 0;
      if(resultJson.values.length > 0) {
        for (var i in resultJson.values) {
          var content = resultJson.values[i].content;
          content.id = resultJson.values[i].docid;
          if (this.state.courseCategories.indexOf(content.categoryName) < 0) {
            this.state.courseCategories.push(content.categoryName);
          }
          resultDataCourse.push(content);
            
          if (content.isFeatured === "1" && resultDataCourseFeatured.length === 0) {
            resultDataCourseFeatured.push(content);
            resultDataCourseFeaturedAgenda.push(resultJson.values[i].courseagendalist);
          } 
          inum++;
          if(inum === resultJson.values.length) {
            this.setState({
              courseList: resultDataCourse,
              courseFeatured: resultDataCourseFeatured,
              courseFeaturedAgenda: resultDataCourseFeaturedAgenda,
              isLoad: true
            }) 
          }
          
        }
      } else {
        this.setState({
          courseList: resultDataCourse,
          isLoad: true
        });
      }
    });
  }

  render() {
    
    return (
      <div className="browse-course">
        {
          this.state.courseList.length > 0 ? (
            <section>
                  <Container>
                    <h1 className="mb-4">Workshops</h1>
                    <p className="mb-5">{GetText() ? "Temukan berbagai pilihan workshop dan event online kami yang menampilkan banyak pembicara ahli. Workshop kami mencakup berbagai topik, mulai dari kepemimpinan dan kewirausahaan hingga teknologi dan inovasi. Bergabunglah dengan kami dan belajar dari yang terbaik di industri ini, berkanalan dengan profesional lain, dan tetap up-to-date dengan tren dan teknologi terbaru." : "Discover our wide selection of online and in-site workshops and events, featuring more than one expert speakers. Our events cover various topics, from leadership and entrepreneurship to technology and innovation. Join us and learn from the best in the industry, network with other professionals, and stay up-to-date with the latest trends and practices."}</p>
                    <div className="separator mt-0"><div className="icon"><FaArrowDown/></div></div>
                  </Container>
            </section>
          )
          : this.state.isLoad === true? (
            <div className="mt-5 mb-5">
              <div style={{height:"100px"}}></div>
              <div className="d-flex align-items-center justify-content-center">
                <Card className='card-pop'>
                  <Card.Body className="text-center">
                    <h1 className="mb-4" style={{fontSize:"50pt", color:"#DC3545"}}>COMING SOON</h1>
                    <p style={{fontSize:"13pt", color:"#595959"}}>{GetText() ?  `Tetaplah bersama kami. Hal yang menarik akan segera hadir. \nJadilah yang pertama mengetahuinya.` : `Stay tuned. Something awesome is coming soon. \nBe the first to know.` } </p>
                  </Card.Body>
                </Card>
              </div>
              <div style={{height:"200px"}}></div>
            </div>
          )
          : <></>
          
        }
        {
          this.state.courseFeatured.length > 0 ? (
            <section>
              <Container>
                <h3>{GetText() ? "Workshop Unggulan" : "Featured Workshop"}</h3>
                <div className="row py-4 mb-5">
                  <div className="col-md-4 text-center">
                    <img className="mxw h-300 br-8 mb-4" src={this.state.courseFeatured[0].coursePicture} alt={this.state.courseFeatured[0].courseTitle}/>
                  </div>
                  <div className="col-md-8">
                    <a href={'/course/' + this.state.courseFeatured[0].id} className="text-dark">
                      <h4 className="mb-3">{this.state.courseFeatured[0].courseTitle}</h4>
                    </a>
                    <p>{ReplaceText(this.state.courseFeatured[0].courseShortDesc)}</p>
                    <div className="information p-4">
                      <div className="row">
                        <div className="col-md-6">
                          <p>
                            <strong>{GetText() ? "Tanggal" : "Date"}</strong>
                            <br/>
                            <span className="mr-2">
                              <FaRegCalendarAlt className="mr-1"/>
                              {this.state.courseFeatured[0].courseDate ? Moment.unix(this.state.courseFeatured[0].courseDate._seconds).format('D MMMM Y') : ''}
                            </span>
                            <span>
                              <FaRegClock className="mr-1"/>
                              {this.state.courseFeatured[0].courseDate ? Moment.unix(this.state.courseFeatured[0].courseStartTime._seconds).format('HH:mm') : ''}
                            </span>
                          </p>
                          <p>
                            <strong>{GetText() ? "Harga" : "Price"}</strong>
                            <br/>
                            {this.state.courseFeatured[0].courseCurrency === "IDR" ? "Rp " : "$"} 
                            {new Intl.NumberFormat().format(this.state.courseFeatured[0].coursePrice)}
                          </p>
                        </div>
                        <div className="col-md-6">
                          <p>
                            <strong>{GetText() ? "Kategori" : "Category"}</strong>
                            <br/>
                            {this.state.courseFeatured[0].categoryName}
                          </p>
                          <p>
                            <strong>{GetText() ? "Pembicara" : "Trainer"}</strong>
                            <br/>
                            {this.state.courseFeatured[0].tutorName.split(",").join(", ")}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="py-3 text-right">
                      <a href={'/course/' + this.state.courseFeatured[0].id} className="link-more"><strong>{GetText() ? "Selengkapnya" : "View Details"} <FaArrowRight/></strong></a>
                    </div>
                  </div>
                </div>
              </Container>
              <div className="separator mt-0"><div className="icon"><FaArrowDown/></div></div>
            </section>
          ) : ''
        }
        {
          this.state.courseList.length > 0 ? (
            <section>
              <Container>
                <div className="row py-4">
                  <Tab.Container defaultActiveKey="category-all">
                    <div className="col-md-3">
                      <div className="sidebar-tab sticky-top">
                        <h3 className="mb-4">{GetText() ? "Pencarian" : "Search"}</h3>
                        <InputGroup className="mb-5">
                          <FormControl
                            placeholder={GetText() ? "Ketik di sini..." : "Type here..."}
                            aria-label="Course"
                            aria-describedby="search" 
                            id="search"
                            onKeyUp={SearchList}
                         />
                          <InputGroup.Append>
                            <InputGroup.Text><FaSearch/></InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                        <h3 className="mb-4">{GetText() ? "Kategori" : "Category"}</h3>
                        <Nav variant="pills" className="nav-scroll flex-column">
                          <Nav.Item key="all">
                            <Nav.Link eventKey="category-all">{GetText() ? "Semua" : "All"}</Nav.Link>
                          </Nav.Item>
                          {
                            this.state.courseCategories
                            .sort((a, b) => { return (a < b) ? -1 : ((a > b) ? 1 : 0) })
                            .map((courseCategory, i) => {
                              return (
                                <Nav.Item key={i}>
                                  <Nav.Link eventKey={"category-" + i}>{courseCategory}</Nav.Link>
                                </Nav.Item>
                              )
                            })
                          }
                        </Nav>
                      </div>
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-8 bg-light p-5 br-8">
                      <h3 className="mb-4">{GetText() ? "Daftar Workshop" : "Workshop List"}</h3>
                      <Tab.Content>
                        <Tab.Pane eventKey="category-all" key="all">
                          {
                            this.state.courseList
                            .sort((a,b) => b.courseDate._seconds - a.courseDate._seconds)
                            .map((courseItem, i) => {
                              return (
                                <CourseList value={courseItem} key={'option-' + i}/>
                              )
                            })
                          }
                        </Tab.Pane>
                        {
                          this.state.courseCategories
                            .sort((a, b) => { return (a < b) ? -1 : ((a > b) ? 1 : 0) })
                            .map((courseCategory, i) => {
                            return (
                              <Tab.Pane eventKey={"category-" + i} key={i}>
                                {
                                  this.state.courseList
                                  .sort((a,b) => b.courseDate._seconds - a.courseDate._seconds)
                                  .filter(courseItem => courseItem.categoryName === courseCategory)
                                  .map((courseItem, j) => {
                                    return (
                                      <CourseList value={courseItem} key={'course-' + j}/>
                                    )
                                  })
                                }
                              </Tab.Pane>
                            )
                          })
                        }
                      </Tab.Content>
                    </div>
                  </Tab.Container>
                </div>
              </Container>
            </section>
          ) : ''
        }
      </div>
      
    );
  }
}