import React, { Component } from 'react';
import { Container, Accordion, Card } from 'react-bootstrap';
import { FaChevronRight, FaChevronDown } from 'react-icons/fa';
import { ToggleArrow, GetText } from './helper';

export default class Help extends Component {

  render() {
    return (
      <div className="help">
        <Container>
          <h1 className="mb-4">{GetText() ? "Bantuan" : "Help"}</h1>
          <Accordion>
            <Card id="accordion-1">
              <Accordion.Toggle as={Card.Header} eventKey="1" onClick={()=> ToggleArrow(1)}>
                {GetText() ? "Bagaimana cara saya mendaftar di Novaio Academy?" : "How do I enroll in a course on Novaio Academy?"}
                <FaChevronRight className="accordion-toggle toggle-close"/>
                <FaChevronDown className="accordion-toggle toggle-open d-none"/>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1" id="collapse-1">
                <Card.Body>{GetText() ? "Mendaftar di Novaio Academy itu mudah. Cukup telusuri katalog library kami dan pilih pelatihan yang ingin Anda ambil. Klik tombol \"Daftar\", dan ikuti petunjuk di layar untuk menyelesaikan proses pembayaran. Setelah mendaftar, Anda akan memiliki akses langsung ke materi pelatihan." : "Enrolling in a course on Novaio Academy is easy. Simply browse our course catalog and select the course you want to take. Click on the \"Enroll\" button, and follow the on-screen instructions to complete the payment process. Once you've enrolled, you'll have immediate access to the course materials."}</Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card id="accordion-2">
              <Accordion.Toggle as={Card.Header} eventKey="2" onClick={()=> ToggleArrow(2)}>
                {GetText() ? "Dapatkah saya mengakses Novaio Academy dari ponsel saya?" : "Can I access the courses on Novaio Academy on my mobile device?"}
                <FaChevronRight className="accordion-toggle toggle-close"/>
                <FaChevronDown className="accordion-toggle toggle-open d-none"/>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2" id="collapse-2">
                <Card.Body>{GetText() ? "Ya, Anda dapat mengakses semua pelatihan yang kami sediakan di perangkat seluler Anda melalui link \"https://novaio.academy/\" di aplikasi browser. Aplikasi kami tersedia di semua platform browser iOS dan Android dan memberikan pengalaman belajar kapan pun dan di mana pun." : "Yes, you can access all our courses on your mobile by opening \"https://novaio.academy/\" through your browser app. Our app is available on both iOS and Android browsers and provides a seamless learning experience anywhere and anytime."}</Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card id="accordion-3">
              <Accordion.Toggle as={Card.Header} eventKey="3" onClick={()=> ToggleArrow(3)}>
                {GetText() ? "Apakah ada prasyarat khusus untuk mengikuti pelatihan di Novaio Academy?" : "Are there any prerequisites for taking a course on Novaio Academy?"}
                <FaChevronRight className="accordion-toggle toggle-close"/>
                <FaChevronDown className="accordion-toggle toggle-open d-none"/>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3" id="collapse-3">
                <Card.Body>{GetText() ? "Prasyarat untuk pelatihan kami bervariasi tergantung pada pelatihan yang Anda ambil. Beberapa materi memerlukan pengetahuan sebelumnya tentang konsep atau alat tertentu, sementara yang lain dirancang untuk pemula. Anda dapat menemukan prasyarat untuk setiap materi di halaman pelatihan." : "The prerequisites for our courses vary depending on the course. Some courses require prior knowledge of certain concepts or tools, while others are designed for beginners. You can find the prerequisites for each course on the course page."}</Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card id="accordion-4">
              <Accordion.Toggle as={Card.Header} eventKey="4" onClick={()=> ToggleArrow(4)}>
                {GetText() ? "Bagaimana jika saya tidak puas dengan pelatihan yang ada di Novaio Academy?" : "What if I'm not satisfied with a course on Novaio Academy?"}
                <FaChevronRight className="accordion-toggle toggle-close"/>
                <FaChevronDown className="accordion-toggle toggle-open d-none"/>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="4" id="collapse-4">
                <Card.Body>{GetText() ? "Kami menawarkan jaminan uang kembali dalam 30 hari untuk semua pelatihan. Jika Anda tidak puas, Anda dapat meminta pengembalian dana penuh dalam waktu 30 hari setelah mendaftar di Novaio Academy." : "We offer a 30-day money-back guarantee on all our courses. If you're not satisfied with a course, you can request a full refund within 30 days of enrolling in the course."}</Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card id="accordion-5">
              <Accordion.Toggle as={Card.Header} eventKey="5" onClick={()=> ToggleArrow(5)}>
                {GetText() ? "Adakah sertifikat yang saya dapatkan setelah menyelesaikan kursus di Novaio Academy?" : "Can I get a certificate for completing a course on Novaio Academy?"}
                <FaChevronRight className="accordion-toggle toggle-close"/>
                <FaChevronDown className="accordion-toggle toggle-open d-none"/>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="5" id="collapse-5">
                <Card.Body>{GetText() ? "Ya, Anda bisa mendapatkan sertifikat kelulusan untuk setiap pelatihan yang Anda selesaikan di Novaio Academy. Sertifikat akan tersedia untuk diunduh di akun Anda setelah Anda menyelesaikan semua persyaratan pelatihan." : "Yes, you can get a certificate of completion for each course you complete on Novaio Academy. The certificate will be available for download in your account once you complete all the course requirements."}</Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Container>
      </div>
    );
  }
}