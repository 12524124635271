import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';

export default class Block extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: '',
      title: '',
      image: '',
      link: ''
    }
  }

  componentDidMount() {
    var content = this.props.value;
    this.setState({
      id: content.id,
      title: content.libraryTitle,
      image: content.libraryPicture,
      link: '/library/' + content.id
    });
  }

  render() {
    return (
			<a href={this.state.link}>
        <Card className="library-block">
          <Card.Img variant="top" src={this.state.image} alt={"Library " + this.state.title}/>
          <Card.Body className="text-center">
            <Card.Title>{this.state.title}</Card.Title>
          </Card.Body>
        </Card>
      </a>
    )
  }
}