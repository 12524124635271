import React, { Component } from 'react';
import Moment from 'moment';
import { FaRegCalendarAlt, FaRegClock, FaRegFolder } from 'react-icons/fa';

export default class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      title: '',
      category: '',
      date: '',
      time: '',
      image: '',
      language: '',
      currency: '',
      price: '',
      link: ''
    }
  }

  componentDidMount() {
    var content = this.props.value;
    this.setState({
      id: content.id,
      title: content.courseTitle,
      category: content.categoryName,
      date: Moment.unix(content.courseDate._seconds).format('D MMMM Y'),
      time: Moment.unix(content.courseStartTime._seconds).format('HH:mm'),
      image: content.coursePicture,
      language: content.courseLanguage,
      currency: content.courseCurrency,
      price: new Intl.NumberFormat().format(content.coursePrice),
      link: '/course/' + content.id
    });
  }

  render() {
    return (
      <a href={this.state.link}>
        <div className="course-list row">
          <div className="col-md-3">
            <img className="w-100 br-8" src={this.state.image} alt={"Course " + this.state.title}/>
          </div>
          <div className="col-md-9">
            <h5 className="mb-3">{this.state.title}</h5>
            <p>
              <FaRegCalendarAlt className="mr-1"/>
              {this.state.date}
              <FaRegClock className="ml-3 mr-1"/>
              {this.state.time}
              <FaRegFolder className="ml-3 mr-1"/>
              {this.state.category}
            </p>
            <p>
              {this.state.currency === "IDR" ? "Rp " : "$"} 
              {this.state.price}
            </p>
          </div>
        </div>
      </a>
    )
  }
}