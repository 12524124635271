import React, { Component } from 'react';
import { ReplaceText } from '../../helper';
import { FaRegFolder } from 'react-icons/fa';

export default class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      title: '',
      category: '',
      excerpt: '',
      image: '',
      link: ''
    }
  }

  componentDidMount() {
    var content = this.props.value;
    this.setState({
      id: content.id,
      image: content.libraryPicture,
      title: content.libraryTitle,
      category: content.categoryName,
      excerpt: ReplaceText(content.libraryShortDesc),
      link: '/library/' + content.id
    });
  }
  
  render() {    
    return (
			<a href={this.state.link}>
        <div className="library-list row">
          <div className="col-md-3">
            <img className="w-100" src={this.state.image} alt={"Library " + this.state.title}/>
          </div>
          <div className="col-md-9">
            <h5 className="mb-3">{this.state.title}</h5>
            <p>
              <FaRegFolder className="mr-1"/>
              {this.state.category}
            </p>
            <p className="clamp-3">{this.state.excerpt}</p>
          </div>
        </div>
      </a>
    )
  }
}