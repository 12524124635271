import React, { Component } from 'react';
import Moment from 'moment';
import { Container } from 'react-bootstrap';
import './config/global';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[13];

export default class InvoiceSubscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      subdoc_id: '',
      order_id: '',
      order_date: '',
      order_type: '',
      amount: '',
      payment_status: '',
      payment_method: '',
      payment_currency: '',
      payment_account: '',
      payment_date: '',
      confirmation_date: '',
      confirmation_remarks: '',
      confirmation_photo: null,
      input_date: '',
      input_remarks: '',
      input_photo: null,
      payment_voucher_code: '',
      payment_voucher_amount: 0,
      grand_total: 0,
      expired_date: '',
      modal_show: false,
      form_error: []
    }
  }

  componentDidMount() {
    var url = window.location.href;
    var order_id = url.substring(url.lastIndexOf('/') + 1);

    var datatext ='{"keyword":"","pageShow":"10"}';
    var data = JSON.parse(datatext); 
    fetch(global.config.urlLink + "/traininguserlist", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey,
      }, body : JSON.stringify(data)
    }).then(response => {
      if(response.status === 200) {
        return response.json()
      } else {
        console.log('Something happened wrong');
      }
    }).then(resJson => {
      if (resJson.values.length > 0) {
        for (var i = 0; i < resJson.values.length; i++) {
          fetch(global.config.urlLink + "/traininguserview/" + resJson.values[i].docid, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey
            }
          })
          .then(response => {
            if(response.status === 200) {
              return response.json();
            } else {
              console.log('Something happened wrong');
            }
          })
          .then(resultJson => {
            var member = resultJson.values[0].content[0].maincontent;
            var subscriptions = resultJson.values[0].scontent;
            subscriptions.forEach((subscription) => {
              if (subscription.subcontent.orderId === order_id) {
                var content = subscription.subcontent;
                var currency = content.paymentCurrency ? content.paymentCurrency : "IDR";
                this.setState({
                  name: member.memberName,
                  email: member.memberEmail,
                  subdoc_id: subscription.subdocid,
                  order_id: content.orderId,
                  order_date: Moment.unix(content.requestSubscribeDate._seconds).format('D MMMM Y'),
                  order_type: content.subscribePlan,
                  amount: content.subscribePrice,
                  payment_status: content.subscribeStatus,
                  payment_method: content.paymentMethod,
                  payment_info: content.paymentInfo,
                  payment_currency: currency === "IDR" ? "Rp " : "$",
                  payment_account: content.paymentInfo.replace('<br/>', ' '),
                  payment_date: content.paymentReceivedDate && Moment.unix(content.paymentReceivedDate._seconds).format('D MMMM Y'),
                  confirmation_date: content.paymentConfirmationDate && Moment.unix(content.paymentConfirmationDate._seconds).format('D MMMM Y'),
                  confirmation_remarks: content.paymentConfirmationRemarks ? content.paymentConfirmationRemarks : "",
                  confirmation_photo: content.paymentConfirmationPhoto ? content.paymentConfirmationPhoto : "",
                  payment_voucher_code: content.subscribeVoucherCode,
                  payment_voucher_amount: content.subscribevoucheramount,
                  grand_total: content.subscribeGTotal,
                  expired_date: content.dateEnd ? Moment.unix(content.dateEnd._seconds).format('D MMMM Y') : "",
                });
              }
            });
          });
        }
      }
    });
  }

  render() {
    return (
      <div className="invoice">
        <Container>
          {
            this.state.order_id !== '' ? (
              <div className="row visible-print" id="invoice-ready">
                <div className="col-md-12">
                  <div className="card card-invoice">
                    <div className="card-header">
                      <img className="my-5" src={process.env.PUBLIC_URL + '/img/logo-novaio.png'} width={200}/>
                      <div className="row mb-5">
                        <div className="col-md-5">
                          <h1 className="mb-5">Invoice</h1>
                          <table className="w-100">
                            <tbody>
                              <tr>
                                <td><strong>Billed to:</strong></td>
                              </tr>
                              <tr>
                                <td>{this.state.name}</td>
                              </tr>
                              <tr>
                                <td>{this.state.email}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="col-md-2">&nbsp;</div>
                        <div className="col-md-5 align-self-end">
                          <table className="w-100">
                            <tbody className="table-right">
                              <tr>
                                <td><strong>Order Date</strong></td>
                                <td align="right">{this.state.order_date}</td>
                              </tr>
                              <tr>
                                <td><strong>Order ID</strong></td>
                                <td align="right">{this.state.order_id}</td>
                              </tr>
                              <tr>
                                <td><strong>Payment Method</strong></td>
                                <td align="right">{this.state.payment_method}</td>
                              </tr>
                              <tr>
                                <td><strong>Payment Status</strong></td>
                                <td align="right">
                                  <strong>
                                    {
                                      this.state.payment_status === 'Cancel' ? 'CANCELLED' : (
                                        this.state.payment_date ? 'PAID' : 'UNPAID'
                                      )
                                    }
                                  </strong>
                                </td>
                              </tr>
                            </tbody>                    
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <table className="w-100">
                        <thead>
                          <tr>
                            <th width="60%"><strong>Description</strong></th>
                            <th width="10%" className="text-center"><strong>Quantity</strong></th>
                            <th width="30%" className="text-right"><strong>Amount</strong></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <p>
                                {`${this.state.order_type} Membership Subscription`}
                                {
                                  (this.state.expired_date !== "" && this.state.expired_date !== null && this.state.expired_date !== undefined) &&
                                  <small><strong><br/>{`Expired on ${this.state.expired_date}`}</strong></small>
                                }
                              </p>
                            </td>
                            <td align="center">1</td>
                            <td align="right">{this.state.payment_currency + new Intl.NumberFormat().format(this.state.amount)}</td>
                          </tr>
                        </tbody>
                        <tfoot className="card-footer">
                          <tr>
                            <td align="right" colSpan="2">Subtotal</td>
                            <td align="right">{this.state.payment_currency + new Intl.NumberFormat().format(this.state.amount)}</td>
                          </tr>
                          {
                            this.state.payment_voucher_amount > 0 && (
                              <tr>
                                <td align="right" colSpan="2">Voucher</td>
                                <td align="right">({this.state.payment_currency + new Intl.NumberFormat().format(this.state.payment_voucher_amount)})</td>
                              </tr>
                            )
                          }
                          <tr>
                            <td>&nbsp;</td>
                            <td className="total" align="right"><strong>Total</strong></td>
                            <td className="total" align="right"><strong>{this.state.payment_currency + new Intl.NumberFormat().format(this.state.grand_total)}</strong></td>
                          </tr>
                        </tfoot>
                      </table>
                      <div className="row mt-5">
                        <div className="col-md-6">
                          <p>This is computer generated invoice, no signature required</p>
                        </div>
                        <div className="col-md-6 text-right">
                          {
                            this.state.payment_date && (
                              <p>
                                Last updated on {this.state.payment_date}
                              </p>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-md-12">
                  <h1 className="mb-4 pmt-4">Invoice does not exist</h1>
                  <p></p>
                  <div className="mt-4">
                    <p>We're sorry, but it appears the invoice is not found</p>
                  </div>
                </div>
              </div>
            )
          }
        </Container>
      </div>
    );
  }
}