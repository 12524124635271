import React, { Component } from 'react';
import axios from 'axios';
import '../ctinc/ct-Style-2.css';
import '../ctinc/ct-Global';
import { FormErrors } from '../ctinc/error-form';
import CTHeader from './ct-fragmentheader';
import CTSidemenu from './ct-fragmentsidemenu';
import CTNA from './ct-fragmentna';
import DatePicker from 'react-date-picker';

const authKey = global.ctconfig.authBearerKey+"##"+global.ctconfig.kindcode[45];

class CTCompanyTrainingsForm extends Component {
  constructor (props) {
    super(props);
    this.state = {
      stTitle: '',
      company_docid: '',
      company_name: '',
      company_domain: '',
      company_logo: '',
      company_logo_shape: '',
      expiry_date: new Date(),
      new_expiry: false,
      selectedFileDocument: null,
      formErrors: {company_name: '', company_domain: '', expiry_date: '', selectedFileDocument:'', company_logo_shape: ''},
      companynameValid: false,
      companydomainValid: false,
      expirydateValid: false,
      selectedFileDocumentValid: false,
      companylogoshapeValid: false,
      formValid: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.submitData = this.submitData.bind(this);
  }

  componentDidMount() {
    document.getElementById('loading-area').style.display = 'block'; 
    document.getElementById('loading-area').style.display = 'none';
    var arrString = window.location.pathname;
    var arrUrl = [];
    arrUrl = arrString.split('/');
    var typelink = arrUrl[2];
    var docid = arrUrl[3];
    if(typelink === 'cteditcompanytraining') {
      if(docid !== '') { 
        fetch(global.ctconfig.urlLink+"/trainingcompanyview/"+docid, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
          }
        }).then(res => {
          if(res.status === 200) return res.json() 
        }).then( resJson => {
          if(resJson.status === '200') {
            this.setState({
              stTitle: 'Edit Company',
              company_docid: resJson.values[0].docid,
              company_name: resJson.values[0].content[0].maincontent.companyName,
              company_domain: resJson.values[0].content[0].maincontent.companyDomain,
              company_logo: resJson.values[0].content[0].maincontent.companyLogo,
              company_logo_shape: resJson.values[0].content[0].maincontent.companyLogoShape,
              expiry_date: new Date(resJson.values[0].content[0].maincontent.expiredSubscription._seconds * 1000),
            });
          } else {
            console.log('Something wrong happened');
          }
        }).catch(err => err);
      }
    } else if(typelink === 'ctaddcompanytraining') {
      this.setState( 
        prevState => {
          return {
            ...prevState.stTitle, stTitle: 'New Company'
          }
        }
      );
    }    
  }
  
  handleBack = (id) => {
    window.location.href = global.ctconfig.urlHost + '/ctcompanytraining';
  }
  
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value
    }, () => { this.validateField(name, value)});
  }

  handleDateInput = date => {
    var new_expiry = false;
    if (date !== this.state.expiry_date) {
      new_expiry = true;
    }
    this.setState({
      expiry_date: date,
      new_expiry: new_expiry
    });
  }
  handleUserSelect = (e) => {
    const name = e.target.name; 
    const value = e.target.value;
    this.setState(prevState => {
        return {  
            ...prevState[name], [name]: value
        }
    }, () => { 
        this.validateField(name, value)
    });
  }

  onChangeFileHandler = (e) => {
    if(e.target.files[0] !== undefined) {
      var inputType = e.target.files[0].type;
      var inputValue = e.target.files[0];
      let fieldValidationErrors = this.state.formErrors;
      let selectedFileValid = false;
      if(inputType.indexOf('pdf') > -1 || inputType.indexOf('video') > -1|| inputType.indexOf('image') > -1) {
        selectedFileValid = true;
      } else {
        selectedFileValid = false;
      }
      fieldValidationErrors.selectedFileDocument = selectedFileValid ? '' : 'please upload image only';        
      this.setState({
        selectedFileDocument: inputValue,
        formErrors: fieldValidationErrors,
        selectedFileDocumentValid: selectedFileValid
      }, this.validateForm);
    }
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let companynameValid = this.state.companynameValid;
    let companydomainValid = this.state.companydomainValid;
    let expirydateValid = this.state.expirydateValid;
    let selectedFileDocumentValid = this.state.selectedFileDocumentValid;
    let companylogoshapeValid = this.state.companylogoshapeValid;
    
    switch(fieldName) {
      case 'company_name': 
      console.log("company name");
      console.log(value.trim());
        companynameValid = value.trim().length > 0;
        fieldValidationErrors.company_name = companynameValid ? '' : 'company name need to be filled';
        break;
      case 'company_domain': 
      console.log("company domain");
      console.log(value.trim());
        companydomainValid = value.trim().length > 0;
        fieldValidationErrors.company_domain = companydomainValid ? '' : 'company domain need to be filled';
        break;
      case 'company_logo_shape': 
      console.log("validate");
      console.log(value.trim());
        if(this.state.selectedFileDocument !== "" && this.state.selectedFileDocument !== null) {
          companylogoshapeValid = value.trim().length > 0;
          fieldValidationErrors.company_logo_shape = companylogoshapeValid ? '' : 'shape of company logo need to be filled';
        }
        break;
      default:
        break;
    }    
    this.setState({
      formErrors: fieldValidationErrors,
      companynameValid: companynameValid,
      companydomainValid: companydomainValid,
      expirydateValid: expirydateValid,
      selectedFileDocumentValid: selectedFileDocumentValid,
      companylogoshapeValid: companylogoshapeValid
    }, this.validateForm);
  }

  validateForm() {
    this.setState({
      formValid: this.state.companynameValid && this.state.companydomainValid && this.state.expirydateValid && this.state.selectedFileDocumentValid && this.state.companylogoshapeValid 
    });
  }
  
  handleSubmit(e) {
    e.preventDefault();
    if(this.state.company_name !== '' && this.state.company_domain !== '' && this.state.expiry_date !== '') {
      var selectedFileValue = this.state.selectedFileDocument;
      if(selectedFileValue !== null && ((selectedFileValue.type.indexOf('pdf') > -1 || selectedFileValue.type.indexOf('video') > -1|| selectedFileValue.type.indexOf('image') > -1))) { 
        const data = new FormData() 
        data.append('file', selectedFileValue);
        data.append('docid', this.state.company_docid);
        data.append('source', 'company');
        axios.post(global.ctconfig.urlLink+"/uploadfile",
        data, { 
          headers: { 
            'content-type': 'multipart/form-data',
            'Authorization': authKey
          }
        }).then(res => {
          if(res.data.status === "200") {
            var linkfile = res.data.values.linkfile1.replace('https=', 'https:');
            this.submitData(this, linkfile);
          } else {
            console.log('Something happened error');
          }
        }).catch(err => { 
          console.log(err);
        });
      } else {
        this.submitData(this,"");
      }
    } else {
      console.log("err");
      var arrfieldName = [
        'company_name',
        'company_domain',
        'expiry_date',
        'company_logo_shape',
        'selectedFileDocument'
      ];
      var arrvalue = [
        this.state.company_name,
        this.state.company_domain,
        this.state.expiry_date,
        this.state.company_logo_shape,
        this.state.selectedFileDocument
      ];
      for(var ck in arrfieldName) {
        console.log(arrfieldName[ck]);
        console.log(arrvalue[ck]);
        this.validateField(arrfieldName[ck], arrvalue[ck]);
      }
    }
  }

  submitData(datasend,imgurl) {
    var urlFetch, datatext;
    var companyLogo;
    document.getElementById('loading-area').style.display = 'block'; 
    if(imgurl !== '' && imgurl !== undefined) { companyLogo = imgurl; } else { companyLogo = this.state.company_logo; }
    if(this.state.company_docid !== null && this.state.company_docid !== ''  && this.state.company_docid !== undefined) {  
      if(this.state.new_expiry === true) {
        datatext ='{"company_docid":"'+this.state.company_docid+'","company_name":"'+this.state.company_name+'","company_domain":"'+this.state.company_domain+'","expiry_date":"'+this.state.expiry_date+'","company_logo":"'+companyLogo+'","company_logo_shape":"'+this.state.company_logo_shape+'","form_id":"form-update-all"}';
      } else {
        datatext ='{"company_docid":"'+this.state.company_docid+'","company_name":"'+this.state.company_name+'","company_domain":"'+this.state.company_domain+'","expiry_date":"'+this.state.expiry_date+'","company_logo":"'+companyLogo+'","company_logo_shape":"'+this.state.company_logo_shape+'","form_id":"form-edit"}';
      }
      urlFetch = global.ctconfig.urlLink+"/trainingcompanyedit";
    } else {
      datatext ='{"company_name":"'+this.state.company_name+'","company_domain":"'+this.state.company_domain+'","expiry_date":"'+this.state.expiry_date+'","company_logo":"'+companyLogo+'","company_logo_shape":"'+this.state.company_logo_shape+'","form_id":"form-add"}';
      urlFetch = global.ctconfig.urlLink+"/trainingcompanyadd";
    }
    var data = JSON.parse(datatext);    
    fetch(urlFetch, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey,
      }, body : JSON.stringify(data)
    }).then(res => {
      if(res.status === 200) return res.json() 
    }).then( resJson => {
      console.log(resJson);
      if(resJson.status === '200') {
        var docId = resJson.values;
        window.location.href=global.ctconfig.urlHost+'/ctviewcompanytraining/'+docId;
      } else {
        console.log('Something happened wrong');
      }
      document.getElementById('loading-area').style.display = 'none'; 
      if(resJson.status !== '200') {
        console.log('Something happened wrong');
      }
    }).catch(err => { 
      document.getElementById('loading-area').style.display = 'none'; 
    });
  }

  render() {
    return (
      <div className="main-container">
        <div className="ct-header-main"><CTHeader/></div>
        <div className="flex-container">
          <div className="sidebar-main"><CTSidemenu/></div>
          <div className="main-content">
            <div className="ct-stTitle">Companies</div>
            {
              ((localStorage.getItem('ctulevel') === global.ctconfig.userlevel[0]) || (JSON.parse(localStorage.getItem('cturole'))[2] === "ON") || (JSON.parse(localStorage.getItem('cturole'))[3] === "ON") || (JSON.parse(localStorage.getItem('cturole'))[4] === "ON")) ? (
                <div>
                  <div className="stIndiTitle">Companies { this.state.stTitle !== '' ? '> ' + this.state.stTitle : '' }</div>
                  <div className="mgT30">
                    <div className="content-container card card-body">
                      <div className="flex-container">
                        <div className="st2Title w70p">{ this.state.stTitle.includes("New")? 'Add ' : '' } {this.state.stTitle}</div>
                        <div className="divRight w30p"><div><button className="backButton" onClick={this.handleBack.bind(this, this.state.company_docid)}>&#9664; back</button></div></div>
                      </div>
                      <div id="FormCenter" className="mgT20">
                        <div id="FormCourseInfo">
                          <form onSubmit={this.handleSubmit}>
                            <div className="flex-container">
                              <input type="hidden" required name="company_docid" value={this.state.company_docid}/>
                            </div>
                            <div className="row-field mgT20">
                              <label className="labelFormL" htmlFor="company_name">Name</label>
                              <div className="w70p">
                                <input type="text" className="form-control" name="company_name"
                                  placeholder={this.state.company_name}
                                  value={this.state.company_name}
                                  onChange={this.handleUserInput}  
                                  ref={(input) => this.textInputCompanyName = input}/>
                                <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.company_name}/>  }</div>
                              </div>
                            </div>
                            <div className="row-field mgT20">
                              <label className="labelFormL" htmlFor="company_domain">Domain</label>
                              <div className="w70p">
                                <input type="text" className="form-control" name="company_domain"
                                  placeholder={this.state.company_domain}
                                  value={this.state.company_domain}
                                  onChange={this.handleUserInput}
                                  ref={(input) => this.textInputCompanyDomain = input}/>
                                <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.company_domain}/>  }</div>
                              </div>
                            </div>
                            <div className="row-field mgT20">
                              <label className="labelFormL" htmlFor="expiry_date">Expiry Date</label>
                              <div className="w70p">
                                <DatePicker
                                  onChange={this.handleDateInput}
                                  value={this.state.expiry_date}
                                  customStyles={{dateInput:{borderWidth: 0}}}
                                  />
                                <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.expiry_date}/>  }</div>
                              </div>
                            </div>
                            <div>
                              {
                                (this.state.company_logo !== "") && (
                                  <div className="row-field mgT20">
                                    <div className="labelFormL"></div>
                                    <div className="w70p"><img width="150" src={this.state.company_logo} alt=""/></div>
                                  </div>
                                )    
                              }
                            </div>
                            <div className="row-field mgT20">
                              <label className="labelFormL" htmlFor="company_logo">Company Logo</label>
                              <div className="w70p">
                                <input id="fileDocument" type="file" onChange={this.onChangeFileHandler}/>
                                <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.selectedFileDocument}/>  }</div>
                                <div className="mgT10 warningText">Please upload file in jpg, jpeg, png format.</div>
                              </div>
                            </div>
                            <div className="row-field mgT20">
                              <label className="labelFormL" htmlFor="company_domain">Logo will be shown in </label>
                              <div className="w70p">
                                <div className="flex-container">
                                  <div>
                                    <select name="company_logo_shape" className="form-control" 
                                        value={this.state.company_logo_shape} 
                                        onChange={this.handleUserSelect}
                                        ref={(input) => this.textInputCompanyLogoShape = input}
                                    >
                                        <option value="" key="">-- please select --</option>
                                        <option value="round" key="1">Round</option>
                                        <option value="rectangle" key="2">Rectangle</option>
                                    </select> 
                                    <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.company_logo_shape}/>  }</div>
                                  </div>
                                  <div className="mgL20 labelFormL">form</div>
                                </div>
                                
                              </div>
                            </div>
                            
                            <div className="row-field mgT30 mgB30">
                              <div className="labelFormL"></div>
                              <div className="w70p"><button type="submit" className="w100p ct-submitButton">Submit</button></div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>  
                </div>
              ) : (
                <div><CTNA/></div>
              )
            }
          </div>
        </div>
        <div id="loading-area" className="divHide loading"></div> 
      </div>
    );
  }
}
 
export default CTCompanyTrainingsForm;